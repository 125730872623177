import { Flex } from 'antd'
import InputNumber from 'antd/es/input-number'
import { defaultBudgets } from 'constants/rewards'
import { Pane, Tab, Tablist, Text, toaster } from 'evergreen-ui'
import { isNaN } from 'lodash-es'
import { useState } from 'react'

export function SelectAmount({
  amount,
  onAmountChange,
  amountOptions = defaultBudgets,
  showCustomInput = true,
  maxCustomBudget,
  size = 16,
}: {
  amount: number
  onAmountChange: (newBudget: number) => void
  amountOptions?: number[]
  showCustomInput?: boolean
  maxCustomBudget?: number
  size?: number
}) {
  const [customBudget, setCustomBudget] = useState<number | undefined>(
    !amountOptions.includes(amount) ? amount : undefined
  )
  const handleCustomBudgetChange = (newBudget: number | null) => {
    // Ant design sets value to null if the input field was cleared or invalid
    if (!newBudget) {
      onAmountChange(amountOptions[0])
    } else {
      onAmountChange(newBudget)
    }
  }

  // If 3 or less budgets, form looks better with noWrap
  const shouldWrap = amountOptions.length > 3

  return (
    <Pane
      display="flex"
      justifyContent="space-between"
      gap={32}
      flexDirection="column"
    >
      <Tablist
        flex={1}
        display="flex"
        alignItems="center"
        flexWrap={shouldWrap ? 'wrap' : 'nowrap'}
        gap={16}
        maxWidth={720}
      >
        {amountOptions.map(num => (
          <Tab
            key={num}
            id={`${num}`}
            onSelect={() => {
              onAmountChange(Math.round(num))

              setCustomBudget(undefined)
            }}
            isSelected={num === amount}
            whiteSpace="nowrap"
            size={size === 16 ? 400 : 500}
            padding={size}
          >
            $ {num / 100}
          </Tab>
        ))}
      </Tablist>
      {showCustomInput && (
        <Flex align="center" gap={16}>
          <InputNumber
            size="large"
            style={{ minWidth: 88 }}
            addonBefore="$"
            placeholder="Custom"
            value={customBudget ? customBudget / 100 : undefined}
            min={1}
            onBlur={e => {
              const newBudget = Number(e.target.value)
              if (!newBudget || isNaN(newBudget)) {
                setCustomBudget(undefined)
                return
              }

              // Don't allow more than two decimals places in the custom budget field
              const roundedAmount = Math.round(newBudget * 100)

              if (maxCustomBudget && roundedAmount > maxCustomBudget) {
                setCustomBudget(undefined)
                toaster.warning(
                  `Budget cannot exceed $${maxCustomBudget / 100}`
                )
                return
              }
              setCustomBudget(roundedAmount)
              handleCustomBudgetChange(roundedAmount)
            }}
          />
          {maxCustomBudget && (
            <Text color="muted">{`$${maxCustomBudget / 100} maximum`}</Text>
          )}
        </Flex>
      )}
    </Pane>
  )
}
