import { Flex } from 'antd'
import { Loader, PerkEmptyState, ProductsGrid } from 'components'
import { PRODUCT } from 'constants/productVariants'
import { Pane, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useInfiniteProductVariants from 'hooks/productVariants/use-infinite-product-variants'
import { unionBy } from 'lodash-es'
import { Selectable } from 'types'
import { makePlural } from 'utils'

export function AlgoliaProductsGrid({
  onCardClick,
  withProductAmounts = false,
  withAmountsInUsd = false,
  withShipping = false,
  addedVariants,
  onCardAddButtonClick,
  selectedRibbonText,
  showAddedVariantsFirst = true,
  showPrepaidBadge,
}: {
  onCardClick: (productVariant: ProductVariant) => void
  withShipping?: boolean
  withProductAmounts?: boolean
  withAmountsInUsd?: boolean
  addedVariants?: Selectable<ProductVariant[]>
  onCardAddButtonClick?: (productVariant: ProductVariant) => void
  selectedRibbonText?: string
  showAddedVariantsFirst?: boolean
  showPrepaidBadge?: boolean
}) {
  const {
    productVariants,
    totalProductVariantCount,
    isLoadingInitial,
    isLoadingMore,
    isEmpty,
    sentinelRef,
  } = useInfiniteProductVariants()

  const sortVariantsToRender = () => {
    if (!addedVariants) return productVariants

    if (showAddedVariantsFirst) {
      return unionBy(addedVariants, productVariants, 'productId')
    }

    return productVariants.map(pv => {
      if (addedVariants.find(addedPv => addedPv.productId === pv.productId)) {
        Object.assign(pv, { isSelected: true })
      }
      return pv
    })
  }

  if (isLoadingInitial) return <Loader />

  if (isEmpty) {
    return (
      <section style={{ marginTop: 64 }}>
        <PerkEmptyState
          header="No results found"
          description={"We couldn't find exactly what you were looking for"}
        />
      </section>
    )
  }

  return (
    <Flex vertical justify="space-between" style={{ height: '100%' }}>
      <ProductsGrid
        products={sortVariantsToRender()}
        onCardClick={onCardClick}
        withPrices={withProductAmounts}
        withShipping={withShipping}
        withAmountsInUsd={withAmountsInUsd}
        onCardAddButtonClick={onCardAddButtonClick}
        selectedRibbonText={selectedRibbonText}
        showPrepaidBadge={showPrepaidBadge}
      />
      {isLoadingMore && <Loader />}
      <div id="scroll-sentinel" ref={sentinelRef} aria-hidden="true" />
      <Pane marginTop={16} alignSelf="end">
        <Text color="muted">
          {productVariants.length} of {totalProductVariantCount}{' '}
          {makePlural(PRODUCT, totalProductVariantCount)}
        </Text>
      </Pane>
    </Flex>
  )
}
