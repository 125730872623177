import { Loader } from 'components'
import { REWARD_ACCEPTANCE } from 'constants/routes'
import { Pane } from 'evergreen-ui'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RewardNotFound } from '../RewardAcceptance/components'
import { useCustomTokenSignIn, useDecryptRewardDetails } from './hooks'

export default function RewardPreview() {
  const { id } = useParams()
  const navigate = useNavigate()

  const { isLoading: isLoadingSignin } = useCustomTokenSignIn({ previewId: id })
  const { rewardDetails, isLoading: isLoadingRewardDetails } =
    useDecryptRewardDetails({ previewId: id })

  useEffect(() => {
    // TODO @CD - Check if current user needs to be signed into a different org
    // Mostly for multi org users
    if (rewardDetails?.programId) {
      const redirectRoute = REWARD_ACCEPTANCE.replace(
        ':programId',
        rewardDetails.programId
      )
      navigate(redirectRoute)
    }
  }, [navigate, rewardDetails])

  const noPreviewId = !id

  const showLoader = isLoadingSignin || isLoadingRewardDetails

  if (showLoader) return <Loader />

  const rewardNotFound = !rewardDetails || noPreviewId

  if (rewardNotFound)
    return (
      <Pane display="flex" margin="auto" marginTop={32}>
        <RewardNotFound />
      </Pane>
    )

  return null
}
