import { protoInt64 } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { updateOrgStripeConnectAccountId } from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { toaster } from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import {
  Organization,
  Organization_StripeConnectAccountCapability,
  Organization_StripeConnectAccountCapabilityStatus_Enum,
} from 'gen/perkup/v1/organization_pb'
import { StripeService } from 'gen/perkup/v1/stripe_invoice_connect'
import { TopUpInvoiceRequest } from 'gen/perkup/v1/stripe_invoice_pb'
import { createClient } from 'hooks/useClient'
import Stripe from 'stripe'
import { toSentry } from 'utils'

export async function CreateTopUpInvoice({
  customer,
  paymentMethod,
  orgId,
  amount,
  accountId,
  isEmbeddedCheckout = false,
  includeTakeRate = false,
}: {
  customer: string
  paymentMethod: Stripe.Invoice.PaymentSettings.PaymentMethodType
  orgId: string
  amount: number
  accountId?: string
  isEmbeddedCheckout?: boolean
  includeTakeRate?: boolean
}) {
  try {
    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(StripeService)

    const topUpInvoiceReq = new TopUpInvoiceRequest({
      amount: protoInt64.parse(Math.round(amount)),
      customer,
      paymentMethod,
      orgId,
      accountId,
      isEmbeddedCheckout,
      includeTakeRate,
    })

    return await client.createTopUpInvoice(topUpInvoiceReq, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  } catch (error) {
    toaster.warning('Error creating top-up invoice, please contact support')

    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        createTopUpInvoice: {
          customer,
          paymentMethod,
          orgId,
          amount,
          accountId,
        },
      },
    })
  }
  return undefined
}

export async function handleConnectOnboarding(data: {
  stripeConnectAccountId: string | undefined
  individualEmail: string
  orgId: string
}): Promise<string | undefined> {
  const { stripeConnectAccountId, individualEmail, orgId } = data
  let accountId = stripeConnectAccountId
  if (!accountId) {
    const account = await callFunction('stripe-CreateCustomConnectAccount', {
      email: individualEmail,
    })
    accountId = account?.id
    if (accountId && !stripeConnectAccountId) {
      await updateOrgStripeConnectAccountId({
        orgId,
        stripeConnectAccountId: accountId,
      })
    }
  }

  if (accountId) {
    const { origin, pathname } = window.document.location
    const returnUrl = `${origin}${pathname}`

    // create custom connect account link
    const accountLink = await callFunction(
      'stripe-CreateCustomConnectAccountLink',
      {
        accountId,
        refreshUrl: returnUrl,
        returnUrl,
      }
    )
    const { url } = accountLink

    window.location.href = url
    return url
  }
  return undefined
  toaster.danger('Unable to create Stripe account')
}

export function isConnectAccountActive({
  org,
}: {
  org: Organization
}): boolean {
  const acctCaps = org.stripeConnectAccountCapabilities
  const acctCap = Organization_StripeConnectAccountCapability
  const activeCap =
    Organization_StripeConnectAccountCapabilityStatus_Enum.active
  return (
    acctCaps[acctCap[acctCap.cardIssuing]] === activeCap &&
    acctCaps[acctCap[acctCap.cardPayments]] === activeCap &&
    acctCaps[acctCap[acctCap.transfers]] === activeCap
  )
}

export function getStripeAccountStatus({
  stripeConnectAccountCapabilities,
  acctCap,
}: {
  stripeConnectAccountCapabilities: {
    [key: string]: Organization_StripeConnectAccountCapabilityStatus_Enum
  }
  acctCap: Organization_StripeConnectAccountCapability
}): Organization_StripeConnectAccountCapabilityStatus_Enum {
  return stripeConnectAccountCapabilities[
    Organization_StripeConnectAccountCapability[acctCap]
  ]
}
