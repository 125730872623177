import {
  IconButton,
  IconButtonOwnProps,
  PolymorphicBoxProps,
  RefreshIcon,
  Tooltip,
  TooltipProps,
} from 'evergreen-ui'

export function AlgoliaRefreshButton({
  tooltipProps,
  iconButtonProps,
}: {
  tooltipProps: TooltipProps
  iconButtonProps: PolymorphicBoxProps<'button', IconButtonOwnProps>
}) {
  return (
    <Tooltip {...tooltipProps}>
      <IconButton
        appearance="minimal"
        icon={RefreshIcon}
        {...iconButtonProps}
      />
    </Tooltip>
  )
}
