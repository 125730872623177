import { AppstoreOutlined, SkinOutlined } from '@ant-design/icons'
import { Flex, Modal, Segmented } from 'antd'
import { ProductCount } from 'components'
import { SWAG_COLLECTION, SWAG_PRODUCTS } from 'constants/newReward'
import { COLLECTION_IDS } from 'constants/productCollections'
import { Heading } from 'evergreen-ui'
import {
  AlgoliaBrowseCollectionProducts,
  BrowseProductCollections,
  BrowseSwagProducts,
} from 'features'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { useState } from 'react'
import { useSelectRewardContext } from '../SelectRewardContext'

export function SwagForm({ withHeadings = false }: { withHeadings?: boolean }) {
  const { selectedReward, swagForm } = useSelectRewardContext()

  const [collectionToShow, setCollectionToShow] = useState<ProductCollection>()

  const isSwagCollection =
    selectedReward?.gift?.productCollectionId &&
    !COLLECTION_IDS.includes(selectedReward.gift.productCollectionId)

  const [selectedTab, setSelectedTab] = useState<
    typeof SWAG_PRODUCTS | typeof SWAG_COLLECTION
  >(isSwagCollection ? SWAG_COLLECTION : SWAG_PRODUCTS)

  const swagFormToggle = (
    <Segmented
      options={[
        { value: SWAG_PRODUCTS, label: SWAG_PRODUCTS, icon: <SkinOutlined /> },
        {
          value: SWAG_COLLECTION,
          label: SWAG_COLLECTION,
          icon: <AppstoreOutlined />,
        },
      ]}
      style={{ width: 'fit-content', marginLeft: 16 }}
      className="small-custom-segment"
      size="large"
      value={selectedTab}
      onChange={setSelectedTab}
    />
  )

  if (selectedTab === SWAG_PRODUCTS) {
    return (
      <Flex vertical style={{ width: '100%', marginBottom: 64 }}>
        <BrowseSwagProducts
          selectedProductIds={selectedReward?.gift?.productIds ?? []}
          onProductVariantClick={swagForm.handleSelectSwagProductVariant}
          firstRowPrefix={
            withHeadings && <Heading size={700}>Select swag</Heading>
          }
          extraElementsInFirstRow={
            <Flex flex={1} justify="end" align="center">
              <ProductCount />
              {swagFormToggle}
            </Flex>
          }
          bottomOffsetAmount={73}
        />
      </Flex>
    )
  }

  return (
    <>
      <Flex
        style={{
          width: '100%',
          marginBottom: 64,
        }}
      >
        <BrowseProductCollections.Selectable
          currentProductCollectionId={selectedReward?.gift?.productCollectionId}
          firstRowSuffix={swagFormToggle}
          onCollectionSelect={swagForm.handleSelectSwagProductCollection}
          onCollectionRemoveClick={swagForm.handleRemoveCollection}
          onCollectionCardClick={c => setCollectionToShow(c)}
        />
      </Flex>

      {collectionToShow && (
        <Modal
          open={!!collectionToShow}
          title={collectionToShow.name}
          onCancel={() => {
            setCollectionToShow(undefined)
          }}
          footer={null}
          width="min(80%, 800px)"
        >
          <AlgoliaBrowseCollectionProducts
            productCollectionId={collectionToShow.id}
          />
        </Modal>
      )}
    </>
  )
}
