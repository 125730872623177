import { Button, Flex, Select, Tabs } from 'antd'
import { ALL_PRODUCTS, FILTER_DISPLAY_NAMES } from 'constants/algolia'
import {
  COLLECTION_IDS,
  COLLECTION_ID_TO_NAME,
} from 'constants/productCollections'
import { Strong } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { compact, startCase } from 'lodash-es'
import { useClearRefinements, useMenu } from 'react-instantsearch'
import { startCaseWithAmpersand } from 'utils/labels'

export function AlgoliaMenuFilter({
  attribute,
  limit,
  asSelectDropdown,
  asPills,
}: {
  attribute: keyof ProductVariant
  limit: number
  asSelectDropdown?: boolean
  asPills?: boolean
}) {
  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useMenu({
      limit,
      attribute,
      sortBy: ['name:asc'],
      showMore: true,
      showMoreLimit: 1000,
    })

  const isCollectionMenu = attribute === 'collectionIds'

  // We only want to display the collections that we've hard-coded if the attribute is collectionIds
  const itemsToRender = isCollectionMenu
    ? compact(COLLECTION_IDS.map(id => items.find(item => item.value === id))) // As the user refines, we still only display what we've hard-coded and in the same order!
    : items

  const itemsWithAllTab = [
    {
      key: ALL_PRODUCTS,
      label: isCollectionMenu ? `⭐ ${startCase(ALL_PRODUCTS)}` : 'All',
      value: ALL_PRODUCTS,
      isRefined: !itemsToRender.some(item => item.isRefined),
    },
    ...itemsToRender.map(item => ({
      key: item.value,
      label: isCollectionMenu
        ? COLLECTION_ID_TO_NAME[item.label] || item.label
        : startCaseWithAmpersand(item.label),
      value: item.value,
      isRefined: item.isRefined,
    })),
  ]

  if (asPills) {
    return (
      <Flex vertical gap={8}>
        <Strong>{startCase(FILTER_DISPLAY_NAMES[attribute])}</Strong>
        <Flex gap={8} wrap>
          {itemsWithAllTab.map(item => (
            <Button
              key={item.key}
              ghost={item.isRefined}
              type={item.isRefined ? 'primary' : 'default'}
              shape="round"
              onClick={() =>
                item.key === ALL_PRODUCTS
                  ? clearRefinements()
                  : refine(item.key)
              }
            >
              {item.label}
            </Button>
          ))}
        </Flex>
      </Flex>
    )
  }

  if (asSelectDropdown) {
    return (
      <Select
        style={{ width: '100%' }}
        allowClear
        onClear={clearRefinements}
        popupMatchSelectWidth={false}
        placeholder={startCase(FILTER_DISPLAY_NAMES[attribute])}
        onSelect={(_, { key }) =>
          key === ALL_PRODUCTS ? clearRefinements() : refine(key)
        }
        options={itemsWithAllTab}
        showSearch
      />
    )
  }

  return (
    <Flex vertical gap={8}>
      <Strong>{startCase(FILTER_DISPLAY_NAMES[attribute])}</Strong>

      <Tabs
        className="custom-ant-vertical-tabs"
        defaultActiveKey="All"
        tabPosition="right"
        items={itemsWithAllTab}
        onChange={activeKey =>
          activeKey === ALL_PRODUCTS ? clearRefinements() : refine(activeKey)
        }
        tabBarGutter={1}
      />

      {canToggleShowMore && (
        <Button onClick={toggleShowMore}>
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Flex>
  )
}
