import { Button, Flex, Modal, Rate } from 'antd'
import { updateProgram } from 'api/databaseCalls'
import { LinkBox } from 'components'
import { FEEDBACK_SUBMITTED } from 'constants/events'
import { IndividualContext } from 'context'
import { Heading, Strong, Text } from 'evergreen-ui'
import { Program_Feedback } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'
import { getProgramAcceptanceLink, logEvent } from 'utils'

const improvementOptions = [
  'Swag options',
  'Gift options',
  'Gift card options',
  'Ease of use',
  'Reporting',
  'Tracking',
]

export function ProgramFeedbackModal({
  programId,
  onAfterSubmit,
}: {
  programId: string
  onAfterSubmit: () => void
}) {
  const individual = useContext(IndividualContext)
  const { userId, orgId, email } = individual
  const [selectedImprovements, setSelectedImprovements] = useState<string[]>([])
  const [rating, setRating] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    setIsLoading(true)
    const feedback = new Program_Feedback({
      rating: rating.toString(),
      response: selectedImprovements.join(', '),
    })
    await updateProgram({
      orgId,
      programId,
      program: {
        feedback,
      },
    })
    logEvent(FEEDBACK_SUBMITTED, {
      feedback,
      programId,
      orgId,
      userId,
      email,
    })

    onAfterSubmit()
    setIsLoading(false)
  }

  return (
    <Modal
      open
      okText="Submit"
      onCancel={onAfterSubmit}
      onOk={handleSubmit}
      destroyOnClose
      width={632}
      style={{ padding: 24 }}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: !rating }}
    >
      <Flex vertical gap={16}>
        <Heading size={700}>Reward created 🥳</Heading>
        <LinkBox
          href={getProgramAcceptanceLink(programId)}
          copyText="Copy gift link"
        />
        <Text>We&apos;d love your feedback on the platform.</Text>
        <Flex vertical gap={8}>
          <Strong>How was your experience?</Strong>
          <Rate value={rating} onChange={setRating} />
        </Flex>
        <Flex vertical gap={8}>
          <Flex gap={4}>
            <Strong>How can PerkUp improve?</Strong>
            <Text>(Max 3)</Text>
          </Flex>
          <Flex gap={8} wrap="wrap">
            {improvementOptions.map(option => {
              const isSelected = selectedImprovements.includes(option)
              return (
                <Button
                  key={option}
                  size="large"
                  type={isSelected ? 'primary' : 'default'}
                  shape="round"
                  disabled={selectedImprovements.length >= 3 && !isSelected}
                  onClick={() => {
                    if (isSelected) {
                      setSelectedImprovements(
                        selectedImprovements.filter(i => i !== option)
                      )
                    } else {
                      setSelectedImprovements([...selectedImprovements, option])
                    }
                  }}
                  ghost={isSelected}
                >
                  {option}
                </Button>
              )
            })}
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}
