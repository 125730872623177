import { GiftOutlined } from '@ant-design/icons'
import { Alert, AlertProps, Button, Flex, Image } from 'antd'
import Collapse from 'antd/es/collapse'
import { GetVendorById } from 'api/databaseCalls'
import { giftNotAvailableAsset } from 'assets'
import primeLogo from 'assets/amazon-prime-logo-prime-amazon.png'
import amazonBusinessLogo from 'assets/AmazonBusinessPrimaryLogo_Small.png'
import { FooterWithCTA, Loader, PerkEmptyState } from 'components'
import { SwapGiftButton } from 'components/Buttons/SwapGiftButton'
import { USA_ISO3 } from 'constants/countries'
import { ACCEPTANCE_FLOW_WIDTH } from 'constants/layout'
import { CHOOSE_YOUR_GIFT } from 'constants/rewards'
import { REWARD_ACCEPTANCE, REWARD_ACCEPTANCE_CHECKOUT } from 'constants/routes'
import {
  CountryContext,
  IndividualContext,
  MemberContext,
  OrgContext,
  ProgramContext,
} from 'context'
import {
  EmptyState,
  Heading,
  InboxSearchIcon,
  ListItem,
  Pane,
  Paragraph,
  Strong,
  Text,
  UnorderedList,
} from 'evergreen-ui'
import { ProductDetails, SelectProductVariants } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import {
  Vendor,
  VendorProduct,
  VendorProductPrice,
} from 'gen/perkup/v1/vendor_pb'
import { useAccount, useGiftRedeemed } from 'hooks'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import useProductVariantById from 'hooks/productVariants/useProductVariantById'
import { isEmpty } from 'lodash-es'
import toUpper from 'lodash-es/toUpper'
import { useIsSignedInUser } from 'pages/RewardPreview/hooks/useIsSignedInUser'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { AmazonProduct, PageDirection } from 'types'
import {
  getMaxProgramBudgetFlexibility,
  isNearCashGiftProgram,
  isPublicGiftProgram,
  isSwappableGift,
} from 'utils'
import { getAlgoliaFeaturedCollectionFilter } from 'utils/Algolia'
import {
  getProductVariantOptions,
  saveProductVariantsToSessionStorage,
} from 'utils/productVariant'
import { useProductType } from 'utils/rewardAcceptance'
import { isUnavailableInCountry } from 'utils/vendors'
import {
  useMemberProduct,
  useMemberVendorProductPrices,
  useProductVariantsFromSessionStorage,
} from '../hooks'
import { GiftAcceptanceAlert } from './GiftAcceptanceAlert'
import { MultiProductGiftRevealed } from './MultiProductGiftRevealed'

const imageStyles: React.CSSProperties = {
  minWidth: '15em',
  minHeight: '15em',
  objectFit: 'contain',
}

// TODO - YOINK
function VendorGiftRevealed({
  product,
  productPrices,
}: {
  product: VendorProduct
  productPrices: VendorProductPrice[]
}) {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const memberId = member.id
  const individual = useContext(IndividualContext)
  const { email } = individual
  const country = useContext(CountryContext)
  const org = useContext(OrgContext)
  const giftRedeemed = useGiftRedeemed()

  const { primaryColor } = org

  const isSignedInUser = useIsSignedInUser({ email, memberId })
  const navigate = useNavigate()
  const [vendor, setVendor] = useState<Vendor>()
  const isGiftUnavailable = isUnavailableInCountry({
    programBudget: program.budget,
    userCountryCode: country.iso3,
    productPrices,
  })

  const handleContinue = () => {
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        program.id
      )}${REWARD_ACCEPTANCE_CHECKOUT}`,
      { state: { direction: PageDirection.FORWARD } } // Used for page sliding animation
    )
  }

  const handleSwap = (product: ProductVariant) => {
    saveProductVariantsToSessionStorage({
      productVariants: [product],
      programId: program?.id,
    })
    handleContinue()
  }

  const vendorId = product?.vendorId
  useEffect(() => {
    if (vendorId) {
      GetVendorById({ vendorId }).then(setVendor)
    }
  }, [vendorId])

  const isSwappable = isSwappableGift({ gift: program?.gift })

  const productVendorTitle = vendor?.name ?? ''
  const productDescription = product?.description
  const productTitle = product?.name
  const productImage = product?.images[0] || ''

  return (
    <Pane display="flex" flexDirection="column" gap={32} paddingY={32}>
      {/** Preview */}
      <Pane display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={24}>
        <Image src={productImage} style={{ ...imageStyles }} />

        <Pane display="flex" flexDirection="column" gap={16}>
          <Heading
            size={600}
            fontWeight={800}
            color={primaryColor || 'default'}
          >
            {toUpper(productVendorTitle)}
          </Heading>
          <Heading size={800}>{productTitle}</Heading>

          <Paragraph dangerouslySetInnerHTML={{ __html: productDescription }} />
        </Pane>
      </Pane>

      <GiftAcceptanceAlert />
      {/** Preview Call to Action */}
      <Pane display="flex" justifyContent="space-between" gap={8}>
        <Pane display="flex" gap={8}>
          {isSwappable && (
            <SwapGiftButton
              program={program}
              memberId={memberId}
              onSwap={handleSwap}
            />
          )}
          <Button
            onClick={handleContinue}
            disabled={!isSignedInUser || isGiftUnavailable || giftRedeemed}
            type={isGiftUnavailable ? 'default' : 'primary'}
          >
            Continue
          </Button>
        </Pane>
      </Pane>

      {/** Alert */}
      {isGiftUnavailable && (
        <Pane display="flex" justifyContent="center">
          <Alert
            type="warning"
            showIcon
            message={`This item is not available to ship to ${country.name}.`}
            description="Please swap your gift or change country."
          />
        </Pane>
      )}
    </Pane>
  )
}

function AmazonGiftRevealed({ product }: { product: AmazonProduct }) {
  const navigate = useNavigate()
  const giftRedeemed = useGiftRedeemed()
  const program = useContext(ProgramContext)

  const productTitle = product.title
  const productImage = product.includedDataTypes.IMAGES?.length
    ? product.includedDataTypes.IMAGES[0].large.url
    : ''

  const handleAcceptAmazonGift = () => {
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        program.id
      )}${REWARD_ACCEPTANCE_CHECKOUT}`,
      { state: { direction: PageDirection.FORWARD } } // Used for page sliding animation
    )
  }

  return (
    <Pane display="flex" flexDirection="column" gap={32}>
      {/** Preview */}
      <Pane display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={24}>
        <Image src={productImage} style={{ ...imageStyles }} />
        {/** Amazon product information */}
        <Pane display="flex" flexDirection="column" gap={8}>
          <Image src={amazonBusinessLogo} width={100} />
          <Heading size={800}>{productTitle}</Heading>
          <Image src={primeLogo} width={50} />

          <Pane marginTop={16}>
            <Pane display="flex" flexDirection="column" gap="0.5rem">
              {Object.entries(product.productOverview).map(([key, value]) => (
                <Pane key={key} display="flex" gap="0.2rem">
                  <Text flex={1}>
                    <Strong>{key}</Strong>
                  </Text>
                  <Text flex={1}>{value}</Text>
                </Pane>
              ))}
            </Pane>
          </Pane>
        </Pane>
      </Pane>

      {/** Amazon product features as a collapse */}
      <Collapse
        bordered={false}
        expandIconPosition="right"
        items={[
          {
            key: '0',
            label: <Heading>About this item</Heading>,
            children: (
              <UnorderedList>
                {product.features?.map(feature => (
                  <ListItem key={feature}>{feature}</ListItem>
                ))}
              </UnorderedList>
            ),
          },
        ]}
      />

      {/** Call to Action */}
      <Pane display="flex" justifyContent="space-between">
        <Button
          disabled={giftRedeemed}
          onClick={handleAcceptAmazonGift}
          type="primary"
        >
          Continue
        </Button>
      </Pane>
    </Pane>
  )
}

function ProductRevealed() {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const navigate = useNavigate()
  const giftRedeemed = useGiftRedeemed()
  const country = useContext(CountryContext)

  const currentIso3 = country?.iso3 || USA_ISO3

  const productId = program.gift?.productIds[0] || member?.gift?.giftId

  const memberId = member.id
  const isSpecificVariant = !!program.gift?.productVariantId

  const { defaultVariant, isLoading: isLoadingAllVariants } =
    useListAllProductVariantsByProductId({
      productId: isSpecificVariant ? undefined : productId,
    })

  const { productVariant, isLoading: isLoadingSpecificVariant } =
    useProductVariantById({ productVariantId: program.gift?.productVariantId })

  const {
    addedVariants,
    setAddedVariants,
    isLoading: isLoadingAddedVariants,
  } = useProductVariantsFromSessionStorage({ programId: program.id })

  const { accountId } = program

  const { account } = useAccount({ accountId })
  const notEnoughAccountFunds = account?.balance
    ? account.balance < program.budget
    : true

  const handleNavigateForward = () => {
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        program.id
      )}${REWARD_ACCEPTANCE_CHECKOUT}`,
      { state: { direction: PageDirection.FORWARD } }
    )
  }

  const handleAcceptGift = (product: ProductVariant) => {
    const variantAdded = addedVariants.find(
      pv => pv.productId === product.productId
    )
    saveProductVariantsToSessionStorage({
      productVariants: variantAdded ? [] : [product],
      programId: program?.id,
    })
    if (!variantAdded) {
      handleNavigateForward()
    } else {
      setAddedVariants([])
    }
  }

  if (
    isLoadingAllVariants ||
    isLoadingSpecificVariant ||
    isLoadingAddedVariants
  )
    return <Loader />

  const variantToUse = isSpecificVariant ? productVariant : defaultVariant

  const isPublicGiftOrNearCash =
    program?.gift &&
    (isNearCashGiftProgram(program.gift) || isPublicGiftProgram(program.gift))

  // If public gift and no variant found, allow swap - else bigger problem, loop support
  if (!variantToUse) {
    if (isPublicGiftOrNearCash) {
      const showContinueCTA = !isEmpty(addedVariants)
      return (
        <Pane width={ACCEPTANCE_FLOW_WIDTH} height="100%">
          <SelectProductVariants
            addedVariants={addedVariants}
            onAddVariant={handleAcceptGift}
            withAlgolia
            searchFilter={getAlgoliaFeaturedCollectionFilter({
              maxAmount: program.budget,
              currentIso3,
            })}
            header={
              <Flex vertical gap={16}>
                <Alert
                  type="info"
                  description="The gift you were sent is no longer available. Select a gift of your choice instead."
                  showIcon
                  style={{
                    width: 'fit-content',
                  }}
                />
                <Heading size={900}>{CHOOSE_YOUR_GIFT}</Heading>
              </Flex>
            }
            continueFooter={
              showContinueCTA ? (
                <FooterWithCTA
                  ctaText="Continue"
                  onCTAClick={handleNavigateForward}
                  fullCta={isMobile}
                />
              ) : undefined
            }
          />
        </Pane>
      )
    }
    return (
      <Flex vertical gap={32}>
        <PerkEmptyState
          header={"Oops, we couldn't find your gift."}
          description="Contact support so we can help you out. (error: SPV)"
          iconUrl={giftNotAvailableAsset}
        />
      </Flex>
    )
  }

  const publicGiftOrNearCashNotAvailable =
    variantToUse?.productIsAvailable === false && isPublicGiftOrNearCash

  const disableAcceptance = giftRedeemed || notEnoughAccountFunds

  const isSwappable = isSwappableGift({ gift: program?.gift })

  const defaultSelectedOptions = isSpecificVariant
    ? {
        filterOtherOptions: true,
        options: getProductVariantOptions({ productVariant: variantToUse }),
      }
    : undefined

  const showSwapButton = isSwappable || publicGiftOrNearCashNotAvailable

  const alertProps: AlertProps | undefined = publicGiftOrNearCashNotAvailable
    ? {
        type: 'info',
        showIcon: true,
        description: 'This gift is no longer available.',
      }
    : undefined

  return (
    <Pane
      display="flex"
      flexDirection="column"
      height="100%"
      maxWidth={1080}
      gap={32}
    >
      <GiftAcceptanceAlert />
      <ProductDetails
        key={variantToUse.id}
        onPrimaryCtaClick={handleAcceptGift}
        disableCta={disableAcceptance}
        primaryCtaProps={{
          children: 'Continue',
          hidden: publicGiftOrNearCashNotAvailable,
        }}
        productVariant={variantToUse}
        withAmountInUsd
        maxAmount={getMaxProgramBudgetFlexibility(program)}
        defaultSelected={defaultSelectedOptions}
        isVerticalLayout={isMobile}
        alertProps={alertProps}
      >
        <ProductDetails.Slot name="belowCta">
          {showSwapButton && (
            <SwapGiftButton
              program={program}
              memberId={memberId}
              swapGiftCta="Continue"
              onSwap={handleAcceptGift}
              swapGiftButtonProps={
                publicGiftOrNearCashNotAvailable
                  ? {
                      type: 'primary',
                      children: 'Select a gift',
                      icon: <GiftOutlined />,
                      disabled: disableAcceptance,
                    }
                  : {
                      disabled: disableAcceptance,
                    }
              }
            />
          )}
        </ProductDetails.Slot>
      </ProductDetails>
    </Pane>
  )
}

export function GiftRevealed() {
  const program = useContext(ProgramContext)

  // Determine the current product
  const {
    isAmazonGift,
    isProductVariantGift,
    isVendorGift,
    isCollectionGift,
    isMultiProductGift,
  } = useProductType()

  const isMultiSelectGift = isMultiProductGift || isCollectionGift

  const { vendorProductPrices } = useMemberVendorProductPrices()
  const { product, isLoading } = useMemberProduct({})

  if (isLoading) return <Loader />

  if (!product && !isMultiSelectGift && !isProductVariantGift) {
    return (
      <EmptyState
        background="light"
        title="Oops, we couldn't find your gift."
        orientation="vertical"
        icon={<InboxSearchIcon />}
        description="Contact support so we can help you out. (error: PN)"
        iconBgColor="white"
      />
    )
  }

  const noMaxWidth = isMultiSelectGift || isProductVariantGift

  return (
    <Pane
      maxWidth={noMaxWidth ? undefined : 960}
      padding={noMaxWidth ? undefined : 32}
      height="100%"
    >
      {isAmazonGift && product && (
        <AmazonGiftRevealed
          key={program.id}
          product={product as AmazonProduct}
        />
      )}
      {isProductVariantGift && !isVendorGift && (
        <ProductRevealed key={program.id} />
      )}
      {/* TODO - YOINK AIRTABLE  */}
      {isVendorGift && product && (
        <VendorGiftRevealed
          key={program.id}
          product={product as VendorProduct}
          productPrices={vendorProductPrices}
        />
      )}

      {isMultiSelectGift && <MultiProductGiftRevealed />}
    </Pane>
  )
}
