import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Dialog, Heading } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryParams } from 'utils'

export function NewProgramDialog() {
  const [showDialog, setShowDialog] = useState(false)
  const query = useQueryParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isRewards = location.pathname?.includes(
    DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT
  )

  const title = isRewards ? 'reward' : 'perk program'
  const confirmLabel = isRewards ? 'See reward' : 'See program'

  // Show dialog if just finished created a new policy
  useEffect(() => {
    if (query.get(ROUTES.PARAM_NEW_PROGRAM)) {
      setShowDialog(true)
    }
  }, [query])

  const handleClose = () => {
    setShowDialog(false)
    navigate(location.pathname)
  }

  return (
    <Dialog
      isShown={showDialog}
      onCloseComplete={handleClose}
      confirmLabel={confirmLabel}
      onConfirm={handleClose}
      hasHeader={false}
      hasCancel={false}
    >
      <Heading size={600} marginTop={16}>
        Congratulations, you created a {title}!{' '}
        <span role="img" aria-label="party">
          🥳
        </span>
      </Heading>
    </Dialog>
  )
}
