import { LinkOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { copyProgramAcceptanceLink } from 'utils'

export function RewardLinkButton({
  withText,
  programId,
}: {
  withText?: boolean
  programId: string
}) {
  const handleCopyGiftLink = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    copyProgramAcceptanceLink(programId)
  }

  const text = 'Copy gift link'

  return (
    <Tooltip title={withText ? '' : text}>
      <Button icon={<LinkOutlined />} type="text" onClick={handleCopyGiftLink}>
        {withText && text}
      </Button>
    </Tooltip>
  )
}
