/* eslint-disable jsx-a11y/media-has-caption */
import { store } from 'assets/icons'
import { BrandedTag } from 'components/Typography'
import * as ROUTES from 'constants/routes'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Heading, Image, Pane, Text, useTheme } from 'evergreen-ui'
import {
  Member_ConvertedTo,
  Membership,
  ProgramFrequency,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors, useShopRoute } from 'hooks'
import parse from 'html-react-parser'
import ProgramCategories from 'pages/PerkProgram/ProgramCategories'
import { useContext, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import { useReward } from 'react-rewards'
import { useNavigate } from 'react-router-dom'
import { daysDiff, getProgramEndDateCountdown } from 'utils'
import {
  getAmountSpent,
  getDateDisplayString,
  getResetsOnDateString,
} from 'utils/programs'
import { MembershipCard } from './MembershipCard'
import { NewGiftCard } from './NewGiftCard'
import { ProgramHeader } from './ProgramHeader'

export function MembershipSummary({ membership }: { membership: Membership }) {
  const [isCardHovered, setIsCardHovered] = useState<boolean>(false)

  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const theme = useTheme()

  const navigate = useNavigate()
  const { defaultColor } = useDefaultOrgColors()

  const { shopRoute } = useShopRoute()

  const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false)
  const rewardId = `newHeading-${membership.program?.id}`
  const { reward } = useReward(rewardId, 'emoji')

  const { program, member } = membership
  if (!program || !member) return null
  const {
    id: programId,
    type: programType,
    status: programStatus,
    created,
    name,
    rollover,
    resetsOn,
    email,
    coreValues,
    note,
    approvedCategories,
    allMerchants,
    frequency,
  } = program

  const convertedToNearCash = member.convertedTo === Member_ConvertedTo.nearCash
  const isGift = !!program?.gift && !convertedToNearCash

  const { spent = 0, budget } = member

  const banner = email?.banner
  const fromName = email?.fromName

  const oneTimeProgram = frequency === ProgramFrequency.once
  const activeProgram = programStatus === ProgramStatus.active

  const createdDateDiff = daysDiff(created?.toDate(), true, false) ?? 0
  const isNew = activeProgram && createdDateDiff <= 7
  const isSpent = budget === spent
  const availableSpend = budget - spent

  if (programType === ProgramType.policies) return null

  const dateDisplayString = getDateDisplayString({
    program,
  })
  const expiredDateDiff = daysDiff(program?.endsOn?.toDate(), false, true)

  const countDownString = getProgramEndDateCountdown({ program })

  const amountSpent = getAmountSpent({
    spent,
    exchangeRate,
    userDisplayCurrency: user.displayCurrency,
    orgDisplayCurrency: org.displayCurrency,
  })

  const handleShowExtraInfo = () => {
    if (isNew) {
      reward()
    }

    setShowExtraInfo(!showExtraInfo)
  }

  const handleAcceptGift = () => {
    navigate(
      `${ROUTES.REWARD_ACCEPTANCE.replace(':programId', programId || '')}`
    )
  }

  if (isGift && spent) return null

  if (isGift && !spent && banner) {
    return (
      <NewGiftCard
        rewardId={rewardId}
        programImage={banner}
        dateDisplayString={dateDisplayString}
        handleAcceptGift={handleAcceptGift}
        expiredDateDiff={expiredDateDiff}
        primaryColor={defaultColor}
        programName={name}
        isCardHovered={isCardHovered}
        setIsCardHovered={setIsCardHovered}
        countDownString={countDownString}
      />
    )
  }
  return (
    <MembershipCard
      handleOnClick={handleShowExtraInfo}
      orgPrimaryColor={defaultColor}
      isCardHovered={isCardHovered}
      setIsCardHovered={setIsCardHovered}
    >
      <Pane display="flex">
        <Pane display="flex" flexDirection="column" gap={6} width="100%">
          {/** HEADER: MONEY, NAME, ICON */}
          <ProgramHeader
            activeProgram={activeProgram}
            isNew={isNew}
            name={name}
            rewardId={rewardId}
            availableSpend={availableSpend}
            exchangeRate={exchangeRate}
            userDisplayCurrency={user.displayCurrency}
            orgDisplayCurrency={org.displayCurrency}
            oneTimeProgram={oneTimeProgram}
            programType={programType}
          />

          {/** PROGRESS BAR */}
          <ProgressBar
            variant={activeProgram ? 'success' : 'info'}
            style={{ height: '0.5rem' }}
            now={((budget - spent) / budget) * 100}
          />

          <Pane
            display="flex"
            flexDirection={isMobile ? 'column-reverse' : 'row'}
            alignItems="start"
            justifyContent={isMobile ? undefined : 'space-between'}
            gap={isMobile ? 8 : 0}
            marginBottom={8}
          >
            {coreValues && (
              <Pane display="flex" flexWrap="wrap" flexShrink={2}>
                {coreValues.map(value => (
                  <BrandedTag
                    key={value}
                    style={{ marginBottom: 4, marginTop: 4 }}
                  >
                    {value}
                  </BrandedTag>
                ))}
              </Pane>
            )}
            {oneTimeProgram ? (
              dateDisplayString &&
              !isSpent && (
                <Pane
                  display="flex"
                  flexShrink={0}
                  alignItems="start"
                  justifyContent="end"
                >
                  <Heading size={100}>{dateDisplayString} </Heading>
                  {activeProgram && !!countDownString && (
                    <Heading
                      color={theme?.colors?.red500}
                      size={100}
                      marginLeft={4}
                    >
                      {countDownString}
                    </Heading>
                  )}
                </Pane>
              )
            ) : (
              <Heading size={100}>
                {`${rollover ? 'Rollover' : 'Resets'} ${getResetsOnDateString(Number(resetsOn))}`}
              </Heading>
            )}
          </Pane>
        </Pane>
      </Pane>
      {showExtraInfo && note && (
        <Pane display="flex">
          <Text color="muted">
            {parse(note)} -{' '}
            {fromName && (
              <Text style={{ fontStyle: 'italic' }}>{fromName}</Text>
            )}
          </Text>
        </Pane>
      )}
      {/** EXTRA INFO */}

      {showExtraInfo && (
        <Pane
          display="grid"
          marginTop={8}
          gridTemplateColumns={isMobile ? '1fr' : ' 1fr 2fr'}
          gridTemplateRows={isMobile ? '1fr' : '2fr 2fr'}
          justifyContent="start"
          alignItems="center"
          gap={8}
        >
          <Heading size={400} color="muted">
            Summary
          </Heading>
          {/** Amount spent info */}

          <Heading size={100}>{amountSpent} Spent</Heading>

          <Heading size={400} color="muted" whiteSpace="nowrap">
            Approved categories
          </Heading>
          {/** Eligible program catergories as icons */}
          {allMerchants ? (
            <Pane
              display="flex"
              gap="8px"
              alignItems="center"
              onClick={() => navigate(shopRoute)}
            >
              <Image src={store} height={28} />
              <Heading size={100}>Approved everywhere</Heading>
            </Pane>
          ) : (
            <ProgramCategories
              withName={false}
              approvedCategories={approvedCategories}
              applyOrgSettings
            />
          )}
        </Pane>
      )}
    </MembershipCard>
  )
}
