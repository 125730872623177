import {
  AutomaticUpdatesIcon,
  Heading,
  Pane,
  Text,
  Tooltip,
} from 'evergreen-ui'
import { Program, ProgramFrequency } from 'gen/perkup/v1/program_pb'

import { numToDollars } from 'utils'

export function ProgramAmount({ program }: { program: Program }) {
  return (
    <Pane display="flex" alignItems="center">
      <Heading size={800} marginRight={8} className="text-success">
        {numToDollars(program.budget)}
      </Heading>
      <Text size={600}>{ProgramFrequency[program.frequency]} per person</Text>
      {program.rollover && (
        <Tooltip content="Budgets rollover">
          <AutomaticUpdatesIcon color="success" marginX={16} />
        </Tooltip>
      )}
    </Pane>
  )
}
