import { SearchOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Flex, InputNumber, InputNumberProps } from 'antd'
import { updateCartItems } from 'api/databaseCalls/writes/carts'
import { AddressShortDisplay, Loader, PerkEmptyState } from 'components'
import { VariantEstimatedShippingTime } from 'components/ProductVariants/VariantEstimatedShippingTime'
import { NUMBER_GREEN } from 'constants/colors'
import { PRODUCT_VARIANT_ID, QUANITTY } from 'constants/params'
import { CARD, HOME, SWAG } from 'constants/routes'
import { SwagCollectionIdsContext, UserShippingAddressesContext } from 'context'
import { Heading, Text, toaster } from 'evergreen-ui'
import { ProductDetails } from 'features'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import { isUndefined } from 'lodash-es'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'
import { WithSelectedQuantity } from 'types'
import { makePlural, numToDollars } from 'utils'
import { shouldSkipPvInventoryChecks } from 'utils/productVariant'

function TotalBreakdown({
  amount,
  quantity,
}: {
  amount: number
  quantity: number
}) {
  return (
    <Flex gap={8} align="baseline">
      <Heading size={400}>Total</Heading>
      <Text color={NUMBER_GREEN}>{numToDollars(amount * quantity)}</Text>
      <Text color="muted">
        {makePlural('item', !amount ? 0 : quantity, true)}
      </Text>
    </Flex>
  )
}

export function SwagStoreProductPage({
  onAddProductToCart,
}: {
  onAddProductToCart: (
    addedProductVariant: WithSelectedQuantity<ProductVariant>
  ) => void
}) {
  const navigate = useNavigate()

  const { productId } = useParams()

  const swagCollections = useContext(SwagCollectionIdsContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const outletContext: {
    collection: ProductCollection | undefined
    cart: Cart | undefined
    shippingAddress: ShippingAddress | undefined
    onShippingAddressChange: (address?: ShippingAddress) => void
  } = useOutletContext()

  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [isSavingCart, setIsSavingCart] = useState(false)
  const [selectedProductVariant, setSelectedProductVariant] =
    useState<ProductVariant>()

  const { defaultVariant, isLoadingInitial } =
    useListAllProductVariantsByProductId({
      productId,
    })

  const collection = outletContext?.collection
  const cart = outletContext?.cart
  const lineItems = cart?.lineItems || []
  const orgHasMultipleCollections = swagCollections.length > 1

  if (isLoadingInitial) return <Loader />

  if (!productId || !defaultVariant) {
    return (
      <PerkEmptyState
        header="Product not found"
        description="We couldn’t find the product you were looking for."
        iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(`${HOME}`),
          type: 'primary',
        }}
      />
    )
  }

  const defaultIsTheOnlyProductVariant =
    Number(defaultVariant.variantsCount) === 1

  const totalAmountToDisplay = defaultIsTheOnlyProductVariant
    ? Number(defaultVariant.amount || 0)
    : Number(selectedProductVariant?.amount || 0)

  const isSelectedProductVariantAlreadyInCart = lineItems.some(li => {
    // If the default fetched product variant has no other options, then check if the default fetched product variant is in the cart
    if (defaultIsTheOnlyProductVariant) {
      return li.productVariantId === defaultVariant.id
    }
    return li.productVariantId === selectedProductVariant?.id
  })

  const handleAddOrRemoveProductVariantFromCart = async (
    productVariant: ProductVariant
  ) => {
    if (!cart) {
      toaster.warning('Cart not found')
      return
    }
    setIsSavingCart(true)

    // If the product variant is already in the cart, remove it
    if (isSelectedProductVariantAlreadyInCart) {
      const newLineItems = lineItems.filter(
        li => li.productId !== productVariant.productId
      )
      await updateCartItems({
        cartId: cart.id,
        lineItems: newLineItems,
      })

      toaster.success(`${productVariant.productName} removed from cart`)
    }
    // If the product variant is not in the cart, add it
    else {
      const pvToCartItem = new Cart_Item({
        productVariantId: productVariant.id,
        productId: productVariant.productId,
        quantity: selectedQuantity,
        provider: productVariant.provider,
        type: productVariant.type,
      })
      await updateCartItems({
        cartId: cart.id,
        lineItems: [...lineItems, pvToCartItem],
      })
      const pvWithQty = Object.assign(productVariant, {
        selectedQuantity,
      })
      onAddProductToCart(pvWithQty)
    }
    setIsSavingCart(false)
  }

  const handleBuyNow = (product?: ProductVariant) => {
    if (!product) return
    const searchParams = new URLSearchParams({
      [PRODUCT_VARIANT_ID]: product.id,
      [QUANITTY]: selectedQuantity.toString(),
    })
    navigate({
      pathname: `${SWAG}/checkout`,
      search: searchParams.toString(),
    })
  }

  const handleChangeQuantity: InputNumberProps['onChange'] = value => {
    if (typeof value === 'number') setSelectedQuantity(value)
  }

  const pvForInventoryCheck = selectedProductVariant || defaultVariant

  // Disable the CTA if: Specific pv selected, inventory of pv is tracked, policy is not continue, and the selected quantity is greater than the inventory quantity
  const disableCta =
    !shouldSkipPvInventoryChecks(pvForInventoryCheck) &&
    !isUndefined(pvForInventoryCheck?.inventoryQuantity) &&
    pvForInventoryCheck.inventoryQuantity < selectedQuantity

  return (
    <>
      <Helmet>
        <title>{defaultVariant.productName}</title>
      </Helmet>
      <Flex
        vertical
        gap={32}
        style={{ marginBottom: isMobile ? 104 : undefined }}
      >
        <Flex gap={8} align="center">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={CARD}>Home</Link>
            </Breadcrumb.Item>
            {orgHasMultipleCollections && (
              <Breadcrumb.Item>
                <Link to={SWAG}>Collections</Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>
              <Link to={`${SWAG}/${collection?.id}`}>{collection?.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{defaultVariant.productName}</Breadcrumb.Item>
          </Breadcrumb>
        </Flex>

        <ProductDetails
          productVariant={defaultVariant}
          showPrice
          showShipping
          primaryCtaProps={{
            children: isSelectedProductVariantAlreadyInCart
              ? 'Remove from cart'
              : 'Add to cart',
            loading: isSavingCart,
          }}
          onPrimaryCtaClick={
            isMobile ? undefined : handleAddOrRemoveProductVariantFromCart
          }
          secondaryCtaProps={{ children: 'Buy now' }}
          onSecondaryCtaClick={isMobile ? undefined : handleBuyNow}
          onSelectedVariantChange={setSelectedProductVariant}
          disableCta={disableCta}
        >
          <ProductDetails.Slot name="aboveCta">
            <Flex vertical gap={4}>
              <Heading size={400}>Quantity</Heading>
              <InputNumber
                min={1}
                defaultValue={1}
                onChange={handleChangeQuantity}
              />
            </Flex>
            {!isMobile && (
              <TotalBreakdown
                amount={totalAmountToDisplay}
                quantity={selectedQuantity}
              />
            )}
          </ProductDetails.Slot>
          {outletContext?.shippingAddress && (
            <ProductDetails.Slot name="displayAddress">
              <AddressShortDisplay
                isMuted
                shippingAddress={outletContext?.shippingAddress}
                shippingAddresses={shippingAddresses}
                onShippingAddressChange={outletContext.onShippingAddressChange}
              />
            </ProductDetails.Slot>
          )}
          <ProductDetails.Slot name="shippingEstimation">
            <VariantEstimatedShippingTime
              productVariant={defaultVariant}
              shippingAddress={outletContext?.shippingAddress}
            />
          </ProductDetails.Slot>
        </ProductDetails>
      </Flex>

      {isMobile && (
        <Flex
          vertical
          gap={8}
          style={{
            position: 'fixed',
            padding: 16,
            width: '100%',
            bottom: 0,
            left: 0,
            backgroundColor: 'white',
          }}
        >
          <TotalBreakdown
            amount={totalAmountToDisplay}
            quantity={selectedQuantity}
          />
          <Flex gap={8}>
            <Button
              disabled={
                defaultIsTheOnlyProductVariant ? false : !selectedProductVariant
              }
              type="primary"
              size="large"
              style={{ flex: 1 }}
              loading={isSavingCart}
              onClick={() =>
                handleAddOrRemoveProductVariantFromCart(
                  selectedProductVariant ?? defaultVariant
                )
              }
            >
              {isSelectedProductVariantAlreadyInCart
                ? 'Remove from cart'
                : 'Add to cart'}
            </Button>
            <Button
              disabled={!selectedProductVariant}
              size="large"
              style={{ flex: 1 }}
              onClick={() => handleBuyNow(selectedProductVariant)}
            >
              Buy now
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
}
