import { Button } from 'antd'
import { ComponentProps, useState } from 'react'

export function AsyncButton({
  onClick,
  disabled,
  ...props
}: Omit<ComponentProps<typeof Button>, 'loading' | 'onClick'> & {
  onClick: React.MouseEventHandler<HTMLButtonElement> | (() => Promise<void>)
}) {
  const [loading, setLoading] = useState(false)

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async e => {
    setLoading(true)
    try {
      await onClick(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      onClick={handleClick}
      disabled={disabled || loading}
      loading={loading}
      {...props}
    />
  )
}
