import { protoInt64, Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { toaster } from 'evergreen-ui'
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {
  Account,
  AccountType_Enum,
  Account_Permission,
} from 'gen/perkup/v1/account_pb'
import { toSentry } from 'utils'
import { converter, messageToFirestore } from 'utils/firestore'

export async function createAccount({
  name,
  orgId,
  permissions,
  type,
  individualId,
}: {
  name: string
  orgId: string
  permissions: Record<string, Account_Permission>
  type: AccountType_Enum
  individualId: string
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/accounts`
  ).withConverter(converter(Account))

  const newAccount = new Account({
    name,
    created: Timestamp.now(),
    balance: protoInt64.parse(0),
    spent: protoInt64.parse(0),
    fixedThresholdAmount: 0,
    permissions,
    type,
    individualId,
    programReserved: protoInt64.parse(0),
    isHidden: false,
  })
  try {
    return await addDoc(colRef, newAccount)
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createAccount: {
          name,
          orgId,
          permissions,
          type,
        },
      },
    })
    return undefined
  }
}

export async function updateAccountPermissions({
  orgId,
  accountId,
  permissions,
}: {
  orgId: string
  accountId: string
  permissions: Record<string, Account_Permission>
}) {
  if (!orgId) return
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}/accounts/${accountId}`)
  const account = messageToFirestore(Account, { permissions })
  await updateDoc(docRef, {
    permissions: account.permissions,
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateAccountPermissions: {
          orgId,
          accountId,
          permissions,
        },
      },
    })
  })
}

export async function updateAccountThreshold({
  orgId,
  accountId,
  fixedThresholdAmount,
}: {
  orgId: string
  accountId: string
  fixedThresholdAmount: number
}) {
  if (!orgId) return
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}/accounts/${accountId}`)
  const account = messageToFirestore(Account, { fixedThresholdAmount })
  await updateDoc(docRef, {
    fixedThresholdAmount: account.fixedThresholdAmount,
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateAccountThreshold: {
          orgId,
          accountId,
          fixedThresholdAmount,
        },
      },
    })
  })
}

export async function deleteAccount({
  accountId,
  orgId,
}: {
  accountId: string
  orgId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `organizations/${orgId}/accounts/${accountId}`)

    await setDoc(docRef, { isHidden: true }, { merge: true })

    toaster.notify('Account deleted')
  } catch (error) {
    console.error(error)
    toaster.warning('Error deleting account')
    captureException(toSentry(error), {
      contexts: {
        deleteAccount: {
          accountId,
          orgId,
        },
      },
    })
  }
}

export async function updateAccountName({
  orgId,
  accountId,
  name,
}: {
  orgId: string
  accountId: string
  name: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `organizations/${orgId}/accounts/${accountId}`)
    await updateDoc(docRef, {
      name,
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateAccountTitle: {
          orgId,
          accountId,
          name,
        },
      },
    })
  }
}
