import { Flex } from 'antd'
import { CountryIconGroup } from 'components/Countries'
import { PerkImage } from 'components/PerkImage'
import { SelectedRibbonWrapper } from 'components/Ribbons/SelectedRibbonWrapper'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  PUBLIC_GIFT_TAG,
} from 'constants/algolia'
import { NUMBER_GREEN, NUMBER_RED } from 'constants/colors'
import { WidthBreakpoints } from 'constants/layout'
import { OrgContext, UserContext } from 'context'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { isEmpty } from 'lodash-es'
import { useContext } from 'react'
import { AlgoliaProductClicked } from 'utils/Algolia'
import { PrepaidBadge } from './PrepaidBadge'

export function ProductGridCard({
  onClick,
  price,
  position,
  queryID,
  eventProps,
  productId,
  productVariantId,
  productImage,
  vendor,
  productName,
  cardWidth,
  showSelectedRibbon = false,
  outOfStock = false,
  showShipping = false,
  tagline,
  tags,
  cardCTA,
  iso3s,
  selectedRibbonText,
  showPrepaidBadge = false,
}: {
  onClick?: () => void
  productVariantId: string
  productId: string
  productName: string
  vendor: string | undefined
  productImage: string | undefined
  queryID?: string // Algolia queryID
  price?: string
  position?: number
  eventProps?: any
  cardWidth?: number
  showSelectedRibbon?: boolean
  outOfStock?: boolean
  showShipping?: boolean
  tagline?: string
  tags: string[]
  cardCTA?: JSX.Element
  iso3s?: string[]
  selectedRibbonText?: string
  showPrepaidBadge?: boolean
}) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const imageFit = tags.includes(PUBLIC_GIFT_TAG) ? 'cover' : 'contain'

  const showVendor = tags.includes(PUBLIC_GIFT_TAG)

  const handleClick = () => {
    if (!onClick) return
    AlgoliaProductClicked({
      orgId: org.id,
      userId: user.id,
      userToken: user.id,
      productId,
      productVariantId,
      index: ALGOLIA_PRODUCT_VARIANTS_INDEX,
      eventType: 'click',
      queryID,
      objectIDs: [productVariantId],
      positions: [position],
      ...eventProps,
    })
    onClick()
  }

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 0,
      }}
    >
      <SelectedRibbonWrapper
        show={showSelectedRibbon}
        text={selectedRibbonText}
      >
        {showPrepaidBadge && (
          <Flex
            style={{
              position: 'absolute',
              top: 8,
              left: 8,
              zIndex: 10,
            }}
          >
            <PrepaidBadge />
          </Flex>
        )}
        <Pane
          display="flex"
          flexDirection="column"
          alignItems="center"
          cursor={!onClick ? undefined : 'pointer'}
          onClick={handleClick}
          borderRadius={8}
          hoverElevation={!onClick ? undefined : 1}
          width={cardWidth || '100%'}
          overflow="hidden"
          padding={16}
        >
          <PerkImage
            src={productImage}
            sizes={`(max-width: ${WidthBreakpoints.XS}px) 50vw, (max-width: ${WidthBreakpoints.XL}px) 25vw, 15vw`}
            style={{
              width: '100%',
              aspectRatio: '1 / 1',
              objectFit: imageFit,
              borderRadius: 8,
            }}
          />

          <Flex
            vertical
            style={{
              width: '100%',
              alignItems: 'start',
              padding: '16px 24px',
            }}
            gap={8}
          >
            {showVendor && (
              <Heading size={300} color={org.primaryColor}>
                {vendor?.toUpperCase()}
              </Heading>
            )}
            <Text className="two-line-truncate">{productName}</Text>
            <Flex gap={16}>
              {price && (
                <Flex gap={16}>
                  <Strong color={NUMBER_GREEN}>{price}</Strong>
                  {showShipping && <Text color="muted">FREE shipping</Text>}
                </Flex>
              )}
              {outOfStock && <Text color={NUMBER_RED}>Out of stock</Text>}
            </Flex>
            {iso3s && !isEmpty(iso3s) && <CountryIconGroup iso3s={iso3s} />}
            {cardCTA}
            {tagline && <Text color="muted">{tagline}</Text>}
          </Flex>
        </Pane>
      </SelectedRibbonWrapper>
    </div>
  )
}
