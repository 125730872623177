import SyncOutlined from '@ant-design/icons/SyncOutlined'
import { captureException } from '@sentry/react'
import { Tooltip } from 'antd'
import Button from 'antd/es/button'
import { ThemeEditorModal } from 'components'
import { Image, Pane, toaster } from 'evergreen-ui'
import { DocumentReference, setDoc } from 'firebase/firestore'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import { CustomAction } from 'gen/perkup/v1/rules_pb'
import capitalize from 'lodash-es/capitalize'
import isFunction from 'lodash-es/isFunction'
import { useMemo } from 'react'
import { NotificationOccassions } from 'types/Notifications'
import { getNotificationOccassionByPath } from 'utils'
import { toSentry } from 'utils/sentry'

export function EditableImageCell({
  docRef,
  columnTitle,
  currentValue,
  isInDrawer,
  onSubmit,
}: {
  docRef?: DocumentReference<CustomAction>
  columnTitle?: string
  currentValue: string
  isInDrawer?: Boolean
  onSubmit?: (newImage: string) => void
}) {
  const handleSubmit = (newImage: string) => {
    if (onSubmit && isFunction(onSubmit)) {
      onSubmit(newImage)
    }

    if (docRef) {
      setDoc(
        docRef,
        {
          image: newImage,
        },
        { merge: true }
      )
        .then(() => {
          toaster.success(`${capitalize(columnTitle)} updated`, {
            id: 'image',
          })
        })
        .catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              EditableImageCell: {
                docRef,
                columnTitle,
                currentValue,
              },
            },
          })
          toaster.warning('Something went wrong, try again or contact support.')
        })
    }
  }

  const ruleOccasion = getNotificationOccassionByPath(window.location.pathname)
  const programOccasion = useMemo(() => {
    if (ruleOccasion === NotificationOccassions.BIRTHDAY) {
      return ProgramOccasion.birthday
    }
    if (ruleOccasion === NotificationOccassions.ANNIVERSARY) {
      return ProgramOccasion.workAnniversary
    }
    return ProgramOccasion.newEmployee
  }, [ruleOccasion])

  if (isInDrawer) {
    return (
      <Pane display="flex" alignItems="center" gap="8px">
        <Image width={300} borderRadius={4} src={currentValue} />
        <ThemeEditorModal
          defaultOccasion={programOccasion}
          onConfirmImage={handleSubmit}
          modalTrigger={
            <Tooltip title="Change image">
              <Button type="text" icon={<SyncOutlined />} size="small" />
            </Tooltip>
          }
        />
      </Pane>
    )
  }

  return (
    <Pane display="flex" alignItems="center" gap={8}>
      <ThemeEditorModal
        defaultOccasion={programOccasion}
        onConfirmImage={handleSubmit}
        modalTrigger={
          <Tooltip title="Change">
            <Image width={50} borderRadius={4} src={currentValue} />
          </Tooltip>
        }
      />
    </Pane>
  )
}
