import { captureException } from '@sentry/react'
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  setDoc,
} from 'firebase/firestore'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { toSentry } from 'utils'
import { converter, storedId } from 'utils/firestore'

export async function createOrgTemplate({
  template,
  orgId,
}: {
  template: Template
  orgId: string
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `/organizations/${orgId}/templates`
  ).withConverter(converter(Template))

  return addDoc(colRef, template).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createOrgTemplate: { orgId, ...template },
      },
    })
  })
}

export async function updateOrgTemplate({
  template,
  orgId,
}: {
  template: Template
  orgId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `/organizations/${orgId}/templates/${template.id}`
    ).withConverter(storedId(Template))

    await setDoc(docRef, template, { merge: false })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateOrgTemplate: { orgId, ...template },
      },
    })
  }
}

export async function deleteOrgTemplate({
  templateId,
  orgId,
}: {
  templateId: string
  orgId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `/organizations/${orgId}/templates/${templateId}`
  ).withConverter(storedId(Template))

  return setDoc(docRef, { isDeleted: true }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        deleteOrgTemplate: { orgId, templateId },
      },
    })
  })
}
