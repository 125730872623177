// Create a new reward for an organization
import { Flex } from 'antd'
import { AccountBalanceForm, FooterWithCTA } from 'components'
import { allApprovedCats } from 'constants/EligibleCategories'
import { PROGRAM_STEP_COMPLETED } from 'constants/events'
import { DEFAULT_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import { COLLECTION_IDS } from 'constants/productCollections'
import { RewardSteps } from 'constants/rewards'
import { DEFAULT_ROUTES, RECIPIENT } from 'constants/routes'
import { ProgramContext, TemplateContext } from 'context'
import { toaster } from 'evergreen-ui'
import { SelectReward } from 'features'
import { deleteField } from 'firebase/firestore'
import {
  Program_DraftDataField,
  Program_Gift,
  Program_RewardTab,
} from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { getProgramPriceCalculation, logEvent, numToDollars } from 'utils'
import { useFormatDraftProgram } from './hooks/useFormatDraftProgram'
import {
  buildProgramGiftFromProductCollection,
  formatGiftFieldValues,
} from './utils/program-gifts'
import { parseDraftData } from './utils/programs'

const DRAFT_FIELDS = {
  [Program_RewardTab.swag]: 'swagGift',
  [Program_RewardTab.catalog]: 'catalogGift',
  [Program_RewardTab.giftCard]: 'giftCardGift',
} as const

type DraftFields = (typeof DRAFT_FIELDS)[keyof typeof DRAFT_FIELDS]

const getDraftGiftFields = ({
  gift,
  currentTab,
}: {
  gift: Program_Gift | undefined
  currentTab: keyof typeof DRAFT_FIELDS
}) => {
  const res: Partial<Record<DraftFields, Program_Gift | undefined>> = {}

  Object.values(DRAFT_FIELDS).forEach(field => {
    res[field] = field === DRAFT_FIELDS[currentTab] ? gift : undefined
  })

  return res
}

function useCurrentTab({
  draftData,
  handleProgramSave,
}: {
  draftData: Program_DraftDataField | undefined
  handleProgramSave: ReturnType<
    typeof useFormatDraftProgram
  >['handleProgramSave']
}) {
  const { orgId } = useIds()
  const template = useContext(TemplateContext)

  const [currentTab, setCurrentTab] = useState<Program_RewardTab>(
    () => draftData?.rewardTab || Program_RewardTab.perkupDollars
  )
  const handleUpdateCurrentTab = useCallback(
    async (value: Program_RewardTab) => {
      setCurrentTab(value)

      const { swagGift, catalogGift, giftCardGift, perkupDollars } =
        draftData ?? {}

      const updatedDraftData = new Program_DraftDataField({
        ...draftData,
        rewardTab: value,
      }).toJsonString()

      if (value === Program_RewardTab.perkupDollars) {
        handleProgramSave({
          draftData: updatedDraftData,
          gift: deleteField(),
          budget: perkupDollars?.amount,
          approvedCategories: perkupDollars?.approvedCategories,
          predefinedAddress: deleteField(),
        })
      } else if (value === Program_RewardTab.swag) {
        const updatedPriceCalc = swagGift
          ? await getProgramPriceCalculation({
              gift: swagGift,
              orgId,
            })
          : undefined
        const updatedBudget = updatedPriceCalc?.totalCost

        handleProgramSave({
          draftData: updatedDraftData,
          gift: swagGift,
          budget: updatedBudget,
          approvedCategories: allApprovedCats,
        })
      } else if (value === Program_RewardTab.catalog) {
        if (!catalogGift) {
          const collectionGift = buildProgramGiftFromProductCollection(
            COLLECTION_IDS[0]
          )
          const formattedCollectionGift = formatGiftFieldValues({
            gift: collectionGift,
          })
          handleProgramSave({
            draftData: updatedDraftData,
            gift: formattedCollectionGift,
            budget:
              template?.budget?.maxAmount || DEFAULT_PROGRAM_BUDGET_AMOUNT,
            approvedCategories: allApprovedCats,
            predefinedAddress: deleteField(),
          })
          return
        }

        const giftIsCollection = catalogGift?.productCollectionId

        const giftAmount = (
          catalogGift?.productCollectionId
            ? undefined
            : await getProgramPriceCalculation({
                gift: catalogGift,
                orgId,
              })
        )?.totalCost

        const collectionAmount = draftData?.collectionAmount

        const updatedBudget = giftIsCollection ? collectionAmount : giftAmount

        handleProgramSave({
          draftData: updatedDraftData,
          gift: catalogGift,
          budget: updatedBudget,
          approvedCategories: allApprovedCats,
        })
      } else if (value === Program_RewardTab.giftCard) {
        const updatedBudget = giftCardGift
          ? (
              await getProgramPriceCalculation({
                gift: giftCardGift,
                orgId,
              })
            )?.totalCost
          : 0
        handleProgramSave({
          draftData: updatedDraftData,
          gift: giftCardGift,
          budget: updatedBudget,
          approvedCategories: allApprovedCats,
          predefinedAddress: deleteField(),
        })
      }
    },
    [draftData, handleProgramSave, orgId, template?.budget?.maxAmount]
  )

  return { currentTab, handleUpdateCurrentTab }
}

export function EditReward() {
  const program = useContext(ProgramContext)
  const { userId, orgId } = useIds()
  const navigate = useNavigate()
  const template = useContext(TemplateContext)

  const {
    isLoadingSave,
    handleProgramSave,
    hasLoadedDraftProgram,
    selectedAccount,
    setSelectedAccount,
  } = useFormatDraftProgram({ program })

  const draftData = useMemo(() => parseDraftData({ program }), [program])

  const { currentTab, handleUpdateCurrentTab } = useCurrentTab({
    draftData,
    handleProgramSave,
  })

  const stepCompletedInfo = {
    step: RewardSteps.REWARD,
    orgId,
    userId,
    programId: program.id,
    ...program,
  }

  const handleContinue = () => {
    logEvent(PROGRAM_STEP_COMPLETED, {
      ...stepCompletedInfo,
    })

    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${RECIPIENT}`
    )
  }

  const currentGift = useMemo(() => {
    switch (currentTab) {
      case Program_RewardTab.catalog:
        return draftData?.catalogGift
      case Program_RewardTab.giftCard:
        return draftData?.giftCardGift
      case Program_RewardTab.swag:
        return draftData?.swagGift
      default:
        return undefined
    }
  }, [
    currentTab,
    draftData?.catalogGift,
    draftData?.giftCardGift,
    draftData?.swagGift,
  ])

  const reward =
    currentTab === Program_RewardTab.perkupDollars
      ? {
          gift: undefined,
          budget: draftData?.perkupDollars?.amount ?? 0,
          approvedCategories:
            draftData?.perkupDollars?.approvedCategories ?? [],
        }
      : {
          gift: currentGift,
          budget: program.budget,
          approvedCategories: program.approvedCategories,
        }

  const handlePerkUpDollarsChange = async (
    newReward: Partial<typeof reward>
  ) => {
    const cats = newReward.approvedCategories ?? []

    const newDraftData = new Program_DraftDataField({
      ...draftData,
      rewardTab: currentTab,
      perkupDollars: {
        amount: newReward.budget,
        approvedCategories: cats,
      },
    }).toJsonString()
    const allMerchantsEnabled = cats.length > 1

    handleProgramSave({
      approvedCategories: cats,
      allMerchants: allMerchantsEnabled,
      budget: newReward.budget,
      draftData: newDraftData,
    })
  }

  const handleRewardChange = async (
    newReward: Partial<typeof reward>,
    { advance }: { advance?: boolean } = {}
  ) => {
    if (currentTab === Program_RewardTab.perkupDollars) {
      handlePerkUpDollarsChange(newReward)
      return
    }

    if (currentTab === Program_RewardTab.REWARD_TAB_UNSPECIFIED) {
      return
    }

    const maxBudget = template?.budget?.maxAmount
    if (maxBudget && newReward.budget && newReward.budget > maxBudget) {
      toaster.warning(`Budget cannot exceed $${numToDollars(maxBudget, 0)}`)
      return
    }

    const isCatalogCollection =
      currentTab === Program_RewardTab.catalog &&
      !!newReward.gift?.productCollectionId

    const updatedDraftData = new Program_DraftDataField({
      ...draftData,
      ...getDraftGiftFields({ gift: newReward.gift, currentTab }),
      collectionAmount: isCatalogCollection ? newReward.budget : undefined,
    }).toJsonString()

    await handleProgramSave({
      draftData: updatedDraftData,
      gift: newReward.gift
        ? formatGiftFieldValues({ gift: newReward.gift })
        : deleteField(),
      budget: newReward.budget,
    })
    if (advance) {
      handleContinue()
    }
  }

  return (
    <Flex vertical align="center" gap={32}>
      <SelectReward.Context
        selectedReward={reward}
        onSelectReward={handleRewardChange}
        onTabChange={handleUpdateCurrentTab}
        initialTab={draftData?.rewardTab}
      >
        <SelectReward.Form>
          <SelectReward.Form.Slot name="tabs">
            <SelectReward.Form.Tabs />
          </SelectReward.Form.Slot>

          <SelectReward.Form.Slot name="perkupDollars">
            <SelectReward.Form.Dollars1 />
          </SelectReward.Form.Slot>

          <SelectReward.Form.Slot name="swag">
            <SelectReward.Form.Swag withHeadings />
          </SelectReward.Form.Slot>

          <SelectReward.Form.Slot name="giftCatalog">
            <SelectReward.Form.Gift1 />
          </SelectReward.Form.Slot>

          <SelectReward.Form.Slot name="giftCard">
            <SelectReward.Form.Card1 />
          </SelectReward.Form.Slot>
        </SelectReward.Form>

        <FooterWithCTA
          ctaText="Continue"
          onCTAClick={handleContinue}
          isSaving={isLoadingSave}
        >
          <Flex gap={16} align="center">
            <SelectReward.CurrentSelectedRewardPopover />
            <SelectReward.SwagRedemptionPopover />
            {hasLoadedDraftProgram && (
              <AccountBalanceForm
                setSelectedAccount={value => {
                  setSelectedAccount(value)
                  if (!value) return
                  handleProgramSave({
                    accountId: value.id,
                  })
                }}
                selectedAccount={selectedAccount}
                size="large"
              />
            )}
          </Flex>
        </FooterWithCTA>
      </SelectReward.Context>
    </Flex>
  )
}
