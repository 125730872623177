import { Select, Tag } from 'antd'
import {
  adminAccountPermissionsOptions,
  individualAccountPermissionsOptions,
  managerAccountPermissionsOptions,
} from 'constants/accounts'
import { Avatar, Pane, Text } from 'evergreen-ui'
import { useListIndividualsByIds } from 'hooks/individuals/useListIndividualsByIds'
import useIds from 'hooks/useIds'
import { cloneDeep } from 'lodash-es'
import { useMemo } from 'react'
import {
  PERMISSION_ADMIN,
  PERMISSION_MANAGER,
  Permission,
  PermissionOption,
  Permissions,
} from 'types/Permissions'
import { PermissonSelect } from './PermissionSelect'
import { PermissionsIndividualSelectWrapper } from './PermissionsIndividualSelectWrapper'

export function PermissionsOverview({
  currentPermissions,
  onPermissionsChange,
  adminPermissionOptions,
  managerPermissionOptions,
  individualPermissionOptions,
  isModal,
}: {
  currentPermissions: Permissions
  onPermissionsChange: (newPermissions: Permissions) => void
  adminPermissionOptions: PermissionOption[]
  managerPermissionOptions: PermissionOption[]
  individualPermissionOptions: PermissionOption[]
  isModal: boolean
}) {
  const { individualId } = useIds()

  // WARNING: Memozing currentIndividualIds because the PermissionsIndividualSelectWrapper triggers unnecessary re-renders / callbacks, which in turn is affecting the useListIndividualsByIds hook below
  const currentIndividualIds = useMemo(
    () =>
      Object.keys(currentPermissions).filter(
        id => ![PERMISSION_ADMIN, PERMISSION_MANAGER].includes(id)
      ),
    [currentPermissions]
  )

  const { individuals: currentIndividuals } = useListIndividualsByIds({
    individualIds: currentIndividualIds,
  })

  const handleSelectPermission = (
    updatedPermission: Permission,
    permissionKey: string // ACCOUNT_ADMIN or ACCOUNT_MANAGER or individualId
  ) => {
    const newPermissions = cloneDeep(currentPermissions)

    if (updatedPermission === 0 && currentPermissions[permissionKey]) {
      delete newPermissions[permissionKey]
      onPermissionsChange(newPermissions)
      return
    }
    newPermissions[permissionKey] = updatedPermission
    onPermissionsChange(newPermissions)
  }

  const defaultAdminPermission =
    currentPermissions[PERMISSION_ADMIN] ||
    adminAccountPermissionsOptions[2].value // Fallback to the last option in the admin options

  const defaultManagerPermission =
    currentPermissions[PERMISSION_MANAGER] ||
    managerAccountPermissionsOptions[3].value // Fallback to the last option in the manager options

  return (
    <>
      {/** ADMIN PERMISSIONS ROW */}
      <Pane display="flex" justifyContent="space-between">
        <Text>All admins</Text>
        <PermissonSelect
          currentPermission={defaultAdminPermission}
          handleSelectPermission={p =>
            handleSelectPermission(p, PERMISSION_ADMIN)
          }
        >
          {adminPermissionOptions.map(permission => (
            <Select.Option
              label={permission.name}
              key={permission.value}
              value={permission.value}
            >
              <Pane display="flex" flexDirection="column">
                <Text size={500}>{permission.name}</Text>
                <Text color="muted">{permission.description}</Text>
              </Pane>
            </Select.Option>
          ))}
        </PermissonSelect>
      </Pane>

      {/** MANAGER PERMISSIONS ROW */}
      <Pane display="flex" justifyContent="space-between" paddingBottom={8}>
        <Text>All managers</Text>
        <PermissonSelect
          currentPermission={defaultManagerPermission}
          handleSelectPermission={p =>
            handleSelectPermission(p, PERMISSION_MANAGER)
          }
        >
          {managerPermissionOptions.map(permission => (
            <Select.Option
              label={permission.name}
              key={permission.value}
              value={permission.value}
            >
              <Pane display="flex" flexDirection="column">
                <Text size={500}>{permission.name}</Text>
                <Text color="muted">{permission.description}</Text>
              </Pane>
            </Select.Option>
          ))}
        </PermissonSelect>
      </Pane>

      {/** INDIVIDUALS PERMISSIONS ROW */}
      <Text>Add specific users</Text>

      <Pane maxHeight={isModal ? '250px' : ''} overflowY="auto" marginTop={4}>
        {/** INDIVIDUALS SEARCH AND SELECT */}
        <PermissionsIndividualSelectWrapper
          currentPermissions={currentPermissions}
          currentIndividualIds={currentIndividualIds}
          onPermissionsChange={onPermissionsChange}
        />

        {/** INDIVIDUALS CURRENTLY IN THE PERMISSIONS SETTINGS */}
        <Pane display="flex" flexDirection="column" gap={16} marginTop={16}>
          {currentIndividuals.map(individual => (
            <Pane
              key={individual.id}
              display="flex"
              justifyContent="space-between"
            >
              <Pane display="flex" gap={16}>
                <Avatar
                  size={36}
                  name={`${individual.firstName} ${individual.lastName}`}
                />
                <Pane display="flex" flexDirection="column">
                  <Pane display="flex" gap={8} alignItems="center">
                    <Text>
                      {individual.firstName} {individual.lastName}
                    </Text>
                    {individual.id === individualId && (
                      <Tag color="green">You</Tag>
                    )}
                  </Pane>
                  <Text>{individual.email}</Text>
                </Pane>
              </Pane>
              <PermissonSelect
                currentPermission={
                  currentPermissions[individual.id] ||
                  individualAccountPermissionsOptions[0].value
                }
                handleSelectPermission={p =>
                  handleSelectPermission(p, individual.id)
                }
              >
                {individualPermissionOptions.map(permission => (
                  <Select.Option
                    key={permission.value}
                    label={permission.name}
                    value={permission.value}
                  >
                    <Pane display="flex" flexDirection="column">
                      <Text
                        size={500}
                        style={{
                          color: permission.value === 0 ? 'red' : undefined,
                        }}
                      >
                        {permission.name}
                      </Text>
                      <Text color="muted">{permission.description}</Text>
                    </Pane>
                  </Select.Option>
                ))}
              </PermissonSelect>
            </Pane>
          ))}
        </Pane>
      </Pane>
    </>
  )
}
