// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/cart.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { ProductVariant_Provider, ProductVariant_Type } from "./product_variant_pb.js";

/**
 * Firestore /carts
 *
 * @generated from message perkup.v1.Cart
 */
export class Cart extends Message<Cart> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 2;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 3;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: string individual_id = 4;
   */
  individualId = "";

  /**
   * @generated from field: string org_id = 5;
   */
  orgId = "";

  /**
   * @generated from field: bool is_active = 6;
   */
  isActive = false;

  /**
   * @generated from field: repeated perkup.v1.Cart.Item line_items = 7;
   */
  lineItems: Cart_Item[] = [];

  constructor(data?: PartialMessage<Cart>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cart";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 4, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "line_items", kind: "message", T: Cart_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cart {
    return new Cart().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cart {
    return new Cart().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cart {
    return new Cart().fromJsonString(jsonString, options);
  }

  static equals(a: Cart | PlainMessage<Cart> | undefined, b: Cart | PlainMessage<Cart> | undefined): boolean {
    return proto3.util.equals(Cart, a, b);
  }
}

/**
 * @generated from message perkup.v1.Cart.Item
 */
export class Cart_Item extends Message<Cart_Item> {
  /**
   * @generated from field: string product_variant_id = 1;
   */
  productVariantId = "";

  /**
   * @generated from field: string product_id = 2;
   */
  productId = "";

  /**
   * @generated from field: int32 quantity = 3;
   */
  quantity = 0;

  /**
   * @generated from field: perkup.v1.ProductVariant.Provider provider = 4;
   */
  provider = ProductVariant_Provider.PROVIDER_UNSPECIFIED;

  /**
   * @generated from field: perkup.v1.ProductVariant.Type type = 5;
   */
  type = ProductVariant_Type.TYPE_UNSPECIFIED;

  constructor(data?: PartialMessage<Cart_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Cart.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "provider", kind: "enum", T: proto3.getEnumType(ProductVariant_Provider) },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(ProductVariant_Type) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Cart_Item {
    return new Cart_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Cart_Item {
    return new Cart_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Cart_Item {
    return new Cart_Item().fromJsonString(jsonString, options);
  }

  static equals(a: Cart_Item | PlainMessage<Cart_Item> | undefined, b: Cart_Item | PlainMessage<Cart_Item> | undefined): boolean {
    return proto3.util.equals(Cart_Item, a, b);
  }
}

