import { Pane } from 'evergreen-ui'
import { Discover } from 'pages/Marketplace/Discover'
import { Outlet } from 'react-router'
import Hero from './components/Hero'

export default function Dashboard() {
  return (
    <Pane display="flex" flexDirection="column" gap={32}>
      <Hero />
      {/* This outlet needed for rendering perk card setup */}
      <Outlet />
      <Discover />
    </Pane>
  )
}
