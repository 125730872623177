import {
  CalendarOutlined,
  ClockCircleOutlined,
  DownOutlined,
  GiftTwoTone,
  HourglassOutlined,
  ReloadOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { Button, Flex, Popover, Progress, Tooltip } from 'antd'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Heading, Pane, Strong, Text, useTheme } from 'evergreen-ui'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { ProductVariant_Provider } from 'gen/perkup/v1/product_variant_pb'
import {
  Member,
  Member_ConvertedTo,
  Program,
  ProgramFrequency,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { Order, OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { flatten, isEmpty } from 'lodash-es'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  getProgramEndDateCountdown,
  makePlural,
  numToDollars,
  redemptionStatus,
} from 'utils'
import { getOrderTrackingNumbers } from 'utils/orders'
import { getDateDisplayString } from 'utils/programs'
import { GiftStatusTag } from './GiftStatusTag'
import { TrackingList } from './Orders'
import { PerkImage } from './PerkImage'
import { ItemInfo } from './ProductVariants'
import { ProgramStatusTag } from './Programs/ProgramStatusTag'
import { RemoveMember } from './Tables/Users/MemberTable'

const iconProps: React.CSSProperties = {
  color: 'gray',
  height: 12,
  width: 12,
  marginRight: 4,
}

export default function SidesheetRewardCard({
  program,
  member,
  orderFulfillments,
  orgTransactions,
  orders,
  onAfterRemove,
}: {
  program: Program
  member: Member
  orderFulfillments: OrderFulfillment[]
  orgTransactions: OrgTransaction[]
  orders: Order[]
  onAfterRemove?: (id: string) => void
}) {
  const { id: programId, name, gift } = program
  const { budget: memberBudget, spent } = member
  const [showRemoveIcon, setShowRemoveIcon] = useState(false)
  const theme = useTheme()
  const isGift = !!gift && member?.convertedTo !== Member_ConvertedTo.nearCash

  const imageUrl = program.email?.banner

  const dateDisplayString = getDateDisplayString({
    program,
  })

  const expirationCountdown = getProgramEndDateCountdown({ program })

  const { thanksMessage } = member

  const { isRedeemedGift } = redemptionStatus({
    program,
    member,
  })

  const isMultiItemGift =
    gift && (gift.productCollectionId || gift.productIds.length > 1)

  const expiredProgram =
    program.endsOn && program.endsOn.seconds < Timestamp.now().seconds
  const futureProgram =
    program.startsOn && program.startsOn.seconds > Timestamp.now().seconds

  const showExpiry = !isRedeemedGift && expiredProgram
  const showDateString = expiredProgram ? showExpiry : !!dateDisplayString
  const showProgramStatus = expiredProgram ? showExpiry : true

  const percentRemaining = Math.round((spent / memberBudget) * 100)

  const programBudgetString = () => {
    if (program.frequency !== ProgramFrequency.once) {
      return `${numToDollars(memberBudget, 2)} (${numToDollars(program.budget, 2)} ${ProgramFrequency[program.frequency]})`
    }
    return `${numToDollars(memberBudget, 2)}`
  }

  const PerkProgramDateIcon = program?.rollover ? SyncOutlined : ReloadOutlined
  const getOneTimeProgramIcon = () => {
    if (futureProgram) return CalendarOutlined
    if (expiredProgram) return ClockCircleOutlined
    return HourglassOutlined
  }
  const OneTimeProgramIcon = getOneTimeProgramIcon()

  // Navigate to the recurring program page if the program is not a one-time program, else just go to the spot rewards page
  const linkDestination =
    program.type === ProgramType.perks
      ? `${DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.ROOT}/${programId}`
      : `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${programId}`

  const flattenedOrders = flatten(orders.map(order => order.items))

  return (
    <Pane
      is={Link}
      to={linkDestination}
      marginY={8}
      borderRadius={8}
      hoverElevation={1}
      cursor="pointer"
      padding={16}
      onMouseEnter={() => setShowRemoveIcon(true)}
      onMouseLeave={() => setShowRemoveIcon(false)}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex vertical gap={8} style={{ width: '100%' }}>
        <Flex gap={16} style={{ width: '100%' }}>
          <PerkImage
            src={imageUrl || program.email?.banner} // Some swag collection programs in the past were created without an imageUrl, so fallback to the email banner
            style={{
              height: 80,
              width: 80,
              objectFit: 'cover',
              borderRadius: 8,
            }}
            sizes="80px"
          />
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <Flex justify="space-between" style={{ width: '100%' }}>
              <Flex gap={8} align="center">
                <Heading size={400}>{name}</Heading>
                {showProgramStatus && <ProgramStatusTag program={program} />}
              </Flex>
              {!isRedeemedGift && (
                <Pane visibility={showRemoveIcon ? 'visible' : 'hidden'}>
                  <RemoveMember
                    programId={program.id}
                    memberId={member.id}
                    onAfterRemove={() => {
                      if (onAfterRemove) onAfterRemove(member.id)
                    }}
                  />
                </Pane>
              )}
            </Flex>
            {!isGift && (
              <Flex vertical style={{ width: '100%' }}>
                <Flex justify="space-between">
                  <Text size={500}>{`${numToDollars(spent, 2)} spent`}</Text>
                  <Flex gap={8}>
                    {member.convertedTo === Member_ConvertedTo.nearCash && (
                      <Tooltip title="Converted from gift">
                        <GiftTwoTone />
                      </Tooltip>
                    )}
                    <Text size={500}>{programBudgetString()}</Text>
                  </Flex>
                </Flex>
                <Progress
                  trailColor={
                    expiredProgram
                      ? theme.colors.gray100
                      : theme.colors.green100
                  }
                  strokeColor={
                    expiredProgram
                      ? theme.colors.gray500
                      : theme.colors.green500
                  }
                  percent={percentRemaining}
                  strokeLinecap="round"
                  showInfo={false}
                />
                <Flex justify="space-between">
                  <Text color="muted">{`${numToDollars(memberBudget - spent, 2)} remaining`}</Text>
                  {(showExpiry || showDateString) && (
                    <Flex gap={4} align="center">
                      {(showDateString || showExpiry) && (
                        <Text whiteSpace="nowrap" color="muted">
                          {program?.resetsOn ? (
                            <PerkProgramDateIcon style={iconProps} />
                          ) : (
                            <OneTimeProgramIcon style={iconProps} />
                          )}
                          {showDateString
                            ? dateDisplayString
                            : expirationCountdown}
                        </Text>
                      )}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}
            {isGift && (
              <Flex gap={8}>
                <GiftStatusTag member={member} />
                {!isEmpty(orders) && (
                  <Popover
                    content={
                      <Flex vertical gap={8}>
                        {isMultiItemGift && (
                          <Flex justify="space-between">
                            {gift?.productCollectionId && (
                              // Gift title same as the collection title
                              <Strong>{gift.title}</Strong>
                            )}
                            {!gift.redeemableQuantity && (
                              // If no redeemable quantity, show budget
                              <Text color="muted">
                                {numToDollars(program.budget, 2)} per person
                              </Text>
                            )}
                          </Flex>
                        )}
                        <Text color="muted">
                          {`${flattenedOrders.length}
                          ${makePlural('item', flattenedOrders.length)} per
                          person`}
                        </Text>
                        {orders.map(order => {
                          const { items, id: orderId } = order
                          const orderFulfillmentsForOrder =
                            orderFulfillments.filter(
                              of => of.orderId === orderId
                            )
                          const orgTransactionsForOrder =
                            orgTransactions.filter(ot => ot.id === orderId)

                          const trackingInfo = getOrderTrackingNumbers({
                            orderFulfillments: orderFulfillmentsForOrder,
                            orgTransactions: orgTransactionsForOrder,
                          })

                          return items.map(orderItem => (
                            <Flex
                              vertical
                              gap={4}
                              key={
                                orderItem.productVariantId || orderItem.vendorId
                              }
                            >
                              <ItemInfo
                                image={orderItem.image}
                                imageSize={56}
                                imageFit={
                                  orderItem.provider ===
                                  ProductVariant_Provider.plum
                                    ? 'contain'
                                    : 'cover'
                                }
                                headingSize={300}
                                containerStyles={{ justifyContent: 'center' }}
                              >
                                <Flex vertical gap={4}>
                                  <Text>{orderItem.title}</Text>
                                  {!isEmpty(trackingInfo) && (
                                    <TrackingList
                                      trackingNumbers={trackingInfo}
                                    />
                                  )}
                                </Flex>
                              </ItemInfo>
                            </Flex>
                          ))
                        })}
                      </Flex>
                    }
                  >
                    <Button type="text" size="small">
                      {`${flattenedOrders.length}
                          ${makePlural('item', flattenedOrders.length)}`}
                      <DownOutlined />
                    </Button>
                  </Popover>
                )}
              </Flex>
            )}
            {(showExpiry || showDateString) && isGift && (
              <Flex gap={4} align="center">
                {(showDateString || showExpiry) && (
                  <Text whiteSpace="nowrap" color="muted">
                    <OneTimeProgramIcon style={iconProps} />
                    {showDateString ? dateDisplayString : expirationCountdown}
                  </Text>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>

        {thanksMessage && <Text color="muted">{`"${thanksMessage}"`}</Text>}
      </Flex>
    </Pane>
  )
}
