import { Flex } from 'antd'
import {
  AlgoliaDropdownFilter,
  AlgoliaSearchBox,
  Loader,
  ProductCount,
} from 'components'
import { ALGOLIA_PRODUCT_VARIANTS_INDEX } from 'constants/algolia'
import { ORG_SWAG_SEARCH_QUERY } from 'constants/sessionOrLocalStorage'
import { Pane } from 'evergreen-ui'
import { useProductVariantSearchClient } from 'hooks'
import useIds from 'hooks/useIds'
import { Configure, InstantSearch } from 'react-instantsearch'
import { getAlgoliaOrgSwagFilters } from 'utils/Algolia'
import { AlgoliaBrowseSwagTable } from './AlgoliaBrowseSwagTable'

/**
 * @deprecated This only gets used in the OrgSwag products tab, which will be converted over to using our AlgoliaProductsGrid soon
 */
export function AlgoliaBrowseSwag() {
  const { searchClient } = useProductVariantSearchClient()
  const { orgId } = useIds()

  if (!searchClient) return <Loader />

  const searchFilter = getAlgoliaOrgSwagFilters({ orgId })

  return (
    <InstantSearch
      indexName={ALGOLIA_PRODUCT_VARIANTS_INDEX}
      searchClient={searchClient}
      initialUiState={{
        [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
          query: sessionStorage.getItem(ORG_SWAG_SEARCH_QUERY) || '',
        },
      }}
    >
      <Configure filters={searchFilter} hitsPerPage={40} />
      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Pane display="flex" gap={16} alignItems="center">
          <AlgoliaSearchBox
            placeholder="Search by product name"
            onChange={(value: string) => {
              sessionStorage.setItem(ORG_SWAG_SEARCH_QUERY, value)
            }}
          />
          <AlgoliaDropdownFilter name="Category" attribute="productType" />
          <ProductCount />
        </Pane>
        <AlgoliaBrowseSwagTable />
      </Flex>
    </InstantSearch>
  )
}
