import {
  AppstoreOutlined,
  GiftOutlined,
  SendOutlined,
  SkinOutlined,
  TruckOutlined,
} from '@ant-design/icons'
import { Button, Flex, Modal, Segmented } from 'antd'
import {
  ConvertableToOptions,
  FooterWithCTA,
  PageHeader,
  withOrgSidebar,
} from 'components'
import { SwitchAndText } from 'components/SwitchAndText'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  INITIAL_AMOUNT_FILTER,
} from 'constants/algolia'
import { PAGE_WIDTH_MINUS_SIDEBAR } from 'constants/layout'
import { DEFAULT_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import { GIFT_COLLECTION, GIFT_PRODUCTS } from 'constants/newReward'
import { COLLECTION_IDS } from 'constants/productCollections'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Paragraph, Text } from 'evergreen-ui'
import {
  AlgoliaBrowseCollections,
  AlgoliaBrowseProducts,
  ProductDetails,
} from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useIds from 'hooks/useIds'
import {
  buildProgramGiftAsGenericProduct,
  buildProgramGiftAsSpecificProductVariant,
  buildProgramGiftFromProductCollection,
} from 'pages/NewReward/utils/program-gifts'
import { useState } from 'react'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { ConvertableTo } from 'types/Gift'
import {
  calculateMaxVariantPriceByProductIds,
  calculateShopifyProductVariantTotal,
} from 'utils'
import { getAlgoliaShippingCountriesFilter } from 'utils/Algolia'
import { getProductVariantOptions } from 'utils/productVariant'

function BrowseGifts() {
  const { orgId } = useIds()
  const navigate = useNavigate()
  const location = useLocation()

  const [convertableTo, setConvertableTo] = useState<string[]>([
    ConvertableTo.gift,
  ])
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()
  const [selectedProductCollectionId, setSelectedProductCollectionId] =
    useState(COLLECTION_IDS[0])
  const [selectedProgramBudget, setSelectedProgramBudget] = useState<number>(
    DEFAULT_PROGRAM_BUDGET_AMOUNT
  )
  const [sendingSpecificVariant, setSendingSpecificVariant] = useState(false)

  const handleSendProductVariant = async (productVariant: ProductVariant) => {
    if (sendingSpecificVariant) {
      const total = calculateShopifyProductVariantTotal(productVariant)
      const gift = buildProgramGiftAsSpecificProductVariant({
        productVariant,
        convertableTo,
      })

      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
        state: { gift, budget: total },
      })
    } else {
      const total = await calculateMaxVariantPriceByProductIds({
        orgId,
        productIds: [productVariant.productId],
      })

      const gift = buildProgramGiftAsGenericProduct({
        productVariant,
        convertableTo,
      })

      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
        state: { gift, budget: total },
      })
    }
  }

  const handleSetProductVariantInView = (productVariant: ProductVariant) => {
    setProductVariantToShow(productVariant)
    setSendingSpecificVariant(false)
  }

  const handleConfirmProductCollection = (
    productCollectionId: string,
    programBudget: number
  ) => {
    setSelectedProductCollectionId(productCollectionId)
    setSelectedProgramBudget(programBudget)
  }

  const handleSendProductCollection = () => {
    const gift = buildProgramGiftFromProductCollection(
      selectedProductCollectionId
    )
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
      state: { gift, budget: selectedProgramBudget },
    })
  }

  const isOnCollectionsPage = location.pathname.includes('collections')

  const handleChangeTab = (tab: string) => {
    if (tab === GIFT_PRODUCTS) {
      navigate(DEFAULT_ROUTES.ORGANIZATION.GIFTS.ROOT)
    } else {
      navigate(DEFAULT_ROUTES.ORGANIZATION.GIFTS.COLLECTIONS)
    }
  }

  const giftFormToggle = (
    <Segmented
      style={{ width: 'fit-content' }}
      className="small-custom-segment"
      options={[
        { value: GIFT_PRODUCTS, label: GIFT_PRODUCTS, icon: <SkinOutlined /> },
        {
          value: GIFT_COLLECTION,
          label: GIFT_COLLECTION,
          icon: <AppstoreOutlined />,
        },
      ]}
      value={isOnCollectionsPage ? GIFT_COLLECTION : GIFT_PRODUCTS}
      onChange={handleChangeTab}
    />
  )

  const productHasOptions =
    Object.keys(productVariantToShow?.options || {}).length >= 1

  const shippingCountries = getAlgoliaShippingCountriesFilter()

  const defaultSelectedOptions = sendingSpecificVariant
    ? {
        options: getProductVariantOptions({
          productVariant: productVariantToShow,
        }),
        filterOtherOptions: false,
      }
    : undefined

  return (
    <Routes>
      <Route
        element={
          <>
            <Flex vertical gap={8}>
              <PageHeader
                title="Browse Gifts"
                description={
                  <Paragraph display="flex" gap={32}>
                    <Text size={500}>
                      Browse our selection of gifts to send to your recipients.
                    </Text>
                    <Text size={500} display="flex" gap={4} color="muted">
                      <TruckOutlined />
                      All products include free shipping
                    </Text>
                  </Paragraph>
                }
                vlogLink="https://www.loom.com/embed/23227a1f71104a8f9c6fed0cc5b141d1?sid=6ee05506-93e4-4a76-b08c-2167802c592e"
                buttonsToRenderOnLeft={
                  <Button
                    icon={<GiftOutlined />}
                    type="primary"
                    onClick={() =>
                      navigate(DEFAULT_ROUTES.ORGANIZATION.ORDER.GIFTS)
                    }
                  >
                    Order gift
                  </Button>
                }
              />
              <Outlet />
            </Flex>
            <Modal
              width={1280}
              centered
              title="‎"
              open={!!productVariantToShow}
              footer={false}
              onCancel={() => setProductVariantToShow(undefined)}
            >
              {productVariantToShow && (
                <ProductDetails
                  key={productVariantToShow.id}
                  productVariant={productVariantToShow}
                  showPrice
                  showShipping
                  showShippingCountries
                  withAmountInUsd
                  onPrimaryCtaClick={handleSendProductVariant}
                  primaryCtaProps={{ children: 'Send gift' }}
                  onSecondaryCtaClick={
                    isOnCollectionsPage
                      ? handleSendProductCollection
                      : undefined
                  }
                  secondaryCtaProps={
                    isOnCollectionsPage
                      ? { children: 'Send collection' }
                      : undefined
                  }
                  defaultSelected={defaultSelectedOptions}
                  disableVariantOptions={!sendingSpecificVariant}
                >
                  <ProductDetails.Slot name="aboveOptions">
                    {productHasOptions && (
                      <SwitchAndText
                        label="Let recipient select size & color"
                        checked={!sendingSpecificVariant}
                        onCheck={checked => setSendingSpecificVariant(!checked)}
                      />
                    )}
                  </ProductDetails.Slot>
                </ProductDetails>
              )}
            </Modal>
          </>
        }
      >
        <Route
          path="/"
          element={
            <AlgoliaBrowseProducts
              initialUiState={{
                [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
                  menu: { shippingCountries },
                },
              }}
            >
              <AlgoliaBrowseProducts.Old
                onProductCardClick={handleSetProductVariantInView}
                withCountryFilter
                withPriceFilter
                withProductAmounts
                withProductCount
                withCollectionsFilter
                withCategoriesFilter
                stickyNavTopAmount={60}
                extraElementsInFirstRow={
                  <Flex flex={1} justify="space-between">
                    <ConvertableToOptions
                      currentConvertableTo={convertableTo}
                      onConvertableToChange={setConvertableTo}
                    />
                    {giftFormToggle}
                  </Flex>
                }
              />
            </AlgoliaBrowseProducts>
          }
        />
        <Route
          path="/collections"
          element={
            <Flex vertical>
              <AlgoliaBrowseCollections
                onProductClick={handleSetProductVariantInView}
                onConfirmProductCollection={handleConfirmProductCollection}
                extraElementsInNavBar={giftFormToggle}
                initialUiState={{
                  [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
                    menu: { shippingCountries },
                    numericMenu: { amount: INITIAL_AMOUNT_FILTER },
                  },
                }}
              />
              <FooterWithCTA
                ctaText="Send collection"
                ctaIcon={<SendOutlined />}
                footerWidth={PAGE_WIDTH_MINUS_SIDEBAR}
                disabled={
                  !selectedProductCollectionId || !selectedProgramBudget
                }
                onCTAClick={handleSendProductCollection}
              />
            </Flex>
          }
        />
      </Route>
    </Routes>
  )
}

export default withOrgSidebar(BrowseGifts)
