import {
  Heading,
  Pane,
  PersonIcon,
  Text,
  Tooltip,
  useTheme,
} from 'evergreen-ui'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { getAmountSpent } from 'utils/programs'

export interface IMembershipBalanceStyles {
  className?: string
  color?: string
}
export interface IProgramHeader {
  activeProgram: boolean
  isNew: boolean
  name: string
  rewardId: string
  availableSpend: number
  exchangeRate: number
  userDisplayCurrency: string | undefined
  orgDisplayCurrency: string | undefined
  oneTimeProgram: boolean
  programType: ProgramType
}

export function ProgramHeader({
  activeProgram,
  isNew,
  name,
  rewardId,
  availableSpend,
  exchangeRate,
  userDisplayCurrency,
  orgDisplayCurrency,
  oneTimeProgram,
  programType,
}: IProgramHeader) {
  const theme = useTheme()

  const membershipBalanceStyles: IMembershipBalanceStyles =
    activeProgram && availableSpend > 0
      ? { className: 'text-success' }
      : { color: theme.colors.gray700 }
  return (
    <Pane display="flex">
      <Pane display="flex" flexDirection="column" width="100%">
        <Pane
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Pane display="flex">
            <Heading size={500}>{name}</Heading>
            {isNew && (
              <Heading id={rewardId} size={500} marginLeft={8} color="#ec407a">
                New
              </Heading>
            )}
          </Pane>

          <Heading {...membershipBalanceStyles} marginTop="-4px" size={800}>
            {getAmountSpent({
              spent: availableSpend,
              exchangeRate,
              userDisplayCurrency,
              orgDisplayCurrency,
            })}
          </Heading>
        </Pane>
      </Pane>

      {(programType === ProgramType.personalFunds || !oneTimeProgram) && (
        <Pane marginTop={4} marginLeft="auto">
          {programType === ProgramType.personalFunds && (
            <Tooltip content={<Text color="white">Personal Funds</Text>}>
              <PersonIcon color="gray700" marginLeft={16} />
            </Tooltip>
          )}
        </Pane>
      )}
    </Pane>
  )
}
