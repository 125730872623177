import { captureException } from '@sentry/react'
import { GetCountryByIso2 } from 'api/databaseCalls'
import { useEffect, useState } from 'react'
import { toSentry } from 'utils'

export default function useCountryDisplayName({ iso2 }: { iso2?: string }) {
  const [countryDisplayName, setCountryDisplayName] = useState<string>()
  useEffect(() => {
    if (!iso2) return

    // Invalid iso2, just display it and report to sentry
    if (iso2.length !== 2) {
      setCountryDisplayName(iso2)
      captureException(toSentry('Invalid iso2 field'), {
        contexts: {
          useCountryDisplayName: {
            iso2,
          },
        },
      })
      return
    }

    if (!Intl.DisplayNames) {
      GetCountryByIso2({ iso2 }).then(country => {
        if (country) setCountryDisplayName(country.name)
      })
    } else {
      const displayName = new Intl.DisplayNames(['en'], {
        type: 'region',
      }).of(iso2)
      if (displayName) setCountryDisplayName(displayName)
    }
  }, [iso2])

  return { countryDisplayName }
}
