import { OrgList } from 'gen/perkup/v1/organization_pb'
import { startCase } from 'lodash-es'

export function formatLabelSelectOptions({
  orgLists,
}: {
  orgLists: OrgList[]
}) {
  const response: {
    label: string
    options: { label: string; value: string }[]
  }[] = []
  orgLists.forEach(value => {
    response.push({
      label: startCase(value.name),
      options: value?.options.map((v: string) => ({
        label: v,
        value: `${value.id}:'${v}'`,
      })),
    })
  })
  return response
}

export function startCaseWithAmpersand(text: string): string {
  // Split the text into words using regular expression
  const words: string[] = text.split(' ')

  // Capitalize the first letter of each word, while keeping '&' unchanged
  const capitalizedWords = words.map(word =>
    word === '&'
      ? '&'
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )

  // Join the words back together with spaces
  let result: string = capitalizedWords.join(' ')

  // Handling ampersand
  result = result.replace(/\bAnd\b/g, 'and') // Lowercase 'and' to handle cases like "Rock And Roll"

  // Return the result
  return result
}
