export const US_ID = '1KPypLQqAglFz2bGYNbWsa'
export const CA_ID = '2Stl7RCau1sETG4fPgDBkZ'

export const countryIdToCountryCode = new Map<string, string>([
  [US_ID, 'US'],
  [CA_ID, 'CA'],
])

export const US_ISO2 = 'us'

export const USA_ISO3 = 'usa'
export const CANADA_ISO3 = 'can'
export const INDIA_ISO3 = 'ind'
export const VIETNAM_ISO3 = 'vnm'

export const hideShopAndGiftIso3s = [INDIA_ISO3, VIETNAM_ISO3]

export const WORLDWIDE = 'WORLDWIDE'
