import { ReactComponent as PerkUpLogo } from 'assets/PerkUp-Logo.svg'
import { ReactComponent as Logo } from 'assets/PerkUp-LogoIcon.svg'
import { ReactComponent as Name } from 'assets/PerkUp-Name.svg'
import { Pane } from 'evergreen-ui'

export function PerkUpName(props: {
  showLogo?: boolean
  showName?: boolean
  height?: number
  color?: string
}) {
  const {
    showLogo = true,
    showName = true,
    height = 28,
    color = '#002540',
  } = props

  let element = <Name fill={color} style={{ width: 'auto' }} />

  if (showName && showLogo) {
    element = <PerkUpLogo fill={color} style={{ width: 'auto' }} />
  } else if (showLogo) {
    element = <Logo fill={color} style={{ width: 'auto' }} />
  }

  return <Pane height={height}>{element}</Pane>
}
