import { DatePicker, Flex, Select } from 'antd'
import { IndividualsSelection, PageHeader } from 'components'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import {
  notificationStatusSelectOptions,
  notificationTypeSelectOptions,
} from 'constants/notifications'
import { BrowseNotifications } from 'features'
import {
  Notification_Status,
  Notification_Type,
} from 'gen/perkup/v1/notification_pb'
import useIds from 'hooks/useIds'
import { compact, sortBy } from 'lodash-es'
import { useState } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { useLocation } from 'react-router-dom'
import { individualsSearchClient } from 'services/algolia'
import { RangeValue } from 'types/RewardsDate'

export function NotificationLogs() {
  const { orgId } = useIds()

  const location = useLocation() as {
    state: { defaultNotificationType: Notification_Type }
  }

  const [individualId, setIndividualId] = useState<string>()
  const [dates, setDates] = useState<RangeValue>(null)
  const [notificationType, setNotificationType] = useState<
    Notification_Type | undefined
  >(location?.state?.defaultNotificationType || undefined)
  const [notificationStatus, setNotificationStatus] =
    useState<Notification_Status>()

  const searchClient = individualsSearchClient(orgId)

  const handleFilterLogsByIndividualId = (individualId?: string) => {
    setIndividualId(individualId)
  }

  const handleSetDates = (range: RangeValue) => {
    const start = range?.[0] ? range[0].startOf('day') : null
    const end = range?.[1] ? range[1].endOf('day') : null

    // Make dates inclusive with startOf and endOf
    setDates([start, end])
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
    >
      <Flex vertical gap={16}>
        <PageHeader title="Notification Activity" />
        <Flex vertical gap={32}>
          <Flex gap={16} wrap="wrap">
            <IndividualsSelection
              mode="single"
              onIndividualClick={({ selectedIndividualIds }) => {
                const selectedId = selectedIndividualIds
                  ? selectedIndividualIds[0]
                  : undefined
                handleFilterLogsByIndividualId(selectedId)
              }}
              selectedIndividualIds={compact([individualId])}
              showAdvancedSelectOptions={false}
              defaultLabel="Search by name, or email"
              width={400}
            />
            <DatePicker.RangePicker
              format="YYYY/MM/DD"
              onChange={handleSetDates}
            />
            <Select
              placeholder="Notification Type"
              options={sortBy(notificationTypeSelectOptions, 'label')}
              style={{ minWidth: 240 }}
              defaultValue={location?.state?.defaultNotificationType}
              popupMatchSelectWidth
              showSearch
              optionFilterProp="label"
              onSelect={setNotificationType}
              allowClear
              onClear={() => setNotificationType(undefined)}
            />
            <Select
              placeholder="Status"
              options={sortBy(notificationStatusSelectOptions, 'label')}
              style={{ minWidth: 240 }}
              popupMatchSelectWidth
              showSearch
              optionFilterProp="label"
              onSelect={(value: string) => {
                setNotificationStatus(Number(value))
              }}
              allowClear
              onClear={() => setNotificationStatus(undefined)}
            />
          </Flex>
          <BrowseNotifications
            key={`${individualId}-${dates?.[0]}-${dates?.[1]}-${notificationType}-${notificationStatus}`}
            dates={dates}
            individualId={individualId}
            notificationType={notificationType}
            notificationStatus={notificationStatus}
          />
        </Flex>
      </Flex>
    </InstantSearch>
  )
}
