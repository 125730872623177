import { DEFAULT_TOPUP_AMOUNT } from 'components'
import { Account } from 'gen/perkup/v1/account_pb'

export function getDefaultAccount({
  accounts,
  defaultAccountId,
}: {
  accounts: Account[]
  defaultAccountId: string
}) {
  const defaultAccountBalance = accounts.find(
    account => account.id === defaultAccountId
  )
  if (defaultAccountBalance) {
    return defaultAccountBalance
  }
  return accounts[0]
}

export function getAccountBalances({ account }: { account?: Account }) {
  const balance = Number(account?.balance)
  const outstandingFunds = Number(account?.programReserved)
  const unallocatedFunds = balance - outstandingFunds
  const spent = Number(account?.spent)

  return {
    balance,
    outstandingFunds,
    unallocatedFunds,
    spent,
  }
}

export const getTopUpDefault = ({
  unallocatedFunds,
}: {
  unallocatedFunds: number
}) => {
  if (unallocatedFunds < DEFAULT_TOPUP_AMOUNT * -1) {
    return unallocatedFunds * -1
  }
  return DEFAULT_TOPUP_AMOUNT
}
