import { PlusCircleOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { loadStripe } from '@stripe/stripe-js'
import { Button, ButtonProps, Radio, RadioChangeEvent, Tooltip } from 'antd'
import { callFunction } from 'api/functionCalls'
import { AmountTextInput } from 'components/Forms/AmountTextInput'
import * as KEYS from 'constants/keys'
import { STRIPE_FEE_MULTIPLIER } from 'constants/money'
import { OrgContext, UserContext } from 'context'
import dayjs from 'dayjs'
import {
  Alert,
  Dialog,
  Link,
  Pane,
  Paragraph,
  Strong,
  Text,
  toaster,
} from 'evergreen-ui'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { Individual } from 'gen/perkup/v1/individual_pb'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import { Program } from 'gen/perkup/v1/program_pb'
import toNumber from 'lodash-es/toNumber'
import React, { useContext, useMemo, useState } from 'react'
import { CreateTopUpInvoice } from 'services'
import Stripe from 'stripe'
import { numToDollars } from 'utils'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

const stripePromise = loadStripe(KEYS.STRIPE_API_PUBLIC_KEY)

const MIN_AMOUNT = 10

const MIN_PROCESS_FEE = 300

export enum CheckoutType {
  PAYMENT = 'payment',
}

export const DEFAULT_TOPUP_AMOUNT = 50000

interface CheckoutButtonProps extends ButtonProps {
  name?: string
  accountId: string
  useIconButton?: boolean
  iconAfter?: JSX.Element
  defaultAmount?: number
  program?: Program
  recipients?: Individual[]
  sendDate?: dayjs.Dayjs
}

interface PaymentOption {
  label: string
  value: Stripe.Invoice.PaymentSettings.PaymentMethodType
}

const paymentOptions: PaymentOption[] = [
  { label: 'Credit Card', value: 'card' },
  {
    label: 'Bank Transfer',
    value: 'customer_balance',
  },
]

const markUpPercent = 0.15

function CheckoutLineItem({
  label,
  amount,
}: {
  label: string
  amount: number
}) {
  return (
    <Pane display="flex" justifyContent="space-between" marginY={16}>
      <Pane display="flex" flexDirection="column">
        <Text>{label}</Text>
      </Pane>
      <Pane>
        <Text>{numToDollars(amount)}</Text>
      </Pane>
    </Pane>
  )
}

const orgIdsWhoPayTakeRate = [
  'K32yEQkiilBBIr6vIqIW', // Muse
  '8n4BlxHeI4B4wNy9mEqd', // MFCP
  'URUzZhaCE6ejdqUDGpGr', // AEG
  'eCYCdSvIcuiYQtkDkoFT', // Crestline
  'iwuhvinovhk3A1LlmpPX', // Vertus
  'u6u3ZtMXJnYPWobwBVLO', // Tally
  'uM7DuaohCZ3OIVMMzKGa', // Whitmor
  KEYS.PERKUP_ORG_ID, // PerkUp main account
  'Iuur9RpfMMHhGVq12H8Z', // Micro Precision Calibration
  'TklgLm6VaUL3Rkc878uR', // PUBG
  'SXQAy4rKjuftreuXCBov', // TigerConnect
  'K2HPsMdFFd1QcmCEO06q', // Fishman Flooring
  'gh08fQFa0knNVkYlLfgN', // Turing
  'aLjbkgXYDcR4s3Srjpt9', // Passumpsic Bank
  '67lFuvCqMmeM7iK9rugh', // Wise
  'qeoTyNjMCtYszHovUKmY', // GenII
  '1MQiwZsds1xdeukAioVP', // Aquestive Therapeutics Inc.
  'plFRAL6tOrAKlWOrlw3y', // Orium
  '8Et8mAFcyWINTLfCeYJx', // Barrios
  'ddYtPv9CjuMSp84E0zzP', // Glowforge
  '1ySOohoifH33Oa4uo4H4', // Pathfinder Hospitality
]

export function CheckoutButton({
  name = 'Add funds',
  accountId,
  useIconButton = false,
  iconAfter,
  defaultAmount = DEFAULT_TOPUP_AMOUNT,
  ...other
}: CheckoutButtonProps) {
  const org = useContext(OrgContext)
  const user = useContext(UserContext)

  const [isLoading, setIsloading] = useState(false)

  const [showDialog, setShowDialog] = useState(false)
  const [topupAmount, setTopupAmount] = useState(defaultAmount)
  const [invoiceURL, setInvoiceURL] = useState('')
  const [selectedMode, setSelectedMode] =
    useState<Stripe.Invoice.PaymentSettings.PaymentMethodType>('card')

  const processingFees = useMemo(() => {
    if (selectedMode === 'card') {
      if (topupAmount < 10000) {
        return MIN_PROCESS_FEE
      }
      return topupAmount * STRIPE_FEE_MULTIPLIER
    }

    return 0
  }, [selectedMode, topupAmount])

  const underMinimumAmount = MIN_AMOUNT > topupAmount

  const orgId = org.id

  const includeTakeRate = orgIdsWhoPayTakeRate.includes(orgId)
  const perkupFee = includeTakeRate ? topupAmount * markUpPercent : 0

  const handleCheckout = async () => {
    if (underMinimumAmount) {
      console.error('Under minimum amount')
      return
    }
    try {
      setIsloading(true)
      const stripe = await stripePromise.catch(error => {
        console.error(error)
        toaster.warning(
          'Something went wrong, refresh the page or contact support'
        )
        // Note: don't capture this error to Sentry
      })

      if (!stripe) return

      let { customerId } = org

      // Create Stripe Customer if one doesn't already exist
      if (!org.customerId) {
        const customer = await callFunction('stripe-CreateOrgCustomer', {
          name: org.name,
          orgId: org.id,
          userId: user.id,
          email: user.profile?.email,
        })
        customerId = customer.id
        // Add customer account to Organization
        const db = getFirestore()
        const docRef = doc(db, `organizations/${org.id}`).withConverter(
          converter(Organization)
        )
        await setDoc(docRef, { customerId }, { merge: true }).catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              updateOrgCustomer: {
                orgId: org.id,
                customerId,
              },
            },
          })
        })
      }

      const invoice = await CreateTopUpInvoice({
        customer: customerId,
        paymentMethod: selectedMode,
        orgId,
        amount: topupAmount,
        accountId,
        includeTakeRate,
      })
      const hostedInvoiceUrl = invoice?.hostedInvoiceUrl

      if (hostedInvoiceUrl) {
        setInvoiceURL(hostedInvoiceUrl)
      } else {
        toaster.warning('Something went wrong, please contact support')
      }
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        toaster.danger(error.message)
      }
      captureException(toSentry(error), {
        contexts: {
          checkoutButton: {
            orgId: org.id,
            accountId,
          },
        },
      })
    } finally {
      setIsloading(false)
    }
  }

  const handleCTAClick = async (
    e:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setShowDialog(true)
  }

  const paymentMethodIsCard = selectedMode === 'card'
  const total = processingFees + perkupFee + topupAmount

  return (
    <>
      {!useIconButton && (
        <Button
          type="default"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleCTAClick}
          loading={isLoading}
          {...other}
        >
          {name}
          {iconAfter}
        </Button>
      )}
      {useIconButton && (
        <Tooltip title={name}>
          <Button
            icon={<PlusCircleOutlined />}
            type="text"
            onClick={handleCTAClick}
            loading={isLoading}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </Tooltip>
      )}
      <Dialog
        title="Add funds"
        isShown={showDialog}
        onCloseComplete={() => setShowDialog(false)}
        confirmLabel="Create invoice"
        onConfirm={handleCheckout}
        isConfirmLoading={isLoading}
        hasFooter={!invoiceURL}
        isConfirmDisabled={underMinimumAmount}
      >
        {invoiceURL ? (
          <Pane>
            <Link href={invoiceURL} target="_blank" size={600}>
              View invoice
            </Link>
            {selectedMode === 'customer_balance' && (
              <Paragraph marginY={16} size={300}>
                Make sure to include the invoice number in the memo field of the
                transfer. For more information, refer to{' '}
                <Link
                  href="https://help.perkupapp.com/articles/848861-paying-invoices-by-bank-transfer"
                  target="_blank"
                >
                  paying invoices by bank transfer.
                </Link>
              </Paragraph>
            )}
          </Pane>
        ) : (
          <Pane>
            {org.subscriptionStatus ===
              Organization_SubscriptionStatus.active && (
              <Pane>
                <Strong>Payment options</Strong>
                <Pane marginY={16}>
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => {
                      setSelectedMode(e.target.value)
                    }}
                    value={selectedMode}
                  >
                    {paymentOptions.map((tab: PaymentOption) => (
                      <Radio.Button key={tab.label} value={tab.value}>
                        {tab.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Pane>
              </Pane>
            )}

            <Text>
              {paymentMethodIsCard
                ? 'Your funds will be available after paying the invoice.'
                : 'Your funds will be availabe once the bank transfer is complete.'}
            </Text>

            <Pane display="flex" marginY={16}>
              <Strong>Top-up amount</Strong>
              <Pane marginLeft="auto" marginRight={108}>
                <AmountTextInput
                  value={topupAmount / 100}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTopupAmount(toNumber(e.target.value) * 100)
                  }
                  autoFocus
                  required
                  width={172}
                  min={MIN_AMOUNT}
                />
              </Pane>
            </Pane>
            {underMinimumAmount && (
              <Alert
                title={`$${MIN_AMOUNT} minimum when topping up funds.`}
                intent="warning"
              />
            )}

            {paymentMethodIsCard && (
              <CheckoutLineItem
                label={`${
                  STRIPE_FEE_MULTIPLIER * 100
                }% credit card fee ($3 minimum)`}
                amount={processingFees}
              />
            )}

            {includeTakeRate && (
              <CheckoutLineItem
                label={`${markUpPercent * 100}% PerkUp fee`}
                amount={perkupFee}
              />
            )}

            <Pane
              display="flex"
              justifyContent="space-between"
              borderTop
              paddingTop={16}
            >
              <Strong>Total</Strong>
              <Strong>{numToDollars(Math.round(total))} USD</Strong>
            </Pane>
          </Pane>
        )}
      </Dialog>
    </>
  )
}
