import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Empty, Flex, Modal } from 'antd'
import { FooterWithCTA, Loader } from 'components'
import { SwapGiftButton } from 'components/Buttons/SwapGiftButton'
import { USA_ISO3 } from 'constants/countries'
import { ACCEPTANCE_FLOW_WIDTH } from 'constants/layout'
import { ALL_ORGS, FEATURED_COLLECTION_ID } from 'constants/productCollections'
import { CHOOSE_YOUR_GIFT } from 'constants/rewards'
import {
  DEFAULT_ROUTES,
  REWARD_ACCEPTANCE,
  REWARD_ACCEPTANCE_CHECKOUT,
} from 'constants/routes'
import {
  CountryContext,
  ExchangeRateContext,
  MemberContext,
  ProgramContext,
} from 'context'
import {
  EmptyState,
  Heading,
  Pane,
  SearchIcon,
  Text,
  toaster,
  useTheme,
} from 'evergreen-ui'
import { SelectProductVariants } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useCollectionById, useUserById } from 'hooks'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import { useDisplayCurrency } from 'hooks/useDisplayCurrency'
import { differenceWith, isEmpty, isNaN, uniqBy } from 'lodash-es'
import { MaxGiftRedemption } from 'pages/NewReward/components/MaxQuantityForm'
import { useContext, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { PageDirection, Selectable } from 'types'
import { isSwappableGift, numToDollars } from 'utils'
import { getAlgoliaFeaturedCollectionFilter } from 'utils/Algolia'
import { sortCollectionProductsByRank } from 'utils/productCollections'
import {
  buildProductVariantDisplayName,
  saveProductVariantsToSessionStorage,
} from 'utils/productVariant'
import { useProductVariantsFromSessionStorage } from '../hooks/useProductVariantsFromSessionStorage'
import { GiftAcceptanceAlert } from './GiftAcceptanceAlert'

export function MultiProductGiftRevealed() {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const country = useContext(CountryContext)

  const [modal, contextHolder] = Modal.useModal()
  const displayCurrency = useDisplayCurrency()
  const navigate = useNavigate()
  const theme = useTheme()

  const gift = program?.gift
  const { id: programId } = program
  const currentIso3 = country?.iso3 || USA_ISO3
  const collectionId = gift?.productCollectionId
  const productIds = gift?.productIds
  const isFeaturedCollection =
    gift?.productCollectionId === FEATURED_COLLECTION_ID

  const {
    addedVariants,
    setAddedVariants,
    isLoading: isLoadingProductVariants,
  } = useProductVariantsFromSessionStorage({ programId })
  const { user: programOwner } = useUserById({ userId: program?.ownerId })
  const { collection, hasLoaded: hasLoadedCollection } = useCollectionById({
    id: collectionId,
  })

  const isGlobalCollection = collection?.orgId === ALL_ORGS
  const invalidShippingCountry =
    isGlobalCollection && !collection.shippingCountries.includes(currentIso3)

  const collectionProductIds = useMemo(() => {
    if (isGlobalCollection) return []
    if (collection) return Object.keys(collection?.products)
    if (productIds) return productIds
    return []
  }, [collection, productIds, isGlobalCollection])

  const {
    productVariants: allPossibleVariants,
    isLoadingInitial: isLoadingCollectionVariants,
  } = useListAllProductVariantsByProductIds({
    productIds: collectionProductIds,
  })

  const allPossibleVariantsWithinBudget = useMemo(() => {
    return allPossibleVariants
      .filter(variant => {
        const price = variant?.amount || 0
        return price <= program.budget
      })
      .filter(pv => {
        if (pv.shippingCountries && !isEmpty(pv.shippingCountries)) {
          return pv.shippingCountries.includes(currentIso3)
        }
        return true
      })
  }, [allPossibleVariants, currentIso3, program.budget])

  const collectionProductsWithinBudget = uniqBy(
    allPossibleVariantsWithinBudget,
    'productId'
  )

  const variantsWithSelectable: Selectable<ProductVariant>[] =
    collectionProductsWithinBudget.map(variant => {
      const isAdded = addedVariants.some(
        pv => pv.productId === variant.productId
      )
      Object.assign(variant, {
        selectable: isAdded,
      })

      return variant
    })

  const variantsToRender = sortCollectionProductsByRank({
    uniqueHits: variantsWithSelectable,
    collectionProducts: collection?.products || {},
  })

  const getGiftsToRedeem = () => {
    if (gift?.redeemableQuantity) {
      // Looking at variantsToRender because if this is less than redeemableQuantity, we need to adjust the number that they can redeem to be lower. If it's empty that means algolia is rendering and we dont edit.
      return !isEmpty(variantsToRender) &&
        gift.redeemableQuantity > variantsToRender.length
        ? variantsToRender.length
        : gift?.redeemableQuantity
    }
    return undefined
  }
  const giftsToRedeem = getGiftsToRedeem()
  const redemptionType: MaxGiftRedemption = giftsToRedeem ? 'Items' : 'Budget'
  const isBudgetGift = redemptionType === 'Budget'

  const hasMaxQuantity = !!gift?.redeemableQuantity

  const displayBudget = exchangeRate * program.budget

  const addedVariantsSum = addedVariants.reduce(
    (acc, variant) => acc + Number(variant?.amount || 0),
    0
  )

  const headingDisplayString = () => {
    if (giftsToRedeem && giftsToRedeem > 1) {
      return `Choose your ${giftsToRedeem} gifts`
    }
    if (isBudgetGift) {
      return `You have ${numToDollars(
        (program.budget - addedVariantsSum) * exchangeRate,
        2,
        false,
        displayCurrency
      )} to spend`
    }
    return CHOOSE_YOUR_GIFT
  }

  const budgetRemaining = isBudgetGift
    ? program.budget - addedVariantsSum
    : undefined

  const subHeadingDisplayString = useMemo(() => {
    if (giftsToRedeem && giftsToRedeem === 1) {
      return undefined
    }
    if (hasMaxQuantity) {
      return `${addedVariants.length} of ${giftsToRedeem} selected`
    }

    return `${numToDollars(
      addedVariantsSum * exchangeRate,
      2,
      false,
      displayCurrency
    )} / ${numToDollars(displayBudget, 2, false, displayCurrency)} spent`
  }, [
    addedVariants.length,
    addedVariantsSum,
    displayBudget,
    displayCurrency,
    exchangeRate,
    giftsToRedeem,
    hasMaxQuantity,
  ])

  const remainingVariants = useMemo(() => {
    const remainingVariants = differenceWith(
      allPossibleVariantsWithinBudget,
      addedVariants,
      (allPvs, addedPvs) => allPvs.productId === addedPvs.productId
    )

    const filteredVariants = isBudgetGift
      ? remainingVariants.filter(
          variant => (variant?.amount || 0) <= program.budget - addedVariantsSum
        )
      : remainingVariants

    return uniqBy(filteredVariants, 'productId')
  }, [
    allPossibleVariantsWithinBudget,
    addedVariants,
    isBudgetGift,
    program.budget,
    addedVariantsSum,
  ])

  const maxGiftsSelected = useMemo(() => {
    if (!isBudgetGift) {
      return addedVariants.length === giftsToRedeem
    }
    return isEmpty(remainingVariants)
  }, [addedVariants.length, giftsToRedeem, isBudgetGift, remainingVariants])

  const handleContinue = ({ skipChecks = false }: { skipChecks?: boolean }) => {
    if (
      !skipChecks &&
      budgetRemaining &&
      remainingVariants.some(pv => pv.amount && pv.amount <= budgetRemaining)
    ) {
      const remainingBalanceTitle = `You still have ${numToDollars(
        budgetRemaining * exchangeRate,
        2,
        false,
        displayCurrency
      )} remaining`
      modal.confirm({
        title: remainingBalanceTitle,
        icon: <ExclamationCircleOutlined />,
        content:
          'Once your order is placed, you will not be able to return to add more items. Are you sure you want to proceed?',
        okText: 'Add more items',
        cancelText: 'Continue to shipping',
        onCancel: () => handleContinue({ skipChecks: true }),
        width: isMobile ? '100%' : 512,
      })
      return
    }
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        programId || ''
      )}${REWARD_ACCEPTANCE_CHECKOUT}`,
      { state: { direction: PageDirection.FORWARD } }
    )
  }

  const giftsRemaining =
    !isBudgetGift && giftsToRedeem
      ? giftsToRedeem - addedVariants.length
      : undefined

  const onConfirmSelectProduct = (selectedProductVariant: ProductVariant) => {
    const variantToRemove = addedVariants.find(
      pv => pv.productId === selectedProductVariant.productId
    )

    if (variantToRemove) {
      const filteredVariants = addedVariants.filter(
        pv => pv.productId !== selectedProductVariant.productId
      )
      setAddedVariants(filteredVariants)
      saveProductVariantsToSessionStorage({
        productVariants: filteredVariants,
        programId: program?.id,
      })
      const removedVariantName = buildProductVariantDisplayName({
        productVariant: variantToRemove,
      })
      toaster.success(`${removedVariantName} removed`)
      return
    }

    const variantsToSave = [...addedVariants, selectedProductVariant]
    setAddedVariants(variantsToSave)
    saveProductVariantsToSessionStorage({
      productVariants: variantsToSave,
      programId: program?.id,
    })
    const addedVariantName = buildProductVariantDisplayName({
      productVariant: selectedProductVariant,
    })
    toaster.success(`${addedVariantName} selected`)
    // If last choice, nav forward
    if (giftsRemaining && giftsRemaining === 1) {
      handleContinue({ skipChecks: true })
    }
  }

  const isSwappable = isSwappableGift({ gift })

  const showContinueButton =
    (!isBudgetGift && maxGiftsSelected) ||
    (isBudgetGift && !isEmpty(addedVariants))

  const disableSwap = !isNaN(giftsRemaining) && giftsRemaining === 0

  const continueFooter = showContinueButton ? (
    <FooterWithCTA
      ctaText="Continue"
      onCTAClick={() => handleContinue({ skipChecks: false })}
      fullCta={isMobile}
    />
  ) : undefined

  const selectGiftsHeader = (
    <Pane display="flex" flexDirection="column" gap={8} width="100%">
      <Pane
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        width="100%"
        justifyContent="space-between"
        gap={8}
      >
        <Heading size={900}>{headingDisplayString()}</Heading>
        {isSwappable && !disableSwap && (
          <Pane
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={8}
          >
            <SwapGiftButton
              program={program}
              memberId={member.id}
              onSwap={onConfirmSelectProduct}
              maxBudget={program.budget - addedVariantsSum}
            />
          </Pane>
        )}
      </Pane>
      {subHeadingDisplayString && <Text>{subHeadingDisplayString}</Text>}

      <GiftAcceptanceAlert />
    </Pane>
  )

  const collectionFilter = isFeaturedCollection
    ? ''
    : ` AND collectionIds:${collectionId}`

  const algoliaSearchFilter =
    getAlgoliaFeaturedCollectionFilter({
      maxAmount: program.budget,
      currentIso3,
    }) + collectionFilter

  if (
    isLoadingProductVariants ||
    isLoadingCollectionVariants ||
    !hasLoadedCollection
  )
    return <Loader />

  if (invalidShippingCountry) {
    return (
      <EmptyState
        background="light"
        title={`Collection not available in ${country.name}`}
        orientation="horizontal"
        icon={<SearchIcon color={theme.colors.gray500} />}
        iconBgColor={theme.colors.gray200}
        primaryCta={
          <Button
            type="primary"
            onClick={() => navigate(DEFAULT_ROUTES.CARD.ROOT)}
          >
            Return to dashboard
          </Button>
        }
        description={`Contact ${
          programOwner?.profile?.email || 'your program owner'
        } to get this resolved.`}
      />
    )
  }

  const withAlgolia = isGlobalCollection || isFeaturedCollection

  if (!withAlgolia && isEmpty(variantsToRender)) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={
          <Flex vertical gap={8}>
            <Heading>No products found</Heading>
            <Text>{`Gift is not available in your country. Contact ${programOwner?.profile?.email} to get this resolved.`}</Text>
          </Flex>
        }
      />
    )
  }

  return (
    <>
      <Flex
        vertical
        style={{
          width: ACCEPTANCE_FLOW_WIDTH,
          height: '100%',
        }}
      >
        <SelectProductVariants
          addedVariants={addedVariants}
          productVariantsAvailable={variantsToRender}
          onAddVariant={onConfirmSelectProduct}
          showPrice={isBudgetGift}
          budgetRemaining={budgetRemaining}
          giftsRemaining={giftsRemaining}
          continueFooter={continueFooter}
          header={selectGiftsHeader}
          withAlgolia={withAlgolia}
          searchFilter={algoliaSearchFilter}
          maxAmount={program.budget}
        />
      </Flex>
      {contextHolder}
    </>
  )
}
