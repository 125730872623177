import { captureException } from '@sentry/react'
import { ORG_PROGRAM_TYPES, SPOT_RWARD_PROGRAM_TYPES } from 'constants/programs'
import {
  QueryConstraint,
  QuerySnapshot,
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import {
  Member,
  Program,
  ProgramOccasion,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { uniqBy } from 'lodash-es'
import { ManagerPolicy, ProgramQueryFilters } from 'types'
import { sortTimestamps } from 'utils'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export async function ListProgramsByOrgId({ orgId }: { orgId: string }) {
  const programs: Program[] = []
  try {
    const db = getFirestore()

    const colRef = collection(
      db,
      `organizations/${orgId}/programs`
    ).withConverter(storedId(Program))

    const q = query(
      colRef,
      where('type', 'in', ORG_PROGRAM_TYPES),
      where('isDeleted', '!=', true)
    )
    await getDocs(q).then(qSnaps =>
      qSnaps.docs.forEach(qSnap => programs.push(qSnap.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListPerkProgramsByOrgId: { orgId } },
      tags: { orgId },
    })
  }
  return programs
}

export function ListenToProgramsByOrgId({
  orgId,
  filters,
  callback,
}: {
  orgId: string
  filters?: ProgramQueryFilters
  callback: (querySnapshot: QuerySnapshot<Program>) => void
}) {
  const db = getFirestore()

  const colRef = collection(
    db,
    `organizations/${orgId}/programs`
  ).withConverter(storedId(Program))

  const beginDateFilter = filters?.dates?.[0]?.toDate()
  const endDateFilter = filters?.dates?.[1]?.toDate()
  const statusToFilter = filters?.status
  const typesToFilter = filters?.types
  const ownerIdToFilter = filters?.ownerId
  const occasionToFilter = filters?.occasion

  const queryConstraints: QueryConstraint[] = [
    where('isDeleted', '==', false),
    ...(occasionToFilter
      ? [where('occasion', '==', ProgramOccasion[occasionToFilter])]
      : []),
    ...(typesToFilter
      ? [
          where(
            'type',
            'in',
            typesToFilter.map(type => ProgramType[type])
          ),
        ]
      : []),
    ...(statusToFilter
      ? [where('status', '==', ProgramStatus[statusToFilter])]
      : []),
    ...(ownerIdToFilter ? [where('ownerId', '==', ownerIdToFilter)] : []),
    ...(beginDateFilter ? [where('created', '>=', beginDateFilter)] : []),
    ...(endDateFilter ? [where('created', '<=', endDateFilter)] : []),
    orderBy('created', 'desc'),
  ]

  const q = query(colRef, ...queryConstraints)

  return onSnapshot(q, callback, error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListenToProgramsByOrgId: { orgId, filters },
      },
    })
  })
}

export function ListenToPersonalFundsProgramByUserId({
  orgId,
  userId,
  cb,
}: {
  orgId: string
  userId: string
  cb: (program: Program | undefined) => void
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/programs`
  ).withConverter(storedId(Program))
  const q = query(
    colRef,
    where('type', '==', ProgramType[ProgramType.personalFunds]),
    where('ownerId', '==', userId)
  )
  return onSnapshot(
    q,
    qSnap => {
      const program = qSnap.docs[0]?.data()
      cb(program)
    },
    error => {
      cb(undefined)
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToPersonalFundsProgramByUserId: { orgId, userId } },
        tags: { orgId, userId },
      })
    }
  )
}

export function ListenToProgramById({
  orgId,
  programId,
  cb,
}: {
  orgId: string
  programId: string
  cb: (program?: Program) => void
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}`
  ).withConverter(storedId(Program))
  return onSnapshot(
    docRef,
    dSnap => cb(dSnap.data()),
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: { ListenToProgramById: { orgId, programId } },
        tags: { orgId },
      })
    }
  )
}

export async function GetProgramById({
  orgId,
  programId,
}: {
  orgId: string
  programId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/programs/${programId}`
    ).withConverter(storedId(Program))
    return await getDoc(docRef).then(dSnap => dSnap.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { GetProgramById: { orgId, programId } },
      tags: { orgId },
    })
  }
}

export async function ListPoliciesByMemberId({
  orgId,
  id,
}: {
  orgId: string
  id: string
}) {
  const policies: ManagerPolicy[] = []
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/programs`
    ).withConverter(storedId(Program))
    const q = query(
      colRef,
      where('type', '==', ProgramType[ProgramType.policies]),
      where('isDeleted', '!=', true)
    )
    await getDocs(q).then(async qSnaps => {
      await Promise.all(
        qSnaps.docs.map(policySnap => {
          try {
            const userIdDocRef = doc(
              db,
              `organizations/${orgId}/programs/${policySnap.id}/members/${id}`
            ).withConverter(storedId(Member))

            return getDoc(userIdDocRef).then(userIdDocSnap => {
              if (userIdDocSnap.exists()) {
                const member = userIdDocSnap.data()
                const program = policySnap.data()
                policies.push({
                  member,
                  program,
                })
              }
            })
          } catch (error) {
            console.error(error)
            captureException(toSentry(error), {
              contexts: {
                ListManagerPoliciesByMemberId: { orgId, id },
              },
            })
          }
          return undefined
        })
      )
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListManagerPoliciesByMemberId: { orgId, id },
      },
    })
  }
  return policies
}

export async function ListManagerPolicies({
  orgId,
  userId,
  individualId,
}: {
  orgId: string
  userId: string
  individualId: string
}) {
  const individualPolicies = await ListPoliciesByMemberId({
    orgId,
    id: individualId,
  })
  const userPolicies = await ListPoliciesByMemberId({
    orgId,
    id: userId,
  })

  const uniquePolicies = uniqBy(
    [...individualPolicies, ...userPolicies],
    'program.id'
  )
  return uniquePolicies
}

export async function ListProgramsByOwnerId({
  orgId,
  userId,
  limit,
}: {
  orgId: string
  userId: string
  limit?: number
}) {
  try {
    const db = getFirestore()

    const colRef = collection(
      db,
      `organizations/${orgId}/programs`
    ).withConverter(storedId(Program))

    const q = query(
      colRef,
      where('type', 'in', SPOT_RWARD_PROGRAM_TYPES),
      where('isDeleted', '==', false),
      where('ownerId', '==', userId),
      where('status', '==', ProgramStatus[ProgramStatus.active])
    )

    return await getDocs(q).then(qSnaps => {
      if (limit) {
        return qSnaps.docs
          .map(doc => doc.data())
          .sort((a, b) => sortTimestamps(a.created, b.created))
          .slice(0, limit)
      }
      return qSnaps.docs
        .map(doc => doc.data())
        .sort((a, b) => sortTimestamps(a.created, b.created))
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListProgramsByOwnerId: { orgId, userId } },
      tags: { orgId, userId },
    })
  }
  return []
}

export async function ListDraftProgramsByOwnerId({
  orgId,
  userId,
}: {
  orgId: string
  userId: string
}) {
  try {
    const db = getFirestore()

    const colRef = collection(
      db,
      `organizations/${orgId}/programs`
    ).withConverter(storedId(Program))

    const q = query(
      colRef,
      where('type', 'in', SPOT_RWARD_PROGRAM_TYPES),
      where('isDeleted', '==', false),
      where('ownerId', '==', userId),
      where('status', '==', ProgramStatus[ProgramStatus.draft])
    )

    return await getDocs(q).then(qSnaps => {
      return qSnaps.docs
        .map(doc => doc.data())
        .sort((a, b) => sortTimestamps(a.created, b.created))
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListDraftProgramsByOwnerId: { orgId, userId } },
      tags: { orgId, userId },
    })
  }
  return []
}

export async function ListProgramsByIds({
  orgId,
  programIds,
}: {
  orgId: string
  programIds: string[]
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/programs`
    ).withConverter(storedId(Program))
    const q = query(colRef, where(documentId(), 'in', programIds))
    const qSnaps = await getDocs(q)

    return qSnaps.docs.map(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListProgramsByIds: { orgId, programIds } },
      tags: { orgId },
    })
    return []
  }
}
