import { CopyOutlined } from '@ant-design/icons'
import { Alert, Button, Drawer, Flex } from 'antd'
import { AddressDisplay } from 'components/Addresses'
import { Loader } from 'components/Loader'
import { OrgContext } from 'context'
import {
  Heading,
  Link,
  Pane,
  Position,
  Strong,
  Text,
  Tooltip,
} from 'evergreen-ui'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import { ProductVariant_Provider } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useProgramsByIds } from 'hooks'
import { useOrderById } from 'hooks/orders/useOrderById'
import { useOrderFulfillmentsByOrgTransaction } from 'hooks/orders/useOrderFulfillmentsByOrgTransaction'
import { useBalanceTransactions } from 'hooks/useBalanceTransactions'
import { capitalize, isEmpty } from 'lodash-es'
import { useContext, useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { copyToClipboard, getDateTimeString, numToDollars } from 'utils'
import { DigitalOrderInfo } from './DigitalOrderInfo'
import { TransactionStatus } from './TransactionStatus'
import { OrderFulfillments } from './order-fulfillments'

function PersonalTransactionBreakdown({
  transaction,
}: {
  transaction: OrgTransaction
}) {
  const { id: transactionId, amount, currency } = transaction

  const { balanceTransactions, isLoading: isLoadingBalanceTransactions } =
    useBalanceTransactions({
      transactionId,
    })

  const programIds = useMemo(
    () => balanceTransactions.map(bt => bt.programId),
    [balanceTransactions]
  )

  const { programs } = useProgramsByIds({
    programIds,
  })

  if (isLoadingBalanceTransactions) return <Loader />

  if (isEmpty(balanceTransactions) || isEmpty(programs)) return null

  return (
    <Pane>
      <Heading size={100} marginBottom={16}>
        Source of funds
      </Heading>

      {balanceTransactions.map(bt => {
        const relatedProgram = programs.find(p => p.id === bt.programId)

        return (
          <Pane
            key={bt.id}
            display="flex"
            justifyContent="space-between"
            marginBottom={8}
          >
            <Pane whiteSpace="normal">
              <Text size={isMobile ? 400 : 500}>{relatedProgram?.name}</Text>
            </Pane>
            <Text whiteSpace="nowrap">
              {numToDollars(Number(bt.amount))} {currency.toUpperCase()}
            </Text>
          </Pane>
        )
      })}

      <Pane
        display="flex"
        justifyContent="space-between"
        borderTop="muted"
        paddingTop={8}
      >
        <Strong size={500}>Total</Strong>
        <Strong>
          {numToDollars(amount)} {currency.toUpperCase()}
        </Strong>
      </Pane>
    </Pane>
  )
}

function ShippingTransactionBreakdown({
  transaction,
}: {
  transaction: OrgTransaction
}) {
  const { authId } = transaction

  const { orderFulfillments, isLoading: isLoadingOrderFulfillments } =
    useOrderFulfillmentsByOrgTransaction({
      transaction,
    })

  const { order, isLoading: isLoadingOrder } = useOrderById({
    orderId: authId,
  })

  const shippingAddress = new ShippingAddress({
    ...order?.shippingAddress,
    name: `${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`,
  })

  const multipleFulfillments =
    !!orderFulfillments && orderFulfillments.length > 1

  const isNearCashOrder = order?.items?.every(
    item => item?.provider === ProductVariant_Provider.plum
  )

  if (isLoadingOrderFulfillments || isLoadingOrder) return <Loader />

  return (
    <>
      {shippingAddress?.country && !isNearCashOrder && (
        <AddressDisplay address={shippingAddress} />
      )}

      {multipleFulfillments && (
        <Alert
          type="info"
          message="Order is being fulfilled in multiple shipments"
          showIcon
        />
      )}

      {order && (
        <OrderFulfillments
          order={order}
          orderFulfillments={orderFulfillments}
        />
      )}
    </>
  )
}

function DeclinedTransactionBreakdown({
  transaction,
}: {
  transaction: OrgTransaction
}) {
  const { declinedReason, approved, type } = transaction

  return (
    <>
      {declinedReason && (
        <Alert
          type="warning"
          showIcon
          message="Decline Reason"
          style={{ marginBottom: 16 }}
          description={
            <>
              <Text marginRight={4}>{capitalize(declinedReason)}.</Text>
              <Link
                href="https://help.perkupapp.com/articles/813722-why-was-my-transaction-declined"
                target="_blank"
              >
                Learn more.
              </Link>
            </>
          }
        />
      )}
      {!approved && type !== 'refund' && (
        <Text color="muted">
          If you think this should have been approved, please contact support.
        </Text>
      )}
    </>
  )
}

export function TransactionDrawer({
  transaction,
  onCloseComplete,
}: {
  transaction: OrgTransaction
  onCloseComplete: () => void
}) {
  const org = useContext(OrgContext)

  const {
    authId,
    isGift,
    approved,
    status,
    type,
    amount,
    created,
    digitalOrderInfo,
  } = transaction

  return (
    <Drawer
      open
      title={
        <Flex align="center" justify="space-between" gap={8}>
          <Heading size={isMobile ? 600 : 800}>
            {isGift ? `Gift from ${org.name}` : `${numToDollars(amount)} USD`}
          </Heading>

          <Flex align="center">
            <TransactionStatus
              approved={approved}
              status={status}
              type={type}
            />
          </Flex>
        </Flex>
      }
      width={isMobile ? '100%' : 560}
      onClose={onCloseComplete}
    >
      <Flex vertical gap={32}>
        {/** TRANSACTION ID AND DATE */}
        <Flex vertical gap={8}>
          <Pane display="flex" alignItems="center" gap={8}>
            <Heading>
              Transaction ID: <Text>{authId}</Text>
            </Heading>
            <Tooltip content="Copy ID" position={Position.TOP}>
              <Button
                icon={<CopyOutlined />}
                type="text"
                onClick={() => copyToClipboard(authId, 'ID')}
              />
            </Tooltip>
          </Pane>

          <Text size={400}>{getDateTimeString(created)}</Text>
        </Flex>

        {/** TRANSACTION DETAILS */}
        <Flex flex={1} vertical gap={16}>
          {/** PERSONAL TRANSACTION DETAILS */}
          {!isGift && (
            <PersonalTransactionBreakdown transaction={transaction} />
          )}

          {/** NEARCASH TRANSACTION DETAILS */}
          {digitalOrderInfo && <DigitalOrderInfo info={digitalOrderInfo} />}

          {/** SHIPPING TRANSACTION DETAILS */}
          <ShippingTransactionBreakdown transaction={transaction} />

          {/** DECLINED TRANSACTION DETAILS */}
          <DeclinedTransactionBreakdown transaction={transaction} />
        </Flex>
      </Flex>
    </Drawer>
  )
}
