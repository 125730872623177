// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/program.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { ShippingAddress } from "./root_user_pb.js";
import { ProductVariant_Provider, ProductVariant_Type } from "./product_variant_pb.js";
import { OrgTransaction } from "./organization_pb.js";
import { Order, OrderFulfillment } from "./vendor_pb.js";

/**
 * @generated from enum perkup.v1.ProgramType
 */
export enum ProgramType {
  /**
   * @generated from enum value: UNKNOWN_ProgramType = 0;
   */
  UNKNOWN_ProgramType = 0,

  /**
   * @generated from enum value: perks = 1;
   */
  perks = 1,

  /**
   * @generated from enum value: personalFunds = 2;
   */
  personalFunds = 2,

  /**
   * @generated from enum value: rewards = 3;
   */
  rewards = 3,

  /**
   * @generated from enum value: anniversaries = 4;
   */
  anniversaries = 4,

  /**
   * @generated from enum value: birthdays = 5;
   */
  birthdays = 5,

  /**
   * @generated from enum value: policies = 6;
   */
  policies = 6,

  /**
   * @generated from enum value: direct = 7;
   */
  direct = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(ProgramType)
proto3.util.setEnumType(ProgramType, "perkup.v1.ProgramType", [
  { no: 0, name: "UNKNOWN_ProgramType" },
  { no: 1, name: "perks" },
  { no: 2, name: "personalFunds" },
  { no: 3, name: "rewards" },
  { no: 4, name: "anniversaries" },
  { no: 5, name: "birthdays" },
  { no: 6, name: "policies" },
  { no: 7, name: "direct" },
]);

/**
 * @generated from enum perkup.v1.ProgramFrequency
 */
export enum ProgramFrequency {
  /**
   * @generated from enum value: UNKNOWN_ProgramFrequency = 0;
   */
  UNKNOWN_ProgramFrequency = 0,

  /**
   * @generated from enum value: monthly = 1;
   */
  monthly = 1,

  /**
   * @generated from enum value: yearly = 2;
   */
  yearly = 2,

  /**
   * @generated from enum value: once = 3;
   */
  once = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProgramFrequency)
proto3.util.setEnumType(ProgramFrequency, "perkup.v1.ProgramFrequency", [
  { no: 0, name: "UNKNOWN_ProgramFrequency" },
  { no: 1, name: "monthly" },
  { no: 2, name: "yearly" },
  { no: 3, name: "once" },
]);

/**
 * @generated from enum perkup.v1.ProgramStatus
 */
export enum ProgramStatus {
  /**
   * @generated from enum value: UNKNOWN_ProgramStatus = 0;
   */
  UNKNOWN_ProgramStatus = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: inactive = 2;
   */
  inactive = 2,

  /**
   * @generated from enum value: draft = 3;
   */
  draft = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProgramStatus)
proto3.util.setEnumType(ProgramStatus, "perkup.v1.ProgramStatus", [
  { no: 0, name: "UNKNOWN_ProgramStatus" },
  { no: 1, name: "active" },
  { no: 2, name: "inactive" },
  { no: 3, name: "draft" },
]);

/**
 * @generated from enum perkup.v1.ProgramOccasion
 */
export enum ProgramOccasion {
  /**
   * @generated from enum value: UNKNOWN_ProgramOccasion = 0;
   */
  UNKNOWN_ProgramOccasion = 0,

  /**
   * @generated from enum value: appreciation = 1;
   */
  appreciation = 1,

  /**
   * @generated from enum value: birthday = 2;
   */
  birthday = 2,

  /**
   * @generated from enum value: holiday = 3;
   */
  holiday = 3,

  /**
   * @generated from enum value: incentive = 4;
   */
  incentive = 4,

  /**
   * @generated from enum value: lifeEvent = 5;
   */
  lifeEvent = 5,

  /**
   * @generated from enum value: lunch = 6;
   */
  lunch = 6,

  /**
   * @generated from enum value: newEmployee = 7;
   */
  newEmployee = 7,

  /**
   * @generated from enum value: teamEvent = 8;
   */
  teamEvent = 8,

  /**
   * @generated from enum value: workAnniversary = 9;
   */
  workAnniversary = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(ProgramOccasion)
proto3.util.setEnumType(ProgramOccasion, "perkup.v1.ProgramOccasion", [
  { no: 0, name: "UNKNOWN_ProgramOccasion" },
  { no: 1, name: "appreciation" },
  { no: 2, name: "birthday" },
  { no: 3, name: "holiday" },
  { no: 4, name: "incentive" },
  { no: 5, name: "lifeEvent" },
  { no: 6, name: "lunch" },
  { no: 7, name: "newEmployee" },
  { no: 8, name: "teamEvent" },
  { no: 9, name: "workAnniversary" },
]);

/**
 * Firestore /organizations/:id/programs/:id
 *
 * @generated from message perkup.v1.Program
 */
export class Program extends Message<Program> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: perkup.v1.ProgramType type = 2;
   */
  type = ProgramType.UNKNOWN_ProgramType;

  /**
   * @generated from field: optional string owner_id = 3;
   */
  ownerId?: string;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   *  created: admin.firestore.Timestamp
   *
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  /**
   * @generated from field: int32 budget = 6;
   */
  budget = 0;

  /**
   * @generated from field: int32 total_spent = 7;
   */
  totalSpent = 0;

  /**
   * sum of child member balances
   *
   * @generated from field: int32 total_budget = 8;
   */
  totalBudget = 0;

  /**
   * @generated from field: int32 total_members = 9;
   */
  totalMembers = 0;

  /**
   * @generated from field: int32 total_allocated = 10;
   */
  totalAllocated = 0;

  /**
   * optional?
   *
   * @generated from field: int32 total_schedule_members = 11;
   */
  totalScheduleMembers = 0;

  /**
   * optional?
   *
   * @generated from field: int32 total_accepted = 12;
   */
  totalAccepted = 0;

  /**
   * @generated from field: perkup.v1.ProgramFrequency frequency = 13;
   */
  frequency = ProgramFrequency.UNKNOWN_ProgramFrequency;

  /**
   * in firestore default false
   *
   * @generated from field: optional bool prorate = 14;
   */
  prorate?: boolean;

  /**
   * in firestore default null
   *
   * @generated from field: optional google.protobuf.Timestamp prorate_date = 15;
   */
  prorateDate?: Timestamp;

  /**
   * omit if allMerchants = true
   *
   * @generated from field: repeated string approved_categories = 16;
   */
  approvedCategories: string[] = [];

  /**
   * @generated from field: optional bool rollover = 18;
   */
  rollover?: boolean;

  /**
   * @generated from field: optional bool all_merchants = 19;
   */
  allMerchants?: boolean;

  /**
   * @generated from field: perkup.v1.ProgramStatus status = 20;
   */
  status = ProgramStatus.UNKNOWN_ProgramStatus;

  /**
   * @generated from field: bool auto_enrolled = 21;
   */
  autoEnrolled = false;

  /**
   * in firestore default false
   *
   * @generated from field: optional bool is_deleted = 22;
   */
  isDeleted?: boolean;

  /**
   * @generated from field: optional google.protobuf.Timestamp deleted_at = 23;
   */
  deletedAt?: Timestamp;

  /**
   * @generated from field: optional int64 resets_on = 24;
   */
  resetsOn?: bigint;

  /**
   * @generated from field: optional google.protobuf.Timestamp starts_on = 25;
   */
  startsOn?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp ends_on = 26;
   */
  endsOn?: Timestamp;

  /**
   * @generated from field: optional perkup.v1.Program.EmailData email = 27;
   */
  email?: Program_EmailData;

  /**
   * @generated from field: optional string note = 28;
   */
  note?: string;

  /**
   * @generated from field: optional string parent_program_id = 29;
   */
  parentProgramId?: string;

  /**
   * @generated from field: optional perkup.v1.Program.Gift gift = 30;
   */
  gift?: Program_Gift;

  /**
   * @generated from field: optional string payment_intent_id = 31;
   */
  paymentIntentId?: string;

  /**
   * Current AccountId, this can be changed and will affect new BalanceTransactions to be allocated from selected AccountId.
   *
   * @generated from field: optional string account_id = 32;
   */
  accountId?: string;

  /**
   * organization internal office use meta data like PO or common event name
   *
   * @generated from field: optional string internal_memo = 33;
   */
  internalMemo?: string;

  /**
   * @generated from field: optional perkup.v1.Program.Feedback feedback = 34;
   */
  feedback?: Program_Feedback;

  /**
   * @generated from field: repeated string core_values = 35;
   */
  coreValues: string[] = [];

  /**
   * Draft data field should be raw JSON. Example payload would be:
   * program.draftData = JSON.stringify({emails:['invite@example.com',...],individuals:['SomeIndId',...],rewardStep:2})
   * Remember to remove the field from the program after activation.
   *
   * @generated from field: optional string draft_data = 36;
   */
  draftData?: string;

  /**
   * @generated from field: repeated perkup.v1.Item items = 37;
   */
  items: Item[] = [];

  /**
   * @generated from field: optional perkup.v1.ProgramOccasion occasion = 38;
   */
  occasion?: ProgramOccasion;

  /**
   * @generated from field: optional perkup.v1.Program.RecipientFilters recipient_filters = 39;
   */
  recipientFilters?: Program_RecipientFilters;

  /**
   * id of the template used when creating this program
   *
   * @generated from field: optional string template_id = 40;
   */
  templateId?: string;

  /**
   * Last time a reminder was sent to accept the program
   *
   * @generated from field: optional google.protobuf.Timestamp reminder_last_sent = 41;
   */
  reminderLastSent?: Timestamp;

  /**
   * Predefined address for the swag gift deliveries, for example to send all of them to the office. Doesnt allow recipient to enter their address.
   *
   * @generated from field: optional perkup.v1.ShippingAddress predefined_address = 42;
   */
  predefinedAddress?: ShippingAddress;

  constructor(data?: PartialMessage<Program>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ProgramType) },
    { no: 3, name: "owner_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created", kind: "message", T: Timestamp },
    { no: 6, name: "budget", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "total_spent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "total_budget", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "total_members", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "total_allocated", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "total_schedule_members", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "total_accepted", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "frequency", kind: "enum", T: proto3.getEnumType(ProgramFrequency) },
    { no: 14, name: "prorate", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 15, name: "prorate_date", kind: "message", T: Timestamp, opt: true },
    { no: 16, name: "approved_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "rollover", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 19, name: "all_merchants", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 20, name: "status", kind: "enum", T: proto3.getEnumType(ProgramStatus) },
    { no: 21, name: "auto_enrolled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 23, name: "deleted_at", kind: "message", T: Timestamp, opt: true },
    { no: 24, name: "resets_on", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 25, name: "starts_on", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "ends_on", kind: "message", T: Timestamp, opt: true },
    { no: 27, name: "email", kind: "message", T: Program_EmailData, opt: true },
    { no: 28, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "parent_program_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "gift", kind: "message", T: Program_Gift, opt: true },
    { no: 31, name: "payment_intent_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 32, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "internal_memo", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 34, name: "feedback", kind: "message", T: Program_Feedback, opt: true },
    { no: 35, name: "core_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 36, name: "draft_data", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 37, name: "items", kind: "message", T: Item, repeated: true },
    { no: 38, name: "occasion", kind: "enum", T: proto3.getEnumType(ProgramOccasion), opt: true },
    { no: 39, name: "recipient_filters", kind: "message", T: Program_RecipientFilters, opt: true },
    { no: 40, name: "template_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 41, name: "reminder_last_sent", kind: "message", T: Timestamp, opt: true },
    { no: 42, name: "predefined_address", kind: "message", T: ShippingAddress, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program {
    return new Program().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program {
    return new Program().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program {
    return new Program().fromJsonString(jsonString, options);
  }

  static equals(a: Program | PlainMessage<Program> | undefined, b: Program | PlainMessage<Program> | undefined): boolean {
    return proto3.util.equals(Program, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Program.RewardTab
 */
export enum Program_RewardTab {
  /**
   * @generated from enum value: REWARD_TAB_UNSPECIFIED = 0;
   */
  REWARD_TAB_UNSPECIFIED = 0,

  /**
   * @generated from enum value: perkupDollars = 1;
   */
  perkupDollars = 1,

  /**
   * @generated from enum value: swag = 2;
   */
  swag = 2,

  /**
   * @generated from enum value: catalog = 3;
   */
  catalog = 3,

  /**
   * @generated from enum value: giftCard = 4;
   */
  giftCard = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Program_RewardTab)
proto3.util.setEnumType(Program_RewardTab, "perkup.v1.Program.RewardTab", [
  { no: 0, name: "REWARD_TAB_UNSPECIFIED" },
  { no: 1, name: "perkupDollars" },
  { no: 2, name: "swag" },
  { no: 3, name: "catalog" },
  { no: 4, name: "giftCard" },
]);

/**
 * DraftDataField to be jsonified in and out on the draft_data field
 * individuals []string, emails []string, address: ShippingAddress, rewardStep: int32
 *
 * @generated from message perkup.v1.Program.DraftDataField
 */
export class Program_DraftDataField extends Message<Program_DraftDataField> {
  /**
   * @generated from field: repeated string individuals = 1;
   */
  individuals: string[] = [];

  /**
   * @generated from field: repeated string emails = 2;
   */
  emails: string[] = [];

  /**
   * @generated from field: optional perkup.v1.ShippingAddress address = 3;
   */
  address?: ShippingAddress;

  /**
   * @generated from field: optional int32 reward_step = 4;
   */
  rewardStep?: number;

  /**
   * @generated from field: optional perkup.v1.Program.RecipientFilters recipient_filters = 5;
   */
  recipientFilters?: Program_RecipientFilters;

  /**
   * @generated from field: optional string use_case = 6;
   */
  useCase?: string;

  /**
   * @generated from field: optional perkup.v1.Program.Gift swag_gift = 7;
   */
  swagGift?: Program_Gift;

  /**
   * @generated from field: optional perkup.v1.Program.Gift catalog_gift = 8;
   */
  catalogGift?: Program_Gift;

  /**
   * @generated from field: optional perkup.v1.Program.PerkUpDollars perkup_dollars = 9;
   */
  perkupDollars?: Program_PerkUpDollars;

  /**
   * @generated from field: optional perkup.v1.Program.RewardTab reward_tab = 10;
   */
  rewardTab?: Program_RewardTab;

  /**
   * @generated from field: optional perkup.v1.Program.Gift gift_card_gift = 11;
   */
  giftCardGift?: Program_Gift;

  /**
   * @generated from field: optional int32 collection_amount = 12;
   */
  collectionAmount?: number;

  constructor(data?: PartialMessage<Program_DraftDataField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program.DraftDataField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "individuals", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "address", kind: "message", T: ShippingAddress, opt: true },
    { no: 4, name: "reward_step", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "recipient_filters", kind: "message", T: Program_RecipientFilters, opt: true },
    { no: 6, name: "use_case", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "swag_gift", kind: "message", T: Program_Gift, opt: true },
    { no: 8, name: "catalog_gift", kind: "message", T: Program_Gift, opt: true },
    { no: 9, name: "perkup_dollars", kind: "message", T: Program_PerkUpDollars, opt: true },
    { no: 10, name: "reward_tab", kind: "enum", T: proto3.getEnumType(Program_RewardTab), opt: true },
    { no: 11, name: "gift_card_gift", kind: "message", T: Program_Gift, opt: true },
    { no: 12, name: "collection_amount", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program_DraftDataField {
    return new Program_DraftDataField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program_DraftDataField {
    return new Program_DraftDataField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program_DraftDataField {
    return new Program_DraftDataField().fromJsonString(jsonString, options);
  }

  static equals(a: Program_DraftDataField | PlainMessage<Program_DraftDataField> | undefined, b: Program_DraftDataField | PlainMessage<Program_DraftDataField> | undefined): boolean {
    return proto3.util.equals(Program_DraftDataField, a, b);
  }
}

/**
 * @generated from message perkup.v1.Program.RecipientFilters
 */
export class Program_RecipientFilters extends Message<Program_RecipientFilters> {
  /**
   * @generated from field: optional bool all_selected = 1;
   */
  allSelected?: boolean;

  /**
   * @generated from field: optional string label = 2;
   */
  label?: string;

  constructor(data?: PartialMessage<Program_RecipientFilters>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program.RecipientFilters";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "all_selected", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program_RecipientFilters {
    return new Program_RecipientFilters().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program_RecipientFilters {
    return new Program_RecipientFilters().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program_RecipientFilters {
    return new Program_RecipientFilters().fromJsonString(jsonString, options);
  }

  static equals(a: Program_RecipientFilters | PlainMessage<Program_RecipientFilters> | undefined, b: Program_RecipientFilters | PlainMessage<Program_RecipientFilters> | undefined): boolean {
    return proto3.util.equals(Program_RecipientFilters, a, b);
  }
}

/**
 * @generated from message perkup.v1.Program.PerkUpDollars
 */
export class Program_PerkUpDollars extends Message<Program_PerkUpDollars> {
  /**
   * @generated from field: int32 amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: repeated string approved_categories = 2;
   */
  approvedCategories: string[] = [];

  constructor(data?: PartialMessage<Program_PerkUpDollars>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program.PerkUpDollars";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "approved_categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program_PerkUpDollars {
    return new Program_PerkUpDollars().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program_PerkUpDollars {
    return new Program_PerkUpDollars().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program_PerkUpDollars {
    return new Program_PerkUpDollars().fromJsonString(jsonString, options);
  }

  static equals(a: Program_PerkUpDollars | PlainMessage<Program_PerkUpDollars> | undefined, b: Program_PerkUpDollars | PlainMessage<Program_PerkUpDollars> | undefined): boolean {
    return proto3.util.equals(Program_PerkUpDollars, a, b);
  }
}

/**
 * @generated from message perkup.v1.Program.EmailData
 */
export class Program_EmailData extends Message<Program_EmailData> {
  /**
   * @generated from field: string banner = 1;
   */
  banner = "";

  /**
   * @generated from field: string from_name = 2;
   */
  fromName = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  constructor(data?: PartialMessage<Program_EmailData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program.EmailData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "banner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "from_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program_EmailData {
    return new Program_EmailData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program_EmailData {
    return new Program_EmailData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program_EmailData {
    return new Program_EmailData().fromJsonString(jsonString, options);
  }

  static equals(a: Program_EmailData | PlainMessage<Program_EmailData> | undefined, b: Program_EmailData | PlainMessage<Program_EmailData> | undefined): boolean {
    return proto3.util.equals(Program_EmailData, a, b);
  }
}

/**
 * @generated from message perkup.v1.Program.Gift
 */
export class Program_Gift extends Message<Program_Gift> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional perkup.v1.Program.Gift.FulfilledBy fulfilled_by = 2;
   */
  fulfilledBy?: Program_Gift_FulfilledBy;

  /**
   * When we the program type is direct, we will store the title and image_url of a productVariant
   *
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: optional string image_url = 4;
   */
  imageUrl?: string;

  /**
   * @generated from field: optional string vendor_id = 6;
   */
  vendorId?: string;

  /**
   * @generated from field: optional string country = 7;
   */
  country?: string;

  /**
   * @generated from field: optional string shop_id = 8;
   */
  shopId?: string;

  /**
   * @generated from field: optional string group_id = 9;
   */
  groupId?: string;

  /**
   * @generated from field: optional string product_collection_id = 10;
   */
  productCollectionId?: string;

  /**
   * if a gift is a specific product variant
   *
   * @generated from field: optional string product_variant_id = 11;
   */
  productVariantId?: string;

  /**
   * For gifts with multiple products to choose from - like a collection
   * If the productIds array have only 1 product, it is a single product gift program
   *
   * @generated from field: repeated string product_ids = 12;
   */
  productIds: string[] = [];

  /**
   * The number of redeemable items on a multiple product gift
   *
   * @generated from field: optional int32 redeemable_quantity = 13;
   */
  redeemableQuantity?: number;

  /**
   * If a gift is swappable - what it can be swapped for
   *
   * @generated from field: repeated string convertable_to = 14;
   */
  convertableTo: string[] = [];

  /**
   * @generated from field: optional perkup.v1.ProductVariant.Type product_variant_type = 15;
   */
  productVariantType?: ProductVariant_Type;

  constructor(data?: PartialMessage<Program_Gift>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program.Gift";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fulfilled_by", kind: "enum", T: proto3.getEnumType(Program_Gift_FulfilledBy), opt: true },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "vendor_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "shop_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "product_collection_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "product_variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "redeemable_quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 14, name: "convertable_to", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "product_variant_type", kind: "enum", T: proto3.getEnumType(ProductVariant_Type), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program_Gift {
    return new Program_Gift().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program_Gift {
    return new Program_Gift().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program_Gift {
    return new Program_Gift().fromJsonString(jsonString, options);
  }

  static equals(a: Program_Gift | PlainMessage<Program_Gift> | undefined, b: Program_Gift | PlainMessage<Program_Gift> | undefined): boolean {
    return proto3.util.equals(Program_Gift, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Program.Gift.FulfilledBy
 */
export enum Program_Gift_FulfilledBy {
  /**
   * @generated from enum value: FULFILLED_BY_UNSPECIFIED = 0;
   */
  FULFILLED_BY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: amazon = 1;
   */
  amazon = 1,

  /**
   * Dreamship fulfillment moved to Shopify, retain for historical purposes.
   *
   * @generated from enum value: dreamship = 2 [deprecated = true];
   * @deprecated
   */
  dreamship = 2,

  /**
   * @generated from enum value: manual = 3;
   */
  manual = 3,

  /**
   * @generated from enum value: shopify = 4;
   */
  shopify = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Program_Gift_FulfilledBy)
proto3.util.setEnumType(Program_Gift_FulfilledBy, "perkup.v1.Program.Gift.FulfilledBy", [
  { no: 0, name: "FULFILLED_BY_UNSPECIFIED" },
  { no: 1, name: "amazon" },
  { no: 2, name: "dreamship" },
  { no: 3, name: "manual" },
  { no: 4, name: "shopify" },
]);

/**
 * @generated from message perkup.v1.Program.Feedback
 */
export class Program_Feedback extends Message<Program_Feedback> {
  /**
   * @generated from field: optional string rating = 1;
   */
  rating?: string;

  /**
   * @generated from field: optional string response = 2;
   */
  response?: string;

  constructor(data?: PartialMessage<Program_Feedback>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Program.Feedback";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rating", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "response", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Program_Feedback {
    return new Program_Feedback().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Program_Feedback {
    return new Program_Feedback().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Program_Feedback {
    return new Program_Feedback().fromJsonString(jsonString, options);
  }

  static equals(a: Program_Feedback | PlainMessage<Program_Feedback> | undefined, b: Program_Feedback | PlainMessage<Program_Feedback> | undefined): boolean {
    return proto3.util.equals(Program_Feedback, a, b);
  }
}

/**
 * @generated from message perkup.v1.Item
 */
export class Item extends Message<Item> {
  /**
   * @generated from field: perkup.v1.ProductVariant.Provider provider = 1;
   */
  provider = ProductVariant_Provider.PROVIDER_UNSPECIFIED;

  /**
   * @generated from field: string product_variant_id = 3;
   */
  productVariantId = "";

  /**
   * @generated from field: optional string product_id = 2;
   */
  productId?: string;

  /**
   * @generated from field: int32 quantity = 4;
   */
  quantity = 0;

  constructor(data?: PartialMessage<Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "provider", kind: "enum", T: proto3.getEnumType(ProductVariant_Provider) },
    { no: 3, name: "product_variant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Item {
    return new Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Item {
    return new Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Item {
    return new Item().fromJsonString(jsonString, options);
  }

  static equals(a: Item | PlainMessage<Item> | undefined, b: Item | PlainMessage<Item> | undefined): boolean {
    return proto3.util.equals(Item, a, b);
  }
}

/**
 * Firestore /organizations/:id/programs/:id/members/:id
 *
 * @generated from message perkup.v1.Member
 */
export class Member extends Message<Member> {
  /**
   * @generated from field: int32 allocated = 1;
   */
  allocated = 0;

  /**
   * @generated from field: int32 budget = 2;
   */
  budget = 0;

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: bool enrolled = 4;
   */
  enrolled = false;

  /**
   * @generated from field: optional perkup.v1.Member.Gift gift = 5;
   */
  gift?: Member_Gift;

  /**
   * stored in firestore TODO: should we store?
   *
   * @generated from field: string id = 6;
   */
  id = "";

  /**
   * @generated from field: optional string preview_id = 7;
   */
  previewId?: string;

  /**
   * @generated from field: int32 spent = 8;
   */
  spent = 0;

  /**
   * @generated from field: optional string thanks_message = 9;
   */
  thanksMessage?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp removed_at = 10;
   */
  removedAt?: Timestamp;

  /**
   * actions
   * - emailSent:    (Mailjet Webhook)
   * - emailOpen:    (Mailjet Webhook)
   * - emailClick:   (Mailjet Webhook)
   * - emailBounce:  (Mailjet Webhook)
   * - emailBlocked: (Mailjet Webhook)
   * - emailSpam:    (Mailjet Webhook)
   * - emailUnsub:   (Mailjet Webhook)
   * - accepted:     (Cloud Function) Member has accepted the Program
   * - thanksSubmitted (FE) Member has submitted a thanks message
   * reminderLastSent (FE) Last time a reminder was sent to accept the program
   *
   * @generated from field: map<string, google.protobuf.Timestamp> actions = 11;
   */
  actions: { [key: string]: Timestamp } = {};

  /**
   * convertedTo is set when a gift is swapped
   *
   * @generated from field: optional perkup.v1.Member.ConvertedTo converted_to = 12;
   */
  convertedTo?: Member_ConvertedTo;

  constructor(data?: PartialMessage<Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "allocated", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "budget", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "created", kind: "message", T: Timestamp },
    { no: 4, name: "enrolled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "gift", kind: "message", T: Member_Gift, opt: true },
    { no: 6, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "preview_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "spent", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "thanks_message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "removed_at", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "actions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Timestamp} },
    { no: 12, name: "converted_to", kind: "enum", T: proto3.getEnumType(Member_ConvertedTo), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Member {
    return new Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJsonString(jsonString, options);
  }

  static equals(a: Member | PlainMessage<Member> | undefined, b: Member | PlainMessage<Member> | undefined): boolean {
    return proto3.util.equals(Member, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Member.ConvertedTo
 */
export enum Member_ConvertedTo {
  /**
   * @generated from enum value: CONVERTED_TO_UNSPECIFIED = 0;
   */
  CONVERTED_TO_UNSPECIFIED = 0,

  /**
   * @generated from enum value: nearCash = 1;
   */
  nearCash = 1,

  /**
   * @generated from enum value: alternativeGift = 2;
   */
  alternativeGift = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Member_ConvertedTo)
proto3.util.setEnumType(Member_ConvertedTo, "perkup.v1.Member.ConvertedTo", [
  { no: 0, name: "CONVERTED_TO_UNSPECIFIED" },
  { no: 1, name: "nearCash" },
  { no: 2, name: "alternativeGift" },
]);

/**
 * @generated from enum perkup.v1.Member.Action
 */
export enum Member_Action {
  /**
   * @generated from enum value: ACTION_UNSPECIFIED = 0;
   */
  ACTION_UNSPECIFIED = 0,

  /**
   * @generated from enum value: emailSent = 1;
   */
  emailSent = 1,

  /**
   * @generated from enum value: emailOpen = 2;
   */
  emailOpen = 2,

  /**
   * @generated from enum value: emailClick = 3;
   */
  emailClick = 3,

  /**
   * @generated from enum value: emailBounce = 4;
   */
  emailBounce = 4,

  /**
   * @generated from enum value: emailBlocked = 5;
   */
  emailBlocked = 5,

  /**
   * @generated from enum value: emailSpam = 6;
   */
  emailSpam = 6,

  /**
   * @generated from enum value: emailUnsub = 7;
   */
  emailUnsub = 7,

  /**
   * @generated from enum value: accepted = 8;
   */
  accepted = 8,

  /**
   * @generated from enum value: thanksSubmitted = 9;
   */
  thanksSubmitted = 9,

  /**
   * @generated from enum value: reminderLastSent = 10;
   */
  reminderLastSent = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(Member_Action)
proto3.util.setEnumType(Member_Action, "perkup.v1.Member.Action", [
  { no: 0, name: "ACTION_UNSPECIFIED" },
  { no: 1, name: "emailSent" },
  { no: 2, name: "emailOpen" },
  { no: 3, name: "emailClick" },
  { no: 4, name: "emailBounce" },
  { no: 5, name: "emailBlocked" },
  { no: 6, name: "emailSpam" },
  { no: 7, name: "emailUnsub" },
  { no: 8, name: "accepted" },
  { no: 9, name: "thanksSubmitted" },
  { no: 10, name: "reminderLastSent" },
]);

/**
 * @generated from message perkup.v1.Member.Gift
 */
export class Member_Gift extends Message<Member_Gift> {
  /**
   * the program gift id, if the gift is swapped, this will be variant id
   *
   * @generated from field: string gift_id = 1;
   */
  giftId = "";

  /**
   * @generated from field: string vendor_id = 2;
   */
  vendorId = "";

  constructor(data?: PartialMessage<Member_Gift>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Member.Gift";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gift_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vendor_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Member_Gift {
    return new Member_Gift().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Member_Gift {
    return new Member_Gift().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Member_Gift {
    return new Member_Gift().fromJsonString(jsonString, options);
  }

  static equals(a: Member_Gift | PlainMessage<Member_Gift> | undefined, b: Member_Gift | PlainMessage<Member_Gift> | undefined): boolean {
    return proto3.util.equals(Member_Gift, a, b);
  }
}

/**
 * Firestore /organizations/:id/programs/:id/scheduledMembers/:id
 *
 * @generated from message perkup.v1.ScheduledMember
 */
export class ScheduledMember extends Message<ScheduledMember> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string individual_id = 4;
   */
  individualId = "";

  /**
   * @generated from field: google.protobuf.Timestamp send_at = 5;
   */
  sendAt?: Timestamp;

  /**
   * @generated from field: optional string user_id = 6;
   */
  userId?: string;

  constructor(data?: PartialMessage<ScheduledMember>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ScheduledMember";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "send_at", kind: "message", T: Timestamp },
    { no: 6, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduledMember {
    return new ScheduledMember().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduledMember {
    return new ScheduledMember().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduledMember {
    return new ScheduledMember().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduledMember | PlainMessage<ScheduledMember> | undefined, b: ScheduledMember | PlainMessage<ScheduledMember> | undefined): boolean {
    return proto3.util.equals(ScheduledMember, a, b);
  }
}

/**
 * Membership contains program and member data, plus order, orgTransaction, and orderFulfillment data for that member
 *
 * @generated from message perkup.v1.Membership
 */
export class Membership extends Message<Membership> {
  /**
   * @generated from field: perkup.v1.Program program = 1;
   */
  program?: Program;

  /**
   * @generated from field: perkup.v1.Member member = 2;
   */
  member?: Member;

  /**
   * @generated from field: repeated perkup.v1.OrgTransaction org_transactions = 3;
   */
  orgTransactions: OrgTransaction[] = [];

  /**
   * @generated from field: repeated perkup.v1.Order orders = 4;
   */
  orders: Order[] = [];

  /**
   * @generated from field: repeated perkup.v1.OrderFulfillment order_fulfillments = 5;
   */
  orderFulfillments: OrderFulfillment[] = [];

  constructor(data?: PartialMessage<Membership>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Membership";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "program", kind: "message", T: Program },
    { no: 2, name: "member", kind: "message", T: Member },
    { no: 3, name: "org_transactions", kind: "message", T: OrgTransaction, repeated: true },
    { no: 4, name: "orders", kind: "message", T: Order, repeated: true },
    { no: 5, name: "order_fulfillments", kind: "message", T: OrderFulfillment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Membership {
    return new Membership().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Membership {
    return new Membership().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Membership {
    return new Membership().fromJsonString(jsonString, options);
  }

  static equals(a: Membership | PlainMessage<Membership> | undefined, b: Membership | PlainMessage<Membership> | undefined): boolean {
    return proto3.util.equals(Membership, a, b);
  }
}

/**
 * @generated from message perkup.v1.MembershipList
 */
export class MembershipList extends Message<MembershipList> {
  /**
   * @generated from field: repeated perkup.v1.Membership memberships = 1;
   */
  memberships: Membership[] = [];

  constructor(data?: PartialMessage<MembershipList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.MembershipList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "memberships", kind: "message", T: Membership, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MembershipList {
    return new MembershipList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MembershipList {
    return new MembershipList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MembershipList {
    return new MembershipList().fromJsonString(jsonString, options);
  }

  static equals(a: MembershipList | PlainMessage<MembershipList> | undefined, b: MembershipList | PlainMessage<MembershipList> | undefined): boolean {
    return proto3.util.equals(MembershipList, a, b);
  }
}

