import { Pane, Strong } from 'evergreen-ui'
import { DefaultBackgroundImageBanner } from './Images'

export function GiftThumbnail({
  imageUrl,
  title,
  minWidth,
  imageHeight = 200,
}: {
  imageUrl?: string
  title?: string
  imageHeight?: number
  minWidth?: number
}) {
  return (
    <Pane minWidth={minWidth}>
      <DefaultBackgroundImageBanner
        src={imageUrl}
        height={imageHeight}
        objectFit="contain"
      />
      {title && (
        <Pane textAlign="center" maxWidth={256} marginX="auto">
          <Strong
            display="block"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {title}
          </Strong>
        </Pane>
      )}
    </Pane>
  )
}
