import { Button, Input, Tooltip } from 'antd'
import { SIZE_OPTION } from 'constants/productVariants'
import {
  Pane,
  Strong,
  Text,
  toaster,
  useTheme,
  WarningSignIcon,
} from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_Option,
} from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { useDefaultOrgColors } from 'hooks'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import {
  capitalize,
  isEmpty,
  isEqual,
  isNaN,
  keys,
  uniqBy,
  values,
} from 'lodash-es'
import { useState } from 'react'
import { getSelectedProductVariant } from 'utils/productVariant'
import { sortProductOptions } from 'utils/sorting'

export interface SelectVariantValue {
  selectedOption: { [key: string]: string }
  quantity: number
}

/** @deprecated We should move away from anything related to "Items" and instead use our ProductVariant components */
function BulkProductOptionSelection({
  productVariantOption,
  productOption,
  onSelectVariantValue,
  onUpdateQuantity,
  selectedProductVariants,
  remainingProductVariants,
  items,
}: {
  onSelectVariantValue: (selectedVariantValue: {
    [key: string]: string
  }) => void
  onUpdateQuantity: ({
    quantity,
    item,
  }: {
    quantity: number
    item: Item
  }) => void
  productVariantOption: ProductVariant_Option
  productOption: string
  selectedProductVariants: ProductVariant[] | undefined
  remainingProductVariants: ProductVariant[] | undefined
  items: Item[]
}) {
  const theme = useTheme()
  const { backgroundColor } = useDefaultOrgColors()

  const needsQuantitySelection = !items.some(item => item.quantity > 0)

  const isSizeOption = productOption === SIZE_OPTION

  const sortedOptionValues = sortProductOptions({
    productOptions: productVariantOption.values,
    productOption,
  })

  return (
    <Pane display="flex" gap={16} paddingBottom={16}>
      <Pane
        flex={1}
        display="flex"
        flexWrap="nowrap"
        gap={8}
        alignItems="center"
        marginBottom={8}
      >
        <Tooltip title={capitalize(productOption)}>
          <Strong className="two-line-truncate" size={500}>
            {capitalize(productOption)}
          </Strong>
        </Tooltip>
        {needsQuantitySelection && isSizeOption && (
          <WarningSignIcon color="warning" />
        )}
      </Pane>
      <Pane
        display="flex"
        width="100%"
        gap={8}
        flexWrap="wrap"
        alignItems="left"
        flex={4}
      >
        {sortedOptionValues.map(value => {
          const selectedProductVariant = selectedProductVariants?.find(
            pv => pv.options[productOption].value === value
          )

          const productVariantWithOptionValue = remainingProductVariants?.find(
            pv => pv.options[productOption].value === value
          )

          // Don't disable if we have a product variant with this option value
          const alreadySelectedValue = selectedProductVariant || !isSizeOption
          const disabled =
            !productVariantWithOptionValue && !alreadySelectedValue

          const item = items.find(item => {
            return item.productVariantId === selectedProductVariant?.id
          })

          const isSelected = !isSizeOption && item
          const showInput: boolean = (item?.quantity || 0) > 0 && isSizeOption

          return (
            <Pane key={value}>
              {showInput && item && (
                <Input
                  style={{ maxWidth: value.length < 5 ? 120 : 200 }}
                  defaultValue={item.quantity}
                  addonBefore={<Text>{value}</Text>}
                  onBlur={e => {
                    if (isNaN(Number(e.target.value))) {
                      toaster.warning('Quantity must be a valid number')
                      return
                    }
                    onUpdateQuantity({
                      item,
                      quantity: Number(e.target.value),
                    })
                  }}
                />
              )}
              {!showInput && (
                <Button
                  type="text"
                  key={value}
                  onClick={() =>
                    onSelectVariantValue({ [productOption]: value })
                  }
                  style={{
                    backgroundColor: isSelected ? backgroundColor : '',
                    borderColor: theme.colors.gray300,
                  }}
                  disabled={disabled}
                >
                  <Text color={isSelected ? 'selected' : null}>{value}</Text>
                </Button>
              )}
            </Pane>
          )
        })}
      </Pane>
    </Pane>
  )
}

/** @deprecated We should move away from anything related to "Items" and instead use our ProductVariant components */
export function BulkOptionSelection({
  productId,
  onChangeItems,
  items,
  productOptions,
  itemsKey,
}: {
  itemsKey: string
  productId: string
  onChangeItems: ({
    items,
    itemsKey,
  }: {
    items: Item[]
    itemsKey: string
  }) => void
  items: Item[]
  productOptions: { [key: string]: ProductVariant_Option }
}) {
  const defaultQuantity = items[0]?.quantity.toString()
  const [quantityInput, setQuantityInput] = useState(defaultQuantity)

  const { allProductVariants } = useListAllProductVariantsByProductId({
    productId,
  })

  const selectedProductVariants = allProductVariants?.filter(pv =>
    items.find(item => {
      return item.productVariantId === pv.id
    })
  )

  const productKeys = keys(productOptions).sort()

  const firstItem = items[0]
  const firstSelectedProductVariant = selectedProductVariants?.find(
    pv => pv.id === firstItem.productVariantId
  )

  const remainingProductVariants = allProductVariants?.filter(pv => {
    if (isEmpty(productOptions)) return true

    let keep = true
    if (productKeys.length <= 1) return true
    productKeys.forEach(key => {
      const isSizeOption = key === SIZE_OPTION
      // do we have a selected variant value for this option?
      // if yes, check if this variant has the same value
      // if no, then we can keep it
      const selectedValue = firstSelectedProductVariant?.options[key].value
      if (
        !isSizeOption &&
        selectedValue &&
        pv.options[key].value !== selectedValue
      ) {
        keep = false
      }
    })
    return keep
  })

  const handleUpdateQuantity = ({
    quantity,
    item,
  }: {
    quantity: number
    item: Item
  }) => {
    const newItems = [
      ...items.filter(i => i.productVariantId !== item.productVariantId),
      new Item({
        ...item,
        quantity,
      }),
    ]

    onChangeItems({ itemsKey, items: newItems })
  }
  const handleSelectVariantValue = (selectedVariantValue: {
    [key: string]: string
  }) => {
    if (!allProductVariants) return

    const newItems: Item[] = []
    const changedKey = Object.keys(selectedVariantValue)[0]
    const changedSize = changedKey === SIZE_OPTION

    allProductVariants.forEach(pv => {
      const item = items.find(item => {
        return item.productVariantId === pv.id
      })
      if (item) {
        const selVariantValues: { [key: string]: string } = {}
        // Put the existing selectedVariantValues into the selVariantValues
        Object.entries(pv.options)?.forEach(([key, value]) => {
          selVariantValues[key] = value.value
        })
        // Overwrite the selVariantValues with the newly selectedVariantValue
        Object.entries(selectedVariantValue)?.forEach(([key, value]) => {
          selVariantValues[key] = value
        })

        const productVariantWVariants = getSelectedProductVariant({
          productVariants: allProductVariants,
          selectedVariantValues: selVariantValues,
        })

        const useBackupVariant = items.length === 1

        const productVariantSingleVariants = useBackupVariant
          ? allProductVariants.find(pv => {
              const selectedVariantValues = values(selectedVariantValue)
              return (
                pv.options[keys(selectedVariantValue)[0]].value ===
                selectedVariantValues[0]
              )
            })
          : undefined

        const productVariant =
          productVariantWVariants || productVariantSingleVariants

        // if no productVariant, then we the option will be disabled
        // no need to show a warning or log to Sentry
        if (productVariant) {
          const newItem = new Item({
            provider: item.provider,
            productVariantId: productVariant.id,
            productId: productVariant.productId,
            quantity: changedSize ? 1 : item.quantity,
          })
          newItems.push(newItem)
        }
      }
    })

    const filteredItems = changedSize
      ? items.filter(
          i =>
            !newItems.find(
              newItem => newItem.productVariantId === i.productVariantId
            )
        )
      : []

    const updatedItems = uniqBy(
      [...newItems, ...filteredItems],
      'productVariantId'
    )

    if (isEqual(updatedItems, items)) return

    onChangeItems({
      itemsKey,
      items: updatedItems,
    })
  }

  const hasSize = productKeys.includes(SIZE_OPTION)

  return (
    <Pane display="flex" flexDirection="column" alignItems="center" gap={16}>
      <Pane width="100%">
        {productKeys.map(productOption => {
          const productVariantOption = productOptions[productOption]
          if (!productVariantOption) return null
          return (
            <BulkProductOptionSelection
              key={productOption}
              onSelectVariantValue={handleSelectVariantValue}
              onUpdateQuantity={handleUpdateQuantity}
              productVariantOption={productVariantOption}
              productOption={productOption}
              selectedProductVariants={selectedProductVariants}
              remainingProductVariants={remainingProductVariants}
              items={items}
            />
          )
        })}
      </Pane>
      {/* // If we don't have any sizes on our variant, we need to show a quantity input */}
      {!hasSize && (
        <Pane display="flex" width="100%" gap={16}>
          <Pane flex={1}>
            <Strong alignSelf="start">Qty</Strong>
          </Pane>
          <Pane flex={4}>
            <Input
              value={quantityInput}
              onBlur={e => {
                const quantity = Number(e.target.value)
                if (isNaN(quantity)) {
                  toaster.warning('Quantity must be a valid number')
                  setQuantityInput(defaultQuantity)
                  return
                }
                if (quantity < 1) {
                  toaster.warning('Quantity must be greater than 0')
                  setQuantityInput(defaultQuantity)
                  return
                }

                handleUpdateQuantity({ quantity, item: items[0] })
              }}
              onChange={e => {
                setQuantityInput(e.target.value)
              }}
              style={{ maxWidth: 100 }}
            />
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}
