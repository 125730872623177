import { captureException, captureMessage } from '@sentry/react'
import { Tag } from 'antd'
import { callFunction } from 'api/functionCalls'
import AppleWalletLogo from 'assets/logos/addToAppleWallet2.png'
import GooglePayLogo from 'assets/logos/googlePay.png'
import { RecentTransactions } from 'components'
import { OrgUserContext } from 'context'
import {
  Link as ALink,
  Button,
  ClipboardIcon,
  DotIcon,
  DuplicateIcon,
  EyeOpenIcon,
  IconButton,
  LockIcon,
  ManualIcon,
  Pane,
  Paragraph,
  Position,
  Strong,
  Text,
  Tooltip,
  majorScale,
} from 'evergreen-ui'
import { capitalize } from 'lodash-es'
import debounce from 'lodash-es/debounce'
import { useContext, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { isMobile } from 'react-device-detect'
import Stripe from 'stripe'
import { fontFamilies } from 'themes'
import { CombineAddress, copyToClipboard, getCardBadgeStatusColor } from 'utils'
import { ActivateCard } from './ActivateCard'
import VirtualCard from './VirtualCard'

export default function CardDetails() {
  const orgUser = useContext(OrgUserContext)

  const [card, setCard] = useState<Stripe.Issuing.Card>()
  const [cardDetails, setCardDetails] = useState<any>()
  const [reveal, setReveal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const VIRTUAL = 'virtual'
  const PHYSICAL = 'physical'

  const isVirtualCard = card?.type === VIRTUAL
  const isPhysicalCard = card?.type === PHYSICAL

  // Get card
  useEffect(() => {
    if (!orgUser?.cardId) return undefined
    setIsLoading(true)
    callFunction('stripe-RetrieveCard', {})
      .then(card => {
        if (card?.id) {
          setCard(card)
        } else {
          captureException(card)
        }
      })
      .catch(error => {
        captureMessage(error, {
          contexts: {
            'stripe-RetrieveCard': {
              cardId: orgUser?.cardId,
            },
          },
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
    return undefined
  }, [orgUser?.cardId])

  // Get card details
  useEffect(() => {
    if (orgUser?.cardId && isVirtualCard) {
      setIsLoading(true)
      callFunction('stripe-RetrieveCardDetails', {})
        .then(cardDetails => {
          if (cardDetails?.id) {
            setCardDetails(cardDetails)
          } else {
            captureException(cardDetails, {
              contexts: {
                'stripe-RetrieveCardDetails': {
                  cardId: orgUser?.cardId,
                },
              },
            })
          }
        })
        .catch(error => {
          captureMessage(error, {
            contexts: {
              'stripe-RetrieveCardDetails': {
                cardId: orgUser?.cardId,
              },
            },
          })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [orgUser?.cardId, isVirtualCard])

  const cardStatus = card?.status || 'inactive'

  const address = cardDetails
    ? cardDetails.cardholder?.billing?.address
    : card?.cardholder?.billing?.address

  // Styling
  const headingPaddingX = isMobile ? 4 : 24

  const chooseIcon = () => {
    if (isLoading) return false
    if (reveal) return LockIcon
    return EyeOpenIcon
  }

  return (
    <Pane
      padding={headingPaddingX}
      width={isMobile ? '100%' : 398}
      margin="auto"
    >
      {/* Card Status */}
      <Pane display="flex" justifyContent="center" marginBottom={8}>
        <Tag color={getCardBadgeStatusColor(cardStatus)}>
          {capitalize(cardStatus)}
        </Tag>
      </Pane>

      <Pane margin="auto" display="flex" justifyContent="center">
        <ActivateCard card={card} />
      </Pane>
      <Pane
        width={isMobile ? '100%' : 'fit-content'}
        marginX="auto"
        marginBottom={16}
      >
        <VirtualCard showCardNumber={reveal} {...(cardDetails || card)} />
      </Pane>

      <Pane
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        marginBottom={16}
        marginX="auto"
        width="100%"
      >
        <Pane display="flex" flexDirection="column" width={200}>
          {isVirtualCard ? (
            <Pane
              display={isMobile ? 'flex' : 'inline'}
              flexDirection={isMobile ? 'row' : false}
              justifyContent="space-between"
              flexWrap="wrap"
              width="100%"
            >
              <Pane>
                <Button
                  iconBefore={chooseIcon()}
                  appearance="minimal"
                  height={32}
                  marginBottom={16}
                  onClick={() => setReveal(prevState => !prevState)}
                  disabled={!cardDetails?.number}
                  isLoading={isLoading}
                >
                  {isLoading && 'Retrieving card number'}
                  {!isLoading && (reveal ? 'Hide' : 'Reveal')}
                </Button>
              </Pane>
              <Pane>
                <Button
                  iconBefore={isLoading ? false : ClipboardIcon}
                  onClick={() =>
                    copyToClipboard(cardDetails?.number, 'card number')
                  }
                  disabled={!cardDetails?.number}
                  marginBottom={16}
                  isLoading={isLoading}
                >
                  {isLoading ? 'Retrieving card number' : 'Copy Card Number'}
                </Button>
              </Pane>
            </Pane>
          ) : (
            isPhysicalCard && (
              <Pane marginBottom={16}>
                <Tag color="gold">Physical card</Tag>
              </Pane>
            )
          )}
          {address?.line1 && (
            <Tooltip
              content="Copy Billing Address"
              position={Position.BOTTOM_LEFT}
            >
              <Pane
                paddingBottom={isMobile ? 12 : 0}
                onClick={() =>
                  copyToClipboard(CombineAddress(address), 'address')
                }
              >
                <Pane display="flex" alignItems="center" gap={16}>
                  <Pane>
                    <Strong>Billing address</Strong>
                  </Pane>
                  <IconButton
                    border="none"
                    onClick={debounce(() => {
                      copyToClipboard(CombineAddress(address), 'address')
                    }, 500)}
                    icon={DuplicateIcon}
                    marginRight={majorScale(2)}
                  />
                </Pane>
                <Pane className="card-details">
                  <Text is="div">{address?.line1}</Text>
                  <Text>
                    {' '}
                    {address?.line2} {address?.line2 && ' '}
                  </Text>
                  <Text>{address?.city}, </Text>
                  <Text>{address?.state}, </Text>
                  <Text>{address?.postal_code}</Text>
                </Pane>
              </Pane>
            </Tooltip>
          )}
        </Pane>
        <Pane display="flex" flexDirection="column">
          {isVirtualCard && (
            <>
              {cardDetails?.exp_month ? (
                <Tooltip content="Copy Expiration Date" position={Position.TOP}>
                  <Pane
                    display="flex"
                    alignItems="center"
                    marginBottom={8}
                    onClick={() => {
                      copyToClipboard(
                        `${cardDetails?.exp_month}/${cardDetails?.exp_year
                          ?.toString()
                          .substring(2, 4)}`,
                        'expiration date'
                      )
                    }}
                  >
                    <Strong size={300} marginRight={4}>
                      Valid Thru:
                    </Strong>
                    <Strong
                      size={300}
                      fontFamily={fontFamilies.digits}
                      className="card-details"
                    >
                      {cardDetails?.exp_month}/
                      {cardDetails?.exp_year?.toString().substring(2, 4)}
                    </Strong>
                  </Pane>
                </Tooltip>
              ) : (
                <Text size={300} color="muted">
                  <DotIcon color="muted" />
                  <DotIcon color="muted" />
                  <DotIcon color="muted" />
                  <DotIcon color="muted" />
                </Text>
              )}
              {cardDetails && reveal ? (
                <Tooltip content="Copy CVC Number">
                  <Pane
                    display="flex"
                    alignItems="center"
                    marginBottom={isMobile ? 4 : 16}
                    onClick={() => {
                      if (cardDetails && reveal) {
                        copyToClipboard(cardDetails.cvc, 'CVC number')
                      }
                    }}
                  >
                    <Strong size={300} marginRight={4}>
                      CVC:
                    </Strong>
                    <Strong
                      size={300}
                      fontFamily={fontFamilies.digits}
                      className={reveal ? 'card-details' : ''}
                    >
                      {cardDetails.cvc}
                    </Strong>
                  </Pane>
                </Tooltip>
              ) : (
                <Pane display="flex">
                  <Strong size={300} marginRight={4}>
                    CVC:
                  </Strong>
                  <DotIcon color="muted" />
                  <DotIcon color="muted" />
                  <DotIcon color="muted" />
                </Pane>
              )}
            </>
          )}
          {isPhysicalCard && (
            <Pane>
              <Strong>Physical card</Strong>
            </Pane>
          )}
        </Pane>
      </Pane>

      <Pane marginY={16} />

      <Pane display="flex" alignItems="center">
        <ALink
          href="https://help.perkupapp.com/articles/356891-add-perk-card-to-apple-wallet"
          target="_blank"
          rel="noopener noreferrer"
          marginRight={32}
        >
          <Image src={AppleWalletLogo} height={40} />
        </ALink>
        <ALink
          href="https://help.perkupapp.com/articles/712186-add-perk-card-to-google-pay"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={GooglePayLogo} height={24} />
        </ALink>
      </Pane>

      <Pane borderBottom marginY={16} />

      <ALink
        href="https://help.perkupapp.com/articles/707693-how-do-i-use-my-perk-card"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ManualIcon marginRight={8} />
        How to use your Perk Card
      </ALink>

      <Paragraph marginTop={8}>
        Using your Perk Card is just like using a credit card. In fact, our Perk
        Cards act as prepaid Visa cards that you can use to spend anywhere Visa
        is accepted.
      </Paragraph>

      <Pane marginY={32} width="100%">
        <RecentTransactions />
      </Pane>
      <Pane
        position="relative"
        paddingBottom="64.5933014354067%"
        height={0}
        marginBottom={16}
      >
        <iframe
          title="Loom PerkUp Visa Card"
          src="https://www.loom.com/embed/aee0a1f0a07e4fac9f11a306336ead43?sid=1ac72974-d2ca-4414-bf72-7fc2529887b9"
          frameBorder="0"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Pane>
    </Pane>
  )
}
