import { PLACID_BUCKET_URL_SUFFIX } from 'constants/images'
import type { RewardUseCase } from 'constants/newReward/rewardUseCases'
import { intersectionWith } from 'lodash-es'
import { getBrandedImagesByUseCases } from 'pages/NewReward/utils/uiUtils'
import { useEffect, useMemo, useState } from 'react'
import { ListFirebaseStorageImages } from 'services/images'
import useIds from './useIds'

export function useBrandedImages({ useCases }: { useCases: RewardUseCase[] }) {
  const { orgId } = useIds()
  const [allBrandedImages, setAllBrandedImages] = useState<string[]>([])

  useEffect(() => {
    ListFirebaseStorageImages({
      orgId,
      suffix: PLACID_BUCKET_URL_SUFFIX,
    }).then(res => setAllBrandedImages(res.map(({ url }) => url)))
  }, [orgId])

  const placidTemplateIds = useMemo(
    () =>
      useCases.reduce(
        (acc, useCase) => ({
          ...acc,
          [useCase]: getBrandedImagesByUseCases({ useCases: [useCase] }),
        }),
        {} as Record<RewardUseCase, string[]>
      ),
    [useCases]
  )

  const placidTemplateIdsArray = useMemo(() => {
    return Object.values(placidTemplateIds).flat()
  }, [placidTemplateIds])

  const brandedImages = useMemo(() => {
    return intersectionWith(
      allBrandedImages,
      placidTemplateIdsArray,
      (image, templateId) => image.includes(templateId)
    )
  }, [allBrandedImages, placidTemplateIdsArray])

  const brandedImagesByUseCase = useMemo(() => {
    return Object.entries(placidTemplateIds).reduce(
      (acc, [useCase, templateIds]) => ({
        ...acc,
        [useCase]: intersectionWith(
          allBrandedImages,
          templateIds,
          (image, templateId) => image.includes(templateId)
        ),
      }),
      {} as Record<RewardUseCase, string[]>
    )
  }, [allBrandedImages, placidTemplateIds])

  return { brandedImages, allBrandedImages, brandedImagesByUseCase }
}
