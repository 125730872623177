import type { SelectRewardContextType } from 'features/Programs/SelectReward/SelectRewardContext'
import { Country, MacroCategory } from 'gen/perkup/v1/contentful_pb'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Integration } from 'gen/perkup/v1/integration_pb'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { User as OrgUser } from 'gen/perkup/v1/org_user_pb'
import { Organization } from 'gen/perkup/v1/organization_pb'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { Member, Program } from 'gen/perkup/v1/program_pb'
import { RootUser, ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { Action, Rule } from 'gen/perkup/v1/rules_pb'
import { createContext } from 'react'
import { RewardStepGuide } from 'types/Rewards'

export const UserContext = createContext<RootUser>(undefined!)

export const OrgContext = createContext<Organization>(undefined!)

/**
 * @deprecated OrgUser is deprecated.
 */
export const OrgUserContext = createContext<OrgUser>(undefined!)

export const IndividualContext = createContext<Individual>(undefined!)

export const CountryContext = createContext<Country>(undefined!)

export const CountriesContext = createContext<Country[]>([])

export const CatContext = createContext<MacroCategory[]>([])

export const ExchangeRateContext = createContext<number>(1)

export const OrgBalanceContext = createContext<number>(0)

export const MemberContext = createContext<Member>(undefined!)

export const ProgramContext = createContext<Program>(undefined!)

export const SwagCollectionIdsContext = createContext<string[]>([])

export const RewardStepGuideContext = createContext<RewardStepGuide>(undefined!)

export const TemplateContext = createContext<Template | undefined>(undefined)

export const OrgIntegrationsContext = createContext<Integration[]>([])

export const SelectRewardContext =
  createContext<SelectRewardContextType | null>(null)

export const UserShippingAddressesContext = createContext<ShippingAddress[]>([])

export const ProductCollectionCardCurrentModalContext = createContext<{
  setCurrentModal: (productCollection: ProductCollection) => void
} | null>(null)

export const RulesContext = createContext<Rule[]>([])

export const ActionsContext = createContext<Map<string, Action>>(new Map())
