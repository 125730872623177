import { ProductCollectionCard } from 'components'
import { useCollectionById } from 'hooks'

export function ProductCollectionSummary({
  productCollectionId,
}: {
  productCollectionId: string
}) {
  const { collection } = useCollectionById({
    id: productCollectionId,
  })

  if (!collection) return null

  return (
    <ProductCollectionCard productCollection={collection}>
      <ProductCollectionCard.Details />
    </ProductCollectionCard>
  )
}
