import { Button } from 'antd'
import { Heading, Pane, TextInput } from 'evergreen-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export function EditableTitle({
  currentTitle,
  onConfirmTitle,
}: {
  currentTitle: string
  onConfirmTitle: (title: string) => void
}) {
  const [edit, setEdit] = useState(false)
  const { register, handleSubmit } = useForm()

  if (edit) {
    return (
      <Pane
        is="form"
        onSubmit={handleSubmit(({ title }: { title: string }) => {
          onConfirmTitle(title)
          setEdit(false)
        })}
      >
        <TextInput
          name="title"
          placeholder="Title"
          defaultValue={currentTitle}
          marginRight={16}
          ref={register}
          required
          autoFocus
        />
        <Button
          htmlType="button"
          onClick={() => setEdit(false)}
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Save
        </Button>
      </Pane>
    )
  }

  return (
    <Heading size={800} cursor="text" onClick={() => setEdit(true)}>
      {currentTitle}
    </Heading>
  )
}
