import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Modal } from 'antd'
import { PersonalFundsPayment } from 'components/Stripe'
import { PersonalFundsDisclaimer } from 'components/Typography'
import { DEFAULT_PERSONAL_FUNDS_DEPOSIT } from 'constants/money'
import { Pane, Position, toaster, Tooltip } from 'evergreen-ui'
import { SelectAmount } from 'features/Programs/SelectAmount'
import { useState } from 'react'

export function AddPersonalFundsButton({
  defaultAmount = DEFAULT_PERSONAL_FUNDS_DEPOSIT,
  asIcon = false,
  oneClickCheckout = false,
}: {
  defaultAmount?: number
  asIcon?: boolean
  oneClickCheckout?: boolean
}) {
  const [isShown, setIsShown] = useState(false)
  const [amount, setAmount] = useState(defaultAmount)
  return (
    <Pane>
      {!asIcon && (
        <Button onClick={() => setIsShown(true)}>Add personal funds</Button>
      )}
      {asIcon && (
        <Tooltip content="Add personal funds" position={Position.TOP}>
          <Button
            onClick={() => setIsShown(true)}
            type="text"
            icon={<PlusCircleOutlined />}
          />
        </Tooltip>
      )}
      <Modal
        open={isShown}
        title="Add Personal Funds"
        onClose={() => setIsShown(false)}
        okText="Checkout"
        width={590}
        footer={null}
        destroyOnClose
        onCancel={() => setIsShown(false)}
      >
        <Flex vertical gap={16}>
          {!oneClickCheckout && (
            <SelectAmount amount={amount} onAmountChange={setAmount} />
          )}

          <PersonalFundsPayment
            key={amount}
            amount={amount}
            onSubmit={paymentIntent => {
              if (paymentIntent.status === 'succeeded') {
                setIsShown(false)
                toaster.success('Personal funds added successfully')
              }
            }}
          />

          <PersonalFundsDisclaimer />
        </Flex>
      </Modal>
    </Pane>
  )
}
