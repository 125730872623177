import { setUser as setSentryUser, withProfiler } from '@sentry/react'
import { Elements } from '@stripe/react-stripe-js'
import { CourierProvider } from '@trycourier/react-provider'
import { Toast } from '@trycourier/react-toast'
import { ConfigProvider } from 'antd'
import { listenToIntegrationsByOrgId } from 'api/databaseCalls/reads/integrations'
import { callFunction } from 'api/functionCalls'
import axios from 'axios'
import {
  ActivatedPerkCard,
  IsAdmin,
  IsManager,
  Loader,
  ScrollToTop,
  withOrgUserSidebar,
  withShortNav,
} from 'components'
import CardDetails from 'components/PerkCard/CardDetails'
import { PERKUP_PRIMARY_COLOR } from 'constants/colors'
import { US_ID } from 'constants/countries'
import { firebaseConfig, firebaseConfigTest } from 'constants/firebaseConfig'
import * as HOSTS from 'constants/hosts'
import * as KEYS from 'constants/keys'
import { CODE, CREATE_ORG } from 'constants/params'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import { ThemeProvider } from 'evergreen-ui'
import { getAnalytics, setUserProperties } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  connectFirestoreEmulator,
  FirestoreSettings,
  initializeFirestore,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { Country, MacroCategory } from 'gen/perkup/v1/contentful_pb'
import {
  Individual,
  Individual_Role,
  Individual_Status,
} from 'gen/perkup/v1/individual_pb'
import { Integration } from 'gen/perkup/v1/integration_pb'
import { User as OrgUser } from 'gen/perkup/v1/org_user_pb'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import { RootUser, ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useIndividualRole, useStripe } from 'hooks'
import sum from 'lodash-es/sum'
import * as P from 'pages'
import { SessionExpired } from 'pages/SessionExpired'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import { getCourierToken } from 'services/courier'
import { customEvergreenTheme } from 'themes'
import { buildFullName, getDomainFromEmail, getHeadTitle } from 'utils'
import { getFunctionsHost } from 'utils/hosts'
import { filterProductCollectionsByCountry } from 'utils/productCollections'
import {
  GetCountryById,
  GetDomainById,
  GetExchangeRate,
  GetInvitesByEmail,
  ListCountries,
  ListenCreateToUserById,
  ListenToIndividualByEmail,
  ListenToOrgAccounts,
  ListenToOrgById,
  ListenToOrgUserById,
  ListenToPublicSwagCollections,
  ListenToShippingAddressesByUserId,
  ListMacroCategories,
} from './api/databaseCalls'
import {
  CatContext,
  CountriesContext,
  CountryContext,
  ExchangeRateContext,
  IndividualContext,
  OrgBalanceContext,
  OrgContext,
  OrgIntegrationsContext,
  OrgUserContext,
  SwagCollectionIdsContext,
  UserContext,
  UserShippingAddressesContext,
} from './context'
import './custom.scss'

const { hostname } = window.location
const isProdHost = hostname === HOSTS.PROD_HOSTNAME

const firestoreSettings: FirestoreSettings = {
  experimentalAutoDetectLongPolling: true,
}

// Initialize Firebase
if (isProdHost) {
  // web9
  const firebaseApp = initializeApp(firebaseConfig)
  getAnalytics(firebaseApp) // only on prod
  getAuth(firebaseApp)
  initializeFirestore(firebaseApp, firestoreSettings)
  getFunctions(firebaseApp)
  getStorage(firebaseApp)
} else if (hostname === HOSTS.LOCALHOST) {
  // web9
  const firebaseApp = initializeApp(firebaseConfig)
  getAnalytics(firebaseApp)
  const auth = getAuth(firebaseApp)
  const firestore = initializeFirestore(firebaseApp, firestoreSettings)
  const functions = getFunctions(firebaseApp)
  const storage = getStorage(firebaseApp)
  // Use Firestore Emulator Auth, Firestore and Functions if on localhost
  connectAuthEmulator(auth, 'http://localhost:9099/')
  connectFirestoreEmulator(firestore, HOSTS.LOCALHOST, 8080)
  connectFunctionsEmulator(functions, HOSTS.LOCALHOST, 5001)
  connectStorageEmulator(storage, HOSTS.LOCALHOST, 9199)
} else {
  // Use test config if none other
  // web9
  initializeApp(firebaseConfigTest)
}

// Remove Later: only for Slack Testing
if (hostname === 'perkup-slack-redirect.ngrok.io') {
  const currentHref = window.location.href
  const newHref = currentHref.replace(
    HOSTS.SLACK_TUNNEL_HOST,
    `http://localhost:3000`
  )
  window.location.replace(newHref)
}

const Dashboard = withOrgUserSidebar(P.Dashboard)
const Transactions = withOrgUserSidebar(P.UserTransactions)
const Account = withOrgUserSidebar(P.Account)
const CreateFirstReward = withShortNav(P.FirstReward)
const CardDetailsComponent = withOrgUserSidebar(CardDetails)

// Admin routes
const PerkProgram = IsAdmin(P.PerkProgram)
const OrgTransactions = IsAdmin(P.OrgTransactions)
const Billing = IsAdmin(P.Billing)
const CompanyBranding = IsAdmin(P.CompanyBranding)
const OrgSettings = IsAdmin(P.OrgSettingsPage)
const Integrations = IsAdmin(P.Integrations)
const FirstReward = IsAdmin(CreateFirstReward)
const Insights = IsAdmin(P.Insights)
const PerkProgramDetails = IsAdmin(P.PerkProgramDetails)
const Templates = IsAdmin(P.Templates)

// Admin or Manager routes
const OrgAccounts = IsManager(P.OrgAccounts)
const SpotRewards = IsManager(P.SpotRewards)
const Rewards = IsManager(P.Rewards)
const NewReward = IsManager(P.NewReward)
const ProgramDetails = IsManager(P.ProgramDetails)
const Directory = IsManager(P.Directory)
const BrowseGifts = IsManager(P.BrowseGifts)
const OrgSwag = IsManager(P.OrgSwag)

function AuthPages() {
  return (
    <Routes>
      <Route path="/*" element={<P.SignInPage />} />
      <Route path={ROUTES.SIGN_UP} element={<P.SignUpPage />} />
      <Route
        path={ROUTES.EMAIL_CONFIRMATION}
        element={<P.EmailConfirmation />}
      />
      <Route path={ROUTES.VERIFY_EMAIL} element={<P.VerifyEmailPage />} />
      <Route
        path={`${ROUTES.PREVIEW_REWARD}/*`}
        element={<P.RewardPreview />}
      />
    </Routes>
  )
}

function AppPages() {
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const orgUser = useContext(OrgUserContext)
  const { isManager, isAdmin } = useIndividualRole()
  const isAdminOrManager = isManager || isAdmin
  const { pathname } = useLocation()

  const [searchParams] = useSearchParams()
  const createOrg = searchParams.get(CREATE_ORG) === true.toString()

  const isAuthPage = useMemo(() => {
    return [
      ROUTES.SIGN_IN,
      ROUTES.SIGN_UP,
      ROUTES.VERIFY_EMAIL,
      ROUTES.EMAIL_CONFIRMATION,
    ].includes(pathname)
  }, [pathname])

  const notRewardsPreviewPage = !pathname.includes(ROUTES.PREVIEW_REWARDS)
  const incompleteUserOnboarding = !user.onboarding?.complete
  if (incompleteUserOnboarding && notRewardsPreviewPage) {
    return <P.UserOnboarding />
  }

  const isRemovedIndividual = individual?.status === Individual_Status.removed

  if (createOrg) {
    return <P.CreateOrg />
  }

  if (!user.currentOrganization || isRemovedIndividual) {
    return <P.NoOrg />
  }

  // Org, individual, and orgUser must be loaded before rendering the rest of the app
  if (!org || !individual || !orgUser) {
    return <Loader />
  }

  const defaultToOrgView = !isMobile && isAdminOrManager

  if (isAuthPage) {
    const startingRoute = defaultToOrgView
      ? DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT
      : ROUTES.HOME

    return <Navigate to={startingRoute} />
  }

  const HomeElement = defaultToOrgView ? <SpotRewards /> : <Dashboard />

  return (
    <Routes>
      {/* USER ROUTES */}
      <Route path={ROUTES.HOME} element={HomeElement} />
      <Route path={ROUTES.CARD} element={<Dashboard />}>
        <Route path={ROUTES.CARD_SETUP} element={<P.CardSetupDialog />} />
        <Route path={ROUTES.CARD_ACTIVATED} element={<ActivatedPerkCard />} />
      </Route>
      <Route path={`${ROUTES.AMAZON}/*`} element={<P.Amazon />} />

      <Route path={`${ROUTES.SWAG}/*`} element={<P.Swag />} />
      <Route
        path={`${ROUTES.PREVIEW_REWARD}/*`}
        element={<P.RewardPreview />}
      />
      <Route
        path={`${ROUTES.REWARD_ACCEPTANCE}/*`}
        element={<P.RewardAcceptance />}
      />
      <Route path={ROUTES.TRANSACTIONS} element={<Transactions />}>
        <Route path={ROUTES.TRANSACTIONS_DETAILS} element={<P.Transaction />} />
      </Route>
      <Route path={`${ROUTES.ACCOUNT}/*`} element={<Account />} />
      <Route path={ROUTES.CARD_DETAILS} element={<CardDetailsComponent />} />
      <Route path={`${ROUTES.SHOP}/*`} element={<P.Marketplace />} />

      {/* ADMIN ROUTES */}
      <Route path={ROUTES.FIRST_REWARD} element={<FirstReward />} />
      <Route path={ROUTES.ORGANIZATION} element={<SpotRewards />} />
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.ROOT}
        element={<PerkProgram />}
      />
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.PROGRAM_DETAILS}
        element={<PerkProgramDetails />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.GIFTS.ROOT}/*`}
        element={<BrowseGifts />}
      />
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.TRANSACTIONS}
        element={<OrgTransactions />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}/*`}
        element={<OrgAccounts />}
      />
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.INTEGRATIONS.ROOT}
        element={<Integrations />}
      />
      <Route
        path={DEFAULT_ROUTES.ORGANIZATION.BRANDING}
        element={<CompanyBranding />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.SETTINGS}`}
        element={<OrgSettings />}
      />
      <Route path={DEFAULT_ROUTES.ORGANIZATION.BILLING} element={<Billing />} />

      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT}/*`}
        element={<OrgSwag />}
      />

      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.ORDER.ROOT}/*`}
        element={<P.OrderProducts />}
      />

      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.ROOT}/*`}
        element={<Directory />}
      />
      <Route
        path={`${DEFAULT_ROUTES.ORGANIZATION.INSIGHTS.ROOT}/*`}
        element={<Insights />}
      />

      {/* ADMIN REWARD ROUTES */}
      <Route path={DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}>
        <Route
          path={DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT}
          element={<SpotRewards />}
        />
        <Route
          path={DEFAULT_ROUTES.ORGANIZATION.REWARDS.REWARD}
          element={<ProgramDetails />}
        />
        <Route
          path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT}/*`}
          element={<Templates />}
        />
        <Route
          path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/*`}
          element={<NewReward />}
        />
        <Route
          path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT}/*`}
          element={<Rewards />}
        />
        <Route
          path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.ROOT}/*`}
          element={<Rewards />}
        />
        <Route
          path={`${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_HIRE.ROOT}/*`}
          element={<Rewards />}
        />
      </Route>

      <Route path="*" element={<P.NoMatch404 />} />
    </Routes>
  )
}

function App() {
  const [user, setUser] = useState<RootUser>()
  const [org, setOrg] = useState<Organization>()
  const [exchangeRate, setExchangeRate] = useState(1)
  const [orgUser, setOrgUser] = useState<OrgUser>()
  const [loadingClaims, setLoadingClaims] = useState(false)
  const [macroCategories, setMacroCategories] = useState<MacroCategory[]>([])

  const [countries, setCountries] = useState<Country[]>([])
  const [country, setCountry] = useState<Country>()

  const [userAtlasHash, setUserAtlasHash] = useState('')
  const [courierUserSignature, setCourierUserSignature] = useState<string>()
  const [claimOrgId, setClaimOrgId] = useState('')
  const [totalAccountBalance, setTotalAccountBalance] = useState<number>(0)
  const [individual, setIndividual] = useState<Individual>()
  const [swagCollectionIds, setSwagCollectionIds] = useState<string[]>([])
  const [domain, setDomain] = useState<string>()
  const [orgIntegrations, setOrgIntegrations] = useState<Integration[]>([])
  const [shippingAddresses, setShippingAddresses] = useState<ShippingAddress[]>(
    []
  )

  // get current signed in user
  const [authUser, loadingAuthUser] = useAuthState(getAuth())
  const orgId = org?.id
  const userId = authUser?.uid
  const userCurrentOrganization = user?.currentOrganization
  const userEmail = user?.profile?.email

  const isChangingOrgs = userCurrentOrganization !== orgId

  const role = individual?.role

  const { pathname } = window.location

  // Set logged in user
  useEffect(() => {
    if (!userId) return undefined
    getAuth()?.currentUser?.getIdToken(true)

    return ListenCreateToUserById({
      id: userId,
      cb: setUser,
    })
  }, [userId])

  // Inject claims
  useEffect(() => {
    async function injectClaims(id: string) {
      const host = getFunctionsHost()
      const url = `${host}/firestore-InjectInfo`
      await axios.post(url, { authId: id })
    }

    function wrongClaims(claims: any) {
      if (!individual?.role) return true

      const wrongOrgIdClaim = claims?.orgId !== userCurrentOrganization
      const wrongOrgRoleClaim =
        claims?.orgRole !== Individual_Role[individual.role]

      return wrongOrgIdClaim || wrongOrgRoleClaim
    }

    async function handleAuthChange() {
      if (!authUser) {
        return
      }
      const { claims } = await authUser.getIdTokenResult(true)
      if (claims?.orgId) {
        const orgId = claims.orgId as string
        setClaimOrgId(orgId)
      }

      const hasWrongClaims = wrongClaims(claims)

      if (hasWrongClaims) {
        setLoadingClaims(true)
        const userId = claims.user_id as string
        await injectClaims(userId).finally(() => setLoadingClaims(false))

        const newAuthToken = await authUser.getIdTokenResult(true)
        if (newAuthToken.claims?.orgId) {
          const orgId = newAuthToken.claims.orgId as string
          setClaimOrgId(orgId)
        }
      }
    }

    if (authUser && userCurrentOrganization) {
      handleAuthChange()
    }
  }, [authUser, userCurrentOrganization, individual?.role])

  // Set current organization
  useEffect(() => {
    if (!claimOrgId) return undefined
    return ListenToOrgById({ id: claimOrgId, cb: setOrg })
  }, [claimOrgId])

  useEffect(() => {
    if (!userId) return undefined
    return ListenToShippingAddressesByUserId({
      userId,
      cb: setShippingAddresses,
    })
  }, [userId])

  // Set current organization to empty object if user doesn't have an organization
  useEffect(() => {
    const checkDomainsAndInvites = async () => {
      if (userEmail) {
        // 1: Check domain based on email
        if (!userCurrentOrganization) {
          const domain = getDomainFromEmail(userEmail)
          await GetDomainById({ domain }).then(async domain => {
            if (domain?.orgId && domain?.verified) {
              // Add user to org based on verified domain
              await callFunction('firestore-AddUserToOrg', {
                userId,
                orgId: domain?.orgId,
                role: Individual_Role[Individual_Role.member],
              })
            }
          })
          // 2: Check if there are any invites for email
          await GetInvitesByEmail({ email: userEmail }).then(invitesDocs => {
            invitesDocs?.forEach(invite => {
              // Add user to org based on invite
              callFunction('firestore-AddUserToOrg', {
                userId,
                orgId: invite.orgId,
                role: Individual_Role[invite.role],
              })
            })
          })
        }
      }
    }

    checkDomainsAndInvites()
  }, [userCurrentOrganization, userEmail, userId])

  useEffect(() => {
    if (userEmail) {
      const domain = getDomainFromEmail(userEmail)
      GetDomainById({ domain }).then(async domain => {
        if (domain?.orgId && domain?.verified && domain?.domain) {
          setDomain(domain?.domain)
        }
      })
    }
  }, [userEmail])

  // Set user role and orgUser based on organization
  useEffect(() => {
    if (!userId || !claimOrgId) return undefined

    return ListenToOrgUserById({
      userId,
      orgId: claimOrgId,
      cb: setOrgUser,
    })
  }, [userId, claimOrgId])

  // Set individual
  useEffect(() => {
    if (!userEmail || !claimOrgId) return undefined

    return ListenToIndividualByEmail({
      email: userEmail,
      orgId: claimOrgId,
      cb: setIndividual,
    })
  }, [userEmail, claimOrgId])

  // List macro categories
  useEffect(() => {
    if (userId) {
      ListMacroCategories().then(setMacroCategories)
    }
  }, [userId])

  // Atlas user signature
  useEffect(() => {
    if (userId) {
      callFunction('services-CreateAtlasIdentityHash', { userId }).then(
        setUserAtlasHash
      )
    }
  }, [userId])

  // Listen to org integrations
  useEffect(() => {
    const isAdmin = role === Individual_Role.admin
    if (!isAdmin) return undefined
    if (!orgId) return undefined
    return listenToIntegrationsByOrgId({ orgId, cb: setOrgIntegrations })
  }, [orgId, role])

  // Set user in Sentry, Firebase Analytics & Segment Identify
  useEffect(() => {
    if (user?.profile && userAtlasHash && org?.name && individual?.role) {
      const { email, firstName, lastName } = user.profile

      const companyData = {
        name: org.name,
        company_id: claimOrgId,
        subscriptionStatus: org.subscriptionStatus
          ? Organization_SubscriptionStatus[org.subscriptionStatus]
          : null,
        domain,
      }

      // Set Segment Identify User
      window.analytics.identify(user.id, {
        email,
        firstName,
        lastName,
        role: Individual_Role[individual.role],
        balance: individual?.balance,
        company: {
          id: claimOrgId,
          ...companyData,
        },
        customFields: {
          role: Individual_Role[individual.role],
          title: individual?.title,
          labels: individual?.labels
            ? Object.entries(individual?.labels)
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ')
            : null,
        },
      })
      if (claimOrgId) {
        window.analytics?.group(claimOrgId, companyData)
      }

      window.Atlas.call('identify', {
        userId: user.id,
        name: buildFullName({ firstName, lastName }),
        email,
        userHash: userAtlasHash,
        account: {
          name: companyData?.name,
          website: domain,
          externalId: claimOrgId,
          customFields: {
            subscription_status: companyData.subscriptionStatus,
          },
        },
      })

      // Set Firebase Analytics User
      setUserProperties(getAnalytics(), {
        id: user.id,
        email,
        firstName,
        lastName,
        role: individual?.role ? Individual_Role[individual.role] : null,
      })

      // Set Sentry User
      setSentryUser({
        id: user.id,
        email,
      })
    }
  }, [
    claimOrgId,
    org?.name,
    org?.subscriptionStatus,
    individual?.balance,
    individual?.role,
    user?.id,
    user?.profile,
    userAtlasHash,
    domain,
    individual?.title,
    individual?.labels,
  ])

  // Get user's exchange rate
  const displayCurrency = user?.displayCurrency || org?.displayCurrency || 'usd'
  useEffect(() => {
    if (userId && orgId) {
      GetExchangeRate({
        fromCurrency: 'usd',
        toCurrency: displayCurrency,
      }).then(setExchangeRate)
    }
  }, [orgId, displayCurrency, userId])

  // Get Country
  useEffect(() => {
    if (userId) {
      const countryId = user?.countryId || US_ID // Default to US country id

      GetCountryById({ countryId }).then(setCountry)
    }
  }, [userId, user?.countryId])

  // List Countries
  useEffect(() => {
    if (userId) {
      ListCountries().then(setCountries)
    }
  }, [userId])

  // Courier user signature
  useEffect(() => {
    if (claimOrgId) {
      getCourierToken().then(setCourierUserSignature)
    }
  }, [claimOrgId])

  // Get Total Account Balances

  useEffect(() => {
    const isAdminOrManager =
      role && [Individual_Role.admin, Individual_Role.manager].includes(role)
    if (!claimOrgId || !isAdminOrManager) return undefined
    return ListenToOrgAccounts({
      orgId: claimOrgId,
      cb: accounts => {
        setTotalAccountBalance(sum(accounts.map(a => Number(a.balance))))
      },
    })
  }, [claimOrgId, role])

  // Get ids for org's public swag collections
  useEffect(() => {
    if (orgId && country) {
      return ListenToPublicSwagCollections({
        orgId,
        cb: collections => {
          const countryFilteredCollections = filterProductCollectionsByCountry({
            iso3: country.iso3,
            collections,
          })

          const collectionIds = countryFilteredCollections.map(
            collection => collection.id
          )

          setSwagCollectionIds(collectionIds)
        },
      })
    }
    return undefined
  }, [orgId, country, org])

  const primaryColor = org?.primaryColor || PERKUP_PRIMARY_COLOR

  const customEGTheme = useMemo(() => {
    return customEvergreenTheme({
      primaryColor,
    })
  }, [primaryColor])

  const customAntTheme = useMemo(() => {
    return {
      components: {
        Table: {
          headerBorderRadius: 0,
        },
      },
      token: {
        colorPrimary: primaryColor,
      },
    }
  }, [primaryColor])

  const signInPath = pathname?.includes(ROUTES.SIGN_IN)
  const params = new URLSearchParams(document.location.search)
  const code = params.get(CODE)
  const SSOworkOS = signInPath && code

  // isChangingOrgs is a temp fix for org mismatch, need to refactor app.tsx
  if (loadingAuthUser || loadingClaims || isChangingOrgs) return <Loader />

  if (!authUser || SSOworkOS)
    return (
      <ConfigProvider theme={customAntTheme}>
        <AuthPages />
      </ConfigProvider>
    )

  if (!user || !country || !countries.length) {
    return <Loader />
  }

  return (
    <ThemeProvider value={customEGTheme}>
      <ConfigProvider theme={customAntTheme}>
        <UserContext.Provider value={user}>
          <OrgContext.Provider value={org!}>
            <IndividualContext.Provider value={individual!}>
              <OrgBalanceContext.Provider value={totalAccountBalance}>
                <OrgUserContext.Provider value={orgUser!}>
                  <ExchangeRateContext.Provider value={exchangeRate}>
                    <CatContext.Provider value={macroCategories}>
                      <CountriesContext.Provider value={countries}>
                        <CountryContext.Provider value={country}>
                          <SwagCollectionIdsContext.Provider
                            value={swagCollectionIds}
                          >
                            <UserShippingAddressesContext.Provider
                              value={shippingAddresses}
                            >
                              <OrgIntegrationsContext.Provider
                                value={orgIntegrations}
                              >
                                <CourierProvider
                                  clientKey={KEYS.COURIER_CLIENT_KEY}
                                  userSignature={courierUserSignature}
                                  userId={userId}
                                  brand={{
                                    colors: {
                                      primary: primaryColor,
                                    },
                                  }}
                                >
                                  <Toast />
                                  <AppPages />
                                </CourierProvider>
                              </OrgIntegrationsContext.Provider>
                            </UserShippingAddressesContext.Provider>
                          </SwagCollectionIdsContext.Provider>
                        </CountryContext.Provider>
                      </CountriesContext.Provider>
                    </CatContext.Provider>
                  </ExchangeRateContext.Provider>
                </OrgUserContext.Provider>
              </OrgBalanceContext.Provider>
            </IndividualContext.Provider>
          </OrgContext.Provider>
        </UserContext.Provider>
      </ConfigProvider>
    </ThemeProvider>
  )
}

// Only to be used for global providers
function AppWrapper() {
  const [isInactive, setIsInactive] = useState(false)
  const { pathname } = window.location

  const { stripe } = useStripe()

  // Set Segment Page
  const title = getHeadTitle(pathname)
  useEffect(() => {
    if (isProdHost) {
      window.analytics.page({ title })
    }
  }, [title])

  const hours = 12
  const msPerHour = 1000 * 60 * 60
  const timeoutMillis = hours * msPerHour
  const timeoutRef = useRef<null | NodeJS.Timeout>(null)

  useEffect(() => {
    const onInactive = () => {
      setIsInactive(true)
    }
    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(onInactive, timeoutMillis)
    }

    const handleUserActivity = () => {
      if (!isInactive) {
        resetTimeout()
      }
    }
    resetTimeout()

    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)
    document.addEventListener('mousedown', handleUserActivity)
    document.addEventListener('touchstart', handleUserActivity)

    return () => {
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
      document.removeEventListener('mousedown', handleUserActivity)
      document.removeEventListener('touchstart', handleUserActivity)
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
  }, [timeoutMillis, isInactive])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content="Welcome back! Log in to PerkUp. Sign In with Google SSO or Email address. Don't have an account? Sign Up."
        />
      </Helmet>
      <BrowserRouter>
        {isInactive ? (
          <SessionExpired />
        ) : (
          <Elements
            stripe={stripe}
            options={{
              appearance: { theme: 'stripe' },
            }}
          >
            <ScrollToTop />
            <App />
          </Elements>
        )}
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default withProfiler(AppWrapper)
