import { Timestamp } from '@bufbuild/protobuf'
import { ConnectError } from '@connectrpc/connect'
import { captureException } from '@sentry/react'
import { deleteProgram, GetProductVariantById } from 'api/databaseCalls'
import { SWAG_CARD } from 'assets/contentful'
import { allApprovedCats } from 'constants/EligibleCategories'
import { Account } from 'gen/perkup/v1/account_pb'
import { Individual } from 'gen/perkup/v1/individual_pb'
import { Organization } from 'gen/perkup/v1/organization_pb'
import {
  Item,
  Program,
  ProgramFrequency,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { RootUser, ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { CalculatedDraftOrder } from 'gen/shopifyapi/admingql_pb'
import { createProgram } from 'pages/NewReward/utils/programs'
import { validateFullName } from 'pages/NewReward/utils/uiUtils'
import { placeManualOrder } from 'services'
import { getFirstAndLastNameFromAddress } from 'utils'
import { getOrderErrorMessage } from 'utils/errors'
import { groupItemsByQuantity } from 'utils/items'
import {
  getOutOfStockProductVariants,
  getProductVariantOutOfStockMessage,
} from 'utils/productVariant'

interface PlaceDirectOrderResponse {
  status: 'success' | 'error'
  message: string
  data: {
    addedProgramId?: string
  }
}

export async function placeProductVariantsOrder({
  shippingAddress,
  items,
  user,
  org,
  individual,
  orderTitle,
  account,
  internalMemo,
  draftOrderCalculation,
  orderTotal,
  program,
}: {
  shippingAddress: ShippingAddress
  items: Item[]
  user: RootUser
  org: Organization
  individual: Individual
  orderTitle?: string
  account?: Account
  internalMemo?: string
  draftOrderCalculation?: CalculatedDraftOrder
  orderTotal?: number
  program?: Program
}): Promise<PlaceDirectOrderResponse> {
  const errorContext = {
    shippingAddress,
    items,
    user,
    org,
    individual,
    orderTitle,
    account,
    internalMemo,
    draftOrderCalculation,
    orderTotal,
  }

  const { firstName, lastName } =
    getFirstAndLastNameFromAddress(shippingAddress)

  const validFullName = validateFullName({
    firstName,
    lastName,
  })

  if (!validFullName || !firstName || !lastName) {
    captureException('User did not have valid full name while placing order', {
      contexts: {
        placeDirectMailOrder: {
          ...errorContext,
        },
      },
    })
    return {
      status: 'error',
      message: 'Shipping address is missing a valid name',
      data: {},
    }
  }

  const programItemsWithQuantity = items.filter(item => item.quantity > 0)

  const itemsGroupedByQty = groupItemsByQuantity({
    items: programItemsWithQuantity,
  })

  const outOfStockVariants = await getOutOfStockProductVariants({
    items: itemsGroupedByQty,
  })

  if (outOfStockVariants.length > 0) {
    const inventoryAlert = getProductVariantOutOfStockMessage({
      variants: outOfStockVariants,
    })

    captureException('User tried to order out of stock items', {
      contexts: {
        placeDirectMailOrder: {
          inventoryAlert,
          ...errorContext,
        },
      },
    })

    return {
      status: 'error',
      message: inventoryAlert,
      data: {},
    }
  }

  // If a specific program gets passed in, place the order using it

  if (program) {
    try {
      await placeManualOrder({
        firstName,
        lastName,
        shippingAddress,
        programIds: [program.id],
        items: itemsGroupedByQty,
        shippingRateHandle:
          draftOrderCalculation?.shippingLine?.shippingRateHandle,
        orgId: org.id,
        userId: user.id,
      })
    } catch (error) {
      const { title, description } = getOrderErrorMessage({
        error: error as ConnectError,
      })

      console.error(title, description)

      captureException(title, {
        contexts: {
          placeDirectMailOrder: {
            description,
            ...errorContext,
          },
        },
      })

      return {
        status: 'error',
        message: 'Unable to place order with selected account',
        data: {},
      }
    }

    return {
      status: 'success',
      message: 'Order placed successfully',
      data: {
        addedProgramId: program.id,
      },
    }
  }

  // We place the order directly without creating a program if an account was not passed in
  if (!account) {
    try {
      await placeManualOrder({
        firstName,
        lastName,
        shippingAddress,
        items: itemsGroupedByQty,
        orgId: org.id,
        userId: user.id,
      })
    } catch (error) {
      const { title, description } = getOrderErrorMessage({
        error: error as ConnectError,
      })
      console.error(title, description)
      captureException(title, {
        contexts: {
          placeDirectMailOrder: {
            description,
            ...errorContext,
          },
        },
      })

      return {
        status: 'error',
        message: 'Unable to place order',
        data: {},
      }
    }

    return {
      status: 'success',
      message: 'Order placed successfully',
      data: {},
    }
  }

  // Else, starting the process of creating a program, then placing the order
  const defaultProductVariantId = items[0].productVariantId

  const defaultVariant = await GetProductVariantById({
    productVariantId: defaultProductVariantId,
  })

  const productImage = defaultVariant?.imageUrl || SWAG_CARD

  const activeProgram = new Program({
    status: ProgramStatus.active,
    items: itemsGroupedByQty,
    email: {
      banner: productImage,
    },
    ownerId: user.id,
    type: ProgramType.direct,
    name: orderTitle || `${firstName} ${lastName}'s order`,
    isDeleted: false,
    frequency: ProgramFrequency.once,
    accountId: account.id,
    budget: orderTotal,
    prorate: false,
    totalSpent: 0,
    totalBudget: 0,
    approvedCategories: allApprovedCats,
    allMerchants: true,
    internalMemo,
    created: Timestamp.now(),
    startsOn: Timestamp.now(),
  })

  const addedProgram = await createProgram({
    org,
    program: activeProgram,
    recipientEmails: [],
    recipientIds: [individual.id],
  })

  if (!addedProgram) {
    captureException(
      'Issue creating a program while placing direct mail order',
      {
        contexts: {
          placeDirectMailOrder: {
            ...errorContext,
          },
        },
      }
    )
    return {
      status: 'error',
      message: 'Oops, we made a mistake',
      data: {},
    }
  }

  try {
    await placeManualOrder({
      firstName,
      lastName,
      shippingAddress,
      programIds: [addedProgram.id],
      items: itemsGroupedByQty,
      shippingRateHandle:
        draftOrderCalculation?.shippingLine?.shippingRateHandle,
      orgId: org.id,
      userId: user.id,
    })
  } catch (error) {
    const { title, description } = getOrderErrorMessage({
      error: error as ConnectError,
    })

    console.error(title, description)

    captureException(title, {
      contexts: {
        placeDirectMailOrder: {
          description,
          ...errorContext,
        },
      },
    })

    deleteProgram({ orgId: org.id, programId: addedProgram.id })

    return {
      status: 'error',
      message: 'Unable to place order with selected account',
      data: {},
    }
  }

  return {
    status: 'success',
    message: 'Order placed successfully',
    data: {
      addedProgramId: addedProgram.id,
    },
  }
}
