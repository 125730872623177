import { Breadcrumb, Flex } from 'antd'
import {
  AddressShortDisplay,
  BackIconButton,
  Loader,
  MarketplaceCheckout,
  PerkUpLink,
} from 'components'
import { ProductVariantCarousel } from 'components/Carousels/ProductVariantCarousel'
import { VariantEstimatedShippingTime } from 'components/ProductVariants/VariantEstimatedShippingTime'
import { BalanceSubHeading } from 'components/Typography/BalanceSubHeading'
import {
  ALGOLIA_COUNTRY_CODE_FACET_VALUE,
  ALGOLIA_FOOD_FACET_VALUE,
  ALGOLIA_PRODUCT_ID_FACET_VALUE,
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  ALGOLIA_TAGS_FACET_VALUE,
  PUBLIC_GIFT_TAG,
  SCRAPE_IT_TAG,
  TYPE_NEAR_CASH,
} from 'constants/algolia'
import { CHECKOUT_STARTED, PRODUCT_CATEGORY_CLICKED } from 'constants/events'
import * as ROUTES from 'constants/routes'
import { CountryContext, UserShippingAddressesContext } from 'context'
import { Pane } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useOrgUserBalances, useProductVariantSearchClient } from 'hooks'
import useProductVariantById from 'hooks/productVariants/useProductVariantById'
import useIds from 'hooks/useIds'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Configure, InstantSearch } from 'react-instantsearch'
import { useOutletContext, useParams } from 'react-router'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { logEvent } from 'utils'

function SelectVariant({
  searchFilter,
  onSelectVariant,
}: {
  searchFilter: string
  onSelectVariant: (productVariant: ProductVariant) => void
}) {
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const { userId, orgId } = useIds()
  const { pid } = useParams()
  const navigate = useNavigate()
  const { productVariant, isLoading } = useProductVariantById({
    productVariantId: pid,
  })

  const outletContext: {
    shippingAddress: ShippingAddress | undefined
    onShippingAddressChange: (address?: ShippingAddress) => void
  } = useOutletContext()

  const { searchClient } = useProductVariantSearchClient()

  const handleSeeMore = () => {
    logEvent(PRODUCT_CATEGORY_CLICKED, {
      userId,
      orgId,
      filterType: 'seeMore',
    })
    navigate(`${ROUTES.SHOP}${ROUTES.PRODUCTS}`)
    window.scrollTo(0, 0)
  }

  if (isLoading) return <Loader />
  if (!productVariant || !searchClient) return null

  return (
    <Flex vertical gap={16}>
      <ProductDetails
        productVariant={productVariant}
        showPrice
        showShipping
        onPrimaryCtaClick={onSelectVariant}
        primaryCtaProps={{
          children: 'Purchase',
        }}
      >
        {outletContext?.shippingAddress && (
          <ProductDetails.Slot name="displayAddress">
            <AddressShortDisplay
              isMuted
              shippingAddress={outletContext?.shippingAddress}
              shippingAddresses={shippingAddresses}
              onShippingAddressChange={outletContext.onShippingAddressChange}
            />
          </ProductDetails.Slot>
        )}
        <ProductDetails.Slot name="shippingEstimation">
          <VariantEstimatedShippingTime
            productVariant={productVariant}
            shippingAddress={outletContext?.shippingAddress}
          />
        </ProductDetails.Slot>
      </ProductDetails>

      {!isMobile && (
        <InstantSearch
          indexName={ALGOLIA_PRODUCT_VARIANTS_INDEX}
          searchClient={searchClient}
        >
          <Configure
            filters={`${searchFilter} AND NOT ${ALGOLIA_PRODUCT_ID_FACET_VALUE}:${productVariant.productId}`}
            hitsPerPage={30}
            userToken={userId}
          />
          <ProductVariantCarousel
            showSeeAllButton
            showHeading
            header="You might also like..."
            handleSeeMoreClick={handleSeeMore}
            handleCardClick={({ productVariantId }) => {
              navigate(
                `${ROUTES.SHOP}${ROUTES.PRODUCTS}${ROUTES.BROWSE}/${productVariantId}`
              )
              window.scrollTo(0, 0)
            }}
            handleCTAText="See more"
          />
        </InstantSearch>
      )}
    </Flex>
  )
}

export function Shopping() {
  const country = useContext(CountryContext)

  const navigate = useNavigate()
  const { orgId, userId } = useIds()

  const { orgUserLifestyleBalance } = useOrgUserBalances()

  let tagsFilter = 'AND'
  const tags = [SCRAPE_IT_TAG, PUBLIC_GIFT_TAG]

  tags.forEach((tag, index) => {
    if (index === 0) {
      tagsFilter += ` ${ALGOLIA_TAGS_FACET_VALUE}:${tag}`
    } else {
      tagsFilter += ` OR ${ALGOLIA_TAGS_FACET_VALUE}:${tag}`
    }
  })

  const searchFilter = `NOT ${TYPE_NEAR_CASH} AND NOT productType:'${ALGOLIA_FOOD_FACET_VALUE}' AND ${ALGOLIA_COUNTRY_CODE_FACET_VALUE}:${country.iso3} ${tagsFilter}`

  const handleViewProduct = (productVariant: ProductVariant) => {
    navigate(
      `${ROUTES.SHOP}${ROUTES.PRODUCTS}${ROUTES.BROWSE}/${productVariant.id}`
    )
    window.scrollTo(0, 0)
  }

  const handleStartCheckout = (productVariant: ProductVariant) => {
    logEvent(CHECKOUT_STARTED, {
      userId,
      orgId,
      userToken: userId,
      eligibleBalance: orgUserLifestyleBalance,
      productVariant,
      ...productVariant,
    })
    navigate(
      `${ROUTES.SHOP}${ROUTES.PRODUCTS}${ROUTES.BROWSE}/${productVariant.id}/${productVariant.id}/${ROUTES.CHECKOUT}`
    )
    window.scrollTo(0, 0)
  }

  return (
    <Routes>
      <Route
        index
        element={
          <AlgoliaBrowseProducts>
            <AlgoliaBrowseProducts.Old
              withPriceFilter
              withProductAmounts
              withCountryFilter
              withCategoriesFilter
              withLocalAmounts
              onProductCardClick={handleViewProduct}
              searchFilter={searchFilter}
              filterUiProps={{
                priceFilterPrefix: '',
              }}
              stickyNavTopAmount={isMobile ? 72 : 60}
            />
          </AlgoliaBrowseProducts>
        }
      />
      <Route
        path={ROUTES.VIEW_PRODUCT}
        element={
          <Pane
            display="flex"
            flexDirection="column"
            gap={64}
            maxWidth={1280}
            marginX="auto"
            marginBottom={32}
          >
            <Flex vertical gap={16}>
              {!isMobile && (
                <Flex gap={16}>
                  <BackIconButton to={`${ROUTES.SHOP}${ROUTES.PRODUCTS}`} />

                  <Flex vertical gap={72} style={{ flexBasis: '100%' }}>
                    <Breadcrumb style={{ marginTop: 6 }}>
                      <Breadcrumb.Item>
                        <PerkUpLink to={`${ROUTES.SHOP}${ROUTES.PRODUCTS}`}>
                          Products
                        </PerkUpLink>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  </Flex>
                </Flex>
              )}
              <BalanceSubHeading
                amount={orgUserLifestyleBalance}
                category="Products"
                showCategory={false}
              />
              <SelectVariant
                searchFilter={searchFilter}
                onSelectVariant={handleStartCheckout}
              />
            </Flex>
          </Pane>
        }
      />
      <Route
        path={ROUTES.PRODUCT_CHECKOUT}
        element={<MarketplaceCheckout balance={orgUserLifestyleBalance} />}
      />
    </Routes>
  )
}
