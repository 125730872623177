import { Empty } from '@bufbuild/protobuf'
import { getAuth } from 'firebase/auth'
import { AccountTransfer } from 'gen/ledger/account_connect'
import { createClient } from 'hooks/useClient'

export function transferFunds({
  sourceAccount,
  destinationAccount,
  amount,
}: {
  sourceAccount: string
  destinationAccount: string
  amount: bigint
}): () => Promise<Empty> {
  return async () => {
    const accessToken = await getAuth().currentUser?.getIdToken()
    const client = createClient(AccountTransfer)
    return client.transferFunds(
      { sourceAccount, destinationAccount, amount },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )
  }
}
