import { CloseOutlined, PlusOutlined, SkinOutlined } from '@ant-design/icons'
import { Alert, AlertProps, Button, Drawer, Flex, Input, Modal } from 'antd'
import { createShippingAddress } from 'api/databaseCalls'
import {
  AddressButton,
  AddressDisplay,
  OrderLineItems,
  PriceBreakdown,
  SelectedItemsList,
  ShippingAddressBlock,
} from 'components'
import { ALGOLIA_PRODUCT_VARIANTS_INDEX } from 'constants/algolia'
import { USA_ISO3 } from 'constants/countries'
import { SIZE_OPTION } from 'constants/productVariants'
import { DEFAULT_DIRECT_MAIL_ORDER_TITLE } from 'constants/rewards'
import { Heading, Pane, Paragraph, Strong, Text, toaster } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import useIds from 'hooks/useIds'
import { cloneDeep, isEmpty } from 'lodash-es'
import InternalMemoForm from 'pages/NewReward/components/InternalMemoForm'
import { useState } from 'react'
import { SelectedItems } from 'types/Items'
import { getAlgoliaOrgSwagFilters } from 'utils/Algolia'
import { flattenItems } from 'utils/items'
import { getProductVariantProvider } from 'utils/productVariant'
import { v4 as uuid } from 'uuid'

export function OrderSwagPage({
  totalCost,
  selectedItems,
  setSelectedItems,
  shippingAddress,
  setShippingAddress,
  setOrderTitle,
  setInternalMemo,
  productAlertProps,
}: {
  totalCost?: number
  selectedItems: SelectedItems
  setSelectedItems: (newItems: SelectedItems) => void
  shippingAddress?: ShippingAddress
  setShippingAddress: (newAddress?: ShippingAddress) => void
  setOrderTitle: (newTitle: string) => void
  setInternalMemo: (newMemo: string) => void
  productAlertProps?: AlertProps
}) {
  const { orgId, userId } = useIds()

  const [showProductsModal, setShowProductsModal] = useState(false)
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const handleSelectProductVariant = (productVariant: ProductVariant) => {
    const hasSizeOption = productVariant.options[SIZE_OPTION]?.value
    const quantity = hasSizeOption ? 0 : 1

    const newItem = new Item({
      productVariantId: productVariant.id,
      productId: productVariant.productId,
      provider: getProductVariantProvider({ productVariant }),
      quantity,
    })

    const updatedItems = cloneDeep(selectedItems)
    updatedItems.set(uuid(), [newItem])
    setSelectedItems(updatedItems)
    setProductVariantToShow(undefined)

    setShowProductsModal(false)
  }

  const handleRemoveItem = (itemKey: string) => {
    const filteredItems = cloneDeep(selectedItems)
    filteredItems.delete(itemKey)
    setSelectedItems(filteredItems)
    toaster.warning('Product removed')
  }

  const handleChangeItems = ({
    items,
    itemsKey,
  }: {
    items: Item[]
    itemsKey: string
  }) => {
    const updatedItems = cloneDeep(selectedItems)
    updatedItems.set(itemsKey, items)
    setSelectedItems(updatedItems)
  }

  const itemCount =
    flattenItems({ items: selectedItems }).filter(item => item.quantity > 0)
      .length || undefined

  const handleAddressChange = (shippingAddress?: ShippingAddress) => {
    if (shippingAddress && !shippingAddress?.id) {
      createShippingAddress({
        userId,
        shippingAddress,
      }).then(() => toaster.success('Successfully created shipping address'))
    }
    setShippingAddress(shippingAddress)
  }

  const addProductsCTAIsButton = !isEmpty(selectedItems)

  return (
    <Flex style={{ height: '100%' }} vertical align="center" gap={32}>
      <Flex vertical gap={16} align="center">
        <Heading size={900}>Order swag</Heading>
        <Text>Send swag directly to a specific address or order samples.</Text>
      </Flex>
      <Modal
        width={1280}
        centered
        zIndex={2}
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShipping
            showShippingCountries
            showInStockText
            primaryCtaProps={{ children: 'Select gift' }}
            withAmountInUsd
            onPrimaryCtaClick={handleSelectProductVariant}
          />
        )}
      </Modal>

      <Flex gap={32} style={{ width: '100%' }}>
        <Flex vertical gap={16} flex={1}>
          <Flex vertical gap={8}>
            <Strong>Order title</Strong>
            <Input
              defaultValue={DEFAULT_DIRECT_MAIL_ORDER_TITLE}
              placeholder="Order title"
              onBlur={e => setOrderTitle(e.target.value)}
            />
          </Flex>

          {addProductsCTAIsButton ? (
            <Button
              icon={<PlusOutlined />}
              style={{ width: 'fit-content' }}
              onClick={() => setShowProductsModal(true)}
            >
              Add product
            </Button>
          ) : (
            <Pane
              onClick={() => setShowProductsModal(true)}
              cursor="pointer"
              display="flex"
              borderRadius={8}
              border="muted"
              hoverElevation={1}
              width="100%"
              overflow="hidden"
              height={120}
              gap={16}
              paddingY={16}
              paddingX={32}
              alignItems="center"
            >
              <SkinOutlined size={48} style={{ fontSize: 32 }} />

              <Pane display="flex" flexDirection="column" gap={8}>
                <Heading size={600}>Select swag</Heading>
                <Paragraph size={400}>
                  Choose from a selection of premium branded swag.
                </Paragraph>
              </Pane>
            </Pane>
          )}
          <SelectedItemsList
            selectedItems={selectedItems}
            onClearItems={handleRemoveItem}
            onChangeItems={handleChangeItems}
          />
          {shippingAddress ? (
            <ShippingAddressBlock
              shippingAddress={shippingAddress}
              onAddressChange={handleAddressChange}
            />
          ) : (
            <AddressButton
              submitLabel="Add address"
              addAddressCTALabel="Add address"
              onAddressSubmit={handleAddressChange}
              dialogTitle="Add new address"
            />
          )}
          <Pane marginTop={16}>
            <InternalMemoForm setInternalMemo={setInternalMemo} />
          </Pane>
        </Flex>
        <Flex vertical gap={16} flex={1}>
          <Pane elevation={1} padding={32} borderRadius={8}>
            <Flex vertical gap={16}>
              <Flex vertical gap={8}>
                <Flex justify="space-between">
                  <Heading size={500}>Order summary</Heading>
                  {!!itemCount && (
                    <Text color="muted">{itemCount} selected</Text>
                  )}
                </Flex>
              </Flex>

              {/** @todo ENG-6192 Use ProductsSummary here instead (The same component that is used on Step 3 Swag page and Step 5 Review page) */}
              <OrderLineItems items={selectedItems} />
              {totalCost && (
                <PriceBreakdown
                  productPrice={totalCost}
                  shippingCost={0}
                  totalCost={totalCost}
                  totalLabel="Total:"
                  estimatedTax={0}
                  perkUpFee={0}
                  hideTaxAndPerkupFee
                />
              )}

              {shippingAddress?.country && (
                <AddressDisplay address={shippingAddress} hasBadge />
              )}
            </Flex>
          </Pane>
          <Paragraph marginTop={12}>
            When using Direct mail, the recipient will not receive an email
            notification once the order is placed. You can view the delivery
            status on the order details page.
          </Paragraph>
          {productAlertProps && (
            <Alert
              showIcon
              type="warning"
              {...productAlertProps}
              style={{
                width: 'fit-content',
                alignSelf: 'center',
                marginBottom: 16,
              }}
            />
          )}
        </Flex>
      </Flex>

      <Drawer
        zIndex={1}
        open={showProductsModal}
        placement="bottom"
        width="100vw"
        height="100vh"
        closeIcon={null}
        title={
          <Pane
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            zIndex={2}
          >
            <Heading size={500}>Add swag</Heading>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setShowProductsModal(false)}
            />
          </Pane>
        }
        styles={{
          header: {
            borderBottom: 'none',
            width: '100%',
          },
          body: {
            paddingTop: '0px',
          },
        }}
        destroyOnClose
      >
        <AlgoliaBrowseProducts
          initialUiState={{
            [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
              menu: {
                shippingCountries: USA_ISO3,
              },
            },
          }}
        >
          <AlgoliaBrowseProducts.Old
            onProductCardClick={setProductVariantToShow}
            withPriceFilter
            withProductAmounts
            withCountryFilter
            bottomOffsetAmount={128}
            menuFilters={[]}
            filterUiProps={{
              priceFilterPrefix: 'Swag',
              searchPlaceholder: 'Search for swag',
            }}
            searchFilter={getAlgoliaOrgSwagFilters({ orgId })}
          />
        </AlgoliaBrowseProducts>
      </Drawer>
    </Flex>
  )
}
