import { TeamOutlined } from '@ant-design/icons'
import { Button, Modal, Tooltip, type ButtonProps } from 'antd'
import { Pane } from 'evergreen-ui'
import { useState } from 'react'
import { PermissionOption, Permissions } from 'types/Permissions'
import { PermissionsOverview } from './PermissionsOverview'

export function EditPermissionsButton({
  tooltipTitle,
  modalTitle,
  currentPermissions,
  onPermissionsChange,
  adminPermissionOptions,
  managerPermissionOptions,
  individualPermissionOptions,
  buttonProps,
}: {
  tooltipTitle: string
  modalTitle: string
  currentPermissions: Permissions
  onPermissionsChange: (updatedPermissions: Permissions) => void
  adminPermissionOptions: PermissionOption[]
  managerPermissionOptions: PermissionOption[]
  individualPermissionOptions: PermissionOption[]
  buttonProps?: ButtonProps
}) {
  const [showPermissionsModal, setShowPermissionsModal] = useState(false)

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Button
          type="text"
          icon={<TeamOutlined />}
          onClick={() => setShowPermissionsModal(true)}
          {...buttonProps}
        />
      </Tooltip>
      <Modal
        open={showPermissionsModal}
        afterClose={() => setShowPermissionsModal(false)}
        onCancel={() => setShowPermissionsModal(false)}
        title={modalTitle}
        width={512}
        footer={null}
      >
        {showPermissionsModal && (
          <Pane display="flex" minHeight="100px">
            <Pane
              display="flex"
              flexDirection="column"
              gap={8}
              marginTop={32}
              width="100%"
            >
              <PermissionsOverview
                currentPermissions={currentPermissions}
                onPermissionsChange={onPermissionsChange}
                adminPermissionOptions={adminPermissionOptions}
                managerPermissionOptions={managerPermissionOptions}
                individualPermissionOptions={individualPermissionOptions}
                isModal
              />
            </Pane>
          </Pane>
        )}
      </Modal>
    </>
  )
}
