import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const employeeRecognitionUseCases = {
  WELCOME: {
    label: 'Welcome',
    emailData: [],
    details: {
      searchQuery: 'welcome',
      templateCategories: [],
      placidTags: [],
    },
  },
  CONGRATS: {
    label: 'Congratulations',
    emailData: [],
    details: {
      searchQuery: 'congratulations',
      templateCategories: [],
      placidTags: [PlacidTags.CONGRATULATIONS, PlacidTags.THANK_YOU],
    },
  },
  NEW_EMPLOYEE: {
    label: 'New employee',
    details: {
      searchQuery: 'welcome',
      templateCategories: [CategoryTitle.NEW_EMPLOYEE],
      placidTags: [PlacidTags.NEW_EMPLOYEE],
    },
    emailData: [
      {
        title: 'Welcome Aboard!',
        note: "Thrilled to have you join our team. Here's to new beginnings and memorable times!",
      },
      {
        title: 'New Journey Begins 🚀',
        note: "We're excited to embark on this journey with you. Welcome!",
      },
      {
        title: 'Hey there, Newbie!',
        note: "A big warm welcome to our team. We're so glad you're here!",
      },
    ],
  },
  NEW_HIRE_SWAG: {
    label: 'New hire swag',
    details: {
      searchQuery: 'welcome',
      templateCategories: [],
      placidTags: [PlacidTags.NEW_EMPLOYEE],
    },
    emailData: [
      {
        title: 'Swag Alert!',
        note: 'Some cool gear to kickstart your journey with us. Welcome!',
      },
      {
        title: 'Dress the Part 🎽',
        note: 'Our newest team member deserves some swag. Enjoy!',
      },
      {
        title: 'Fresh Gear!',
        note: "Welcome to the team! Here's some swag to get you started.",
      },
    ],
  },
  EMPLOYEE_OF_THE_WEEK: {
    label: 'Employee of the week',
    details: {
      searchQuery: "you're the best",
      templateCategories: [],
      placidTags: [PlacidTags.CONGRATULATIONS, PlacidTags.THANK_YOU],
    },
    emailData: [
      {
        title: 'Star of the Week!',
        note: 'Your hard work shines bright! Congrats on being the employee of the week.',
      },
      {
        title: 'Weekly Wonder 🌟',
        note: 'A well-deserved recognition for a job well done this week!',
      },
      {
        title: "Week's Best!",
        note: "You've outshone us all this week. Keep up the incredible work!",
      },
    ],
  },
  LEADERSHIP: {
    label: 'Leadership',
    details: {
      searchQuery: 'leader',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Kudos to Our Leader!',
        note: 'Your leadership has truly inspired us all. Cheers to your exceptional qualities!',
      },
      {
        title: 'Celebrating Leadership Excellence!',
        note: 'Your exemplary leadership shines bright. Thank you for guiding us forward.',
      },
      {
        title: 'Leadership Milestone Achieved!',
        note: "Congratulations on setting a benchmark in leadership. Here's to your continued success!",
      },
    ],
  },
  INNOVATION: {
    label: 'Innovation',
    details: {
      searchQuery: 'scientist',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Cheers to Your Innovative Spirit!',
        note: "Your innovative ideas have truly set a new standard. Here's to pushing boundaries and achieving more!",
      },
      {
        title: 'Celebrating Your Creative Genius!',
        note: 'Your creativity has been a game-changer for us. Thank you for driving innovation and inspiring us all!',
      },
      {
        title: 'Innovation at Its Best!',
        note: "Thanks to your innovative approach, we're at the forefront of our industry. Keep the great ideas coming!",
      },
    ],
  },
  TEAMWORK: {
    label: 'Teamwork',
    details: {
      searchQuery: 'teamwork',
      templateCategories: [],
      placidTags: [PlacidTags.CONGRATULATIONS],
    },
    emailData: [
      {
        title: 'Teamwork Makes the Dream Work!',
        note: "Your teamwork skills have truly united us, leading to outstanding results. Here's to more collaborative success!",
      },
      {
        title: 'Champion of Collaboration!',
        note: "Your ability to collaborate has been key to our team's success. Thank you for being an exemplary team player!",
      },
      {
        title: 'Unity in Action!',
        note: 'Your spirit of cooperation has made a significant impact. Thanks for showing us the power of working together!',
      },
    ],
  },
  ROLE_MODEL: {
    label: 'Role model',
    details: {
      searchQuery: 'leader',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'A True Role Model!',
        note: 'Your actions and ethics set a high standard for everyone. Thank you for being an exemplary role model!',
      },
      {
        title: 'Inspiring Excellence!',
        note: 'Your commitment and professionalism inspire us all. Thanks for being a guiding light and a true role model!',
      },
      {
        title: 'Leading by Example!',
        note: 'Your integrity and dedication serve as a beacon for us all. Thank you for being such a fantastic role model!',
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
