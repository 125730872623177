import { captureMessage } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import { Loader } from 'components/Loader'
import { OrgContext } from 'context'
import { Alert, Pane, toaster } from 'evergreen-ui'
import {
  GroupByType_Enum,
  NotificationSetting,
} from 'gen/perkup/v1/notification_pb'
import { sample } from 'lodash-es'
import { useContext, useEffect, useRef, useState } from 'react'
import { SlackChannel } from 'types'
import {
  NotificationOccassions,
  NotificationSendTypes,
  RuleGroups,
} from 'types/Notifications'
import SlackPublicConfigForm from './SlackPublicConfigForm'
import { SlackPublicNotificationPreview } from './SlackPublicNotificationPreview'

export const personalizeOptions: Record<string, string>[] = [
  {
    beVar: '{{ .FirstName }}',
    description: "Individual's first name",
  },
  {
    beVar: '{{ .LastName }}',
    description: "Individual's last name",
  },
  {
    beVar: '{{ .Year }}',
    description: 'Number of years being celebrated',
  },
  {
    beVar: '{{ .Nth }}',
    description: 'Number of years with suffix (1st, 2nd, etc.)',
  },
]

export function getSlackPublicDefaultsByOccasion(
  occassion: NotificationOccassions
) {
  let defaultImages: string[] = []
  let placeholder = ''
  switch (occassion) {
    case NotificationOccassions.ANNIVERSARY:
      defaultImages = [
        'https://media.giphy.com/media/UyIMHCKMfquVPZiENm/giphy.gif',
        'https://media.giphy.com/media/QrLlWZyabLNAYpdtFZ/giphy.gif',
        'https://media.giphy.com/media/zWXtPswhdr1S4Dz5Rz/giphy.gif',
      ]
      placeholder = `Happy workaversary! We truly appreciate everything you do 🚀`
      break
    case NotificationOccassions.BIRTHDAY:
      defaultImages = [
        'https://media.giphy.com/media/g5R9dok94mrIvplmZd/giphy.gif',
        'https://media.giphy.com/media/31VVbt1NFZ2B2Et8hY/giphy.gif',
        'https://media.giphy.com/media/kItkzgKCripyJxmT4h/giphy.gif',
      ]
      placeholder = `HAPPY BIRTHDAY! It's time to party!`
      break

    default:
      break
  }
  return { defaultImages, placeholder, groupBy: GroupByType_Enum.daily }
}

export default function SlackPublicConfiguration({
  notificationSetting,
  occassion,
  sendType,
  ruleGroup,
}: {
  notificationSetting: NotificationSetting | undefined
  occassion: NotificationOccassions
  sendType: NotificationSendTypes
  ruleGroup: RuleGroups
}) {
  const org = useContext(OrgContext)

  const { placeholder } = getSlackPublicDefaultsByOccasion(occassion)

  const [channels, setChannels] = useState<SlackChannel[]>([])
  const selectedChannel = channels?.find(
    channel => channel.channelId === notificationSetting?.channel
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  // Go get Slack channels
  useEffect(() => {
    if (org?.slackConnected) {
      setIsLoading(true)
      callFunction('slack-ListChannels', {})
        .then(res => {
          if (Array.isArray(res)) {
            setChannels(res)
            setHasError(false)
          } else {
            const errorMsg = 'Error fetching Slack channels'
            setChannels([])
            toaster.danger(errorMsg)
            captureMessage(errorMsg, 'error')
            setHasError(true)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [org?.slackConnected])

  const currentPreviewImage = useRef(sample(notificationSetting?.images))

  if (isLoading) {
    return <Loader />
  }

  if (hasError) {
    return (
      <Alert intent="danger">
        Error loading Slack channels. Please contact support
      </Alert>
    )
  }

  return (
    <Pane display="grid" gridTemplateColumns="2fr 1fr">
      <SlackPublicConfigForm
        channels={channels}
        personalizeOptions={personalizeOptions}
        sendType={sendType}
        ruleGroup={ruleGroup}
        notificationSetting={notificationSetting}
        occassion={occassion}
      />
      <SlackPublicNotificationPreview
        cadence="daily"
        channel={selectedChannel?.channelName}
        time={new Date()}
        message={notificationSetting?.message || placeholder}
        image={currentPreviewImage.current}
        occassion={occassion}
        separateNotifications={
          notificationSetting
            ? notificationSetting?.groupBy === undefined
            : false
        }
      />
    </Pane>
  )
}
