import { Collapse, Flex } from 'antd'
import { CategoryForm } from 'components'
import { allCategories } from 'constants/EligibleCategories'
import { defaultBudgets, selectableCats } from 'constants/rewards'
import { TemplateContext } from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { SelectAmount } from 'features'
import { useContext } from 'react'
import { useSelectRewardContext } from '../SelectRewardContext'

export function DollarsForm1() {
  const { selectedReward, perkupDollarsForm } = useSelectRewardContext()

  const template = useContext(TemplateContext)
  const canSetApprovedCategories = !template
  const maxCustomBudget = template?.budget?.maxAmount
  const canEnterCustomBudget = !template || template?.budget?.writable

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap={24}
      justifyContent="center"
    >
      <Heading size={700} textAlign="center">
        Select an amount
      </Heading>

      <SelectAmount
        key={template?.id}
        amount={selectedReward?.budget || defaultBudgets[0]}
        amountOptions={template?.budget?.amounts}
        onAmountChange={perkupDollarsForm.handleSelectAmount}
        showCustomInput={canEnterCustomBudget}
        maxCustomBudget={maxCustomBudget}
        size={32}
      />
      {canSetApprovedCategories && (
        <Collapse
          ghost
          items={[
            {
              key: '1',
              label: 'Advanced options',
              children: (
                <Flex align="center" gap={16}>
                  <Heading size={400}>Approved categories</Heading>
                  <CategoryForm
                    onChangeApprovedCategories={
                      perkupDollarsForm.handleSelectCategories
                    }
                    selectableCats={selectableCats}
                    defaultCat={
                      selectedReward.approvedCategories.length > 1
                        ? allCategories
                        : selectedReward.approvedCategories[0]
                    }
                  />
                </Flex>
              ),
            },
          ]}
        />
      )}
    </Pane>
  )
}
