import { callFunction } from 'api/functionCalls'
import { CardWithImage } from 'components'
import {
  REWARD_ACCEPTANCE,
  REWARD_ACCEPTANCE_CONFIRMATION,
} from 'constants/routes'
import { MemberContext, ProgramContext } from 'context'
import useIds from 'hooks/useIds'
import { useContext } from 'react'
import { useNavigate } from 'react-router'
import { PageDirection } from 'types'
import { ProgramInfo } from './ProgramInfo'

export function MoneyRevealed() {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const { orgId } = useIds()
  const { id: programId } = program
  const { id: memberId } = member

  const navigate = useNavigate()

  const handleViewConfirm = () => {
    if (!program.gift) {
      callFunction('firestore-UpdateMemberActions', {
        orgId,
        programId,
        memberId,
        action: 'accepted',
      })
    }
    navigate(
      `${REWARD_ACCEPTANCE.replace(
        ':programId',
        programId
      )}${REWARD_ACCEPTANCE_CONFIRMATION}`,
      {
        state: {
          direction: PageDirection.FORWARD,
          confetti: true,
        },
      }
    )
  }

  return (
    <CardWithImage imageUrl={program?.email?.banner}>
      <ProgramInfo CTAText="Accept reward" handleCTAClick={handleViewConfirm} />
    </CardWithImage>
  )
}
