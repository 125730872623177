import { Loader, PerkUpLink } from 'components'
import { AmazonProductRowCard } from 'components/Amazon/AmazonProductRowCard'
import { refinementCategories } from 'constants/amazon'
import { countryIdToCountryCode } from 'constants/countries'
import {
  AMAZON_BUSINESS_STORE,
  PRODUCT_LIST_FILTERED,
  PRODUCT_LIST_VIEWED,
} from 'constants/events'
import * as PARAMS from 'constants/params'
import * as ROUTES from 'constants/routes'
import { CountryContext, OrgContext, UserContext } from 'context'
import {
  Button,
  CrossIcon,
  Heading,
  Pane,
  RadioGroup,
  Tab,
  Tablist,
} from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import toUpper from 'lodash-es/toUpper'
import { useContext, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useSearchParams } from 'react-router-dom'
import {
  AmazonProduct,
  AmazonSearchResult,
  SearchAmazonRequest,
} from 'types/Amazon'
import { logEvent, numToString } from 'utils'

export default function Search({
  searchResult,
  handleSearchAmazon,
  products,
  lastProductElmRef,
  infiniteIsLoading,
  shippingAddress,
}: {
  searchResult?: AmazonSearchResult
  handleSearchAmazon: (data: SearchAmazonRequest) => void
  products: AmazonProduct[]
  lastProductElmRef: (node: HTMLDivElement) => void
  infiniteIsLoading: boolean
  shippingAddress?: ShippingAddress
}) {
  const country = useContext(CountryContext)
  const org = useContext(OrgContext)
  const orgId = org?.id
  const user = useContext(UserContext)
  const userId = user?.id

  const [searchParams, setSearchParams] = useSearchParams()

  const countryCode = countryIdToCountryCode.get(country?.id) || 'US'
  const priorityCountryCode = shippingAddress
    ? shippingAddress?.country
    : countryCode

  useEffect(() => {
    logEvent(PRODUCT_LIST_VIEWED, {
      affiliation: AMAZON_BUSINESS_STORE,
      orgId,
      userId,
    })
  }, [orgId, userId])

  if (!searchResult) return null

  const keywordsParams = searchParams.get(PARAMS.KEYWORDS) || ''
  const categoryParams = searchParams.get(PARAMS.CATEGORY) || ''
  const searchRefinementsParams =
    searchParams.get(PARAMS.SEARCH_REFINEMENTS) || ''

  const priceRefinementOptionsCA = [
    { label: 'Any Price', value: '' },
    { label: 'Under $25', value: 'price#12035760011' },
    { label: '$25 to $50', value: 'price#12035761011' },
    { label: '$50 to $100', value: 'price#12035762011' },
    { label: '$100 to $200', value: 'price#12035763011' },
    { label: '$200 & Above', value: 'price#12035764011' },
  ]

  const priceRefinementOptionsUS = [
    { label: 'Any Price', value: '' },
    { label: 'Under $25', value: 'price#1253503011' },
    { label: '$25 to $50', value: 'price#1253504011' },
    { label: '$50 to $100', value: 'price#1253505011' },
    { label: '$100 to $200', value: 'price#1253506011' },
    { label: '$200 & Above', value: 'price#1253507011' },
  ]

  const category = refinementCategories.find(r => r.id === categoryParams)

  const resultsFor = keywordsParams || category?.displayName

  return (
    <Pane>
      <Pane marginBottom={32}>
        <Heading size={400}>
          {products?.length > 0
            ? `1-${products?.length} of over ${numToString(
                searchResult.matchingProductCount
              )}`
            : 'No'}
          {resultsFor &&
            ` results for "${keywordsParams || category?.displayName}"`}
        </Heading>
      </Pane>
      <Pane marginBottom={32} display="flex" gap="0.5rem">
        {!isMobile && (
          <Pane width="25%" padding={8}>
            {searchResult.searchRefinements
              .filter(
                searchRefinement =>
                  searchRefinement.displayValue === 'Department' &&
                  searchRefinement.refinementValues.length > 10
              )
              .map(searchRefinement => (
                <Pane marginBottom={16} key={searchRefinement.displayValue}>
                  {searchRefinement.refinementValues.length > 1 && (
                    <Heading size={400} marginBottom={8}>
                      {searchRefinement.displayValue}
                    </Heading>
                  )}
                  <Tablist>
                    {searchRefinement.refinementValues
                      .slice(0, 10)
                      .map(({ displayName, searchRefinementValue }) => {
                        return (
                          <Tab
                            direction="vertical"
                            key={searchRefinementValue}
                            onSelect={() => {
                              handleSearchAmazon({
                                searchRefinement: searchRefinementValue,
                              })

                              searchParams.set(
                                PARAMS.SEARCH_REFINEMENTS,
                                searchRefinementValue
                              )
                              setSearchParams(searchParams)
                              logEvent(PRODUCT_LIST_FILTERED, {
                                affiliation: AMAZON_BUSINESS_STORE,
                                filters: [
                                  { type: 'department', value: displayName },
                                ],
                                category: categoryParams,
                                orgId,
                                userId,
                              })
                            }}
                          >
                            {displayName}
                          </Tab>
                        )
                      })}
                  </Tablist>
                </Pane>
              ))}
            {searchRefinementsParams.length > 0 && (
              <Button
                appearance="minimal"
                iconAfter={CrossIcon}
                onClick={() => {
                  searchParams.delete(PARAMS.SEARCH_REFINEMENTS)
                  searchParams.delete(PARAMS.PRICE_REFINEMENT)
                  setSearchParams(searchParams)
                  handleSearchAmazon({
                    resetRefinements: true,
                  })
                }}
              >
                Reset filter
              </Button>
            )}
            <Heading size={400} marginBottom={8} marginTop={32}>
              Price
            </Heading>
            <RadioGroup
              value={searchParams.get(PARAMS.PRICE_REFINEMENT) || ''}
              options={
                toUpper(priorityCountryCode) === 'US'
                  ? priceRefinementOptionsUS
                  : priceRefinementOptionsCA
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleSearchAmazon({ priceRefinement: e.target.value })
                searchParams.set(PARAMS.PRICE_REFINEMENT, e.target.value)
                setSearchParams(searchParams)
                logEvent(PRODUCT_LIST_FILTERED, {
                  affiliation: AMAZON_BUSINESS_STORE,
                  filters: [{ type: 'price', value: e.target.value }],
                  orgId,
                  userId,
                })
              }}
            />
          </Pane>
        )}
        <Pane
          display="flex"
          gap="1.5rem"
          flexWrap="wrap"
          marginTop={32}
          width={isMobile ? '100%' : '75%'}
        >
          {products.map((product: AmazonProduct, index) => {
            const offer =
              product.includedDataTypes?.OFFERS &&
              product.includedDataTypes?.OFFERS[0]

            if (!offer?.price.value) return null

            return (
              <PerkUpLink
                to={`${ROUTES.AMAZON_PRODUCT}/${product.asin}?${searchParams.toString()}`}
                key={product.asin}
              >
                <AmazonProductRowCard
                  lastProductElmRef={
                    products.length === index + 1
                      ? lastProductElmRef
                      : undefined
                  }
                  product={product}
                  offer={offer}
                />
              </PerkUpLink>
            )
          })}
          {infiniteIsLoading && (
            <Pane width="100%" marginY={0} marginX="auto">
              <Loader />
            </Pane>
          )}
        </Pane>
      </Pane>
    </Pane>
  )
}
