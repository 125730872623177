import { captureException } from '@sentry/react'
import {
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  or,
  query,
  where,
} from 'firebase/firestore'
import {
  Account,
  AccountType_Enum,
  Account_Permission,
} from 'gen/perkup/v1/account_pb'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export async function GetOrgAccountById({
  orgId,
  accountId,
}: {
  orgId: string
  accountId: string
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/accounts/${accountId}`
    ).withConverter(converter(Account))
    const docSnap = await getDoc(docRef)
    const account = docSnap.data()
    if (account?.isHidden) return undefined
    return account
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetOrgAccountById: {
          orgId,
          accountId,
        },
      },
    })
  }
}

export function ListenToOrgAccounts({
  orgId,
  cb,
  setIsLoading,
}: {
  orgId: string
  cb: (accounts: Account[]) => void
  setIsLoading?: (isLoading: boolean) => void
}) {
  if (setIsLoading) setIsLoading(true)

  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/accounts`
  ).withConverter(converter(Account))
  const q = query(
    colRef,
    where('type', '==', AccountType_Enum[AccountType_Enum.organizationFunds]),
    where('isHidden', '==', false)
  )

  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    query => {
      cb(query.docs.map(doc => doc.data()))
      if (setIsLoading) setIsLoading(false)
    },
    error => {
      if (setIsLoading) setIsLoading(false)
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToOrgAccounts: { orgId },
        },
      })
    }
  )
}

export async function ListSendableOrgAccounts({
  orgId,
  individualId,
  individualRole,
}: {
  orgId: string
  individualId: string
  individualRole: Individual_Role
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/accounts`
    ).withConverter(converter(Account))

    let q

    const isAdminOrManager =
      individualRole === Individual_Role.admin ||
      individualRole === Individual_Role.manager

    const PERMISSIONS = 'permissions'
    const rolePermKey = `${PERMISSIONS}.${Individual_Role[individualRole]}`
    const indPermKey = `${PERMISSIONS}.${individualId}`

    const FULL = Account_Permission[Account_Permission.full]
    const SEND = Account_Permission[Account_Permission.send]

    if (isAdminOrManager) {
      q = query(
        colRef,
        and(
          or(
            where(rolePermKey, '==', FULL),
            where(rolePermKey, '==', SEND),
            where(indPermKey, '==', FULL),
            where(indPermKey, '==', SEND)
          ),
          where(
            'type',
            '==',
            AccountType_Enum[AccountType_Enum.organizationFunds]
          ),
          where('isHidden', '==', false)
        )
      )
    } else {
      q = query(
        colRef,
        and(
          or(where(indPermKey, '==', FULL), where(indPermKey, '==', SEND)),
          where(
            'type',
            '==',
            AccountType_Enum[AccountType_Enum.organizationFunds]
          ),
          where('isHidden', '==', false)
        )
      )
    }

    const qSnaps = await getDocs(q)

    return qSnaps.docs.map(doc => doc.data())
  } catch (error) {
    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        ListSendableOrgAccounts: { individualRole, individualId, orgId },
      },
    })
  }
}

export function ListenToOrgAccountById({
  orgId,
  cb,
  accountId,
}: {
  orgId: string
  cb: (account: Account | undefined) => void
  accountId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/accounts/${accountId}`
  ).withConverter(converter(Account))

  return onSnapshot(
    docRef,
    { includeMetadataChanges: true },
    doc => {
      const account = doc.data()
      if (account?.isHidden) {
        cb(undefined)
        return
      }
      cb(account)
    },
    error => {
      cb(undefined)
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToOrgAccountById: { orgId, accountId },
        },
      })
    }
  )
}

export function ListenToFullAccessOrgAccounts({
  orgId,
  individualId,
  individualRole,
  cb,
  setIsLoading,
}: {
  orgId: string
  individualId: string
  individualRole: Individual_Role
  cb: (accounts: Account[]) => void
  setIsLoading?: (isLoading: boolean) => void
}) {
  if (setIsLoading) setIsLoading(true)

  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/accounts`
  ).withConverter(converter(Account))

  let q

  const isAdminOrManager =
    individualRole === Individual_Role.admin ||
    individualRole === Individual_Role.manager

  const PERMISSIONS = 'permissions'
  const rolePermKey = `${PERMISSIONS}.${Individual_Role[individualRole]}`
  const indPermKey = `${PERMISSIONS}.${individualId}`

  const FULL = Account_Permission[Account_Permission.full]

  if (isAdminOrManager) {
    q = query(
      colRef,
      and(
        or(where(rolePermKey, '==', FULL), where(indPermKey, '==', FULL)),
        where(
          'type',
          '==',
          AccountType_Enum[AccountType_Enum.organizationFunds]
        ),
        where('isHidden', '==', false)
      )
    )
  } else {
    q = query(
      colRef,
      and(
        or(where(indPermKey, '==', FULL)),
        where(
          'type',
          '==',
          AccountType_Enum[AccountType_Enum.organizationFunds]
        ),
        where('isHidden', '==', false)
      )
    )
  }

  return onSnapshot(
    q,
    { includeMetadataChanges: true },
    querySnapshot => {
      cb(querySnapshot.docs.map(doc => doc.data()))
      if (setIsLoading) setIsLoading(false)
    },
    error => {
      if (setIsLoading) setIsLoading(false)
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          ListenToFullAccessOrgAccounts: {
            individualRole,
            individualId,
            orgId,
          },
        },
      })
    }
  )
}

export async function ListSendableOrgAccountsForAllAdmins({
  orgId,
}: {
  orgId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/accounts`
    ).withConverter(converter(Account))

    const PERMISSIONS = 'permissions'
    const rolePermKey = `${PERMISSIONS}.${
      Individual_Role[Individual_Role.admin]
    }`

    const FULL = Account_Permission[Account_Permission.full]
    const SEND = Account_Permission[Account_Permission.send]

    const q = query(
      colRef,
      and(
        or(where(rolePermKey, '==', FULL), where(rolePermKey, '==', SEND)),
        where(
          'type',
          '==',
          AccountType_Enum[AccountType_Enum.organizationFunds]
        ),
        where('isHidden', '==', false)
      )
    )

    const qSnaps = await getDocs(q)
    return qSnaps.docs.map(doc => doc.data())
  } catch (error) {
    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        ListSendableOrgAccountsForAllAdmins: { orgId },
      },
    })
  }
}
