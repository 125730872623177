import { refinementCategories } from 'constants/amazon'
import { AMAZON_BUSINESS_STORE, PRODUCTS_SEARCHED } from 'constants/events'
import * as PARAMS from 'constants/params'
import { AMAZON_SEARCH } from 'constants/routes'
import { OrgContext, UserContext } from 'context'
import { Combobox, Pane, SearchInput } from 'evergreen-ui'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { logEvent } from 'utils'

export function SearchBar(props: {
  defaultValue?: string
  handleSearchAmazon: Function
  disabled?: boolean
}) {
  const { defaultValue, handleSearchAmazon, disabled } = props
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const orgId = org?.id

  const { register, handleSubmit } = useForm()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const onSubmit = (data: { keywords: string }) => {
    const { keywords } = data

    searchParams.set(PARAMS.KEYWORDS, keywords)
    navigate(`${AMAZON_SEARCH}?${searchParams.toString()}`)
    handleSearchAmazon({ keywords })
    logEvent(PRODUCTS_SEARCHED, {
      query: keywords,
      affiliation: AMAZON_BUSINESS_STORE,
      orgId,
      userId: user.id,
    })
  }

  const ALL = 'All'

  const categoryParams = searchParams.get(PARAMS.CATEGORY) || ''

  return (
    <Pane display="flex" marginBottom={22}>
      <Pane>
        <Combobox
          width={isMobile ? 128 : 200}
          openOnFocus
          items={[
            { label: ALL, value: ALL },
            ...refinementCategories.map(refinementCategory => ({
              label: refinementCategory.displayName,
              value: refinementCategory.id,
            })),
          ]}
          itemToString={item => (item ? item.label : '')}
          onChange={item => {
            if (!item) return

            const isAll = item?.value === ALL
            const category = isAll ? '' : item.value

            if (isAll) {
              searchParams.delete(PARAMS.CATEGORY)
            } else {
              searchParams.set(PARAMS.CATEGORY, category)
            }

            handleSearchAmazon({ category })
            navigate(`${AMAZON_SEARCH}?${searchParams.toString()}`)
          }}
          selectedItem={{
            label:
              refinementCategories?.find(rCat => rCat.id === categoryParams)
                ?.displayName || ALL,
          }}
          marginRight={16}
          disabled={disabled}
        />
      </Pane>
      <Pane width="100%">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SearchInput
            defaultValue={defaultValue}
            placeholder="Search Amazon"
            width="100%"
            name="keywords"
            maxLength={63}
            ref={register}
            disabled={disabled}
          />
        </form>
      </Pane>
    </Pane>
  )
}
