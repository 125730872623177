import { Flex } from 'antd'
import {
  createShippingAddress,
  removeShippingAddress,
  updateDefaultShippingAddress,
  updateShippingAddress,
} from 'api/databaseCalls'
import { AddressButton, ShippingAddressBlock } from 'components'
import { UserContext, UserShippingAddressesContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useContext } from 'react'
import BillingAddressSection from './BillingAddressSection'

export function AddressSettings() {
  const user = useContext(UserContext)
  const userId = user.id

  const shippingAddresses = useContext(UserShippingAddressesContext)

  const handleCreateNewAddress = (shippingAddress: ShippingAddress) => {
    createShippingAddress({
      userId,
      shippingAddress,
    }).then(() => toaster.success('Successfully created shipping address'))
  }

  const handleUpdateShippingAddress = (shippingAddress: ShippingAddress) => {
    updateShippingAddress({
      userId,
      shippingAddress,
      shippingAddressId: shippingAddress.id,
    }).then(() => toaster.success('Successfully updated shipping address'))
  }

  const handleRemoveShippingAddress = (shippingAddressId: string) => {
    removeShippingAddress({
      userId,
      shippingAddressId,
    }).then(() => toaster.success('Successfully removed shipping address'))
  }

  return (
    <Flex vertical gap={32}>
      {user.cardholderId && (
        <>
          <BillingAddressSection />
          <br />
        </>
      )}
      <Pane display="flex" flexDirection="column" gap={16}>
        <Heading size={600}>Shipping Addresses</Heading>
        <Flex wrap="wrap" align="stretch" gap={32}>
          <AddressButton
            addAddressCTALabel="Add address"
            submitLabel="Add"
            dialogTitle="Add a new address"
            asSmallButton={false}
            onAddressSubmit={handleCreateNewAddress}
          />

          {shippingAddresses.map(shippingAddress => (
            <ShippingAddressBlock
              key={shippingAddress.id}
              shippingAddress={shippingAddress}
              onSetDefaultAddress={
                shippingAddress.id === user.defaultShippingAddressId
                  ? undefined
                  : () => {
                      updateDefaultShippingAddress({
                        shippingAddressId: shippingAddress.id,
                        userId,
                      })
                    }
              }
              onAddressChange={addressData => {
                if (!addressData) {
                  handleRemoveShippingAddress(shippingAddress.id)
                } else if (shippingAddress.id) {
                  handleUpdateShippingAddress(addressData)
                } else {
                  handleCreateNewAddress(addressData)
                }
              }}
              isDefault={shippingAddress.id === user.defaultShippingAddressId}
            />
          ))}
        </Flex>
      </Pane>
    </Flex>
  )
}
