import { FullStory, init as initFullStory } from '@fullstory/browser'
import SentryFullStory from '@sentry/fullstory'
import * as Sentry from '@sentry/react'
import 'antd/dist/reset.css'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

const environment = process.env.REACT_APP_ENV || process.env.NODE_ENV

initFullStory({
  orgId: '15J67Z',
  devMode: environment !== 'production',
})

// Initialize Sentry
Sentry.init({
  environment,
  dsn: 'https://a3132586bd0749eb8a744ad18a2c6698@o466262.ingest.sentry.io/5480362',
  release: process.env.REACT_APP_RELEASE_VERSION,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'us-central1-perkup-app.cloudfunctions.net',
        /^https:\/\/amazon-punchout-y52g2palyq-uc.a.run.app/,
        /^http:\/\/localhost:6001/,
        'localhost',
        /^\//,
      ],
    }),
    new SentryFullStory('perkup', { client: FullStory }),
  ],
  maxValueLength: 350, // default 250 truncates firestore index creation messages

  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      // Sentry.showReportDialog({ eventId: event.event_id }) // Sentry report dialog form
    }
    return event
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  sampleRate: 1.0,
  tracesSampleRate: 0.05,
})

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
