import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const performanceAndSalesUseCases = {
  PERFORMANCE_BONUS: {
    label: 'Performance bonus',
    details: {
      searchQuery: 'great work',
      templateCategories: [CategoryTitle.PERFORMANCE],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Outstanding Performance!',
        note: "You've set the bar high. Fantastic job!",
      },
      {
        title: 'Bravo!',
        note: 'Your performance caught our eyes. Keep it up!',
      },
      {
        title: 'Cheers!',
        note: 'To your relentless drive and performance. Stellar job!',
      },
    ],
  },
  SALES_SPIFF: {
    label: 'Sales spiff',
    details: {
      searchQuery: 'well done',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Sales Star!',
        note: 'Your sales figures are impressive. Keep up the excellent work!',
      },
      {
        title: 'Top Seller 🏆',
        note: "Congratulations on hitting those targets. You're an asset to the team!",
      },
      {
        title: 'Sales Pro!',
        note: 'Your hard work and dedication to sales are truly commendable.',
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
