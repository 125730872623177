import { Select } from 'antd'
import {
  getFirstAndLastDateOfYear,
  getFirstDateAndLastDateOnCalendar,
  RewardCalendar,
} from 'components'
import { STATUS_NONE_OR_INVITED_OR_ACTIVE } from 'constants/algolia'
import { IndividualContext } from 'context'
import dayjs from 'dayjs'
import { Pane } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { isEmpty } from 'lodash-es'
import { useContext, useState } from 'react'
import { useConfigure } from 'react-instantsearch'
import { RuleType, RuleTypes } from 'types/rules'
import { getAlgoliaTenureFilterOptions } from 'utils/Algolia'
import { formatLabelSelectOptions } from 'utils/labels'

export interface IDateInfo {
  start: Date
  end: Date
}

const getTenureFilter = ({
  tenuresSelected,
}: {
  tenuresSelected: number[]
}) => {
  if (isEmpty(tenuresSelected)) return ''
  let tenureFilter = ''

  tenuresSelected.forEach((yearStarted, index) => {
    if (index === 0) {
      tenureFilter += ` AND dates.anniversary.year=${yearStarted}`
    } else {
      tenureFilter += ` OR dates.anniversary.year=${yearStarted}`
    }
  })

  return tenureFilter
}

const getLabelsFilter = ({ labelsSelected }: { labelsSelected: string[] }) => {
  if (isEmpty(labelsSelected)) return ''
  let labelFilter = ''

  labelsSelected.forEach((label, index) => {
    if (index === 0) {
      labelFilter += ` AND ${label}`
    } else {
      labelFilter += ` OR ${label}`
    }
  })
  return labelFilter
}

const getDateAndManagerFilter = ({
  ruleType,
  startDate,
  endDate,
  managerId,
}: {
  ruleType: RuleType
  startDate: Date
  endDate: Date
  managerId?: string
}) => {
  const startDateFilter =
    (startDate.getUTCMonth() + 1) * 100 + startDate.getUTCDate()
  const endDateFilter = (endDate.getUTCMonth() + 1) * 100 + endDate.getUTCDate()
  const mmddRangeFilter =
    endDateFilter <= startDateFilter
      ? `0 TO 1231`
      : `${startDateFilter} TO ${endDateFilter}`
  let dateFilter = ''
  if (ruleType === RuleTypes.birthdays) {
    dateFilter = `dates.birthday.mmdd: ${mmddRangeFilter}`
  } else if (ruleType === RuleTypes.anniversaries) {
    dateFilter = `dates.anniversary.mmdd: ${mmddRangeFilter} AND dates.anniversary.year< ${startDate.getFullYear()}`
  } else {
    dateFilter = `dates.anniversary.mmdd: ${mmddRangeFilter} AND dates.anniversary.year: ${startDate.getFullYear()} OR dates.anniversary.year: ${endDate.getFullYear()}`
  }

  if (managerId) {
    dateFilter += ` AND managerId:${managerId}`
  }
  return dateFilter
}

export default function RewardCalendarFiltered({
  ruleType,
  lists,
}: {
  ruleType: RuleType
  lists: OrgList[]
}) {
  const individual = useContext(IndividualContext)
  const isManager = individual?.role === Individual_Role.manager

  const [dateInfo, setDateInfo] = useState<IDateInfo>(
    isManager
      ? getFirstAndLastDateOfYear(dayjs())
      : getFirstDateAndLastDateOnCalendar(dayjs())
  )
  const [labelsSelected, setLabelsSelected] = useState<string[]>([])
  const [tenuresSelected, setTenuresSelected] = useState<number[]>([])
  const dateAndManagerFilter = getDateAndManagerFilter({
    ruleType,
    startDate: dateInfo.start,
    endDate: dateInfo.end,
    managerId: isManager ? individual?.id : undefined,
  })

  const tenureFilter = getTenureFilter({
    tenuresSelected,
  })

  const labelsFilter = getLabelsFilter({ labelsSelected })

  const statusesFilter = ` AND ${STATUS_NONE_OR_INVITED_OR_ACTIVE}`

  const combinedFilters =
    dateAndManagerFilter + tenureFilter + labelsFilter + statusesFilter

  const showTenureFilter = ruleType === RuleTypes.anniversaries
  const hitsPage = 0
  const maxHitsPerPage = 1000
  useConfigure({
    hitsPerPage: maxHitsPerPage,
    page: hitsPage,
    filters: combinedFilters,
  })

  return (
    <>
      <Pane display="flex" gap={16}>
        {showTenureFilter && (
          <Select
            placeholder="Tenure"
            options={getAlgoliaTenureFilterOptions({
              customDate: dateInfo.start,
            })}
            style={{ width: '250px', height: 'max-content' }}
            onSelect={value =>
              setTenuresSelected(selected => [...selected, value])
            }
            onDeselect={value => {
              const filteredTenures = tenuresSelected.filter(
                tenure => tenure !== value
              )
              setTenuresSelected(filteredTenures)
            }}
            allowClear
            onClear={() => setTenuresSelected([])}
            mode="multiple"
            maxTagCount={5}
          />
        )}
        <Select
          placeholder="Label"
          style={{ width: '250px', height: 'max-content' }}
          options={formatLabelSelectOptions({ orgLists: lists })}
          onSelect={value => {
            setLabelsSelected(selected => [...selected, `labels.${value}`])
          }}
          onDeselect={value => {
            const filteredLabels = labelsSelected.filter(
              label => label !== `labels.${value}`
            )
            setLabelsSelected(filteredLabels)
          }}
          allowClear
          onClear={() => setLabelsSelected([])}
          mode="multiple"
          maxTagCount={5}
        />
      </Pane>
      <RewardCalendar
        ruleType={ruleType}
        dateInfo={dateInfo}
        setDateInfo={setDateInfo}
      />
    </>
  )
}
