import { TruckOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import { countryIso3to2Map } from 'constants/addresses'
import { US_ISO2, WORLDWIDE } from 'constants/countries'
import { CountryContext } from 'context'
import { Text, useTheme } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useContext } from 'react'
import { estimateShippingTimeline } from 'services/shipping'

export function VariantEstimatedShippingTime({
  productVariant,
  isAdminView,
  shippingAddress,
}: {
  productVariant: ProductVariant | undefined
  isAdminView?: boolean
  shippingAddress?: ShippingAddress
}) {
  const theme = useTheme()
  const country = useContext(CountryContext)

  if (!productVariant) {
    return null
  }

  const targetCountry =
    shippingAddress?.country.toLowerCase() || countryIso3to2Map[country.iso3]

  const isUS = targetCountry === US_ISO2

  const timeline = estimateShippingTimeline(
    productVariant,
    isUS && !isAdminView ? US_ISO2 : WORLDWIDE
  )

  const countryDisplayName = !isAdminView
    ? `to ${targetCountry.toUpperCase()}`
    : null

  return (
    <Flex gap={4} vertical>
      {timeline && (
        <Flex gap={8} align="center">
          <TruckOutlined
            style={{ fontSize: 24, color: theme.colors.gray600 }}
          />
          <Text color={theme.colors.gray600}>
            Estimated delivery {timeline.displayStr} {countryDisplayName}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
