import { captureException } from '@sentry/react'
import { countryIso3to2Map } from 'constants/addresses'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { Country, MacroCategory } from 'gen/perkup/v1/contentful_pb'
import { lowerCase } from 'lodash-es'
import { converter } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

/**
 * Contentful
 */
export async function ListCountries() {
  const countries: Country[] = []

  try {
    const db = getFirestore()
    const colRef = collection(db, 'countries').withConverter(converter(Country))
    const q = query(colRef, orderBy('name', 'asc'))

    await getDocs(q).then(query =>
      query.docs.forEach(doc => countries.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListCountries: {},
      },
    })
  }

  return countries
}

export async function GetCountryById({ countryId }: { countryId: string }) {
  if (!countryId) return undefined
  try {
    const db = getFirestore()
    const docRef = doc(db, `countries/${countryId}`).withConverter(
      converter(Country)
    )
    return await getDoc(docRef).then(doc => doc.data())
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetCountryById: { countryId },
      },
    })
  }
}

export async function ListMacroCategories() {
  const macroCategories: MacroCategory[] = []
  try {
    const db = getFirestore()
    const colRef = collection(db, 'macroCategories').withConverter(
      converter(MacroCategory)
    )
    const q = query(colRef, orderBy('name', 'asc'))

    await getDocs(q).then(query =>
      query.docs.forEach(doc => macroCategories.push(doc.data()))
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: { ListMacroCategories: {} },
    })
  }
  return macroCategories
}

export async function GetCountryByIso2({ iso2 }: { iso2: string }) {
  if (!iso2) return undefined
  const iso3 = Object.keys(countryIso3to2Map).find(
    key => countryIso3to2Map[key] === lowerCase(iso2)
  )
  try {
    const db = getFirestore()
    const colRef = collection(db, '/countries').withConverter(
      converter(Country)
    )
    const q = query(colRef, where('iso3', '==', iso3))
    const data = await getDocs(q).then(query =>
      query.docs.map(doc => doc.data())
    )
    return data[0]
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetCountryByIso2: { iso2 },
      },
    })
  }
}
