import { Button, ButtonProps, Tooltip } from 'antd'
import { isFunction } from 'lodash-es'
import { ReactNode } from 'react'

export function TooltipIconButton({
  tooltipContent,
  icon,
  handleClick,
  buttonProps,
}: {
  tooltipContent: string
  icon: ReactNode
  handleClick?: () => void
  buttonProps?: ButtonProps
}) {
  return (
    <Tooltip title={tooltipContent}>
      <Button
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        type="text"
        icon={icon}
        onClick={() => {
          if (isFunction(handleClick)) {
            handleClick()
          }
        }}
        {...buttonProps}
      />
    </Tooltip>
  )
}
