import { Button, ButtonProps, Flex, Image } from 'antd'
import { Heading, Text } from 'evergreen-ui'
import React from 'react'

export function PerkEmptyState({
  iconUrl,
  iconNode,
  header,
  description,
  ctaProps,
}: {
  iconUrl?: string
  iconNode?: React.ReactNode
  header: string
  description?: string
  ctaProps?: ButtonProps
}) {
  return (
    <Flex vertical align="center" gap={32} style={{ width: '100%' }}>
      {iconUrl && (
        <Image preview={false} src={iconUrl} style={{ maxWidth: 100 }} />
      )}
      {iconNode && iconNode}
      <Flex vertical gap={16} align="center">
        <Heading>{header}</Heading>
        {description && <Text>{description}</Text>}
      </Flex>
      {ctaProps && <Button {...ctaProps}>{ctaProps.children}</Button>}
    </Flex>
  )
}
