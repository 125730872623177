import { captureException, captureMessage } from '@sentry/react'
import {
  BALANCE_DETAILED_REPORT_URL,
  BALANCE_REPORT_URL,
  ORDERS_REPORT_BASE_URL,
  REWARDS_REPORT_URL,
} from 'constants/tinybird'
import dayjs from 'dayjs'
import 'dayjs/plugin/utc'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { getTinybirdToken } from 'services/tinybird'
import { RangeValue } from 'types/RewardsDate'
import { convertDateRangeForTinybird } from 'utils'
import { parseBigQueryIndividual } from 'utils/individual'
import { toSentry } from 'utils/sentry'
import { OrderDetails, TinyBirdOrderDetails } from './types'

dayjs.extend(require('dayjs/plugin/utc'))

export async function FetchTinyBirdBalanceDetailedReportsByOrgId(data: {
  orgId: string
  accountId: string
  cb: Function
}) {
  const { orgId, cb, accountId } = data

  if (!orgId || !cb) {
    captureMessage(
      'Missing required data for balance report from Tinybird',
      'error'
    )
    return
  }

  try {
    const url = `${BALANCE_DETAILED_REPORT_URL}orgId=${orgId}&accountId=${accountId}`
    const tinybirdToken = await getTinybirdToken(orgId)

    const result = await fetch(url, {
      headers: {
        Authorization: `Bearer ${tinybirdToken}`,
      },
    }).then(r => r.json())

    if (!result.data) {
      sessionStorage.removeItem(`Tinybird Token ${orgId}`)
      captureMessage(
        `Error retrieving balance report from Tinybird: ${result.error}`,
        'error'
      )
    } else {
      cb(result.data)
    }
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        FetchTinyBirdBalanceReportsByOrgId: {
          orgId,
        },
      },
    })
  }
}

export async function FetchTinyBirdBalanceReportsByOrgId(data: {
  orgId: string
  accountId: string
  cb: Function
}) {
  const { orgId, cb, accountId } = data

  if (!orgId || !cb) {
    captureMessage(
      'Missing required data for balance report from Tinybird',
      'error'
    )
    return
  }

  try {
    const url = `${BALANCE_REPORT_URL}orgId=${orgId}&accountId=${accountId}`
    const tinybirdToken = await getTinybirdToken(orgId)

    const result = await fetch(url, {
      headers: {
        Authorization: `Bearer ${tinybirdToken}`,
      },
    }).then(r => r.json())

    if (!result.data) {
      sessionStorage.removeItem(`Tinybird Token ${orgId}`)
      captureMessage(
        `Error retrieving balance report from Tinybird: ${result.error}`,
        'error'
      )
    } else {
      cb(result.data)
    }
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        FetchTinyBirdBalanceReportsByOrgId: {
          orgId,
        },
      },
    })
  }
}

export async function FetchTinyBirdRewardReportsByOrgId({
  dates,
  orgId,
  cb,
  programTypes,
}: {
  dates: RangeValue
  orgId: string
  cb: Function
  programTypes: ProgramType[]
}) {
  if (!orgId || !cb) {
    captureMessage(
      'Missing required data for reward report from Tinybird',
      'error'
    )
    return
  }

  try {
    const [startDate, endDate] = convertDateRangeForTinybird(dates)

    let dateQuery = ''

    if (startDate) {
      dateQuery += `&startDate=${startDate}`
    }
    if (endDate) {
      dateQuery += `&endDate=${endDate}`
    }

    const programTypeStringValues = programTypes.map(
      programType => ProgramType[programType]
    )
    const programTypeQuery = `&programType=${programTypeStringValues.join(',')}`

    const url = REWARDS_REPORT_URL + orgId + dateQuery + programTypeQuery

    const tinybirdToken = await getTinybirdToken(orgId)
    const result = await fetch(url, {
      headers: {
        Authorization: `Bearer ${tinybirdToken}`,
      },
    }).then(r => r.json())

    if (!result.data) {
      sessionStorage.removeItem(`Tinybird Token ${orgId}`)
      captureMessage(
        `Error retrieving reward report from Tinybird: ${result.error}`,
        'error'
      )
    } else {
      cb(result.data)
    }
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        FetchTinyBirdRewardReportsByOrgId: {
          orgId,
        },
      },
    })
  }
}

export async function FetchTinyBirdDataByOrgId({
  orgId,
  cb,
  baseUrl,
  dataTitle,
}: {
  orgId: string
  cb: Function
  baseUrl: string
  dataTitle: string
}) {
  if (!orgId || !cb) {
    captureMessage(
      `Missing reqiured data for ${dataTitle} from Tinybird`,
      'error'
    )
    return
  }

  try {
    const tinybirdToken = await getTinybirdToken(orgId)
    const url = baseUrl + orgId
    const result = await fetch(url, {
      headers: {
        Authorization: `Bearer ${tinybirdToken}`,
      },
    }).then(r => r.json())

    if (!result.data) {
      sessionStorage.removeItem(`Tinybird Token ${orgId}`)
      captureMessage(
        `Error retrieving ${dataTitle} from Tinybird: ${result.error}`,
        'error'
      )
    } else {
      cb(result.data)
    }
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        FetchTinyBirdDataByOrgId: {
          orgId,
          baseUrl,
          dataTitle,
        },
      },
    })
  }
}

function parseOrdersData(data: TinyBirdOrderDetails[]): OrderDetails[] {
  return data.map(o => ({
    ...o,
    orderDetails: o.orderDetails ? JSON.parse(o.orderDetails) : null,
    individual: parseBigQueryIndividual(o.individual),
    items: o.items ? JSON.parse(o.items) ?? [] : [],
    fulfillments: o.fulfillments ? JSON.parse(o.fulfillments) ?? [] : [],
    programs: o.programs ? JSON.parse(o.programs) ?? [] : [],
  }))
}

export async function FetchTinyBirdOrdersTableData({
  args,
  filters,
}: {
  args: {
    orgId: string
  }
  filters: {
    pageSize: number
    page: number
    dateRange?: RangeValue
    individualId?: string
    status?: string[]
    iso2s?: string[]
  }
}) {
  const { orgId } = args
  const { pageSize, page, dateRange, individualId, status, iso2s } = filters
  const [startDate, endDate] = convertDateRangeForTinybird(dateRange)

  try {
    const tinybirdToken = await getTinybirdToken(orgId)

    let tinyBirdVariables = `${orgId}&pageSize=${pageSize}&offset=${pageSize * page}`

    if (individualId) tinyBirdVariables += `&individualId=${individualId}`
    if (startDate) tinyBirdVariables += `&startDate=${startDate}`
    if (endDate) tinyBirdVariables += `&endDate=${endDate}`
    if (status) tinyBirdVariables += `&status=${status.toString()}`
    if (iso2s) tinyBirdVariables += `&iso2s=${iso2s.toString()}`

    const url = ORDERS_REPORT_BASE_URL + tinyBirdVariables

    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${tinybirdToken}`,
      },
    })

    if (!res.ok) {
      throw new Error(
        `Error fetching orders - ${res.status} - ${await res.text()}`
      )
    }

    const { data, rows_before_limit_at_least: total } = await res.json()

    return {
      data: parseOrdersData(data),
      total,
    }
  } catch (error) {
    captureException(toSentry(error), {
      contexts: {
        FetchTinyBirdOrdersTableData: {
          orgId,
          page,
          pageSize,
          dateRange,
          individualId,
        },
      },
    })
    throw error
  }
}
