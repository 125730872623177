import { CloseOutlined } from '@ant-design/icons'
import { Badge, Button, Flex } from 'antd'
import { PerkImage, QuantityPicker } from 'components'
import { ExchangeRateContext } from 'context'
import { Pane, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors, useDisplayCurrency } from 'hooks'
import { useContext, useState } from 'react'
import { WithSelectedQuantity } from 'types'
import { numToDollars } from 'utils'
import { VariantEstimatedShippingTime } from './VariantEstimatedShippingTime'

function LineItems({
  items = [],
  onQuantityChange,
}: {
  items: WithSelectedQuantity<ProductVariant>[]
  onQuantityChange?: (pvId: string, newQuantity: number) => void
}) {
  const displayCurrency = useDisplayCurrency()
  const exchangeRate = useContext(ExchangeRateContext)

  const productHasNoOtherVariants =
    items.length === 1 && Number(items[0].variantsCount) === 1

  if (productHasNoOtherVariants) {
    const { selectedQuantity, amount, id } = items[0]
    const productVariantAmount = numToDollars(
      Number(amount) * exchangeRate,
      2,
      false,
      displayCurrency
    )
    const total = numToDollars(
      Number(amount) * exchangeRate * (selectedQuantity || 0),
      2,
      false,
      displayCurrency
    )
    return (
      <Flex gap={8} align="center">
        <Text color="muted">{productVariantAmount}</Text>
        <Text flex={1} color="muted">
          Qty:{' '}
          {onQuantityChange ? (
            <QuantityPicker
              currentQuantity={selectedQuantity ?? 1}
              onQuantityChange={q => onQuantityChange(id, q)}
            />
          ) : (
            selectedQuantity
          )}
        </Text>
        <Text>{total}</Text>
      </Flex>
    )
  }

  return (
    <Flex vertical gap={4}>
      {items.map(({ amount, variantName, id, selectedQuantity }) => {
        const productVariantAmount = numToDollars(
          Number(amount) * exchangeRate,
          2,
          false,
          displayCurrency
        )
        const subTotal = numToDollars(
          Number(amount) * exchangeRate * (selectedQuantity || 0),
          2,
          false,
          displayCurrency
        )
        return (
          <Flex key={id} gap={8} align="center">
            <Text color="muted">{productVariantAmount}</Text>
            <Text flex={1} color="muted">
              {variantName}:{' '}
              {onQuantityChange ? (
                <QuantityPicker
                  currentQuantity={selectedQuantity ?? 1}
                  onQuantityChange={q => onQuantityChange(id, q)}
                />
              ) : (
                selectedQuantity
              )}
            </Text>
            <Text>{subTotal}</Text>
          </Flex>
        )
      })}
    </Flex>
  )
}

export function BulkProductRowCard({
  productVariantsWithQuantities = [],
  onRemoveClick,
  onQuantityChange,
  elevateOnHover = false,
  withEstimatedShippingTime = false,
  shippingAddress,
}: {
  productVariantsWithQuantities: WithSelectedQuantity<ProductVariant>[]
  onRemoveClick?: () => void
  onQuantityChange?: (pvId: string, newQuanity: number) => void
  elevateOnHover?: boolean
  withEstimatedShippingTime?: boolean
  shippingAddress?: ShippingAddress
}) {
  const { defaultColor } = useDefaultOrgColors()

  const [isHovered, setIsHovered] = useState(false)

  const { productName, productImages, imageUrl, productImageUrl } =
    productVariantsWithQuantities[0]

  const totalQuantity = productVariantsWithQuantities.reduce(
    (acc, { selectedQuantity }) => acc + (selectedQuantity || 0),
    0
  )

  return (
    <Pane
      display="flex"
      position="relative"
      elevation={isHovered && elevateOnHover ? 1 : undefined}
      padding={16}
      gap={16}
      borderRadius={8}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Badge count={totalQuantity} color={defaultColor}>
        <PerkImage
          preview
          src={imageUrl || productImages?.[0]?.src || productImageUrl} // Cam specifically wants the row card to show the image of the first variant
          alt={`Small thumbnail cart image for ${productName}`}
          sizes="72px"
          style={{
            aspectRatio: '1/1',
            objectFit: 'cover',
            width: 72,
            height: 72,
            borderRadius: 8,
          }}
        />
      </Badge>

      <Flex vertical gap={4} flex={1}>
        <Text>{productName}</Text>
        <LineItems
          items={productVariantsWithQuantities}
          onQuantityChange={onQuantityChange}
        />
        {withEstimatedShippingTime && (
          <VariantEstimatedShippingTime
            productVariant={productVariantsWithQuantities[0]}
            shippingAddress={shippingAddress}
          />
        )}
      </Flex>

      {onRemoveClick && isHovered && (
        <Button
          size="small"
          style={{ position: 'absolute', right: 8, top: 8, borderRadius: 6 }}
          icon={<CloseOutlined />}
          onClick={onRemoveClick}
        />
      )}
    </Pane>
  )
}
