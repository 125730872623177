export const NO_SIDEBAR_PAGE_PADDING_Y = 32

export const SIDEBAR_WIDTH = 200
export const SIDEBAR_PADDING = 24
export const ORG_PAGE_PADDING = 32

export const PAGE_WIDTH_MINUS_SIDEBAR = `calc(100% - ${SIDEBAR_WIDTH}px)`

export const MAX_CHECKOUT_PAGE_WIDTH = 1024

// Common width breakpoints (in pixels) in the web world - increments of 128 or 256.
export enum WidthBreakpoints {
  XS = 512,
  SM = 640,
  MD = 768,
  XM = 896,
  LG = 1024,
  XL = 1280,
  XXL = 1536,
}
export const ACCEPTANCE_FLOW_WIDTH = 'clamp(320px, 88vw, 1580px)'
