import { PlusCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import { CheckoutButton } from 'components'
import { TransferFundsButton } from 'components/Buttons/TransferFundsButton'
import { AccountBalanceLabels } from 'constants/accounts'
import { DEFAULT_ROUTES } from 'constants/routes'
import { EmptyState, InboxSearchIcon, Pane, Text, useTheme } from 'evergreen-ui'
import { Account, Account_Permission } from 'gen/perkup/v1/account_pb'
import { useCanCreateAccountInvoices } from 'hooks'
import { useNavigate } from 'react-router'
import { getTopUpDefault } from 'utils/accounts'
import BalanceLabel from './BalanceLabel'
import { OutstandingFundsDrawer } from './OutstandingFundsDrawer'

export function AccountOverview({
  account,
  outstandingBalance,
  unallocatedFunds,
  highestAccess,
}: {
  account: Account | undefined
  outstandingBalance: number
  unallocatedFunds: number
  highestAccess: Account_Permission | undefined
}) {
  const navigate = useNavigate()
  const theme = useTheme()
  const accountId = account?.id

  const canCreateInvoice = useCanCreateAccountInvoices()

  const balanceNumber = Number(account?.balance)
  const hasViewAccessOnly = highestAccess === Account_Permission.view
  const hasFullAccess = highestAccess === Account_Permission.full

  if (!accountId)
    return (
      <EmptyState
        background="light"
        title="No account found"
        orientation="vertical"
        icon={<InboxSearchIcon color={theme.colors.gray500} />}
        iconBgColor={theme.colors.gray200}
        primaryCta={
          <EmptyState.PrimaryButton
            onClick={() => navigate(DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT)}
          >
            Go back
          </EmptyState.PrimaryButton>
        }
      />
    )
  const todaysDate = new Date()
  const formattedDate = todaysDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })

  const defaultTopUp = getTopUpDefault({ unallocatedFunds })

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap={16}
      background="tint2"
      borderRadius={8}
      padding={32}
      border
      maxWidth={944}
    >
      <Pane display="grid" gridTemplateColumns="1fr 2fr" gap={32} rowGap={16}>
        <BalanceLabel
          isImportant
          label="Current balance"
          subHeading="Money stored with PerkUp."
          balance={balanceNumber}
        />

        <BalanceLabel
          label={AccountBalanceLabels.UNREDEEMED_REWARDS}
          subHeading="The value of rewards sent but not redeemed."
          balance={-outstandingBalance}
          link={<OutstandingFundsDrawer accountId={accountId} />}
        />

        <Pane borderBottom />
        <Pane />

        <BalanceLabel
          label="Exposure"
          subHeading="The difference between the current balance and unredeemed rewards."
          balance={unallocatedFunds}
        />
      </Pane>

      {!hasViewAccessOnly && canCreateInvoice && (
        <Pane display="flex" alignItems="center" gap={16} marginTop={16}>
          <CheckoutButton
            name="Add funds"
            style={{ width: 'fit-content' }}
            type="primary"
            accountId={account.id}
            icon={<PlusCircleOutlined />}
            defaultAmount={defaultTopUp}
          />
          {hasFullAccess && <TransferFundsButton defaultAccount={account} />}
        </Pane>
      )}

      {unallocatedFunds < 0 && (
        <Alert
          style={{ width: 'fit-content' }}
          showIcon
          type="warning"
          message="Add funds, your exposure is negative."
        />
      )}

      <Text color="muted">{`As of ${formattedDate}`}</Text>
    </Pane>
  )
}
