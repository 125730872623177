import { PlacidTags } from 'constants/placid/placid-tags'
import { UseCaseRecord } from 'pages/NewReward/types/forms'
import { CategoryTitle } from 'pages/Templates/constants'
import { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const generalUseCases = {
  THANKS: {
    label: 'Thank you',
    details: {
      searchQuery: 'thank you',
      templateCategories: [CategoryTitle.THANKS],
      placidTags: [PlacidTags.THANK_YOU],
    },
    emailData: [
      {
        title: 'Thank You!',
        note: "Your hard work hasn't gone unnoticed. Thanks for everything!",
      },
      {
        title: 'Gratitude Alert 🚨',
        note: 'Just a little note to say thank you for being amazing!',
      },
      { title: 'Kudos!', note: "You've outdone yourself. Thank you!" },
    ],
  },
  ABOVE_AND_BEYOND: {
    label: 'Above & beyond',
    details: {
      searchQuery: 'you rock',
      templateCategories: [CategoryTitle.ABOVE_AND_BEYOND],
      placidTags: [PlacidTags.ABOVE_AND_BEYOND, PlacidTags.CONGRATULATIONS],
    },
    emailData: [
      {
        title: 'Extraordinary Efforts!',
        note: "You've gone above & beyond, and we're beyond grateful. Keep soaring!",
      },
      {
        title: 'Raising the Bar 📈',
        note: 'Your dedication and hard work are unmatched. Thank you for pushing limits!',
      },
      {
        title: 'Standing Ovation 👏',
        note: 'For the one who always gives their best. We see you, and we appreciate you!',
      },
    ],
  },
  CONGRATULATIONS: {
    label: 'Congratulations',
    details: {
      searchQuery: 'congratulations',
      templateCategories: [CategoryTitle.CONGRATULATIONS],
      placidTags: [PlacidTags.CONGRATULATIONS],
    },
    emailData: [
      {
        title: 'Congratulations!',
        note: "You did it and we couldn't be prouder. Bravo!",
      },
      {
        title: 'You Shine ⭐',
        note: 'Your achievement is a testament to your efforts. Congrats!',
      },
      {
        title: 'Kudos to You!',
        note: 'Celebrating your fantastic achievement. Keep it up!',
      },
    ],
  },
  SHOUTOUTS: {
    label: 'Shoutouts',
    details: {
      searchQuery: "you're the best",
      templateCategories: [CategoryTitle.SHOUTOUTS],
      placidTags: [PlacidTags.THANK_YOU],
    },
    emailData: [
      {
        title: 'Shoutout!',
        note: 'Your efforts have not gone unnoticed. Amazing job!',
      },
      {
        title: "You're a Star ⭐",
        note: 'Giving a big shoutout for your outstanding work!',
      },
      {
        title: 'Applause!',
        note: "Everyone's talking about the great job you did. Kudos!",
      },
    ],
  },
  KUDOS: {
    label: 'Kudos',
    details: {
      searchQuery: 'kudos',
      templateCategories: [],
      placidTags: [],
    },
    emailData: [
      {
        title: 'Big Kudos!',
        note: 'Your achievements are making waves. Fantastic job!',
      },
      {
        title: 'Pat on the Back 👏',
        note: "Just some kudos for the amazing work you're doing. Cheers!",
      },
      {
        title: 'Hats Off!',
        note: "We're in awe of your accomplishments. Keep going!",
      },
    ],
  },
  HIGH_IMPACT: {
    label: 'High impact',
    details: {
      searchQuery: 'superstar',
      templateCategories: [],
      placidTags: [PlacidTags.CONGRATULATIONS],
    },
    emailData: [
      {
        title: 'Impact Recognized!',
        note: "Your recent contributions have made a significant impact on our project's success. Thank you for your hard work!",
      },
      {
        title: 'Kudos for Your Exceptional Impact!',
        note: "Your dedication to our latest project didn't go unnoticed. Your efforts have truly made a difference. Thanks!",
      },
      {
        title: 'Thank You for Your Outstanding Contribution!',
        note: "Your role in our project was pivotal to its success. We're grateful for your exceptional effort and dedication!",
      },
    ],
  },
} satisfies Partial<Record<RewardUseCasesKeysType, UseCaseRecord>>
