import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Tooltip } from 'antd'
import { To, useNavigate } from 'react-router'

export function BackIconButton({
  to,
  type = 'text',
}: {
  to?: To
  type?: ButtonProps['type']
}) {
  const navigate = useNavigate()
  return (
    <Tooltip title="Go back">
      <Button
        type={type}
        icon={<ArrowLeftOutlined />}
        onClick={() => {
          if (to) {
            navigate(to)
          } else {
            navigate(-1)
          }
        }}
      />
    </Tooltip>
  )
}
