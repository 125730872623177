import { callFunction } from 'api/functionCalls'
import { Loader } from 'components'
import { Heading, Pane, Text } from 'evergreen-ui'
import { useEffect, useState } from 'react'
import { CampaignOverview, CampaignOverviewKeys } from 'types'

export function EngagementSummary({ programId }: { programId: string }) {
  const [campaignOverview, setCampaignOverview] = useState<CampaignOverview>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (programId) {
      setIsLoading(true)
      callFunction('services-RetrieveCampaignOverviewByProgramId', {
        programId,
      })
        .then(setCampaignOverview)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [programId])

  const campaignStats: {
    field: CampaignOverviewKeys
    label: string
  }[] = [
    { field: 'DeliveredCount', label: 'Emails sent' },
    { field: 'OpenedCount', label: 'Opened' },
    { field: 'ClickedCount', label: 'Clicked' },
  ]

  return (
    <Pane>
      {isLoading ? (
        <Pane width="fit-content">
          <Loader />
        </Pane>
      ) : (
        (campaignOverview?.DeliveredCount || 0) > 0 && (
          <Pane borderTop marginTop={32} paddingTop={32}>
            <Pane display="flex" alignItems="center">
              <Heading size={600} marginRight={64}>
                Engagement
              </Heading>
              {campaignStats?.map(option => (
                <Pane key={option.field} textAlign="center" marginRight={64}>
                  <Heading size={600} marginBottom={8}>
                    {Math.ceil(
                      ((campaignOverview?.[option.field] || 0) /
                        (campaignOverview?.DeliveredCount || 1)) *
                        100
                    )}
                    %
                  </Heading>
                  <Heading size={100}>{option.label}</Heading>
                  <Text size={300} color="muted">
                    {campaignOverview?.[option.field]} emails
                  </Text>
                </Pane>
              ))}
            </Pane>
          </Pane>
        )
      )}
    </Pane>
  )
}
