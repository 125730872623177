import { HourglassOutlined } from '@ant-design/icons'
import { Alert, Button, Flex } from 'antd'
import { BrandedTags } from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { CONFETTI_REF } from 'constants/rewardPreview'
import {
  ExchangeRateContext,
  MemberContext,
  OrgContext,
  ProgramContext,
  UserContext,
} from 'context'
import { Heading, Pane, Paragraph, Text, useTheme } from 'evergreen-ui'
import { Member_ConvertedTo } from 'gen/perkup/v1/program_pb'
import { useGiftRedeemed } from 'hooks'
import { isEmpty } from 'lodash-es'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { getDateTimeString, numToDollars } from 'utils'
import parse from 'html-react-parser'

export function ProgramInfo({
  blurReward = false,
  showAdvancedDetails = true,
  CTAText,
  handleCTAClick,
  showCTA = true,
}: {
  blurReward?: boolean
  showAdvancedDetails?: boolean
  CTAText: string
  handleCTAClick?: () => void
  showCTA?: boolean
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const giftRedeemed = useGiftRedeemed()

  const theme = useTheme()

  const endDate = getDateTimeString(program?.endsOn)
  const displayEndDate = endDate && typeof endDate === 'string'

  const coreValues = program?.coreValues

  const showCoreValues = !isEmpty(coreValues) && showAdvancedDetails

  const programSender = program?.email?.fromName

  const memberGiftConvertedToCash =
    member.convertedTo === Member_ConvertedTo.nearCash
  const isGift = !!program?.gift && !memberGiftConvertedToCash

  const displayAmount = numToDollars(
    program.budget * exchangeRate,
    0,
    false,
    user?.displayCurrency || org?.displayCurrency
  )

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap={isMobile ? 16 : 32}
      width="100%"
    >
      <Pane
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? undefined : 'space-between'}
        alignItems={isMobile ? 'center' : 'start'}
        gap={isMobile ? 16 : undefined}
      >
        <Heading size={800}>{program.name}</Heading>
        {!isGift && (
          <Heading
            id={CONFETTI_REF}
            size={800}
            color={NUMBER_GREEN}
            style={{ filter: blurReward ? 'blur(5px)' : undefined }}
          >
            {displayAmount}
          </Heading>
        )}
      </Pane>

      {showAdvancedDetails && (
        <Pane display="flex " flexDirection="column" gap={16}>
          <Paragraph
            size={500}
            maxHeight={isMobile ? 150 : 200}
            overflow="scroll"
          >
            {parse(program?.note || '')}
          </Paragraph>
          <Text is="em" size={500} color={theme.colors.gray600}>
            {programSender}
          </Text>
        </Pane>
      )}

      <Pane
        display="flex"
        justifyContent={isEmpty(coreValues) ? 'end' : 'space-between'}
        flexDirection="column"
        gap={16}
      >
        {showCoreValues && (
          <Pane display="flex" flexDirection="column" gap={8} width="100%">
            <Heading size={100}>CORE VALUES</Heading>
            <BrandedTags coreValues={coreValues} />
          </Pane>
        )}
        {displayEndDate && (
          <Flex gap={4} align="center">
            <HourglassOutlined style={{ fontSize: 12 }} />
            <Text size={300}>Expires {endDate}</Text>
          </Flex>
        )}

        {giftRedeemed && (
          <Alert
            style={{ maxHeight: 'fit-content', alignSelf: 'end' }}
            message="Gift has already been redeemed."
            type="info"
          />
        )}
        {showCTA && (
          <Button
            disabled={giftRedeemed}
            style={{
              alignSelf: 'end',

              width: isMobile ? '100%' : undefined,
            }}
            type="primary"
            onClick={handleCTAClick}
          >
            {CTAText}
          </Button>
        )}
      </Pane>
    </Pane>
  )
}
