import { FetchTinyBirdOrdersTableData } from 'api/tinybirdCalls/reads'
import type { OrderDetails } from 'api/tinybirdCalls/types'
import { useEffect, useState } from 'react'
import { RangeValue } from 'types/RewardsDate'

export function useOrdersTableData({
  args,
  filters,
}: {
  args: {
    orgId: string
  }
  filters: {
    pageSize: number
    page: number
    dateRange?: RangeValue
    individualId?: string
    status?: string[]
    iso2s?: string[]
  }
}) {
  const { orgId } = args
  const { pageSize, page, dateRange, individualId, status, iso2s } = filters

  const [response, setResponse] = useState<{
    data: OrderDetails[]
    total: number
  }>({
    data: [],
    total: 0,
  })
  const [state, setState] = useState<{
    loading: boolean
    error: Error | undefined
  }>({
    loading: true,
    error: undefined,
  })

  useEffect(() => {
    if (!orgId) {
      setState({
        loading: false,
        error: new Error('missing orgId'),
      })
      return () => {}
    }

    let current = true

    setState({ loading: true, error: undefined })
    FetchTinyBirdOrdersTableData({
      args: { orgId },
      filters: {
        pageSize,
        page,
        dateRange,
        individualId,
        status,
        iso2s,
      },
    })
      .then(res => {
        if (!current) return
        setState({ loading: false, error: undefined })
        setResponse({ data: res.data, total: res.total })
      })
      .catch(err => {
        if (!current) return
        console.error(err)
        setState({ loading: false, error: err })
      })

    return () => {
      current = false
    }
  }, [orgId, page, pageSize, individualId, dateRange, status, iso2s])

  return {
    data: response.data,
    total: response.total,
    loading: state.loading,
    error: state.error,
  }
}
