import { withOrgSidebar } from 'components'
import { LOGS, REPORTS } from 'constants/routes'
import NoMatch404 from 'pages/NoMatch404'
import { Route, Routes } from 'react-router'
import { InsightsDashboard } from './InsightsDashboard'
import { NotificationLogs } from './NotificationLogs'
import { RewardReports } from './Reports'

function Insights() {
  return (
    <Routes>
      <Route path="/" index element={<InsightsDashboard />} />
      <Route path={REPORTS} element={<RewardReports />} />
      <Route path={LOGS} element={<NotificationLogs />} />
      <Route path="*" element={<NoMatch404 />} />
    </Routes>
  )
}

export default withOrgSidebar(Insights)
