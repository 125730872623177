import { Flex, Space, Table, TableProps } from 'antd'
import { Fulfillment, LineItem, OrderDetails } from 'api/tinybirdCalls/types'
import { ProductThumbnail, TruncatedCopyableText } from 'components'
import { GHOST_FULFILLMENT_NAME } from 'constants/orders'
import { Text } from 'evergreen-ui'
import { numToDollars } from 'utils'
import { parseBigQueryItemOptions } from 'utils/individual'
import { OrderStatusTag } from './OrderStatusTag'
import Tracking from './Tracking'

interface TableRow {
  key: string
  quantity: LineItem['quantity']
  image: OrderDetails['items'][0]['image']
  name: OrderDetails['items'][0]['productName']
  options: OrderDetails['items'][0]['options']
  amount: OrderDetails['items'][0]['amount']
}

const columns: TableProps<TableRow>['columns'] = [
  {
    title: 'Product name',
    width: '20%',
    render: (_, record) => {
      return (
        <Space style={{ width: '100%', paddingLeft: 8 }}>
          <ProductThumbnail>
            <ProductThumbnail.Image imageUrl={record.image ?? ''} />

            <Flex vertical>
              <ProductThumbnail.Name>{record.name}</ProductThumbnail.Name>
              <ProductThumbnail.Options
                options={parseBigQueryItemOptions(record.options)}
              />
            </Flex>
          </ProductThumbnail>
        </Space>
      )
    },
  },
  {
    title: 'Amount',
    width: '5%',
    align: 'right',
    render: (_, record) => <Text>{numToDollars(record.amount ?? 0)}</Text>,
  },
  {
    title: 'Multiplcation',
    align: 'right',
    width: '3%',
    render: () => <Text>x</Text>,
  },
  {
    title: 'Quantity',
    align: 'right',
    width: '3%',
    render: (_, record) => <Text>{record.quantity}</Text>,
  },
  {
    title: 'Total',
    align: 'right',
    width: '5%',
    render: (_, record) => (
      <Text>
        {numToDollars((record.amount ?? 0) * (record?.quantity ?? 0))}
      </Text>
    ),
  },
  {
    title: 'spacer',
  },
]

function OrderFulfillmentTableHeader({
  fulfillment,
}: {
  fulfillment: Fulfillment
}) {
  const { name, status, trackingCompany, trackingNumber, trackingUrl } =
    fulfillment

  const isGhostFulfillment = name === GHOST_FULFILLMENT_NAME

  return (
    <Flex
      vertical
      gap={8}
      style={{
        padding: '8px 0px 16px 8px',
        borderBottom: '1px solid #f0f0f0',
      }}
    >
      <Flex gap={8} align="center">
        <OrderStatusTag withIcon status={status} />
        {name && !isGhostFulfillment && (
          <TruncatedCopyableText
            text={name}
            textMaxWidth={100}
            type="fulfillment ID"
          />
        )}
      </Flex>
      <Flex gap={8}>
        {trackingCompany && <Text color="muted">{trackingCompany}</Text>}
        {trackingNumber && trackingUrl && (
          <Tracking>
            <Tracking.Link url={trackingUrl}>{trackingNumber}</Tracking.Link>
          </Tracking>
        )}
      </Flex>
    </Flex>
  )
}

export function OrderFulfillmentTable({
  itemsOnOrder,
  fulfillment,
}: {
  itemsOnOrder: OrderDetails['items']
  fulfillment: Fulfillment
}) {
  return (
    <Flex vertical style={{ width: '100%' }}>
      <Table
        showHeader={false}
        columns={columns}
        dataSource={fulfillment.lineItems.map((lineItem, index) => {
          const itemForThisLineItem = itemsOnOrder.find(
            item => item.productVariantId === lineItem.variantId
          )

          return {
            key: `${lineItem.variantId}-${lineItem.quantity}-${index}`,
            image: itemForThisLineItem?.image ?? '',
            name: itemForThisLineItem?.productName ?? '',
            options: itemForThisLineItem?.options ?? [],
            amount: itemForThisLineItem?.amount ?? 0,
            quantity: lineItem.quantity,
          }
        })}
        pagination={false}
        size="small"
        title={() => OrderFulfillmentTableHeader({ fulfillment })}
      />
    </Flex>
  )
}
