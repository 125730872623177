import { Checkbox, Flex } from 'antd'
import { Pane, Text } from 'evergreen-ui'
import { Template } from 'gen/perkup/v1/org_template_pb'
import { every, intersectionWith, isEmpty } from 'lodash-es'

import { PlusOutlined } from '@ant-design/icons'
import { orgTemplateAsset } from 'assets'
import { PerkEmptyState } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import {
  TEMPLATE_DETAILS_CARD_WIDTH,
  TemplateDetailsCard,
} from './TemplateDetailsCard'

export function TemplateGrid({ templates }: { templates: Template[] }) {
  const navigate = useNavigate()
  const defaultTemplatesToRender = templates.map(template => {
    return {
      ...template,
      visible: false,
    }
  })

  const [templatesWithVisibleField, setTemplatesWithVisibleField] = useState(
    defaultTemplatesToRender
  )

  if (isEmpty(templates)) {
    return (
      <Pane marginTop={64}>
        <PerkEmptyState
          header="Create your first template"
          description="Simplify sending rewards for your team."
          iconUrl={orgTemplateAsset}
          ctaProps={{
            type: 'primary',
            onClick: () =>
              navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.NEW),
            icon: <PlusOutlined />,
            style: { width: 'fit-content' },
            children: 'Create template',
          }}
        />
      </Pane>
    )
  }

  const handleSetAllTemplatesVisible = (visible: boolean) => {
    setTemplatesWithVisibleField(
      templatesWithVisibleField.map(template => {
        return {
          ...template,
          visible,
        }
      })
    )
  }

  const handleTemplateCardClick = (clickedTemplate: Template) => {
    const newTemplates = templatesWithVisibleField.map(template => {
      if (template.id === clickedTemplate.id) {
        return {
          ...template,
          visible: !template.visible,
        }
      }
      return template
    })
    setTemplatesWithVisibleField(newTemplates)
  }

  const templatesToRender = intersectionWith(
    templatesWithVisibleField,
    templates,
    (templateWithVisibleField, template) => {
      return templateWithVisibleField.id === template.id
    }
  )

  return (
    <Flex vertical gap={16}>
      <Flex gap={8} align="center">
        <Checkbox
          checked={every(templatesWithVisibleField, 'visible')}
          onChange={e => handleSetAllTemplatesVisible(e.target.checked)}
        />
        <Text>Expand all</Text>
      </Flex>
      <Pane
        display="grid"
        gridTemplateColumns={`repeat(auto-fit, ${TEMPLATE_DETAILS_CARD_WIDTH}px)`}
        gridAutoFlow="row dense"
        gap={32}
      >
        {templatesToRender.map(template => {
          const typedTemplate = new Template(template)
          return (
            <TemplateDetailsCard
              key={template.id}
              template={typedTemplate}
              isExpanded={template.visible}
              onCardClick={() => handleTemplateCardClick(typedTemplate)}
            />
          )
        })}
      </Pane>
    </Flex>
  )
}
