import { Badge as AntBadge, Tooltip } from 'antd'
import { PerkUpLink } from 'components/Typography/PerkUpLink'
import { Pane, Text } from 'evergreen-ui'
import { OrderFulfillment } from 'gen/perkup/v1/vendor_pb'
import { isEmpty } from 'lodash-es'

export interface ShipmentActionLabel {
  color: string
  label: string
}

export const getShipmentAction = (
  orderFulfillment: OrderFulfillment
): ShipmentActionLabel | undefined => {
  const { shipmentActions } = orderFulfillment

  if (shipmentActions?.delivered) {
    return { color: 'green', label: 'Delivered' }
  }
  if (shipmentActions?.outForDelivery) {
    return { color: 'blue', label: 'Out for delivery' }
  }
  if (shipmentActions?.inTransit) {
    return { color: 'yellow', label: 'In transit' }
  }
  return undefined
}

export function ShipmentActionTracking({
  orderFulfillments,
  displayTracking = true,
}: {
  orderFulfillments: OrderFulfillment[]
  displayTracking?: boolean
}) {
  return (
    <Pane display="flex" flexDirection="column">
      {isEmpty(orderFulfillments) && <Text>-</Text>}
      {orderFulfillments.map(orderFulfillment => {
        const shipmentAction = getShipmentAction(orderFulfillment)
        const hasTracking =
          !!orderFulfillment.trackingUrl &&
          !!orderFulfillment.trackingNumber &&
          displayTracking

        const hasActionOrTracking = shipmentAction || hasTracking

        return (
          <Pane
            display="flex"
            alignItems="center"
            gap={8}
            key={orderFulfillment.id}
          >
            {!hasActionOrTracking && <Text>—</Text>}
            {hasActionOrTracking && (
              <>
                {shipmentAction && (
                  <>
                    {hasTracking && (
                      <Tooltip title={shipmentAction.label}>
                        <AntBadge
                          style={{ whiteSpace: 'nowrap' }}
                          color={shipmentAction.color}
                        />
                      </Tooltip>
                    )}
                    {!hasTracking && (
                      <AntBadge
                        style={{ whiteSpace: 'nowrap' }}
                        color={shipmentAction.color}
                        text={shipmentAction.label}
                      />
                    )}
                  </>
                )}

                {hasTracking && (
                  <PerkUpLink
                    to={orderFulfillment.trackingUrl}
                    onClick={e => e.stopPropagation()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {orderFulfillment.trackingNumber}
                  </PerkUpLink>
                )}
              </>
            )}
          </Pane>
        )
      })}
    </Pane>
  )
}
