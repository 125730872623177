import { CopyOutlined } from '@ant-design/icons'
import { Heading, Pane, Text, Tooltip } from 'evergreen-ui'
import { copyToClipboard } from 'utils'

export function DirectRewardOrderStatus({
  productVariantName,
  orderId,
}: {
  productVariantName: string | undefined
  orderId?: string | undefined
}) {
  return (
    <Pane>
      <Heading size={600} marginBottom={8}>
        {productVariantName}
      </Heading>
      <Pane
        display="grid"
        gridTemplateColumns="max-content 2fr"
        columnGap={32}
        rowGap={16}
        alignItems="center"
      >
        {orderId && (
          <Pane>
            <Heading size={100}>ORDER ID</Heading>

            <Text marginRight={8}>{orderId}</Text>
            <Tooltip content="Copy order id">
              <CopyOutlined
                onClick={() => copyToClipboard(orderId, 'order id')}
              />
            </Tooltip>
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}
