import { PLACID_BUCKET_URL_SUFFIX } from 'constants/images'
import { PlacidTags } from 'constants/placid/placid-tags'
import { getPlacidDataByTag } from 'utils/placid'
import { ListFirebaseStorageImages } from './images'

export async function listPlacidImagesByTag({
  tag,
  orgId,
}: {
  tag: PlacidTags
  orgId: string
}) {
  const allPlacidImages = await ListFirebaseStorageImages({
    orgId,
    suffix: PLACID_BUCKET_URL_SUFFIX,
  })

  const desiredImageIds = getPlacidDataByTag(tag).map(data => data.uuid)
  const desiredImages = allPlacidImages.filter(image =>
    desiredImageIds.some(id => image.name.includes(id))
  )

  return desiredImages
}
