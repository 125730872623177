import { PartialMessage } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { toaster } from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { Admin } from 'gen/shopify/admin_connect'
import {
  ReorderProductImagesRequest,
  UpdateProductRequest,
} from 'gen/shopify/admin_pb'
import {
  CountryCode_Enum,
  DraftOrderInput,
  DraftOrderLineItemInput,
  MailingAddressInput,
  MoveInput,
  ProductInput,
  ShippingLineInput,
} from 'gen/shopifyapi/admingql_pb'
import { createClient } from 'hooks/useClient'
import { toSentry } from 'utils/sentry'

export async function CalculateDraftOrder({
  lineItems,
  address,
  shippingRateHandle,
}: {
  lineItems: DraftOrderLineItemInput[]
  address: ShippingAddress
  shippingRateHandle?: string
}) {
  try {
    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(Admin)

    const shippingAddress = new MailingAddressInput({
      address1: address.line1,
      address2: address.line2,
      city: address.city,
      provinceCode: address.state,
      zip: address.postalCode,
      // @ts-ignore
      countryCode: CountryCode_Enum[address.country.toUpperCase()],
    })

    const draftOrderRequest: DraftOrderInput = new DraftOrderInput({
      lineItems,
      shippingAddress,
      email: 'orders@perkupapp.com',
      shippingLine: shippingRateHandle
        ? new ShippingLineInput({
            shippingRateHandle,
          })
        : undefined,
    })

    return await client.calculateDraftOrder(draftOrderRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  } catch (error) {
    toaster.warning('Error calculating draft order, please contact support')

    console.log('error', error)

    captureException(toSentry(error), {
      contexts: {
        CalculateDraftOrder: {
          lineItems,
          address,
        },
      },
    })
  }
  return undefined
}

export async function UpdateProduct(
  productInput: PartialMessage<ProductInput>
) {
  try {
    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(Admin)

    const updateProductRequest = new UpdateProductRequest({
      productInput,
    })

    return await client.updateProduct(updateProductRequest, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  } catch (error) {
    toaster.warning('Error updating product, please contact support')

    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        UpdateProduct: {
          productInput,
        },
      },
    })
    throw error
  }
  return undefined
}

export async function ReorderProductImages({
  productId,
  moves,
}: {
  productId: string
  moves: MoveInput[]
}) {
  try {
    const accessToken = await getAuth().currentUser?.getIdToken()

    const client = createClient(Admin)

    const reorderProductImagesReq = new ReorderProductImagesRequest({
      productId,
      moves,
    })

    return await client.reorderProductImages(reorderProductImagesReq, {
      headers: { Authorization: `Bearer ${accessToken}` },
    })
  } catch (error) {
    toaster.warning('Error reordering product images, please contact support')

    console.error(error)

    captureException(toSentry(error), {
      contexts: {
        ReorderProductImages: {
          productId,
          moves,
        },
      },
    })
  }
  return undefined
}
