import { Modal } from 'antd'
import { OrgContext } from 'context'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import { keys } from 'lodash-es'
import { ReactNode, useContext, useState } from 'react'
import { Selectable } from 'types'
import { getAlgoliaOrgSwagFilters } from 'utils/Algolia'
import { getUniqAndFormatWithSelectable } from 'utils/productVariant'

export function BrowseSwagProducts({
  selectedProductIds,
  onProductVariantClick,
  firstRowPrefix,
  extraElementsInFirstRow,
  bottomOffsetAmount,
}: {
  selectedProductIds: string[]
  onProductVariantClick: (
    productVariant: ProductVariant,
    allSelectedVariants: ProductVariant[]
  ) => void | Promise<void>
  firstRowPrefix?: ReactNode
  extraElementsInFirstRow?: ReactNode
  bottomOffsetAmount?: number
}) {
  const { id: orgId } = useContext(OrgContext)
  const [productVariantToShow, setProductVariantToShow] =
    useState<Selectable<ProductVariant>>()

  const { productVariants } = useListAllProductVariantsByProductIds({
    productIds: selectedProductIds,
  })

  const addedVariants = getUniqAndFormatWithSelectable(productVariants)

  const handleCtaClick = async (productVariant: ProductVariant) => {
    setProductVariantToShow(undefined)

    await onProductVariantClick(productVariant, addedVariants)
  }

  return (
    <>
      <AlgoliaBrowseProducts>
        <AlgoliaBrowseProducts.Old
          onProductCardClick={pv => setProductVariantToShow(pv)}
          addedVariants={addedVariants}
          onCardAddButtonClick={handleCtaClick}
          searchFilter={getAlgoliaOrgSwagFilters({ orgId })}
          withCountryFilter
          withPriceFilter
          withProductAmounts
          firstRowPrefix={firstRowPrefix}
          extraElementsInFirstRow={extraElementsInFirstRow}
          filterUiProps={{
            searchPlaceholder: 'Search swag',
            priceFilterPrefix: 'Swag',
          }}
          menuFilters={['productType']}
          selectedRibbonText="Added"
          showAddedVariantsFirst={false}
          showPrepaidBadge
          bottomOffsetAmount={bottomOffsetAmount}
        />
      </AlgoliaBrowseProducts>
      <Modal
        width={1280}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShipping
            showShippingCountries
            showInStockText
            disableOutOfStock={false}
            onPrimaryCtaClick={handleCtaClick}
            disableVariantOptions
            primaryCtaProps={{
              type: productVariantToShow.isSelected ? 'default' : 'primary',
              danger: productVariantToShow.isSelected,
              children: productVariantToShow.isSelected
                ? 'Remove swag'
                : 'Add swag',
            }}
            alertProps={
              keys(productVariantToShow?.options || {}).length >= 1
                ? {
                    type: 'info',
                    message:
                      'Recipients will only see options less than the reward budget',
                  }
                : undefined
            }
          />
        )}
      </Modal>
    </>
  )
}
