import { Flex } from 'antd'
import { ProductCollectionNavigation } from 'components'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE,
} from 'constants/algolia'
import { Pane } from 'evergreen-ui'
import { AlgoliaProductsGrid } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useProductVariantSearchClient } from 'hooks'
import { useScrollObserver } from 'hooks/useScrollObserver'
import {
  Configure,
  InstantSearch,
  InstantSearchProps,
  UseNumericMenuProps,
} from 'react-instantsearch'

export function AlgoliaBrowseCollections({
  onProductClick,
  onConfirmProductCollection,
  extraElementsInNavBar,
  navbarPrefix,
  initialUiState,
  defaultSelectedBudget,
  disableBudgetSelect = false,
  hideProductCount = false,
  hiddenStickyNavTopAmount = undefined,
}: {
  onProductClick: (productVariant: ProductVariant) => void
  onConfirmProductCollection: (
    productCollectionId: string,
    programBudget: number
  ) => void
  extraElementsInNavBar?: React.ReactNode
  initialUiState?: InstantSearchProps['initialUiState']
  defaultSelectedBudget?: UseNumericMenuProps['items'][0]
  disableBudgetSelect?: boolean
  hideProductCount?: boolean
  hiddenStickyNavTopAmount?: number | undefined
  navbarPrefix?: React.ReactNode
}) {
  const { searchClient } = useProductVariantSearchClient()

  const { show } = useScrollObserver({ hideThreshold: 1000 })

  return (
    <InstantSearch
      indexName={ALGOLIA_PRODUCT_VARIANTS_INDEX}
      searchClient={searchClient}
      initialUiState={initialUiState}
    >
      <Configure filters={TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE} hitsPerPage={40} />

      <Flex style={{ width: '100%' }} vertical>
        <ProductCollectionNavigation
          onConfirmProductCollection={onConfirmProductCollection}
          buttonsOnRight={extraElementsInNavBar}
          buttonsOnLeft={navbarPrefix}
          defaultSelectedBudget={defaultSelectedBudget}
          disableBudgetSelect={disableBudgetSelect}
          hideProductCount={hideProductCount}
          stickyNavTopAmount={show ? undefined : hiddenStickyNavTopAmount}
        />

        <Pane width="100%">
          <AlgoliaProductsGrid onCardClick={onProductClick} />
        </Pane>
      </Flex>
    </InstantSearch>
  )
}
