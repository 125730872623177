import { PartialMessage, Timestamp } from '@bufbuild/protobuf'
import { captureException, captureMessage } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import * as EVENTS from 'constants/events'
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getFirestore,
  PartialWithFieldValue,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import {
  Organization,
  Organization_SubscriptionStatus,
} from 'gen/perkup/v1/organization_pb'
import {
  Member,
  Member_ConvertedTo,
  Program,
  ProgramFrequency,
  ProgramStatus,
  ProgramType,
  Program_Feedback,
  Program_Gift,
  ScheduledMember,
} from 'gen/perkup/v1/program_pb'
import isNaN from 'lodash-es/isNaN'
import { getProgramKeyAsString } from 'utils'
import { logEvent } from 'utils/firebaseUtils'
import { converter, storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'
import { GetProgramById } from '../reads'
import { GetIndividualIdsByEmail } from '../reads/individuals'
import { createIndividualByEmail } from './individual'
import { inviteMember } from './misc'

export async function updateProgram({
  orgId,
  programId,
  program,
  merge = true,
}: {
  orgId: string
  programId: string
  program: PartialWithFieldValue<Program>
  merge?: boolean
}) {
  if (!orgId || !programId || !program) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}`
  ).withConverter(storedId(Program))
  await setDoc(docRef, program, { merge })
}

export async function deleteProgram({
  orgId,
  programId,
}: {
  orgId: string
  programId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}`
  ).withConverter(storedId(Program))
  await deleteDoc(docRef).catch(error => {
    captureException(toSentry(error), {
      contexts: {
        deleteProgram: {
          orgId,
          programId,
        },
      },
    })
  })
}

export function updateProgramEnrollment({
  orgId,
  programId,
  autoEnrolled = false,
}: {
  orgId: string
  programId: string
  autoEnrolled: boolean
}) {
  if (!orgId || !programId) return
  // logEvent(EVENTS.PROGRAM_UPDATED)
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}`
  ).withConverter(storedId(Program))
  const update: PartialMessage<Program> = { autoEnrolled }
  setDoc(docRef, update, { merge: true }).catch(e =>
    captureException(toSentry(e))
  )
}

export function updateAllocatedByOwnerId({
  orgId,
  memberId,
  allocated,
  policyId,
}: {
  orgId: string
  memberId: string
  allocated: number
  policyId: string
}) {
  if (
    !orgId ||
    !memberId ||
    isNaN(allocated) ||
    allocated === null ||
    !policyId
  )
    return

  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${policyId}/members/${memberId}`
  ).withConverter(storedId(Member))
  const update: PartialMessage<Member> = { allocated }
  setDoc(docRef, update, { merge: true }).catch(e =>
    captureException(toSentry(e))
  )
}

export async function createProgram({
  orgId,
  userId,
  name,
  budget,
  autoEnrolled = false,
  frequency = ProgramFrequency.monthly,
  peopleIds = [],
  prorate = false,
  type = ProgramType.perks,
  approvedCategories,
  allMerchants = false,
  startsOn,
  endsOn,
  note,
  emails = [],
  banner,
  fromName,
  title,
  ownerId,
  parentProgramId,
  sendAtTimestamp,
  isScheduledReward = false,
  gift,
  accountId,
}: {
  orgId: string
  userId: string
  name: string
  budget: number
  autoEnrolled: boolean
  frequency: ProgramFrequency
  peopleIds: string[]
  prorate?: boolean
  type: ProgramType
  approvedCategories: string[]
  allMerchants: boolean
  startsOn?: Date
  endsOn?: Date
  note?: string
  emails?: string[]
  banner?: string
  fromName?: string
  title?: string
  ownerId?: string
  parentProgramId?: string
  sendAtTimestamp?: number
  isScheduledReward?: boolean
  gift?: Program_Gift
  accountId?: string
}) {
  if (!orgId || !budget || !approvedCategories) return undefined

  const program = new Program({
    type,
    name,
    created: Timestamp.now(),
    budget: Math.round(budget),
    totalSpent: 0,
    totalBudget: 0,
    frequency,
    approvedCategories,
    prorate,
    prorateDate: prorate ? Timestamp.now() : undefined,
    status: ProgramStatus.inactive,
    isDeleted: false,
    autoEnrolled,
    allMerchants,
    startsOn: startsOn ? Timestamp.fromDate(startsOn) : undefined,
    endsOn: endsOn ? Timestamp.fromDate(endsOn) : undefined,
    note,
    ownerId,
    email: {
      banner,
      fromName,
      title,
    },
    parentProgramId,
    gift,
    accountId,
  })

  const db = getFirestore()
  const colRef = collection(
    getFirestore(),
    `organizations/${orgId}/programs`
  ).withConverter(storedId(Program))

  const newProgram = await addDoc(colRef, program).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createProgram: {
          orgId,
          name,
          budget,
          frequency,
          type,
          allMerchants,
          startsOn,
          endsOn,
          ownerId,
          parentProgramId,
          sendAtTimestamp,
          isScheduledReward,
          gift,
          accountId,
        },
      },
    })
  })

  const orgRef = doc(db, `organizations/${orgId}`).withConverter(
    converter(Organization)
  )
  const org = await getDoc(orgRef)
    .then(doc => doc.data())
    .catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          createProgram: {
            orgId,
            name,
            budget,
            frequency,
            type,
            allMerchants,
            startsOn,
            endsOn,
            ownerId,
            parentProgramId,
            sendAtTimestamp,
            isScheduledReward,
            gift,
            accountId,
          },
        },
      })
    })

  logEvent(EVENTS.PROGRAM_CREATED, {
    program: program.toJson(),
    orgId,
    orgSubscriptionStatus:
      Organization_SubscriptionStatus[
        org
          ? org.subscriptionStatus
          : Organization_SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED
      ],
    userId,
  })

  const newIndividualIds: string[] = []
  if (newProgram && emails) {
    await Promise.all(
      emails.map(async email => {
        const role = Individual_Role.member
        await createIndividualByEmail({ email, orgId, role })
        await inviteMember({ orgId, email, role })
        const individualIds = await GetIndividualIdsByEmail({ email, orgId })
        if (!individualIds) return
        newIndividualIds.push(...individualIds)
      })
    )
  }

  // Make sure the new Program was properly created
  if (newProgram && (peopleIds?.length || newIndividualIds?.length)) {
    if (isScheduledReward) {
      // Wait for people to be added on the first program created
      await callFunction('firestore-SchedulePeopleIdsToProgram', {
        orgId,
        programId: newProgram.id,
        selectedIds: peopleIds,
        type: ProgramType[type],
        sendAtTimestamp,
      })
    } else {
      // Wait for people to be added on the first program created
      await callFunction('firestore-AddPeopleIdsToProgram', {
        orgId,
        programId: newProgram.id,
        selectedIds: [...newIndividualIds, ...peopleIds],
      })
    }
  } else {
    captureMessage(`People were not added to program: ${newProgram?.id}`, {
      contexts: {
        program: { ...newProgram },
        peopleIds: { peopleIds },
        newIndividualIds: { newIndividualIds },
      },
    })
  }

  return newProgram
}

export async function addProgramToOrg({
  orgId,
  program,
}: {
  orgId: string
  program: Program
}) {
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/programs`
  ).withConverter(storedId(Program))

  try {
    const ref = await addDoc(colRef, program)
    return await GetProgramById({ orgId, programId: ref.id })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        addProgramToOrg: {
          orgId,
          programId: program.id,
        },
      },
    })
    return undefined
  }
}

export async function createNewIndividualsByEmails({
  orgId,
  emails,
}: {
  orgId: string
  emails: string[]
}) {
  const newIndividualIds: string[] = []
  await Promise.all(
    emails.map(async email => {
      await createIndividualByEmail({
        email,
        orgId,
        role: Individual_Role.member,
      })
      await inviteMember({ orgId, email, role: Individual_Role.member })
      const individualIds = await GetIndividualIdsByEmail({ email, orgId })
      if (!individualIds) return
      newIndividualIds.push(...individualIds)
    })
  )

  return newIndividualIds
}

export async function updateSwappedGift({
  orgId,
  programId,
  memberId,
  newGift,
}: {
  orgId: string
  programId: string
  memberId: string
  newGift: Program_Gift
}) {
  const db = getFirestore()

  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/members/${memberId}`
  ).withConverter(storedId(Member))

  const updateMemberGift: PartialMessage<Member> = {
    gift: { giftId: newGift?.productIds[0] },
    convertedTo: Member_ConvertedTo.alternativeGift,
  }
  return setDoc(docRef, updateMemberGift, { merge: true }).catch(e => {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        updateSwappedGift: {
          orgId,
          programId,
          memberId,
          newGift,
        },
      },
    })
  })
}

export async function convertIntoPerkUpBalance({
  orgId,
  programId,
  memberId,
}: {
  orgId: string
  programId: string
  memberId: string
}) {
  const db = getFirestore()

  const memberDocRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/members/${memberId}`
  ).withConverter(storedId(Member))

  try {
    setDoc(
      memberDocRef,
      { gift: deleteField(), convertedTo: Member_ConvertedTo.nearCash },
      { merge: true }
    )
  } catch (error) {
    console.error(error)
    captureException(toSentry(error))
  }
}

export async function UpdateScheduledMemberSendAt({
  orgId,
  programId,
  scheduledMemberId,
  date,
}: {
  orgId: string
  programId: string
  scheduledMemberId: string
  date: Date
}) {
  if (!orgId || !programId || !scheduledMemberId || !date) return

  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/scheduledMemberId/${scheduledMemberId}`
  ).withConverter(converter(ScheduledMember))
  const update: PartialMessage<ScheduledMember> = {
    sendAt: Timestamp.fromDate(date),
  }
  setDoc(docRef, update, { merge: true }).catch(e => {
    console.error(e)
    captureException(toSentry(e), {
      contexts: {
        UpdateScheduledMemberSendAt: {
          orgId,
          programId,
          scheduledMemberId,
          date,
        },
      },
    })
  })
}

export async function updateMember({
  orgId,
  programId,
  memberId,
  member,
}: {
  orgId: string
  programId: string
  memberId: string
  member: PartialMessage<Member>
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}/members/${memberId}`
  ).withConverter(storedId(Member))
  return setDoc(docRef, member, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateMember: { orgId, programId, memberId, member },
      },
    })
  })
}

export async function updateProgramFeedback({
  orgId,
  programId,
  rating,
  response,
}: {
  orgId: string
  programId: string
  rating?: string
  response?: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/programs/${programId}`
  ).withConverter(storedId(Program))

  const feedback = new Program_Feedback({
    rating,
    response,
  })
  setDoc(docRef, { feedback }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateProgramFeedback: { orgId, programId, feedback },
      },
    })
  })
}

export async function removeProgramField({
  orgId,
  programId,
  field,
}: {
  orgId: string
  programId: string
  field: keyof Program
}) {
  const keyAsString = getProgramKeyAsString(field)
  if (!orgId) return
  const db = getFirestore()
  const docRef = doc(db, `organizations/${orgId}/programs/${programId}`)

  await updateDoc(docRef, {
    [keyAsString]: deleteField(),
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        removeProgramField: {
          orgId,
          programId,
          field,
        },
      },
    })
  })
}
