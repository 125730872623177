import { Badge } from 'antd'
import { useDefaultOrgColors } from 'hooks'
import React from 'react'

export function SelectedRibbonWrapper({
  show,
  text = 'Selected',
  children,
}: React.PropsWithChildren<{
  show: boolean
  text?: string
}>) {
  const { defaultColor } = useDefaultOrgColors()

  return (
    <Badge.Ribbon
      text={text}
      color={defaultColor}
      style={{
        display: show ? 'block' : 'none',
        userSelect: 'none',
      }}
    >
      {children}
    </Badge.Ribbon>
  )
}
