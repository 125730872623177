import { PERKUP_DOLLARS } from 'constants/newReward'
import { PERKUP_NAME } from 'constants/perkupLinks'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { Template_EligibleRewards } from 'gen/perkup/v1/org_template_pb'

export function sortFeaturedTemplates({
  programTemplates,
}: {
  programTemplates: ProgramTemplate[]
}) {
  const featuredTemplates = programTemplates.sort((a, b) => {
    if (!a.rank && !b.rank) return 0
    if (!a.rank) return 1
    if (!b.rank) return -1
    return a.rank - b.rank
  })
  return featuredTemplates
}

export const tempateImageWidth = 333
export const tempateImageHeight = 192

export function getTemplateImageUrl({
  programImage,
}: {
  programImage: string
}) {
  const bucketSearchString = PERKUP_NAME.toLowerCase()
  const isBucketImage = programImage.includes(bucketSearchString)
  const imageUrl = !isBucketImage
    ? // Resize image for faster load time and 2x resolution for retina displays
      `${programImage}?w=${tempateImageWidth * 2}&h=${tempateImageHeight * 2}`
    : `${programImage}`
  return imageUrl
}

export const getEligibleTemplateRewardLabel = (
  rewardKey: keyof Template_EligibleRewards
) => {
  switch (rewardKey) {
    case 'cash':
      return PERKUP_DOLLARS
    case 'swag':
      return 'Swag'
    case 'gift':
      return 'Gift'
    case 'giftCard':
      return 'Gift Card'
    default:
      return ''
  }
}
