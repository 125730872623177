import { Flex, Grid, Image, Space } from 'antd'
import CelebrateSuccessYourWay from 'assets/celebrate-success-your-way.png'
import { AuthenticationForm, Loader } from 'components'
import { PERKUP_PRIMARY_COLOR } from 'constants/colors'
import { CODE } from 'constants/params'
import { REWARD_ACCEPTANCE } from 'constants/routes'
import { Heading } from 'evergreen-ui'
import { useEffect, useMemo, useState } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { customTokenSignIn } from 'services/firebaseToken'

const { useBreakpoint } = Grid

function Hero() {
  return (
    <Flex vertical align="center">
      <Image
        preview={false}
        src={CelebrateSuccessYourWay}
        alt="Banner of celebrate success your way"
        width="100%"
        style={{ maxWidth: 820, minWidth: 400 }}
      />

      <Heading size={900} textAlign="center">
        Celebrate success{' '}
        <span style={{ color: PERKUP_PRIMARY_COLOR }}>globally</span>, <br />{' '}
        your way.
      </Heading>
    </Flex>
  )
}

export default function SignInPage() {
  const screens = useBreakpoint()
  const isBigScreen = screens.lg || screens.xl
  const isHugeScreen = screens.xxl

  const [searchParams, setSearchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)

  const code = useMemo(() => searchParams.get(CODE), [searchParams])

  // if code is present, then call customTokenSignIn for WorkOS sign in
  useEffect(() => {
    if (code) {
      setIsLoading(true)
      customTokenSignIn({ workosCode: code }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [code])

  // if code is present, then delete from searchParam
  useEffect(() => {
    if (code) {
      searchParams.delete(CODE)
      setSearchParams(searchParams)
    }
  }, [code, searchParams, setSearchParams])

  if (isLoading) return <Loader />

  return (
    <Flex
      vertical={!isBigScreen}
      gap={isHugeScreen ? 120 : 72}
      align="center"
      justify="center"
      style={{ padding: isBigScreen ? 72 : 16, height: '100svh' }}
    >
      <Space style={{ maxWidth: 356, minWidth: 320 }}>
        <Routes>
          <Route path="*" element={<AuthenticationForm />} />
          <Route
            path={`${REWARD_ACCEPTANCE}/*`}
            element={<AuthenticationForm />}
          />
        </Routes>
      </Space>
      {isBigScreen && <Hero />}
    </Flex>
  )
}
