import { ListProductVariantsByProgramItems } from 'api/databaseCalls'
import { BUNDLE_TAG, SCRAPE_IT_TAG } from 'constants/algolia'
import { ProductVariant_Provider } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import {
  CalculatedDraftOrder,
  DraftOrderLineItemInput,
} from 'gen/shopifyapi/admingql_pb'
import { intersectionWith, isEmpty } from 'lodash-es'

import { useEffect, useState } from 'react'
import { CalculateDraftOrder } from 'services/shopify'

async function getDraftOrder({
  items,
  address,
}: {
  items: Item[]
  address: ShippingAddress
}) {
  // First we need to filter out bundled items - need pv to check
  const productVariants = await ListProductVariantsByProgramItems({
    programItems: items,
  })
  if (!productVariants) return undefined
  const nonBundleVariants = productVariants.filter(
    pv => !pv.tags.includes(BUNDLE_TAG)
  )

  const nonBundledItems = intersectionWith(
    items,
    nonBundleVariants,
    (item, variant) => item.productVariantId === variant.id
  )

  const lineItems: DraftOrderLineItemInput[] = nonBundledItems.map(item => {
    const variantForItem = nonBundleVariants.find(
      variant => variant.id === item.productVariantId
    )

    const isScraped = variantForItem?.tags?.includes(SCRAPE_IT_TAG)

    if (isScraped) {
      return new DraftOrderLineItemInput({
        quantity: item.quantity,
        title: variantForItem?.productName,
        taxable: variantForItem?.taxable,
        sku: variantForItem?.sku,
        requiresShipping: variantForItem?.requiresShipping,
        customAttributes: [
          { key: 'variantId', value: variantForItem?.id },
          {
            key: 'productId',
            value: variantForItem?.productId,
          },
          { key: 'handle', value: variantForItem?.handle },
        ],
      })
    }

    return new DraftOrderLineItemInput({
      quantity: item.quantity,
      variantId: variantForItem?.adminGraphqlApiId,
    })
  })

  // Then hit shopify api
  const draftOrder = await CalculateDraftOrder({
    lineItems,
    address,
  })

  return draftOrder?.calculatedDraftOrder
}

export function useShopifyCalcDraftOrder({
  programItems,
  address,
}: {
  programItems?: Item[]
  address?: ShippingAddress
}) {
  const [draftOrderCalculation, setDraftOrderCalculation] =
    useState<CalculatedDraftOrder>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!programItems || !address?.country) return
    const shopifyItemsWithQuantity = programItems.filter(
      item =>
        item.quantity > 0 && item.provider === ProductVariant_Provider.shopify
    )
    if (!isEmpty(shopifyItemsWithQuantity)) {
      setIsLoading(true)

      // We call calculateDraftOrder to get best shipping rates
      getDraftOrder({ items: shopifyItemsWithQuantity, address })
        .then(res => {
          if (res) {
            setDraftOrderCalculation(res)
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [address, programItems])

  return { draftOrderCalculation, isLoading }
}
