import { Flex } from 'antd'
import { PerkUpLink } from 'components/Typography/PerkUpLink'
import { MARKETING_HOSTNAME } from 'constants/hosts'
import { Text } from 'evergreen-ui'
import { isMobile } from 'react-device-detect'

export function Footer() {
  return (
    <Flex
      style={{ width: '100%' }}
      gap={isMobile ? 8 : 16}
      justify="space-between"
      align="baseline"
    >
      <Text size={300} color="muted">
        © PerkUp, Inc. {new Date().getFullYear()}
      </Text>

      <Flex gap={isMobile ? 8 : 16} align="baseline">
        <PerkUpLink
          to={`https://${MARKETING_HOSTNAME}/terms-of-service`}
          target="_blank"
          rel="noopener noreferrer"
          color="muted"
        >
          <Text size={300} color="muted">
            Terms of Service
          </Text>
        </PerkUpLink>
        <PerkUpLink
          to={`https://${MARKETING_HOSTNAME}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text size={300} color="muted">
            Privacy Policy
          </Text>
        </PerkUpLink>
      </Flex>
    </Flex>
  )
}
