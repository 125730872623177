import { USD } from 'constants/currencies'
import { Link, Pane, Paragraph, Text } from 'evergreen-ui'
import { useDisplayCurrency } from 'hooks'

export function PersonalFundsDisclaimer() {
  const displayCurrency = useDisplayCurrency()

  return (
    <Pane display="flex" flexDirection="column" gap={8}>
      <Paragraph>
        Personal funds allow you to top up your perk budget with your own money.
        These funds do not expire and can be refunded at any time.
      </Paragraph>
      <Paragraph>
        For more details, refer to our guide on{' '}
        <Link
          href="https://help.perkupapp.com/articles/287235-how-to-add-personal-funds-to-your-perkup-balance"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to add personal funds
        </Link>
      </Paragraph>

      {displayCurrency?.toLowerCase() !== USD && (
        <Text color="muted">
          Note: You will be charged the selected amount in USD.
        </Text>
      )}
    </Pane>
  )
}
