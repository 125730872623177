import { captureException } from '@sentry/react'
import InputNumber from 'antd/es/input-number'
import Spin from 'antd/es/spin'
import { MIN_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import { COLLECTION_IDS } from 'constants/productCollections'
import { IndividualContext } from 'context'
import { Pane, Text, toaster } from 'evergreen-ui'
import { DocumentReference, setDoc } from 'firebase/firestore'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { Program_Gift } from 'gen/perkup/v1/program_pb'
import { Action } from 'gen/perkup/v1/rules_pb'
import isNaN from 'lodash-es/isNaN'
import isNull from 'lodash-es/isNull'
import isUndefined from 'lodash-es/isUndefined'
import round from 'lodash-es/round'
import { useContext, useEffect, useState } from 'react'
import { numToDollars, toSentry } from 'utils'

export function EditableAmountCell({
  docRef,
  currentValue,
  extraStyles,
  currentGift,
  handleCreateCustomAction,
}: {
  docRef: DocumentReference<Action>
  currentValue: number
  currentGift: Program_Gift | undefined
  extraStyles?: Object
  handleCreateCustomAction?: Function
}) {
  const individual = useContext(IndividualContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [tempValue, setTempValue] = useState<number>(currentValue / 100)
  const [status, setStatus] = useState<'' | 'error' | 'warning' | undefined>(
    undefined
  )

  const isAdmin = individual?.role === Individual_Role.admin

  const isSingleGift =
    !!currentGift?.productVariantId || currentGift?.productIds.length === 1

  const disabled = !isAdmin || isSingleGift

  // Input validation. Prompt admin with UI.
  useEffect(() => {
    if (isNaN(tempValue) || isNull(tempValue) || isUndefined(tempValue)) {
      setStatus('error')
    } else {
      setStatus(undefined)
    }
  }, [tempValue])

  // On focus change, or on press enter, try to submit to firestore.
  function handleSubmit() {
    if (isNaN(tempValue) || isNull(tempValue) || isUndefined(tempValue)) return

    setIsEditing(false)

    if (handleCreateCustomAction) {
      handleCreateCustomAction()
    }

    const newAmount = round(tempValue * 100, 0)

    const isNewCollectionGift =
      currentGift?.productCollectionId &&
      COLLECTION_IDS.includes(currentGift.productCollectionId)

    if (isNewCollectionGift && newAmount < MIN_PROGRAM_BUDGET_AMOUNT) {
      toaster.warning('Amount must be at least $25 to send a collection')
      return
    }

    setDoc(
      docRef,
      {
        amount: newAmount,
      },
      { merge: true }
    ).catch(error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          EditableAmountCell: {
            docRef,
            currentValue,
            extraStyles,
            currentGift,
            handleCreateCustomAction,
            disabled,
          },
        },
      })
      toaster.warning('Something went wrong, try again or contact support.')
    })
  }

  // Display input component only if admin is in editing mode.
  if (isEditing) {
    return (
      <InputNumber
        addonBefore="$"
        min={0}
        value={tempValue}
        status={status}
        onBlur={() => handleSubmit()}
        onPressEnter={() => handleSubmit()}
        onChange={(value: number | null) => setTempValue(value || 0)}
        disabled={disabled}
      />
    )
  }

  // Regular text component. On click, enter editing mode.
  return (
    <Pane
      style={extraStyles}
      className="editable-text-cell"
      onClick={() => (!disabled ? setIsEditing(true) : undefined)}
      cursor={!disabled ? 'pointer' : 'not-allowed'}
    >
      {isNaN(tempValue) ? (
        <Spin size="small" />
      ) : (
        <Text style={extraStyles}>{numToDollars(currentValue, 2)}</Text>
      )}
    </Pane>
  )
}
