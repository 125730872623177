import { countryIso3to2Map } from 'constants/addresses'
import { US_ISO2, WORLDWIDE } from 'constants/countries'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'

interface ShippingTimeline {
  unit: 'business days' | 'weeks'
  minDuration: number
  maxDuration: number
  displayStr: string
}

/**
 * 'WORLDWIDE' or Iso2 country code
 */
type ShippingCountry = typeof WORLDWIDE | typeof US_ISO2

const timelineFunctions: {
  id: string
  fn: (
    variant: ProductVariant,
    shippingCountry: ShippingCountry
  ) => ShippingTimeline
  /**
   * Check function to determine if this timeline function should be applied.
   *
   * This function should only be run in the context of the array because it assumes that the previous check functions have already been executed.
   */
  check: (variant: ProductVariant, shippingCountry: ShippingCountry) => boolean
}[] = [
  {
    id: 'gift-cards',
    check: variant => {
      return variant.id.startsWith('plum_')
    },
    fn() {
      return {
        unit: 'business days',
        minDuration: 0,
        maxDuration: 0,
        displayStr: '0 to 30 minutes',
      }
    },
  },
  {
    id: 'physical-gifts',
    check: variant => {
      return variant.id.startsWith('shop_')
    },
    fn() {
      return {
        unit: 'weeks',
        minDuration: 2,
        maxDuration: 6,
        displayStr: '2-6 weeks',
      }
    },
  },

  {
    id: 'on-demand',
    check: variant => {
      return variant.sourceType !== ProductVariant_SourceType.fullPrepaid
    },
    fn(_, shippingCountry) {
      if (shippingCountry === US_ISO2) {
        return {
          unit: 'weeks',
          minDuration: 2,
          maxDuration: 3,
          displayStr: '2-3 weeks',
        }
      }

      return {
        unit: 'weeks',
        minDuration: 2,
        maxDuration: 6,
        displayStr: '2-6 weeks',
      }
    },
  },

  {
    id: 'bulk-swag',
    check: variant => {
      return !!variant.inventoryTracked && !!variant.inventoryQuantity
    },
    fn(_, shippingCountry) {
      if (shippingCountry === US_ISO2) {
        return {
          unit: 'weeks',
          minDuration: 2,
          maxDuration: 3,
          displayStr: '2-3 weeks',
        }
      }

      return {
        unit: 'weeks',
        minDuration: 2,
        maxDuration: 6,
        displayStr: '2-6 weeks',
      }
    },
  },

  {
    id: 'bulk-and-backOrder',
    check: variant => {
      return !!variant.inventoryTracked && !variant.inventoryQuantity
    },
    fn(_, shippingCountry) {
      if (shippingCountry === US_ISO2) {
        return {
          unit: 'weeks',
          minDuration: 4,
          maxDuration: 6,
          displayStr: '4-6 weeks',
        }
      }

      return {
        unit: 'weeks',
        minDuration: 4,
        maxDuration: 8,
        displayStr: '4-8 weeks',
      }
    },
  },
]

export function estimateShippingTimeline(
  variant: ProductVariant,
  shippingCountry: ShippingCountry
): ShippingTimeline | undefined {
  const iso2VariantShippingCountries = variant.shippingCountries
    .map(c => countryIso3to2Map[c.toLowerCase()])
    .filter(Boolean)

  // check if variant is available on the shipping address
  if (
    shippingCountry !== WORLDWIDE &&
    iso2VariantShippingCountries.length > 0 &&
    !iso2VariantShippingCountries.includes(shippingCountry.toLowerCase())
  ) {
    return undefined
  }

  const timelineFunction = timelineFunctions.find(({ check }) =>
    check(variant, shippingCountry)
  )

  if (timelineFunction) {
    return timelineFunction.fn(variant, shippingCountry)
  }

  return undefined
}
