import { Flex, Tabs } from 'antd'
import { updateAccountName, updateAccountPermissions } from 'api/databaseCalls'
import {
  AcccountBillingHistory,
  BackIconButton,
  BrandedTag,
  CreatedBy,
  Loader,
  PageHeader,
  PermissionsOverview,
} from 'components'
import { ThresholdNotifications } from 'components/ThresholdNotifications'
import {
  adminAccountPermissionsOptions,
  individualAccountPermissionsOptions,
  managerAccountPermissionsOptions,
} from 'constants/accounts'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, OrgContext, UserContext } from 'context'
import { Pane, toaster } from 'evergreen-ui'
import { Account_Permission } from 'gen/perkup/v1/account_pb'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { capitalize } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Permissions } from 'types/Permissions'
import { ACCOUNT_LEVELS, getHighestPermission } from 'utils/permissions'
import { getLastUrlSegment } from 'utils/urlUtils'
import { AccountOverview } from './components/AccountOverview'
import { BalanceActivityTable } from './components/BalanceActivityTable'
import { DeleteAccountButton } from './components/DeleteAccountButton'
import { useAccountBalances } from './hooks/useAccountBalances'

enum AccountBalanceTabs {
  BALANCE_ACTIVITY = 'balance activity',
  INVOICES = 'invoices',
  PERMISSIONS = 'permissions',
  NOTIFICATIONS = 'notifications',
}

interface TabOption {
  key: string
  tab: string
  route: string
  component: React.ReactNode
}

function getEligibleTabs({
  role,
  individualId,
  permissions,
  isDefaultAccount,
}: {
  role: Individual_Role
  individualId: string
  permissions: Record<string, Account_Permission>
  isDefaultAccount: boolean
}) {
  const tabsToRender: AccountBalanceTabs[] = [
    AccountBalanceTabs.BALANCE_ACTIVITY,
    AccountBalanceTabs.INVOICES,
  ]

  const highestAccountPermission = getHighestPermission(ACCOUNT_LEVELS)({
    role,
    individualId,
    permissions,
  })

  const hasFullAccess = highestAccountPermission === Account_Permission.full
  const hasOnlyViewAccess = highestAccountPermission === Account_Permission.view

  if (hasFullAccess && !isDefaultAccount) {
    tabsToRender.push(AccountBalanceTabs.PERMISSIONS)
  }

  if (!hasOnlyViewAccess) {
    tabsToRender.push(AccountBalanceTabs.NOTIFICATIONS)
  }

  return tabsToRender
}

function OrgAccount() {
  const navigate = useNavigate()
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)
  const { accountId } = useParams()
  const location = useLocation()
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const { outstandingFunds, unallocatedFunds, account } = useAccountBalances({
    accountId,
  })

  const isDefaultAccount = org?.defaultAccountId === accountId

  const defaultPermissions = account?.permissions || {}

  // Check to see if this is a redirect back from Checkout
  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.get('success')) {
      toaster.success('Funds added! You will receive an email confirmation.', {
        id: '1234',
      })
    }
    if (query.get('canceled')) {
      toaster.warning('Order canceled', {
        description: "Continue to explore and add funds when you're ready.",
      })
    }
  }, [user.id])

  if (isLoadingDelete) return <Loader />

  if (!accountId || !account) return null

  const handleUpdatePermissions = (newPermissions: Permissions) => {
    updateAccountPermissions({
      orgId: org.id,
      accountId,
      permissions: newPermissions as Record<string, Account_Permission>,
    })
  }

  const tabToComponentMap = {
    [AccountBalanceTabs.BALANCE_ACTIVITY]: (
      <BalanceActivityTable account={account} />
    ),
    [AccountBalanceTabs.INVOICES]: (
      <Flex style={{ maxWidth: 512 }}>
        <AcccountBillingHistory accountId={accountId} />
      </Flex>
    ),
    [AccountBalanceTabs.PERMISSIONS]: (
      <Pane maxWidth={512}>
        <PermissionsOverview
          currentPermissions={defaultPermissions}
          onPermissionsChange={handleUpdatePermissions}
          adminPermissionOptions={adminAccountPermissionsOptions}
          managerPermissionOptions={managerAccountPermissionsOptions}
          individualPermissionOptions={individualAccountPermissionsOptions}
          isModal={false}
        />
      </Pane>
    ),
    [AccountBalanceTabs.NOTIFICATIONS]: (
      <ThresholdNotifications account={account} />
    ),
  }

  const tabOptions: TabOption[] = getEligibleTabs({
    role: individual.role,
    individualId: individual?.id,
    permissions: defaultPermissions,
    isDefaultAccount,
  }).map((tab, index) => {
    const route = index === 0 ? '/*' : tab.split(' ').join('-')

    return {
      key: tab,
      tab,
      route,
      component: tabToComponentMap[tab],
    }
  })

  const highestAccountPermission = getHighestPermission(ACCOUNT_LEVELS)({
    role: individual.role,
    individualId: individual.id,
    permissions: account.permissions,
  })

  // @todo This feels super weird. The way we're determining if a user has full access to an account is by checking if a tab is being rendered... very backwards
  const hasFullAccess = tabOptions.find(
    tabOption => tabOption.tab === AccountBalanceTabs.PERMISSIONS
  )

  // Should only show tabs if tabOptions has more than 1 option, else render the component for the tab

  const accountRoute = getLastUrlSegment(location)

  const activeTabKey =
    accountRoute && tabOptions.find(tabOption => tabOption.tab === accountRoute)
      ? accountRoute
      : tabOptions[0]?.tab

  const accountCreated = account?.created?.toDate()
  const accountIndividualId = account?.individualId

  const handleEditTitle = (name: string) => {
    updateAccountName({ orgId: org.id, accountId, name }).then(() =>
      toaster.success('Account name updated')
    )
  }

  return (
    <Pane display="flex" gap={8}>
      <BackIconButton to={DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT} />
      <Pane width="100%" display="flex" flexDirection="column" gap={32}>
        <Helmet>
          <title>{`${account?.name || 'Account'} — PerkUp`}</title>
        </Helmet>
        <Pane>
          <PageHeader
            title={account.name}
            onEditTitle={hasFullAccess ? handleEditTitle : undefined}
            vlogLink="https://www.loom.com/embed/6c0b30db583c4fc7921db3d881d5b102?sid=579eb8d0-0925-4b20-93de-97b9d103b768"
            blogLink="https://help.perkupapp.com/articles/633176-understanding-your-company-balance"
            blogLinkLabel="How account balances work"
            buttonsToRenderOnRight={
              <Pane display="flex" gap={16} alignItems="center">
                {hasFullAccess && (
                  <DeleteAccountButton
                    accountId={account.id}
                    setIsLoadingDelete={setIsLoadingDelete}
                  />
                )}
              </Pane>
            }
            buttonsToRenderOnLeft={
              isDefaultAccount ? <BrandedTag>Default</BrandedTag> : null
            }
          />
          {!isDefaultAccount && accountCreated && accountIndividualId && (
            <CreatedBy createdDate={accountCreated}>
              <CreatedBy.Individual individualId={accountIndividualId} />
            </CreatedBy>
          )}
        </Pane>
        <Flex vertical gap={32}>
          <AccountOverview
            account={account}
            outstandingBalance={outstandingFunds}
            unallocatedFunds={unallocatedFunds}
            highestAccess={highestAccountPermission}
          />

          <Flex vertical>
            <Tabs
              items={tabOptions.map(option => {
                const label = capitalize(option.tab)
                return { label, key: option.key }
              })}
              onChange={key => navigate(key.split(' ').join('-'))}
              className="customTabs"
              activeKey={activeTabKey}
            />

            <Routes>
              {tabOptions.map(option => {
                return (
                  <Route
                    key={option.key}
                    path={option.route}
                    element={option.component}
                  />
                )
              })}
            </Routes>
          </Flex>
        </Flex>
      </Pane>
    </Pane>
  )
}

export default OrgAccount
