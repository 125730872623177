import { ProductGridCard } from 'components'
import { GiftCard } from 'components/AlgoliaProducts'
import { ExchangeRateContext, UserContext } from 'context'
import { Pane } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_Provider,
} from 'gen/perkup/v1/product_variant_pb'
import { isEmpty } from 'lodash-es'
import { useContext, useMemo } from 'react'
import { useInstantSearch } from 'react-instantsearch'
import { numToDollars } from 'utils'
import {
  algoliaResultsToProductVariants,
  isNearCashProductVariant,
} from 'utils/productVariant'
import { CarouselSkeleton } from './CarouselSkeleton'
import { CustomCarousel } from './CustomCarousel'

export function ProductVariantCarousel({
  handleCardClick,
  handleSeeMoreClick,
  showSeeAllButton = false,
  showHeading = true,
  header = 'Gift cards',
  handleCTAText = 'See all',
}: {
  handleCardClick: ({
    productId,
    productVariantId,
  }: {
    productId: string
    productVariantId: string
  }) => void
  handleSeeMoreClick: () => void
  showSeeAllButton?: boolean
  showHeading?: boolean
  header?: string
  handleCTAText?: string
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)
  const displayCurrency = user?.displayCurrency

  const { results, status } = useInstantSearch()
  const products: ProductVariant[] = useMemo(() => {
    return algoliaResultsToProductVariants(results)
  }, [results])

  const isLoading = status === 'loading' || status === 'stalled'

  if (isLoading) {
    return <CarouselSkeleton skeletonNumber={8} />
  }

  if (isEmpty(products)) return null

  const productVariantCarouselItems = products.map((product, index) => {
    const {
      productId,
      id: productVariantId,
      imageUrl,
      vendor,
      productName,
    } = product

    const price =
      numToDollars(
        Number(product.amount) * exchangeRate,
        2,
        true,
        displayCurrency
      ) || undefined

    const providerString = ProductVariant_Provider[product.provider]
    const eventProps = { ...product, provider: providerString }

    if (isNearCashProductVariant(product)) {
      return (
        <Pane margin={16} key={productVariantId}>
          <GiftCard
            productVariantId={productVariantId}
            productId={productId}
            productImage={imageUrl}
            eventProps={eventProps}
            position={index + 1 + results.page * results.hitsPerPage}
            queryID={results.queryID}
            onClick={() => handleCardClick({ productId, productVariantId })}
            cardWidth={304}
          />
        </Pane>
      )
    }
    return (
      <Pane margin={16} key={productVariantId}>
        <ProductGridCard
          onClick={() => handleCardClick({ productId, productVariantId })}
          price={price}
          productVariantId={productVariantId}
          productId={productId}
          productImage={imageUrl}
          eventProps={eventProps}
          position={index + 1 + results.page * results.hitsPerPage}
          queryID={results.queryID}
          vendor={vendor}
          productName={productName}
          cardWidth={304}
          tags={product.tags}
          tagline={product.productTagline}
        />
      </Pane>
    )
  })

  return (
    <CustomCarousel
      handleSeeMoreClick={handleSeeMoreClick}
      showHeading={showHeading}
      header={header}
      handleCTAText={handleCTAText}
      showSeeAllButton={showSeeAllButton}
    >
      {productVariantCarouselItems}
    </CustomCarousel>
  )
}
