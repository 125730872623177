import { Flex } from 'antd'
import { GoogleAuthButton } from 'components/Buttons'
import { SignUpTerms } from 'components/terms/signup-terms'
import { PerkUpLink, PerkUpName } from 'components/Typography'
import { CREATE_ORG } from 'constants/params'
import { MARKETING_URL } from 'constants/perkupLinks'
import * as ROUTES from 'constants/routes'
import { PROGRAM_ID } from 'constants/sessionOrLocalStorage'
import { Heading, Text } from 'evergreen-ui'
import { isMobile } from 'react-device-detect'
import {
  createSearchParams,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { EmailAuthForm } from './email-auth-form'

export function AuthenticationForm() {
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const { programId } = useParams()

  const createOrgParams = searchParams.get(CREATE_ORG)

  const isSignUpPage = location.pathname === ROUTES.SIGN_UP

  const content = {
    title: isSignUpPage ? 'Get Started' : 'Welcome to PerkUp',
    subTitle: isSignUpPage ? 'Create a free account or' : '',
    googleLabel: isSignUpPage ? 'Sign up with Google' : 'Sign in with Google',
    otherPageLabel: isSignUpPage
      ? 'Already have an account?'
      : "Don't have an account?",
    otherPageRoute: isSignUpPage ? ROUTES.SIGN_IN : ROUTES.SIGN_UP,
    emailCTA: isSignUpPage ? 'Sign up' : 'Sign in',
  }

  const search = createOrgParams
    ? createSearchParams({ [`${CREATE_ORG}`]: createOrgParams }).toString()
    : ''

  if (programId) {
    window.localStorage.setItem(PROGRAM_ID, programId)
  }

  return (
    <Flex vertical align="center" gap={32}>
      <a
        href={MARKETING_URL}
        style={{ alignSelf: isMobile ? undefined : 'start' }}
      >
        <PerkUpName height={21} />
      </a>

      <Heading size={900} alignSelf={isMobile ? undefined : 'start'}>
        {content.title}
      </Heading>

      <Flex vertical gap={8} align="center">
        <GoogleAuthButton>{content.googleLabel}</GoogleAuthButton>

        <Heading size={200} marginY={8}>
          OR
        </Heading>

        <EmailAuthForm ctaLabel={content.emailCTA} />

        <SignUpTerms />
      </Flex>

      <PerkUpLink
        to={`${content.otherPageRoute}${search}`}
        style={{ alignSelf: isMobile ? undefined : 'start' }}
      >
        <Text>{content.otherPageLabel}</Text>
      </PerkUpLink>
    </Flex>
  )
}
