import { Flex } from 'antd'
import { ProductCollectionCard, ProductCollectionsGrid } from 'components'
import OrderConfirmation from 'components/Amazon/OrderConfirmation'
import { BalanceSubHeading } from 'components/Typography/BalanceSubHeading'
import { OrgContext } from 'context'
import { Heading } from 'evergreen-ui'
import { useOrgUserSwagBalance, usePublicProductCollections } from 'hooks'
import { useContext } from 'react'
import { useNavigate } from 'react-router'

export function SwagStoreCollectionsPage() {
  const org = useContext(OrgContext)

  const navigate = useNavigate()

  const { collections } = usePublicProductCollections()
  const { orgUserSwagBalance } = useOrgUserSwagBalance()

  return (
    <Flex vertical gap={24} style={{ width: '100%' }}>
      <OrderConfirmation />

      <Flex vertical gap={16}>
        <Heading size={900}>{org.name} Swag Stores</Heading>
        {orgUserSwagBalance > 0 && (
          <BalanceSubHeading amount={orgUserSwagBalance} category="swag" />
        )}
      </Flex>

      <ProductCollectionsGrid>
        {collections.map(collection => {
          if (Number(collection.productsCount) === 0) return null // Since we're in the member swag store, we don't want to show collections with no products
          return (
            <ProductCollectionCard
              key={collection.id}
              productCollection={collection}
              onClick={() => navigate(collection.id)}
            >
              <ProductCollectionCard.Details hidePrices />
            </ProductCollectionCard>
          )
        })}
      </ProductCollectionsGrid>
    </Flex>
  )
}
