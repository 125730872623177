import { Account_Permission } from 'gen/perkup/v1/account_pb'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import {
  Permission,
  PERMISSION_ADMIN,
  PERMISSION_MANAGER,
} from 'types/Permissions'

/**
 * Use this everywhere when calling getHighestPermission for accounts.
 * The order is very important, this is the specific hierarchy that we have for accounts.
 */
export const ACCOUNT_LEVELS = [
  Account_Permission.full,
  Account_Permission.send,
  Account_Permission.view,
]
/**
 * Use this everywhere when calling getHighestPermission for product collections.
 * The order is very important, this is the specific hierarchy that we have for product collections.
 */
export const PRODUCT_COLLECTION_LEVELS = [
  ProductCollection_Permission.full,
  ProductCollection_Permission.send,
  ProductCollection_Permission.view,
]

/**
 *
 * @param levels The order / hierarchy in which the permissions are checked.
 * @returns A function that takes in the role, individualId, and permissions. That function then returns the highest permission based on the hierarchy of levels passed in.
 */
export function getHighestPermission<T extends Permission>(levels: T[]) {
  return ({
    role,
    individualId,
    permissions,
  }: {
    role: Individual_Role
    individualId: string
    permissions: Record<string, T>
  }) => {
    const adminPermission =
      (role === Individual_Role.admin && permissions[PERMISSION_ADMIN]) ||
      undefined
    const managerPermission =
      (role === Individual_Role.manager && permissions[PERMISSION_MANAGER]) ||
      undefined
    const individualPermission = permissions[individualId] || undefined

    const permissionBank = [
      adminPermission,
      managerPermission,
      individualPermission,
    ]

    // Depending on the heirarchy of levels passed, we immediately return the first hit. This is why the order of levels passed in is important!
    for (let i = 0; i < levels.length; i += 1) {
      const level = levels[i]
      if (permissionBank.includes(level)) return level
    }

    return undefined
  }
}
