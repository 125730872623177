import {
  ArrowLeftOutlined,
  ClockCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { Button, Flex, Tooltip } from 'antd'
import Progress from 'antd/es/progress'
import { noMemberAsset } from 'assets'
import {
  ConfettiCelebration,
  Footer,
  Loader,
  PerkEmptyState,
  WithCountryNavbar,
} from 'components'
import {
  DASHBOARD,
  DEFAULT_ROUTES,
  REWARD_ACCEPTANCE_CHECKOUT,
  REWARD_ACCEPTANCE_CONFIRMATION,
  REWARD_ACCEPTANCE_REVEAL,
} from 'constants/routes'
import { PROGRAM_ID } from 'constants/sessionOrLocalStorage'
import { IndividualContext, MemberContext, ProgramContext } from 'context'
import { Pane } from 'evergreen-ui'
import { AnimatePresence } from 'framer-motion'
import { Member_ConvertedTo, ProgramStatus } from 'gen/perkup/v1/program_pb'
import {
  useDefaultOrgColors,
  useListenToMember,
  useListenToProgram,
  useUserById,
} from 'hooks'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router'
import { Outlet, To } from 'react-router-dom'
import { getDateDisplayString } from 'utils'
import {
  getPageProgressPercent,
  isRewardAcceptanceIndexRoute,
} from 'utils/rewardAcceptance'
import { Checkout } from './Checkout'
import { RewardAcceptanceConfirmation } from './RewardAcceptanceConfirmation'
import { RewardAcceptanceLandingCard } from './RewardAcceptanceLandingCard'
import { RewardAcceptanceRevealed } from './RewardAcceptanceRevealed'

function RewardPreviewLayout() {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)
  const location = useLocation()
  const navigate = useNavigate()

  const progressBarPercent = getPageProgressPercent(location.pathname)
  const { defaultColor, backgroundColor } = useDefaultOrgColors()
  const isIndexRoute = isRewardAcceptanceIndexRoute()

  const memberGiftSwappedForCash =
    member?.convertedTo === Member_ConvertedTo.nearCash

  const isGift = !!program?.gift && !memberGiftSwappedForCash

  // If isMonetaryGift or is index route, use org's bg col
  const isThemedWrapper = !isGift || isIndexRoute
  const wrapperBackground = isThemedWrapper ? backgroundColor : 'white'

  const handleNavigateBack = () => {
    navigate(-1 as To, {
      state: { replace: true },
    })
  }

  return (
    <Pane
      background={wrapperBackground}
      display="flex"
      flexDirection="column"
      height="100vh"
      overflowY="auto"
      maxWidth="100vw"
    >
      <ConfettiCelebration />

      {/** Progress bar */}
      <Pane
        position="absolute"
        top={-10}
        width="100%"
        zIndex={10}
        overflowX="hidden"
        flexGrow={1}
      >
        <Progress
          percent={progressBarPercent}
          status="active"
          showInfo={false}
          strokeColor={defaultColor}
          style={{ marginBottom: 0 }}
        />
      </Pane>

      {/** Navbar */}
      <Pane
        display="flex"
        gap={16}
        paddingX={isMobile ? 16 : 32}
        paddingY={16}
        alignItems="center"
      >
        <Tooltip title="Go back">
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={handleNavigateBack}
          />
        </Tooltip>

        <Pane flex={1}>
          <WithCountryNavbar
            isCuratedGift
            onCompanyLogoClick={() => navigate(DASHBOARD)}
          />
        </Pane>
      </Pane>

      {/** Page Content */}
      <Pane
        display="flex"
        flex={1}
        justifyContent="center"
        paddingX={isMobile ? 16 : 32}
        paddingY={16}
      >
        <AnimatePresence mode="wait">
          <Outlet />
        </AnimatePresence>
      </Pane>

      <Pane margin={16}>
        <Footer />
      </Pane>
    </Pane>
  )
}

export default function RewardAcceptance() {
  const location = useLocation()
  const navigate = useNavigate()
  const individual = useContext(IndividualContext)
  const { email, id: individualId } = individual
  const { programId } = useParams()
  const { program, hasLoaded: hasLoadedProgram } = useListenToProgram({
    programId,
  })
  const { member, hasLoaded: hasLoadedMember } = useListenToMember({
    programId,
    memberId: individualId,
  })

  const programInStorage = window.localStorage.getItem(PROGRAM_ID)
  if (programInStorage) {
    // If getting here from storage, clear it
    window.localStorage.removeItem(PROGRAM_ID)
  }

  const ownerId = program?.ownerId

  const { user: programOwner } = useUserById({ userId: ownerId })

  // Allow preview for any reward that starts in the future but is already sent in email
  const today = new Date()
  const startsOn = program?.startsOn

  const programStartsInFuture = startsOn ? today < startsOn.toDate() : false

  const programIsLive =
    program?.status === ProgramStatus.active || programStartsInFuture

  const memberUnenrolled = member?.enrolled === false

  const showLoader = !hasLoadedMember || !hasLoadedProgram

  if (showLoader) return <Loader />

  if (!member || memberUnenrolled) {
    return (
      <Flex vertical gap={128}>
        <Pane paddingX={isMobile ? 16 : 32} paddingY={16}>
          <WithCountryNavbar
            isCuratedGift
            onCompanyLogoClick={() => navigate(DEFAULT_ROUTES.CARD.ROOT)}
          />
        </Pane>
        <Pane margin="auto">
          <PerkEmptyState
            header={`${email} isn't listed as a recipient of this gift`}
            description={`Double-check your email addresses or the link. If you this this is a mistake, reach out to ${
              programOwner?.profile?.email || 'your admin'
            }.`}
            iconUrl={noMemberAsset}
            ctaProps={{
              type: 'default',
              children: 'Go to dashboard',
              onClick: () => navigate(DEFAULT_ROUTES.CARD.ROOT),
            }}
          />
        </Pane>
      </Flex>
    )
  }

  let errorMessage = 'Reward not found'
  let description = 'Try logging into your account or contact support.'
  if (!program) {
    errorMessage = 'Could not find program'
  } else if (!programIsLive) {
    errorMessage = 'This gift has expired'
    const expirationString = getDateDisplayString({ program })
    if (expirationString) {
      description = expirationString
    }
  }

  const rewardNotFound = !program || !programIsLive

  if (rewardNotFound) {
    const Icon = !programIsLive ? ClockCircleOutlined : SearchOutlined
    return (
      <Flex style={{ height: '90vh', alignItems: 'center' }}>
        <PerkEmptyState
          header={errorMessage}
          description={description}
          iconNode={<Icon style={{ fontSize: 42 }} />}
          ctaProps={{
            onClick: () => navigate(DEFAULT_ROUTES.CARD.ROOT),
            children: 'View account',
            type: 'primary',
          }}
        />
      </Flex>
    )
  }

  return (
    <ProgramContext.Provider value={program}>
      <MemberContext.Provider value={member}>
        <Helmet>
          <title>
            {program.name} | from {program.email?.fromName}
          </title>
        </Helmet>
        <Routes location={location}>
          <Route element={<RewardPreviewLayout />}>
            <Route path="/" element={<RewardAcceptanceLandingCard />} />
            <Route
              path={`${REWARD_ACCEPTANCE_REVEAL}/*`}
              element={<RewardAcceptanceRevealed />}
            />
            <Route
              path={`${REWARD_ACCEPTANCE_CHECKOUT}/*`}
              element={<Checkout />}
            />
            <Route
              path={REWARD_ACCEPTANCE_CONFIRMATION}
              element={<RewardAcceptanceConfirmation />}
            />
          </Route>
        </Routes>
      </MemberContext.Provider>
    </ProgramContext.Provider>
  )
}
