import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import {
  addDoc,
  collection,
  deleteField,
  doc,
  FieldValue,
  getFirestore,
  PartialWithFieldValue,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {
  Individual,
  Individual_PrivacySettings,
  Individual_Role,
  Individual_Status,
} from 'gen/perkup/v1/individual_pb'
import {
  Notification_Type,
  NotificationSetting_RuleGroup,
} from 'gen/perkup/v1/notification_pb'
import { toSentry } from 'utils'
import { storedId } from 'utils/firestore'
import { GetIndividualByEmail } from '../reads'

export function updateIndividualStatus({
  orgId,
  individualId,
  status,
}: {
  orgId: string
  individualId: string
  status: Individual_Status
}) {
  if (!orgId || !individualId || !status) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { status }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualStatus: {
          orgId,
          individualId,
          status,
        },
      },
    })
  })
}

export function updateIndividualIsActive({
  orgId,
  individualId,
  isActive,
}: {
  orgId: string
  individualId: string
  isActive: boolean
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { isActive }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualIsActive: {
          orgId,
          individualId,
          isActive,
        },
      },
    })
  })
}

/** Pass in deleteField() as FieldValue if you wish to have the fields removed from Firestore */
export function updateIndividualRemovedField({
  orgId,
  individualId,
  removedBy,
  removedAt,
}: {
  orgId: string
  individualId: string
  removedBy: string | FieldValue
  removedAt: Timestamp | FieldValue
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { removedAt, removedBy }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualIsActive: {
          orgId,
          individualId,
          removedAt,
          removedBy,
        },
      },
    })
  })
}

export async function createIndividualByEmail({
  email,
  orgId,
  role,
}: {
  email: string
  orgId: string
  role: Individual_Role
}) {
  const cleanedEmail = email.toLowerCase().trim()
  const db = getFirestore()
  const colRef = collection(
    db,
    `organizations/${orgId}/individuals`
  ).withConverter(storedId(Individual))

  const existingIndividual = await GetIndividualByEmail({
    orgId,
    email: cleanedEmail,
  })

  if (existingIndividual) return existingIndividual

  return addDoc(
    colRef,
    new Individual({
      email: cleanedEmail,
      created: Timestamp.now(),
      status: Individual_Status.invited,
      orgId,
      role,
    })
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        createIndividualByEmail: {
          email,
          orgId,
          role,
        },
      },
    })
  })
}

export function updateIndividualManager({
  orgId,
  individualId,
  managerId,
}: {
  orgId: string
  individualId: string
  managerId: string
}) {
  if (!orgId || !individualId || !managerId) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { managerId }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualManager: {
          orgId,
          individualId,
          managerId,
        },
      },
    })
  })
}

export async function removeIndividualManager({
  orgId,
  individualId,
}: {
  orgId: string
  individualId: string
}) {
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))

  await setDoc(docRef, { managerId: deleteField() }, { merge: true }).catch(
    error => {
      console.error(error)
      captureException(toSentry(error), {
        contexts: {
          removeIndividualManager: {
            orgId,
            individualId,
          },
        },
      })
    }
  )
}

export function updateIndividualBlockSync({
  orgId,
  individualId,
  blockSync,
}: {
  orgId: string
  individualId: string
  blockSync: boolean
}) {
  if (!orgId || !individualId) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { blockSync }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualBlockSync: {
          orgId,
          individualId,
          blockSync,
        },
      },
    })
  })
}

export async function updateIndividualDate({
  orgId,
  individualId,
  date,
  fieldKey,
}: {
  orgId: string
  individualId: string
  date: Date
  fieldKey: string
}) {
  if (!orgId || !individualId || !date || !fieldKey) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(
    docRef,
    { [fieldKey]: Timestamp.fromDate(date) },
    { merge: true }
  ).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualDate: {
          orgId,
          individualId,
          date,
          fieldKey,
        },
      },
    })
  })
}

export async function updateIndividualRole({
  orgId,
  individualId,
  role,
}: {
  orgId: string
  individualId: string
  role: Individual_Role
}) {
  if (!orgId || !individualId || !role) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { role }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualRole: {
          orgId,
          individualId,
          role,
        },
      },
    })
  })
}

export async function updatePrimaryEmail({
  orgId,
  individualId,
  email,
}: {
  orgId: string
  individualId: string
  email: string
}) {
  if (!orgId || !individualId || !email) return
  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, { email }, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updatePrimaryEmail: {
          orgId,
          individualId,
          email,
        },
      },
    })
  })
}

export function updateIndividualPrivacySettings({
  orgId,
  individualId,
  privacySettings,
  notificationSettingRuleGroup,
}: {
  orgId: string
  individualId: string
  privacySettings: Individual_PrivacySettings
  notificationSettingRuleGroup: NotificationSetting_RuleGroup
}) {
  if (!orgId || !individualId) return

  let newPrivacySettings: Individual_PrivacySettings | FieldValue =
    privacySettings
  if (
    privacySettings.allowAdmins &&
    privacySettings.allowManagers &&
    privacySettings.allowMembers
  ) {
    newPrivacySettings = deleteField()
  }
  // If everything is true (all allowed), we want to delete the field.
  const updatedPrivacySettings: PartialWithFieldValue<Individual> = {
    privacySettings: {
      [NotificationSetting_RuleGroup[notificationSettingRuleGroup]]:
        newPrivacySettings,
    },
  }

  const db = getFirestore()
  const docRef = doc(
    db,
    `organizations/${orgId}/individuals/${individualId}`
  ).withConverter(storedId(Individual))
  setDoc(docRef, updatedPrivacySettings, { merge: true }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualPrivacySettings: {
          orgId,
          individualId,
        },
      },
    })
  })
}

export async function updateIndividualBlockedNotifications({
  orgId,
  individualId,
  block,
  notificationType,
}: {
  orgId: string
  individualId: string
  block: boolean
  notificationType: Notification_Type
}) {
  try {
    const db = getFirestore()
    const docRef = doc(
      db,
      `organizations/${orgId}/individuals/${individualId}`
    ).withConverter(storedId(Individual))
    await updateDoc(docRef, {
      [`notificationSettings.${Notification_Type[notificationType]}`]: block
        ? { block }
        : deleteField(),
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        updateIndividualNotificationSettings: {
          orgId,
          individualId,
        },
      },
    })
  }
}
