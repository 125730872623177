import { ListProductVariantsByProgramItems } from 'api/databaseCalls'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { useEffect, useState } from 'react'

export function useItemsToVariants({ items }: { items: Item[] }) {
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!items) return
    setIsLoading(true)
    ListProductVariantsByProgramItems({ programItems: items })
      .then(productVariants => {
        if (!productVariants) return
        setProductVariants(productVariants)
      })
      .finally(() => setIsLoading(false))
  }, [items])

  return { productVariants, isLoading }
}
