import { Fulfillment, OrderDetails } from 'api/tinybirdCalls/types'
import { GHOST_FULFILLMENT_NAME } from 'constants/orders'
import { OrgTransaction } from 'gen/perkup/v1/organization_pb'
import {
  Order,
  OrderFulfillment,
  OrderFulfillment_FulfillmentStatus_Enum,
} from 'gen/perkup/v1/vendor_pb'
import { compact, isEmpty } from 'lodash-es'
import { TrackingInfo } from 'types'

export const getOrderTrackingNumbers = ({
  orgTransactions,
  orderFulfillments,
}: {
  orgTransactions: OrgTransaction[]
  orderFulfillments: OrderFulfillment[]
}): TrackingInfo[] => {
  if (!isEmpty(orderFulfillments)) {
    return compact(
      orderFulfillments?.map(fulfillment => {
        const { trackingNumber, trackingUrl } = fulfillment

        if (isEmpty(trackingNumber) || isEmpty(trackingUrl)) return undefined

        return {
          number: trackingNumber,
          url: trackingUrl,
        }
      })
    )
  }
  if (!isEmpty(orgTransactions)) {
    return compact(
      orgTransactions.map(transaction => {
        if (!transaction?.shipping || !transaction?.shipping?.trackingUrl)
          return undefined
        return {
          number: transaction?.shipping.trackingNumber,
          url: transaction?.shipping.trackingUrl,
        }
      })
    )
  }

  return []
}

export const buildGhostFulfillment1 = (
  itemsOnOrder: OrderDetails['items'],
  fulfillments: OrderDetails['fulfillments']
): Fulfillment | undefined => {
  const itemToQuantity = new Map<string, number>()

  itemsOnOrder.forEach(item => {
    if (!item.productVariantId || !item.quantity) return
    itemToQuantity.set(item.productVariantId, item.quantity)
  })

  fulfillments.forEach(fulfillment => {
    fulfillment.lineItems.forEach(lineItem => {
      const { variantId, quantity } = lineItem
      if (!variantId || !quantity) return
      const currentRemainingQty = itemToQuantity.get(variantId)

      if (!currentRemainingQty) return

      const newRemainingQty = currentRemainingQty - quantity

      if (newRemainingQty === 0) {
        itemToQuantity.delete(variantId)
        return
      }

      itemToQuantity.set(variantId, newRemainingQty)
    })
  })

  // Everything was in a fulfillment!
  if (itemToQuantity.size === 0) return undefined

  // We went though all the fulfillments and there are still items missing , return just those items
  const ghostFulfillment: Fulfillment = {
    name: GHOST_FULFILLMENT_NAME,
    lineItems: Array.from(itemToQuantity.entries()).map(
      ([variantId, quantity]) => ({
        variantId,
        quantity,
      })
    ),
    status: 'pending',
    trackingNumber: null,
    trackingUrl: null,
    trackingCompany: null,
  }

  return ghostFulfillment
}

export const buildGhostFulfillment2 = (
  itemsOnOrder: Order['items'],
  fulfillments: OrderFulfillment[]
): OrderFulfillment | undefined => {
  const itemToQuantity = new Map<string, number>()

  itemsOnOrder.forEach(item => {
    if (!item.productVariantId || !item.quantity) return
    itemToQuantity.set(item.productVariantId, item.quantity)
  })

  fulfillments.forEach(fulfillment => {
    fulfillment.lineItems.forEach(lineItem => {
      const { variantId, quantity } = lineItem
      if (!variantId || !quantity) return
      const currentRemainingQty = itemToQuantity.get(variantId)

      if (!currentRemainingQty) return

      const newRemainingQty = currentRemainingQty - quantity

      if (newRemainingQty === 0) {
        itemToQuantity.delete(variantId)
        return
      }

      itemToQuantity.set(variantId, newRemainingQty)
    })
  })

  // Everything was in a fulfillment!
  if (itemToQuantity.size === 0) return undefined

  // We went though all the fulfillments and there are still items missing , return just those items
  const ghostFulfillment = new OrderFulfillment({
    name: GHOST_FULFILLMENT_NAME,
    lineItems: Array.from(itemToQuantity.entries()).map(
      ([variantId, quantity]) => ({
        variantId,
        quantity,
      })
    ),
    status: OrderFulfillment_FulfillmentStatus_Enum.pending,
    trackingNumber: undefined,
    trackingUrl: undefined,
    trackingCompany: undefined,
  })

  return ghostFulfillment
}

export const calculateOrderTotal = (items: OrderDetails['items']) => {
  return items.reduce(
    (acc, item) => acc + (item.amount ?? 0) * (item.quantity ?? 0),
    0
  )
}
