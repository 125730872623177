import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Tooltip } from 'antd'
import {
  AlgoliaBudgetSelect,
  AlgoliaCountryFilter,
  ProductCount,
} from 'components'
import {
  COLLECTION_IDS,
  COLLECTION_ID_TO_NAME,
  FEATURED_COLLECTION_ID,
} from 'constants/productCollections'
import { GlobeIcon, Heading, Text } from 'evergreen-ui'
import { useAlgoliaBudgetOptions } from 'hooks'
import { toNumber } from 'lodash-es'
import {
  UseNumericMenuProps,
  useClearRefinements,
  useInstantSearch,
  useMenu,
} from 'react-instantsearch'
import { compareAlgoliaCountryFacets } from 'utils'

export function ProductCollectionNavigation({
  onConfirmProductCollection,
  buttonsOnRight,
  buttonsOnLeft,
  defaultSelectedBudget,
  disableBudgetSelect = false,
  hideProductCount = false,
  stickyNavTopAmount,
}: {
  onConfirmProductCollection: (
    productCollectionId: string,
    programBudget: number
  ) => void
  buttonsOnRight?: React.ReactNode
  buttonsOnLeft?: React.ReactNode
  defaultSelectedBudget?: UseNumericMenuProps['items'][0]
  disableBudgetSelect?: boolean
  hideProductCount?: boolean
  stickyNavTopAmount?: number | undefined
}) {
  const { indexUiState } = useInstantSearch()

  const activeAlgoliaCollectionId =
    indexUiState?.menu?.collectionIds || FEATURED_COLLECTION_ID

  const { refine: clearCollectionRefinements } = useClearRefinements({
    includedAttributes: ['collectionIds'],
  })

  const { refine: refineCollections } = useMenu({
    attribute: 'collectionIds',
  })

  const { items: algoliaCountries } = useMenu({
    attribute: 'shippingCountries',
    limit: 200,
    sortBy: compareAlgoliaCountryFacets,
  })

  const { activeAlgoliaBudget } = useAlgoliaBudgetOptions({
    defaultSelectedBudget,
  })

  const handleCollectionClick = (collectionId: string) => {
    if (collectionId === activeAlgoliaCollectionId) {
      return
    }

    // User selected our featured tab
    if (collectionId === FEATURED_COLLECTION_ID) {
      clearCollectionRefinements()
    } else {
      // User selected a specific collection
      refineCollections(collectionId)
    }

    // Update program with user's selection
    if (!activeAlgoliaBudget) return
    const programBudget =
      toNumber(
        activeAlgoliaBudget.label.substring(
          activeAlgoliaBudget.label.indexOf('$') + 1
        )
      ) * 100
    onConfirmProductCollection(collectionId, programBudget)
  }

  /**
   *
   * @todo bring back when we have intentional CTA clicks
   */
  // const handleConfirmCollection = () => {
  //   const productCollection = productCollections.find(
  //     collection => collection.id === activeAlgoliaCollection?.value
  //   )

  //   if (!productCollection || !activeAlgoliaBudget) return

  //   const programBudget =
  //     toNumber(activeAlgoliaBudget.label.replace('$', '')) * 100
  //   onConfirmProductCollection(productCollection, programBudget)
  // }

  return (
    <Flex
      vertical
      gap={8}
      style={{
        position: 'sticky',
        backgroundColor: 'white',
        zIndex: 1,
        top: stickyNavTopAmount === undefined ? 8 : stickyNavTopAmount,
        transition: 'top 0.2s ease-in-out',
      }}
    >
      {/* fake bg to top padding */}
      <div
        style={{
          height: 8,
          position: 'absolute',
          width: '100%',
          backgroundColor: 'white',
          zIndex: -1,
          top: -8,
        }}
      />
      <Flex justify="space-between" align="start" gap={16}>
        <Flex gap={8} align="center">
          {buttonsOnLeft}
          <Heading size={200} fontWeight={600} lineHeight="12px" color="black">
            Budget:
          </Heading>
          <AlgoliaBudgetSelect
            defaultSelectedBudget={defaultSelectedBudget}
            onSelect={programBudget => {
              onConfirmProductCollection(
                activeAlgoliaCollectionId,
                programBudget
              )
            }}
            disabled={disableBudgetSelect}
          />
          <Tooltip title="Budget includes shipping and all fees. If a recipient selects a gift below the budget, you keep the difference.">
            <InfoCircleOutlined />
          </Tooltip>
        </Flex>
        {buttonsOnRight}
      </Flex>

      <Flex
        vertical
        gap={16}
        style={{
          padding: '8px 0',
          width: '100%',
        }}
      >
        <Flex vertical gap={8}>
          <Heading size={400}>Collections</Heading>
          <Flex gap={8} wrap="wrap">
            {COLLECTION_IDS.map(id => {
              const isTabSelected = id === activeAlgoliaCollectionId
              return (
                <Button
                  key={id}
                  ghost={isTabSelected}
                  type={isTabSelected ? 'primary' : 'default'}
                  shape="round"
                  onClick={() => handleCollectionClick(id)}
                  style={{ width: 'fit-content' }}
                >
                  {COLLECTION_ID_TO_NAME[id]}
                </Button>
              )
            })}
          </Flex>
        </Flex>
        <Flex gap={16} justify="space-between" align="center">
          <Flex align="center" gap={8}>
            <GlobeIcon color="muted" />
            <Text>
              Send this collection to {algoliaCountries.length}{' '}
              {algoliaCountries.length > 1 ? 'countries' : 'country'}.
              Here&apos;s what recipients will see in
              <span>
                <AlgoliaCountryFilter variant="borderless" />
              </span>
            </Text>
          </Flex>
          {!hideProductCount && <ProductCount />}
        </Flex>
      </Flex>
    </Flex>
  )
}
