import { Flex } from 'antd'
import { BackIconButton } from 'components'
import { SwagProductDetails } from 'features'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'

export default function OrgSwagProductPage() {
  const { productId } = useParams()

  if (!productId) return null

  return (
    <>
      <Helmet>
        <title>Inventory | Swag</title>
      </Helmet>

      <Flex gap={16}>
        <BackIconButton />
        <SwagProductDetails productId={productId} />
      </Flex>
    </>
  )
}
