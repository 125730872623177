import { captureMessage } from '@sentry/react'
import { Button } from 'antd'
import { callFunction } from 'api/functionCalls'
import SlackLogo from 'assets/slack-logo.svg'
import { Loader } from 'components'
import { PROD_HOSTNAME, SLACK_TUNNEL_HOST } from 'constants/hosts'
import { SLACK_CODE, SLACK_ERROR, SLACK_STATE } from 'constants/params'
import { SLACK_CLIENT_ID, SLACK_SCOPES } from 'constants/slack'
import { OrgContext } from 'context'
import {
  CornerDialog,
  Dialog,
  Heading,
  Image,
  Pane,
  Text,
  toaster,
} from 'evergreen-ui'
import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function SlackIntegration({
  displayCard,
}: {
  displayCard?: boolean
}) {
  const org = useContext(OrgContext)

  const [cornerDialogIsShown, setCornerDialogIsShown] = useState(false)
  const [isDisconnectShown, setIsDisconnectShown] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const error = searchParams.get(SLACK_ERROR)
  const code = searchParams.get(SLACK_CODE)
  const state = searchParams.get(SLACK_STATE)

  const { hostname, origin, pathname } = window.location
  const isProdHost = hostname === PROD_HOSTNAME
  const currentUrl = origin + pathname

  const redirectUrl = isProdHost ? currentUrl : SLACK_TUNNEL_HOST + pathname
  const slackOAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=${SLACK_SCOPES}&redirect_uri=${redirectUrl}`

  useEffect(() => {
    if (code) {
      setIsLoading(true)
      callFunction('slack-ConnectSlack', {
        code,
        redirectUrl,
      })
        .then(res => {
          if (res) {
            searchParams.delete(SLACK_CODE)
            searchParams.set(SLACK_STATE, 'connected')
            setSearchParams(searchParams)
          } else {
            captureMessage('Slack could not connect', 'error')
            toaster.warning('Something went wrong, please contact support')
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [code, org.id, redirectUrl, searchParams, setSearchParams])

  useEffect(() => {
    if (error) {
      if (error === 'access_denied') {
        toaster.warning('Access Denied')
      } else {
        toaster.warning(error)
      }
      captureMessage(`Slack oauth error: ${error}`, 'error')
    }
  }, [error])

  useEffect(() => {
    if (state) {
      setCornerDialogIsShown(true)
    } else {
      setCornerDialogIsShown(false)
    }
  }, [state])

  const handleDisconnect = () => {
    setIsDisconnectShown(false)
    setIsLoading(true)
    callFunction('slack-DisconnectSlack', {})
      .then(res => {
        if (res) {
          searchParams.set(SLACK_STATE, 'disconnected')
          setSearchParams(searchParams)
        } else {
          captureMessage('Slack could not disconnect', 'error')
          toaster.warning('Something went wrong, please contact support')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onCloseComplete = () => {
    if (state) {
      searchParams.delete(SLACK_STATE)
      setSearchParams(searchParams)
    }
  }

  return (
    <Pane>
      {displayCard && (
        <Pane
          display="flex"
          alignItems="center"
          width={712}
          elevation={1}
          borderRadius={4}
          paddingY={24}
          paddingX={36}
          gap={36}
        >
          <Pane maxHeight={64} minWidth={80}>
            <Image src={SlackLogo} width={64} />
          </Pane>
          <Pane display="flex" alignItems="center" gap={24} flexGrow={1}>
            <Pane display="flex" flexDirection="column">
              <Heading paddingBottom={12}>Slack</Heading>
              <Text>
                Notify a channel or individual in Slack with real-time PerkUp
                Rewards, anniversary and birthday announcements
              </Text>
            </Pane>
            {isLoading && <Loader />}
            {!isLoading && (
              <Pane
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
              >
                {org?.slackConnected ? (
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    gap={16}
                  >
                    <Button onClick={() => setIsDisconnectShown(true)}>
                      Disconnect
                    </Button>
                  </Pane>
                ) : (
                  <a href={slackOAuthUrl}>
                    <Button type="primary">Connect</Button>
                  </a>
                )}
              </Pane>
            )}
          </Pane>
        </Pane>
      )}

      <CornerDialog
        title={`Slack has been ${state}`}
        isShown={cornerDialogIsShown}
        onCloseComplete={onCloseComplete}
        hasFooter={false}
      >
        {state === 'connected'
          ? 'You can now send messages to people on Slack whenever they receive a reward!'
          : 'Reconnect to send messages to people on Slack whenever they receive a reward.'}
      </CornerDialog>
      <Dialog
        isShown={isDisconnectShown}
        title="Disconnect Slack"
        onCloseComplete={() => setIsDisconnectShown(false)}
        confirmLabel="Disconnect"
        intent="danger"
        onConfirm={handleDisconnect}
      >
        Are you sure you want to disconnect Slack?
      </Dialog>
    </Pane>
  )
}
