import { DeleteOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { removeProgram } from 'api/databaseCalls'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { Dialog, toaster } from 'evergreen-ui'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function DeleteProgram({
  program,
  setStopPropagation,
}: {
  program: Program
  setStopPropagation?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const org = useContext(OrgContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [showDelete, setShowDelete] = useState(false)

  if (!program) return null

  const { id, name, type } = program
  if (!id) return null
  if (!name) return null

  const isRewards = [
    ProgramType.rewards,
    ProgramType.anniversaries,
    ProgramType.birthdays,
  ].includes(type)
  const title = isRewards ? 'Reward' : 'Program'

  return (
    <>
      <Tooltip title="Delete">
        <Button
          icon={<DeleteOutlined />}
          type="text"
          onClick={e => {
            e.stopPropagation()
            if (setStopPropagation) setStopPropagation(true)
            setShowDelete(true)
          }}
        />
      </Tooltip>
      <Dialog
        title={`Delete ${title.toLowerCase()}`}
        isShown={showDelete}
        onCloseComplete={() => {
          setShowDelete(false)
          if (setStopPropagation) setStopPropagation(false)
        }}
        onConfirm={() => {
          removeProgram({ orgId: org.id, programId: program.id })
          toaster.warning(`${title} deleted`)
          setShowDelete(false)
          if (
            isRewards ||
            location.pathname !== '/organization/perk-programs'
          ) {
            navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
          }
        }}
        confirmLabel="Delete"
        intent="danger"
      >
        Are you sure you&lsquo;d like to delete <b>{name}</b>?
      </Dialog>
    </>
  )
}
