import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { UserAvatar } from 'components'
import { THANKS_MESSAGE_URL } from 'constants/tinybird'
import { OrgContext } from 'context'
import { Pane, Strong, Text } from 'evergreen-ui'
import { isEmpty } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { ThanksMessage } from 'types/TinyBird'
import {
  COLUMN_MAX_HEIGHT,
  COLUMN_MIN_HEIGHT,
  COLUMN_MIN_WIDTH,
  COLUMN_WIDTH,
} from '../constants/insights'
import { NoData } from './NoData'

export function ThankYouMessages() {
  const org = useContext(OrgContext)

  const [thanksMessages, setThanksMessages] = useState<ThanksMessage[]>()

  const orgId = org.id

  useEffect(() => {
    FetchTinyBirdDataByOrgId({
      orgId,
      cb: setThanksMessages,
      baseUrl: THANKS_MESSAGE_URL,
      dataTitle: 'thanks messages',
    })
  }, [orgId])

  return (
    <Pane
      maxWidth={COLUMN_WIDTH}
      minWidth={COLUMN_MIN_WIDTH}
      maxHeight={COLUMN_MAX_HEIGHT}
      minHeight={COLUMN_MIN_HEIGHT}
      border="muted"
      display="flex"
      flexDirection="column"
      borderRadius={8}
    >
      <Pane borderBottom="muted" paddingY={8} paddingLeft={16}>
        <Strong>Thank you notes</Strong>
      </Pane>
      {isEmpty(thanksMessages) && (
        <Pane padding={24}>
          <NoData />
        </Pane>
      )}

      <Pane
        display="flex"
        flexDirection="column"
        gap={16}
        paddingTop={32}
        overflowY="auto"
      >
        {thanksMessages?.map(message => {
          const messageAuthor = `${message.firstName} ${message.lastName}`
          const ownerName = `${message.ownerFirstName} ${message.ownerLastName}`
          const createdDate = new Date(message.memberCreated)
          const dateDisplayString = createdDate.toLocaleDateString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })
          return (
            <Pane
              key={message.memberId}
              display="flex"
              flexDirection="column"
              marginX={32}
              paddingBottom={32}
              borderBottom="muted"
              gap={8}
            >
              <Pane display="flex" gap={16}>
                <UserAvatar
                  pfpSize={40}
                  name={
                    isEmpty(messageAuthor.trim()) ? undefined : messageAuthor
                  }
                  email={message.email}
                  profilePicture={message.profilePicture}
                />
                <Pane display="flex" flexDirection="column">
                  <Text>{messageAuthor}</Text>
                  <Text>{message.email}</Text>
                </Pane>
              </Pane>
              <Text>{message.thanksMessage}</Text>
              <Pane display="flex" alignItems="center" gap={4}>
                <Text color="muted" size={300}>
                  {dateDisplayString} • {message.programName}
                </Text>
                {message.ownerEmail && (
                  <Pane display="flex" alignItems="center" gap={8}>
                    <Text size={300} color="muted">
                      — sent by
                    </Text>
                    <UserAvatar
                      email={message.ownerEmail}
                      name={isEmpty(ownerName.trim()) ? undefined : ownerName}
                      profilePicture={message.ownerProfilePicture}
                      pfpSize={16}
                    />
                  </Pane>
                )}
              </Pane>
            </Pane>
          )
        })}
      </Pane>
    </Pane>
  )
}
