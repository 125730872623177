import { InfoCircleOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { Pane, Strong, Tooltip } from 'evergreen-ui'

export default function InternalMemoForm({
  setInternalMemo,
  internalMemo,
  maxWidth,
}: {
  setInternalMemo: (newInternalMemo: string) => void
  internalMemo?: string
  maxWidth?: number
}) {
  return (
    <Pane display="flex" flexDirection="column" gap={8} width="100%">
      <Pane display="flex" gap={8}>
        <Strong>Internal memo</Strong>
        <Tooltip content="Only visible to Admins and Managers within your organization.">
          <InfoCircleOutlined />
        </Tooltip>
      </Pane>
      <Pane width="100%" maxWidth={maxWidth}>
        <Input
          defaultValue={internalMemo}
          onBlur={e => setInternalMemo(e.target.value)}
          placeholder="Optional memo, PO number, or internal description"
        />
      </Pane>
    </Pane>
  )
}
