import {
  ApiOutlined,
  BarChartOutlined,
  DollarCircleOutlined,
  FormatPainterOutlined,
  GiftOutlined,
  HomeOutlined,
  LeftOutlined,
  SendOutlined,
  SettingOutlined,
  SkinOutlined,
  TeamOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons'
import { Inbox } from '@trycourier/react-inbox'
import { Alert, Button, Tag } from 'antd'
import Divider from 'antd/es/divider'
import { ReactComponent as Logo } from 'assets/PerkUp-LogoIcon.svg'
import { AccountBalanceForm } from 'components/Accounts'
import { defaultButtonStyles } from 'constants/antdesign'
import { PROGRAM_TEMPLATE_CLICKED } from 'constants/events'
import { isProduction } from 'constants/keys'
import {
  ORG_PAGE_PADDING,
  SIDEBAR_PADDING,
  SIDEBAR_WIDTH,
} from 'constants/layout'
import * as ROUTES from 'constants/routes'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  IndividualContext,
  OrgBalanceContext,
  OrgContext,
  OrgIntegrationsContext,
  UserContext,
} from 'context'
import {
  ChevronDownIcon,
  Pane,
  Tab,
  Tablist,
  Text,
  Tooltip,
  useTheme,
  WarningSignIcon,
} from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { capitalize, isNumber, uniqueId } from 'lodash-es'
import { useContext } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { isConnectAccountActive } from 'services'
import { NavigationTab } from 'types'
import { integrationNeedsReauthentication, logEvent, numToDollars } from 'utils'
import { CompanyDisplay } from './CompanyDisplay'
import { Footer } from './Footer'
import { OrgDropdown } from './OrgDropdown'
import { UserDropdown } from './UserDropdown'

function BalanceValue({
  tab,
  balance,
}: {
  tab: NavigationTab
  balance: number | undefined
}) {
  if (tab.displayBalance && isNumber(balance)) {
    return (
      <Text color="inherit" fontWeight="inherit" marginLeft="auto">
        {numToDollars(balance, 0)}
      </Text>
    )
  }
  if (tab.hasWarning) {
    return (
      <WarningSignIcon color="warning" marginLeft="auto" marginRight={16} />
    )
  }

  return null
}

export const withOrgSidebar = (BaseComponent: any) =>
  function HOC(props: any) {
    const org = useContext(OrgContext)
    const user = useContext(UserContext)
    const orgId = org.id
    const userId = user.id

    const individual = useContext(IndividualContext)
    const orgBalance = useContext(OrgBalanceContext)
    const integrations = useContext(OrgIntegrationsContext)

    const params = useParams()
    const { programId } = params
    const navigate = useNavigate()
    const location = useLocation()

    const theme = useTheme()

    const isRewardOverview = !!(
      programId &&
      location.pathname.includes(
        `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${programId}`
      )
    )

    const isAdmin = individual.role === Individual_Role.admin

    const activeConnectAccount = isConnectAccountActive({ org })

    const activeSubscription: boolean =
      org.subscriptionStatus === Organization_SubscriptionStatus.active

    const settingsHasWarning = org.settings?.hidePerkCard
      ? false
      : !activeConnectAccount

    const anniversariesAndBirthdaysTab: NavigationTab = {
      name: 'Automations',
      icon: <ThunderboltOutlined />,
      route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.CALENDAR,
      subTabs: [
        {
          name: 'Work Anniversaries',
          route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.CALENDAR,
          isSelected: location.pathname.includes(
            DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.ROOT
          ),
        },
        {
          name: 'Birthdays',
          route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.CALENDAR,
          isSelected: location.pathname.includes(
            DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.ROOT
          ),
        },
        {
          name: 'New Hires',
          hidden: !isAdmin,
          route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_HIRE.CALENDAR,
          isSelected: location.pathname.includes(
            DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_HIRE.ROOT
          ),
        },
      ],
    }

    const perkProgramOrgs = [
      'fczFpQG2hU9PXZBugdRA',
      'NJSu9DKTF4aRslBayb3v',
      'vbUOAGtK177DOLlAqAdR',
      'DMiYGoELyYtiqJElJ6CF',
      'TLN2TrQrVWWsyWT0Umyf',
      'aNXgwunvz24TJaca7MO4',
      '0Q2tZA21aBA0qX3yVLnt',
      'b4P1vaN17XreC25ehsv1',
      'grFacPEJEmN03kJORZ4e',
      'wqVob7WUgA78ag1bGBqi',
      '0ZeDxMltF4516fO0kV3j',
      'KhrU0KbXRAcMqzCYBr6Y',
      'dGkaneMqQGRjcES4cB8i',
      'qPbAh231Q7qN3ANCoWuQ',
      '1gjzYVNKFCphRlXdO5ii',
      'GWKWotQJaYdAxq8JtZEi',
      'H5JBJ4Hgnf6mmQcKkIUc',
      'NLsOTTtmcTw2ch89VQfo',
      'hPZMvTLlKw3bC4aprvzq',
      'sq0lgJ1MavBxpDArR1Ec',
      'UkgIc2KZreTrogNNcbk0',
      'c7ldQXTsIJeoogYJseM9',
      '6nicNX68OJCCeaAjy62b',
      'S0AlaDZ9Gfo6FFY5Fu8E',
      'TukXs4dc8Q8ceGB4l4yu',
      'fbQmaWjUG13vAytgEEtj',
      'zllGlL0r8mGszGbrJKCQ',
    ]

    const memberView = {
      name: 'Member view',
      icon: <LeftOutlined />,
      route: DEFAULT_ROUTES.CARD.ROOT,
      marginTop: 32,
    }

    const adminTabs: NavigationTab[] = [
      {
        name: 'Spot Rewards',
        icon: <HomeOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT,
        isSelected: isRewardOverview,
        subTabs: [
          {
            name: 'Recurring Programs',
            route: DEFAULT_ROUTES.ORGANIZATION.PERK_PROGRAMS.ROOT,
            hidden: !perkProgramOrgs.includes(orgId) && isProduction,
          },
          {
            name: 'Templates',
            route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT,
          },
        ],
      },
      {
        name: 'Insights',
        icon: <BarChartOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.INSIGHTS.ROOT,
        subTabs: [
          {
            name: 'Reports',
            route: DEFAULT_ROUTES.ORGANIZATION.INSIGHTS.REPORTS,
          },
          {
            name: 'Notification Activity',
            route: DEFAULT_ROUTES.ORGANIZATION.INSIGHTS.LOGS,
            hidden: !isAdmin,
          },
        ],
      },
      anniversariesAndBirthdaysTab,
      {
        name: 'Gifts',
        icon: <GiftOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.GIFTS.ROOT,
        hidden: !!org?.settings?.hideStartFromScratch,
      },
      {
        name: 'Swag',
        icon: <SkinOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT,
      },
      {
        name: 'Balances',
        icon: <DollarCircleOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT,
        hasWarning: true,
        displayBalance: isNumber(orgBalance) && orgBalance > 0,
        subTabs: [
          {
            name: 'Transactions',
            route: DEFAULT_ROUTES.ORGANIZATION.TRANSACTIONS,
          },

          {
            name: 'Billing',
            route: DEFAULT_ROUTES.ORGANIZATION.BILLING,
            hasWarning: !activeSubscription,
          },
        ],
      },
      {
        name: 'People',
        icon: <TeamOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.ROOT,
        subTabs: [
          {
            name: 'Custom Labels',
            route: DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.LABELS,
          },
        ],
      },
      {
        name: 'Integrations',
        icon: <ApiOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.INTEGRATIONS.ROOT,
        hasWarning: integrations.some(i => integrationNeedsReauthentication(i)),
      },
      {
        name: 'Branding',
        icon: <FormatPainterOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.BRANDING,
      },
      {
        name: 'Settings',
        icon: <SettingOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.SETTINGS,
        hasWarning: settingsHasWarning,
      },
      memberView,
    ]

    const managerTabs: NavigationTab[] = [
      {
        name: 'Spot Rewards',
        icon: <HomeOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT,
        subTabs: [
          {
            name: 'Templates',
            route: DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT,
            hidden: true,
          },
        ],
      },
      anniversariesAndBirthdaysTab,
      {
        name: 'Gifts',
        icon: <GiftOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.GIFTS.ROOT,
        hidden: !!org?.settings?.hideStartFromScratch,
        subTabs: [
          {
            name: 'Collections',
            route: DEFAULT_ROUTES.ORGANIZATION.COLLECTIONS,
          },
        ],
      },
      {
        name: 'Swag',
        icon: <SkinOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT,
      },
      {
        name: 'Balances',
        icon: <DollarCircleOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT,
      },

      {
        name: 'People',
        icon: <TeamOutlined />,
        route: DEFAULT_ROUTES.ORGANIZATION.DIRECTORY.ROOT,
      },
      memberView,
    ]

    const handleNewRewardClick = (name: string) => {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD)

      logEvent(PROGRAM_TEMPLATE_CLICKED, {
        name,
        fromTemplate: false,
        orgName: org.name,
        orgSubscriptionStatus: org?.subscriptionStatus
          ? Organization_SubscriptionStatus[org.subscriptionStatus]
          : null,
        orgId,
        userId,
      })
    }

    const tabsToDisplay = isAdmin ? adminTabs : managerTabs

    const userHasMoreThan1org = user.organizations.length > 1

    const switchTooltipContent = 'Switch to member view'

    return (
      <Pane
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        minWidth="100vw"
      >
        {/** TOP NAVBAR  */}
        <Pane
          backgroundColor="white"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          position="sticky"
          top={0}
          paddingY={8}
          paddingX={24}
          gap={16}
          zIndex={3} // Needs to be 3 because the antD library is on zIndex 2
          borderBottom="muted"
        >
          <Pane display="flex" gap={16} alignItems="center">
            {/** PerkUp Logo */}
            <Tooltip content={switchTooltipContent}>
              <Pane is={Link} to={ROUTES.DASHBOARD}>
                <Logo fill="#002540" height={24} />
              </Pane>
            </Tooltip>

            <Divider type="vertical" />

            {/** Left menu options */}
            <OrgDropdown>
              <Pane display="flex" alignItems="center" gap={8}>
                <CompanyDisplay />
                {userHasMoreThan1org && <ChevronDownIcon color="inherit" />}
              </Pane>
            </OrgDropdown>

            {individual.role && (
              <Tag color="gold" style={{ marginRight: 8 }}>
                {capitalize(Individual_Role[individual.role])}
              </Tag>
            )}
          </Pane>

          {!activeSubscription && (
            <Pane width={312}>
              <Alert
                message="Subscription is inactive"
                type="warning"
                showIcon
                action={
                  <Button>
                    <Link
                      to="https://www.perkupapp.com/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      See plans
                    </Link>
                  </Button>
                }
              />
            </Pane>
          )}

          {/** Right menu options */}
          <Pane display="flex" alignItems="center" gap={16}>
            <Pane marginRight={32}>
              <AccountBalanceForm />
            </Pane>

            <Pane>
              <Inbox />
            </Pane>

            <Divider type="vertical" />

            <UserDropdown />
          </Pane>
        </Pane>

        {/** SIDE NAVBAR & CONTENT */}
        <Pane display="flex" flex={1}>
          {/** Side nav */}
          <Pane borderRight="muted">
            <Pane
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              position="sticky"
              minWidth={SIDEBAR_WIDTH}
              padding={SIDEBAR_PADDING}
              top={57}
              alignSelf="flex-start"
              gap={16}
            >
              <Button
                style={{
                  ...defaultButtonStyles,
                  gap: 8,
                }}
                onClick={() => handleNewRewardClick('From sidebar')}
                type="primary"
              >
                Send reward
                <SendOutlined />
              </Button>
              <Tablist>
                {tabsToDisplay.map(tab => {
                  if (tab.hidden) return null

                  const isSelected =
                    location.pathname.includes(tab.route) || tab.isSelected

                  const subTabIsSelected = !!tab.subTabs?.find(
                    subTab =>
                      location.pathname.includes(subTab.route) ||
                      subTab.isSelected
                  )

                  return (
                    <div key={uniqueId(tab.name)}>
                      <Tab
                        id={tab.name}
                        direction="vertical"
                        appearance="primary"
                        display="flex"
                        color={theme.colors.gray800}
                        justifyContent="space-between"
                        alignItems="center"
                        isSelected={isSelected && !subTabIsSelected}
                        to={tab.route}
                        is={Link}
                        disabled={tab.disabled}
                        marginTop={tab.marginTop}
                      >
                        <Pane display="flex" gap={8} alignItems="center">
                          {tab.icon} {tab.name}
                        </Pane>

                        <BalanceValue tab={tab} balance={orgBalance} />
                        {tab?.isNew && <Tag color="blue">New</Tag>}
                      </Tab>
                      {(isSelected || subTabIsSelected) &&
                        tab.subTabs?.map(subTab => {
                          if (subTab.hidden || !subTab.route) return null

                          const isSelected =
                            location.pathname.includes(subTab.route) ||
                            subTab.isSelected

                          return (
                            <Tab
                              key={uniqueId(subTab.name)}
                              display="flex"
                              justifyContent="space-between"
                              paddingLeft={24}
                              color="muted"
                              direction="vertical"
                              id={subTab.name}
                              disabled={subTab.disabled}
                              isSelected={isSelected}
                              to={subTab.route}
                              is={Link}
                              minWidth="max-content"
                            >
                              {subTab.name}
                              {subTab.isNew && <Tag color="blue">New</Tag>}
                              {subTab.hasWarning && (
                                <WarningSignIcon
                                  color="warning"
                                  marginLeft="auto"
                                />
                              )}
                            </Tab>
                          )
                        })}
                    </div>
                  )
                })}
              </Tablist>
            </Pane>
          </Pane>

          {/** Content */}
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            padding={ORG_PAGE_PADDING}
            flex={1}
            gap={32}
          >
            <OrgBalanceContext.Provider value={orgBalance}>
              <BaseComponent {...props} />
            </OrgBalanceContext.Provider>

            <Footer />
          </Pane>
        </Pane>
      </Pane>
    )
  }
