import { getProductCollectionById } from 'api/databaseCalls'
import { MIN_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import {
  COLLECTION_IDS,
  FEATURED_COLLECTION_ID,
} from 'constants/productCollections'
import { OrgContext, SelectRewardContext } from 'context'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { Program_Gift, Program_RewardTab } from 'gen/perkup/v1/program_pb'
import {
  buildProgramGiftAsGenericProduct,
  buildProgramGiftAsGenericProducts,
  buildProgramGiftAsSpecificProductVariant,
  buildProgramGiftFromProductCollection,
  buildProgramGiftFromSwagProductCollection,
} from 'pages/NewReward/utils/program-gifts'
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Reward } from 'types'
import {
  calculateMaxVariantPriceByProductIds,
  calculateProductCollectionMinPrice,
  calculateShopifyProductVariantTotal,
  determineNextRedeemableQuantity,
} from 'utils'

const determineDefaultTab = (gift: Program_Gift | undefined) => {
  if (!gift) return Program_RewardTab.perkupDollars

  if (
    gift?.productCollectionId &&
    COLLECTION_IDS.includes(gift.productCollectionId)
  ) {
    return Program_RewardTab.catalog
  }

  if (gift?.productCollectionId || gift.productIds.length > 0) {
    return Program_RewardTab.swag
  }

  return Program_RewardTab.giftCard
}

export interface SelectRewardContextType {
  noRewardSelected: boolean
  selectedReward: Reward
  selectedTab: ReturnType<typeof useSelectedRewardTab>
  swagForm: ReturnType<typeof useSwagForm>
  redemptionOptionsForm: ReturnType<typeof useRedemptionOptionsForm>
  perkupDollarsForm: ReturnType<typeof usePerkupDollarsForm>
  giftCatalogForm: ReturnType<typeof useGiftCatalogForm>
  giftCardForm: {
    handleSelectGiftCard: (selectedProductVariant: ProductVariant) => void
  }
}

export function useSelectRewardContext() {
  const context = useContext(SelectRewardContext)
  if (!context) {
    throw new Error(
      'useSelectRewardContext must be used within a SelectRewardContextProvider'
    )
  }
  return context
}

type OnSelectReward = (
  reward: Partial<Reward>,
  options?: {
    advance?: boolean
  }
) => void | Promise<void>

const useGiftCatalogForm = ({
  onSelectReward,
}: {
  selectedReward: Reward
  onSelectReward: OnSelectReward
}) => {
  const handleSelectProductCollection = (
    selectedProductCollectionId: string,
    budget: number,
    advance?: boolean
  ) => {
    const gift = buildProgramGiftFromProductCollection(
      selectedProductCollectionId
    )
    onSelectReward({ gift, budget, approvedCategories: [] }, { advance })
  }

  const handleUpdateGift = ({
    gift,
    budget,
    advance,
  }: {
    gift?: Program_Gift
    budget?: number
    advance?: boolean
  }) => {
    if (gift?.productCollectionId && budget !== undefined)
      handleSelectProductCollection(gift?.productCollectionId, budget, advance)
    else onSelectReward({ gift, budget, approvedCategories: [] }, { advance })
  }

  return { handleSelectProductCollection, handleUpdateGift }
}

const useSelectedRewardTab = ({
  selectedReward,
  initialTab,
  handleSelectProductCollection,
  onTabChange,
}: {
  selectedReward: Reward
  handleSelectProductCollection: SelectRewardContextType['giftCatalogForm']['handleSelectProductCollection']
  onTabChange: (tab: Program_RewardTab) => void
  initialTab?: Program_RewardTab
}) => {
  const [selectedRewardTab, setSelectedRewardTab] = useState(
    () => initialTab ?? determineDefaultTab(selectedReward.gift)
  )

  const handleSelectRewardTab = (tab: Program_RewardTab) => {
    if (tab === Program_RewardTab.catalog) {
      const alreadyHasNewCollection =
        selectedReward.gift?.productCollectionId &&
        COLLECTION_IDS.includes(selectedReward.gift.productCollectionId)
      if (!alreadyHasNewCollection) {
        handleSelectProductCollection(
          FEATURED_COLLECTION_ID,
          selectedReward.budget
        )
      }
    }
    setSelectedRewardTab(tab)
    onTabChange(tab)
  }

  return {
    selectedRewardTab,
    handleSelectRewardTab,
  }
}

function useSwagForm({
  selectedReward,
  onSelectReward,
}: {
  selectedReward: Reward
  onSelectReward: OnSelectReward
}) {
  const { id: orgId } = useContext(OrgContext)

  const handleRemoveProductVariant = async (
    productVariant: ProductVariant,
    remainingVariants: ProductVariant[]
  ) => {
    const currentQuantity = selectedReward.gift?.redeemableQuantity
    const updatedGift = () => {
      if (remainingVariants.length === 0) return undefined
      if (remainingVariants.length > 1) {
        return buildProgramGiftAsGenericProducts({
          productIds: remainingVariants.map(pv => pv.productId),
          title: selectedReward.gift?.title || '',
          convertableTo: selectedReward.gift?.convertableTo,
          redeemableQuantity: !currentQuantity
            ? undefined // Respect the user's choice to not have a redeemable quantity
            : determineNextRedeemableQuantity(
                currentQuantity,
                remainingVariants.length,
                'down'
              ),
        })
      }
      return buildProgramGiftAsGenericProduct({
        productVariant: remainingVariants[0],
        convertableTo: selectedReward.gift?.convertableTo,
      })
    }

    const newGift = updatedGift()

    let maxProductPrice: number | undefined
    let newBudget = selectedReward.budget

    if (newGift?.productIds && newGift.productIds.length > 0)
      maxProductPrice = await calculateMaxVariantPriceByProductIds({
        productIds: newGift?.productIds,
        orgId,
      })

    if (maxProductPrice && newGift?.redeemableQuantity)
      newBudget = maxProductPrice * newGift.redeemableQuantity

    await onSelectReward({
      gift: newGift,
      budget: newBudget,
      approvedCategories: [],
    })
  }

  const handleRemoveCollection = () => {
    return onSelectReward({
      gift: undefined,
      budget: MIN_PROGRAM_BUDGET_AMOUNT,
      approvedCategories: [],
    })
  }

  const handleSelectSwagProductVariant = async (
    selectedVariant: ProductVariant,
    allSelectedVariants: ProductVariant[]
  ) => {
    // User clicked on a product that is already in the gift
    const giftAlreadyHasProduct = selectedReward.gift?.productIds?.includes(
      selectedVariant.productId
    )
    if (giftAlreadyHasProduct) {
      const remainingVariants = allSelectedVariants.filter(
        pv => pv.productId !== selectedVariant.productId
      )
      await handleRemoveProductVariant(selectedVariant, remainingVariants)
      return
    }

    const updatedGift =
      selectedReward.gift?.productIds &&
      selectedReward.gift?.productIds.length > 0
        ? buildProgramGiftAsGenericProducts({
            productIds: [
              ...selectedReward.gift.productIds,
              selectedVariant.productId,
            ],
            title: 'Swag products',
            convertableTo: selectedReward.gift?.convertableTo,
            redeemableQuantity: !selectedReward.gift?.redeemableQuantity
              ? undefined // Respect the user's choice to not have a redeemable quantity
              : determineNextRedeemableQuantity(
                  selectedReward.gift.redeemableQuantity,
                  selectedReward.gift.productIds.length + 1,
                  'up'
                ),
          })
        : buildProgramGiftAsGenericProduct({
            productVariant: selectedVariant,
            convertableTo: selectedReward.gift?.convertableTo,
          })

    const maxProductPrice = await calculateMaxVariantPriceByProductIds({
      productIds: updatedGift?.productIds || [],
      orgId,
    })

    if (!maxProductPrice) return

    await onSelectReward({
      gift: updatedGift,
      budget: maxProductPrice * (updatedGift.redeemableQuantity || 1),
      approvedCategories: [],
    })
  }

  const handleSelectSwagProductCollection = async (
    productCollection: ProductCollection
  ) => {
    const gift = buildProgramGiftFromSwagProductCollection(productCollection)
    const budget = calculateProductCollectionMinPrice(productCollection)
    if (!budget) return
    await onSelectReward({ gift, budget, approvedCategories: [] })
  }

  return {
    handleRemoveCollection,
    handleRemoveProductVariant,
    handleSelectSwagProductCollection,
    handleSelectSwagProductVariant,
  }
}

function useRedemptionOptionsForm({
  selectedReward,
  onSelectReward,
}: {
  selectedReward: Reward
  onSelectReward: OnSelectReward
}) {
  const { id: orgId } = useContext(OrgContext)

  const handleConvertableToChange = (
    convertableTo: Program_Gift['convertableTo']
  ) => {
    const updatedGift = new Program_Gift({
      ...selectedReward.gift,
      convertableTo,
    })
    onSelectReward({
      gift: updatedGift,
      budget: selectedReward.budget,
      approvedCategories: [],
    })
  }
  const handleRedeemableQuantityChange = async (redeemableQuantity: number) => {
    const newGift = new Program_Gift({
      ...selectedReward.gift,
      redeemableQuantity,
    })

    if (newGift.productCollectionId) {
      const collection = await getProductCollectionById({
        id: newGift.productCollectionId,
      })
      const maxItemPrice = calculateProductCollectionMinPrice(collection)
      const newBudget = (maxItemPrice || 0) * (redeemableQuantity || 1)
      onSelectReward({
        gift: newGift,
        budget: newBudget,
        approvedCategories: [],
      })
    } else {
      const maxItemPrice = await calculateMaxVariantPriceByProductIds({
        productIds: newGift.productIds,
        orgId,
      })
      const newBudget = (maxItemPrice || 0) * (redeemableQuantity || 1)
      onSelectReward({
        gift: newGift,
        budget: newBudget,
        approvedCategories: [],
      })
    }
  }

  const handleBudgetChange = (budget: number) => {
    const newGift = new Program_Gift({
      ...selectedReward.gift,
      redeemableQuantity: undefined,
    })
    onSelectReward({ gift: newGift, budget, approvedCategories: [] })
  }

  return {
    handleConvertableToChange,
    handleRedeemableQuantityChange,
    handleBudgetChange,
  }
}

function usePerkupDollarsForm({
  selectedReward,
  onSelectReward,
}: {
  selectedReward: Reward
  onSelectReward: OnSelectReward
}) {
  const handleSelectCategories = (cats: string[]) => {
    onSelectReward({
      gift: undefined,
      budget: selectedReward.budget,
      approvedCategories: cats,
    })
  }

  const handleSelectAmount = (selectedAmount: number) => {
    // Make sure to not pass a float into Firestore
    const roundedAmount = Math.round(selectedAmount)

    onSelectReward({
      gift: undefined,
      budget: roundedAmount,
      approvedCategories: selectedReward.approvedCategories,
    })
  }

  return { handleSelectCategories, handleSelectAmount }
}

export function SelectRewardContextProvider({
  children,
  selectedReward,
  onSelectReward,
  onTabChange = () => {},
  initialTab,
}: PropsWithChildren<{
  selectedReward: Reward
  onSelectReward: OnSelectReward
  onTabChange?: (tab: Program_RewardTab) => void
  initialTab?: Program_RewardTab
}>) {
  const giftCatalogForm = useGiftCatalogForm({ selectedReward, onSelectReward })

  const selectedTab = useSelectedRewardTab({
    selectedReward,
    initialTab,
    onTabChange,
    handleSelectProductCollection:
      giftCatalogForm.handleSelectProductCollection,
  })

  const swagForm = useSwagForm({ selectedReward, onSelectReward })

  const redemptionOptionsForm = useRedemptionOptionsForm({
    selectedReward,
    onSelectReward,
  })

  const perkupDollarsForm = usePerkupDollarsForm({
    selectedReward,
    onSelectReward,
  })

  const handleSelectGiftCard = useCallback(
    (selectedProductVariant: ProductVariant) => {
      const gift = buildProgramGiftAsSpecificProductVariant({
        productVariant: selectedProductVariant,
      })
      const budget = calculateShopifyProductVariantTotal(selectedProductVariant)
      onSelectReward(
        { gift, budget, approvedCategories: [] },
        { advance: true }
      )
    },
    [onSelectReward]
  )

  const ctx = useMemo(
    () => ({
      selectedReward,
      selectedTab,
      swagForm,
      perkupDollarsForm,
      redemptionOptionsForm,
      giftCatalogForm,
      giftCardForm: { handleSelectGiftCard },
      noRewardSelected:
        // no gift
        !selectedReward.gift &&
        // perkup dollars tab don't set a gift
        selectedTab.selectedRewardTab !== Program_RewardTab.perkupDollars,
    }),
    [
      selectedReward,
      selectedTab,
      swagForm,
      perkupDollarsForm,
      redemptionOptionsForm,
      giftCatalogForm,
      handleSelectGiftCard,
    ]
  )

  return (
    <SelectRewardContext.Provider value={ctx}>
      {children}
    </SelectRewardContext.Provider>
  )
}
