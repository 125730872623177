import integrations from 'constants/integrations'
import { Pane } from 'evergreen-ui'
import FinchButton from './FinchButton'

function IntegrationsList({ onClick }: { onClick: () => void }) {
  return (
    <Pane
      display="grid"
      gridTemplateColumns="repeat(auto-fill, 256px)"
      gap={24}
    >
      {integrations.map(integration => (
        <FinchButton
          key={integration.name}
          {...integration}
          onClick={onClick}
        />
      ))}
    </Pane>
  )
}

export default IntegrationsList
