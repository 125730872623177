import { DownloadOutlined } from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { captureException } from '@sentry/react'
import { Button } from 'antd'
import Tooltip from 'antd/es/tooltip'
import { ListListsByOrgId } from 'api/databaseCalls'
import { ALGOLIA_INDIVIDUALS_INDEX } from 'constants/algolia'
import { OrgContext } from 'context'
import { toaster } from 'evergreen-ui'
import {
  Individual,
  Individual_Role,
  Individual_Status,
} from 'gen/perkup/v1/individual_pb'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import type { UiState } from 'instantsearch.js'
import { startCase } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import { useContext, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'

import { individualsSearchClient } from 'services/algolia'
import { getUTCIndividualDate, toSentry } from 'utils'
import { getAlgoliaFilters } from 'utils/Algolia'
import { objectToMessage } from 'utils/firestore'

interface IIndividualData {
  employeeId: string | undefined
  firstName: string
  lastName: string
  email: string
  permission: string
  status: string
  balance: string | number
  removedBalance?: string | number
  dob?: string
  startDate?: string
  endDate?: string
  title?: string
  managerId?: string
}

interface IIndividualDataWithLabels extends IIndividualData {
  [key: string]: string | number | Timestamp | undefined
}

function formatTimestampToLocaleDateString(timestamp?: Timestamp) {
  if (!timestamp) return ''
  const date = new Date(Number(timestamp.seconds) * 1000)
  return getUTCIndividualDate(date)?.toLocaleString()
}

// Helper function to format individuals
function getFormattedIndividuals({
  individuals,
}: {
  individuals: Individual[]
}) {
  if (isEmpty(individuals)) return []

  return individuals.map(individual => {
    const individualData: IIndividualDataWithLabels = {
      employeeId: individual.employeeId ?? '',
      firstName: individual.firstName ?? '',
      lastName: individual.lastName ?? '',
      email: individual.email ?? '',
      permission: Individual_Role[individual.role] ?? '',
      status: Individual_Status[individual.status] ?? '',
      balance: individual.balance ? Number(individual.balance) / 100 : '',
      removedBalance: individual.removedBalance
        ? Number(individual.removedBalance) / 100
        : '',
      dob: formatTimestampToLocaleDateString(individual?.dob),
      startDate: formatTimestampToLocaleDateString(individual?.startDate),
      endDate: formatTimestampToLocaleDateString(individual?.endDate),
      title: individual.title ?? '',
      managerId: individual.managerId ?? '',
    }

    // loop through labels and add to individualData
    if (individual.labels) {
      Object.keys(individual.labels).forEach(label => {
        individualData[label] = individual.labels[label]
      })
    }

    return individualData
  })
}

export default function DownloadIndividualsButton({
  query,
  uiState,
}: {
  query: string
  uiState: UiState
}) {
  const org = useContext(OrgContext)
  const orgId = org.id
  const [csvData, setCsvData] = useState<IIndividualData[]>([])
  const [downloadActive, setDownloadActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const searchclient = individualsSearchClient(orgId)
  const index = searchclient.initIndex(ALGOLIA_INDIVIDUALS_INDEX)
  const csvLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)
  const { filterString } = getAlgoliaFilters({ uiState })
  const [headers, setHeaders] =
    useState<{ label: string; key: keyof IIndividualData }[]>()
  const [lists, setLists] = useState<OrgList[]>([])

  useEffect(() => {
    if (orgId) {
      ListListsByOrgId({ orgId }).then(setLists)
    }
  }, [orgId])

  const handleClick = () => {
    let hits: Individual[] = []
    setIsLoading(true)
    index
      .browseObjects({
        query,
        filters: filterString,
        batch: batch => {
          const individuals = batch.map(hit => {
            const individualHit: any = hit as unknown as any
            const parseHit = {
              ...individualHit,
              dob: individualHit.dob && new Date(individualHit.dob),
              startDate:
                individualHit.start_date && new Date(individualHit.start_date),
              created: individualHit.created && new Date(individualHit.created),
              id: individualHit.id || individualHit.objectID,
            }
            return objectToMessage(Individual, parseHit)
          })
          hits = hits.concat(individuals)
        },
      })
      .then(() => {
        const formattedIndividuals = getFormattedIndividuals({
          individuals: hits,
        })

        setCsvData(formattedIndividuals)
        const csvHeaders: { label: string; key: keyof IIndividualData }[] = [
          { label: 'Employee ID', key: 'employeeId' },
          { label: 'First Name', key: 'firstName' },
          { label: 'Last Name', key: 'lastName' },
          { label: 'Email', key: 'email' },
          { label: 'Permission', key: 'permission' },
          { label: 'Individual Status', key: 'status' },
          { label: 'Individual Balance (USD)', key: 'balance' },
          { label: 'Balance When Removed (USD)', key: 'removedBalance' },
          { label: 'Birthday', key: 'dob' },
          { label: 'Start Date', key: 'startDate' },
          { label: 'End Date', key: 'endDate' },
          { label: 'Title', key: 'title' },
          { label: 'Manager ID', key: 'managerId' },
        ]

        lists.forEach(list => {
          if (csvHeaders.find(csvHeader => csvHeader.key === list.id)) return
          csvHeaders.push({
            label: startCase(list.name),
            key: list.id as keyof IIndividualData,
          })
        })

        setHeaders(csvHeaders)
        setDownloadActive(true)
      })
      .catch(e => {
        console.error(e)
        captureException(toSentry(e))
        toaster.warning('Error generating CSV report. Please contact support.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (downloadActive) {
      if (csvLinkRef.current) csvLinkRef.current.link.click()
      setDownloadActive(false)
    }
  }, [downloadActive])
  return (
    <Tooltip placement="bottom" title="Download">
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        icon={<DownloadOutlined />}
        onClick={handleClick}
        loading={isLoading}
      />

      <CSVLink
        headers={headers}
        filename="perkup_people_report.csv"
        data={csvData}
        ref={csvLinkRef}
      />
    </Tooltip>
  )
}
