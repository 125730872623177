import { Avatar, Flex, Tooltip } from 'antd'
import { Text } from 'evergreen-ui'
import { useUserById } from 'hooks'
import { useIndividualById } from 'hooks/individuals/useIndividualById'
import { PropsWithChildren } from 'react'
import { buildFullName, getInitials } from 'utils'
import { getIndividualDisplayName } from 'utils/individual'

function CreatedByIndividual({ individualId }: { individualId: string }) {
  const { individual } = useIndividualById({
    individualId,
  })

  if (!individual) return null

  const displayName = getIndividualDisplayName(individual)

  return (
    <Flex gap={4} align="center">
      <Avatar
        size="small"
        src={individual?.profilePicture}
        style={{ cursor: 'default' }}
      >
        {getInitials(displayName)}
      </Avatar>

      <Text size={300}>{displayName}</Text>
    </Flex>
  )
}

function CreatedByUser({ userId }: { userId: string }) {
  const { user } = useUserById({ userId })

  if (!user) return null

  const displayName =
    buildFullName({
      firstName: user?.profile?.firstName,
      lastName: user?.profile?.lastName,
    }) ||
    user.profile?.email ||
    ''

  return (
    <Flex gap={4} align="center">
      <Avatar
        size="small"
        src={user.profile?.profilePicture}
        style={{ cursor: 'default' }}
      >
        {getInitials(displayName)}
      </Avatar>

      <Text size={300}>{displayName}</Text>
    </Flex>
  )
}

function CreatedBy({
  createdDate,
  vertical = false,
  children,
}: PropsWithChildren<{
  createdDate: Date
  vertical?: boolean
}>) {
  const createdOnDisplayDate = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
    timeStyle: 'long',
  }).format(createdDate)

  return (
    <Tooltip title={`Created on ${createdOnDisplayDate}`} placement="bottom">
      <Flex
        gap={4}
        vertical={vertical}
        align="center"
        style={{ width: 'fit-content' }}
      >
        <Text color="muted" size={300}>
          Created by
        </Text>

        {children}
      </Flex>
    </Tooltip>
  )
}

CreatedBy.User = CreatedByUser
CreatedBy.Individual = CreatedByIndividual

export default CreatedBy
