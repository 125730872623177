import { EditOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { Heading, Pane, Text, TextInput, toaster } from 'evergreen-ui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export function Editable({
  type = 'text',
  value,
  placeholder,
  size = 500,
  onSubmit,
  disabled,
  persistEditorMode = false,
}: {
  type: 'text' | 'heading'
  value: string
  placeholder?: string
  size?: number
  onSubmit: (name: string) => void
  disabled?: boolean
  persistEditorMode?: boolean
}) {
  const [edit, setEdit] = useState(false)
  const { register, handleSubmit } = useForm()

  const handleOnSubmit = ({ value: newValue }: { value: string }) => {
    if (newValue !== value) {
      onSubmit(newValue)
      toaster.success(`Updated to ${newValue}`, { id: newValue })
    }
    setEdit(false)
  }

  if (edit || persistEditorMode) {
    return (
      <Pane is="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <TextInput
          name="value"
          placeholder={placeholder}
          defaultValue={value}
          marginRight={16}
          ref={register}
          required
          autoFocus
          onBlur={handleSubmit(handleOnSubmit)}
        />
      </Pane>
    )
  }

  const TagToUse = type === 'text' ? Text : Heading

  return (
    <Flex align="center" gap={8}>
      <TagToUse
        size={size}
        onClick={() => {
          if (!disabled) setEdit(true)
        }}
        cursor={disabled ? 'default' : 'text'}
      >
        {value}
      </TagToUse>

      {!disabled && (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={() => setEdit(true)}
        />
      )}
    </Flex>
  )
}
