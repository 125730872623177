import type { CollapseProps } from 'antd'
import { Button, Checkbox, Collapse, Flex } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Select from 'antd/es/select'
import { STATUS_NONE_OR_INVITED_OR_ACTIVE } from 'constants/algolia'
import { eligibleIndividualStatuses } from 'constants/individuals'
import { Pane, Text } from 'evergreen-ui'
import { Program_RecipientFilters } from 'gen/perkup/v1/program_pb'
import { intersectionWith, isEmpty } from 'lodash-es'

import { useInfiniteSelectableIndividuals } from 'hooks/individuals/useInfiniteSelectableIndividuals'
import LabelSelection from 'pages/NewReward/components/LabelSelection'
import { useMemo, useState } from 'react'
import { Configure, useSearchBox } from 'react-instantsearch'
import { getIndividualIds } from 'services/individuals'
import { getIndividualDisplayName } from 'utils/individual'
import { InviteEmail } from './InviteEmail'

export const MAX_TAG_COUNT = 20

export interface IndividualClickData {
  selectedIndividualIds?: string[]
  emails?: string[]
  selectionFilters?: Program_RecipientFilters
}

export function IndividualsSelection({
  defaultLabel = 'Select people',
  emails = [],
  selectedIndividualIds,
  showAdvancedSelectOptions = true,
  selectionFilters,
  onIndividualClick,
  showTotalCount = false,
  filters = STATUS_NONE_OR_INVITED_OR_ACTIVE,
  eligibleStatuses = eligibleIndividualStatuses,
  mode = 'multiple',
  width = 280,
}: {
  defaultLabel?: string
  emails?: string[]
  selectedIndividualIds: string[]
  showAdvancedSelectOptions?: boolean
  selectionFilters?: Program_RecipientFilters
  onIndividualClick: ({
    emails,
    selectedIndividualIds,
    selectionFilters,
  }: IndividualClickData) => void
  showTotalCount?: boolean
  filters?: string
  eligibleStatuses?: string[]
  mode?: 'multiple' | 'tags' | 'single'
  width?: string | number
}) {
  const [showInviteEmail, setShowInviteEmail] = useState(false)
  const [allSelected, setAllSelected] = useState(
    !!selectionFilters?.allSelected
  )

  const { refine, clear } = useSearchBox()
  const INCREMENT_AMOUNT = 20

  const { individuals, sentinelRef, hits } = useInfiniteSelectableIndividuals({
    selectedIndividualIds,
    incrementAmount: INCREMENT_AMOUNT,
  })

  const isSingleEntryMode = mode === 'single'

  const handleSelect = (individualId: string) => {
    if (isSingleEntryMode) {
      // onDeselect only works in multiple or tags mode, need this block for single selection/deselection
      const individualAlreadySelected =
        selectedIndividualIds.includes(individualId)
      onIndividualClick({
        selectedIndividualIds: individualAlreadySelected
          ? undefined
          : [individualId],
      })

      return
    }

    const concatenatedIndividualIds = [...selectedIndividualIds, individualId]

    onIndividualClick({
      selectedIndividualIds: concatenatedIndividualIds,
    })
    clear()
  }

  const handleDeselect = (id: string) => {
    // Note that onDeselect doesn't work unless mode is multiple or tags
    const filteredIndividualIds = selectedIndividualIds.filter(
      individualId => id !== individualId
    )
    const updatedSelectionFilters = new Program_RecipientFilters({
      allSelected: false,
    })
    onIndividualClick({
      selectedIndividualIds: filteredIndividualIds,
      selectionFilters: updatedSelectionFilters,
    })
    setAllSelected(false)
    clear()
  }

  const handleSelectAll = async (e: CheckboxChangeEvent) => {
    const { checked } = e.target
    const individualIdsResponse = checked
      ? await getIndividualIds({ statuses: eligibleStatuses })
      : { ids: [] }
    const individualIds = individualIdsResponse.ids

    const updatedSelectionFilters = new Program_RecipientFilters({
      ...selectionFilters,
      allSelected: checked,
    })
    onIndividualClick({
      selectionFilters: updatedSelectionFilters,
      selectedIndividualIds: individualIds,
    })
    setAllSelected(checked)
    clear()
  }

  const maxTagPlaceholderText = useMemo(() => {
    if (selectedIndividualIds.length <= MAX_TAG_COUNT) {
      return ''
    }
    return `+ ${selectedIndividualIds.length - MAX_TAG_COUNT} others...`
  }, [selectedIndividualIds.length])

  const selectedOptions = intersectionWith(
    selectedIndividualIds,
    individuals,
    (id, individual) => id === individual.id
  )

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Advanced options',
      children: (
        <Pane display="flex" flexDirection="column" gap={16}>
          {emails && (
            <Pane>
              {showInviteEmail || !isEmpty(emails) ? (
                <InviteEmail
                  emails={emails}
                  setEmails={emails => {
                    onIndividualClick({ emails })
                  }}
                />
              ) : (
                <Button size="small" onClick={() => setShowInviteEmail(true)}>
                  Add by email
                </Button>
              )}
            </Pane>
          )}
          <LabelSelection
            onLabelSelect={individualData => {
              onIndividualClick({ ...individualData })
            }}
          />
        </Pane>
      ),
    },
  ]

  const options = individuals.map((individual, index) => {
    const isLastIndex = index === individuals.length - 1

    return {
      value: individual.id,
      label: (
        <Text ref={isLastIndex ? sentinelRef : null}>
          {getIndividualDisplayName(individual)}
        </Text>
      ),
      key: individual.id,
    }
  })

  return (
    <Pane width={width}>
      <Configure hitsPerPage={INCREMENT_AMOUNT} filters={filters} />
      <Flex vertical>
        {showAdvancedSelectOptions && (
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingY={16}
          >
            {showTotalCount && (
              <Text color="muted">
                {selectedIndividualIds.length + emails.length} selected
              </Text>
            )}

            <Pane display="flex" alignItems="center" gap={8} justifySelf="end">
              <Text color="muted">Select all</Text>
              <Checkbox checked={allSelected} onChange={handleSelectAll} />
            </Pane>
          </Pane>
        )}
        <Select
          mode={isSingleEntryMode ? undefined : mode}
          placeholder={defaultLabel}
          value={selectedOptions}
          onSelect={(_, option) => {
            handleSelect(option.key)
          }}
          onDeselect={(_, option) => handleDeselect(option.key)}
          onBlur={clear}
          optionFilterProp="label"
          onSearch={e => refine(e)}
          popupMatchSelectWidth={false}
          showSearch
          maxTagCount={MAX_TAG_COUNT}
          maxTagPlaceholder={<Text>{maxTagPlaceholderText}</Text>}
          onClear={
            isSingleEntryMode
              ? () => onIndividualClick({ selectedIndividualIds: undefined })
              : undefined
          }
          allowClear={isSingleEntryMode}
          filterOption={(_, option) => {
            //  Check if algolia hits includes option - lets us know whether to filter it. We want to leverage this prop because it keeps the Tag, and only filters the option.
            const searchHit = hits.find(hit => hit.id === option?.key)
            return !!searchHit
          }}
          options={options}
        />
      </Flex>
      {showAdvancedSelectOptions && <Collapse ghost items={items} />}
    </Pane>
  )
}
