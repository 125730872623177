import type {
  UseCaseEmailData,
  UseCaseRecord,
} from 'pages/NewReward/types/forms'
import { anniversariesUseCases } from './anniversaries'
import { birthdayUseCases } from './birthdays'
import { companyEventsUseCases } from './companyEvents'
import { employeeRecognitionUseCases } from './employeeRecognition'
import { generalUseCases } from './general'
import { holidayUseCases } from './holidays'
import { mealsAndBreaksUseCases } from './mealsAndBreaks'
import { otherUseCases } from './other'
import { performanceAndSalesUseCases } from './performanceAndSales'
import { personalEventsUseCases } from './personalEvents'
import type { RewardUseCasesKeysType } from './RewardUseCasesKeys'

export const RewardUseCases = {
  ...generalUseCases,
  ...anniversariesUseCases,
  ...birthdayUseCases,
  ...employeeRecognitionUseCases,
  ...mealsAndBreaksUseCases,
  ...companyEventsUseCases,
  ...holidayUseCases,
  ...personalEventsUseCases,
  ...performanceAndSalesUseCases,
  ...otherUseCases,
} satisfies Record<RewardUseCasesKeysType, UseCaseRecord>

export type RewardUseCase = keyof typeof RewardUseCases

export const RewardUseCaseEmailData = Object.entries(RewardUseCases).reduce<
  Record<string, UseCaseEmailData[]>
>((acc, [key, value]) => {
  acc[key] = value.emailData
  return acc
}, {})
