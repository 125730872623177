import { LockFilled } from '@ant-design/icons'
import { captureException, captureMessage } from '@sentry/react'
import { PaymentElement, useElements } from '@stripe/react-stripe-js'
import { PaymentIntent, Stripe } from '@stripe/stripe-js'
import { Button, Form } from 'antd'
import { HTTPS_PROD_HOSTNAME, LOCAL_HOST_URL } from 'constants/hosts'
import { isProduction } from 'constants/keys'
import { Pane, Text, toaster } from 'evergreen-ui'
import { useState } from 'react'
import { numToDollars } from 'utils'

export function StripeEmbeddedPayment({
  clientSecret,
  stripe,
  amount,
  returnUrl = isProduction ? HTTPS_PROD_HOSTNAME : LOCAL_HOST_URL,
  disabled = false,
  isLoading: isLoadingPayment,
  onSubmit,
}: {
  clientSecret: string
  stripe: Stripe | undefined
  amount: number
  returnUrl?: string
  disabled?: boolean
  isLoading: boolean
  onSubmit: (paymentIntent: PaymentIntent) => void
}) {
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  const sentryContext = {
    contexts: {
      EmbeddedCheckout: {
        clientSecret,
        amount,
        returnUrl,
      },
    },
  }

  const handleSubmit = async () => {
    if (!elements) {
      toaster.warning('Something went wrong.')
      captureMessage('Stripe elements not found.', sentryContext)
      return
    }

    if (!stripe) {
      toaster.warning('Something went wrong.')
      captureMessage('Stripe not initialized.', sentryContext)
      return
    }

    setIsLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: returnUrl },
      redirect: 'if_required',
    })
    if (!error) {
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
      if (paymentIntent) {
        onSubmit(paymentIntent)
        switch (paymentIntent.status) {
          case 'succeeded':
            break
          case 'processing':
            toaster.notify('Your payment is processing.')
            break
          case 'requires_payment_method':
            toaster.warning(
              'Your payment was not successful, please try again.'
            )
            break
          default:
            toaster.warning('Something went wrong.')
            captureMessage('Unexpected payment intent status.', sentryContext)
            break
        }
      }
    }
    if (error && error.message) {
      captureException(error, sentryContext)
      if (error.type === 'card_error' || error.type === 'validation_error') {
        toaster.warning(error.message)
      } else {
        toaster.warning('An unexpected error occurred.')
      }
    }
    setIsLoading(false)
  }

  return (
    <Pane maxWidth={600} display="flex" flexDirection="column" gap={16}>
      <Form onFinish={handleSubmit}>
        <PaymentElement id="customStripeElement" />

        <Pane display="flex" flexDirection="column" gap={8} marginTop={32}>
          <Button
            size="large"
            type="primary"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            disabled={disabled}
            loading={isLoadingPayment || isLoading}
            icon={<LockFilled />}
            htmlType="submit"
          >
            Pay {numToDollars(amount, 2)}
          </Button>
          <Text color="muted">
            By confirming your payment, you allow PerkUp Inc. to charge your
            card for this payment in accordance with their terms.
          </Text>
        </Pane>
      </Form>
    </Pane>
  )
}
