import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Tag } from 'antd'
import { FulfillmentStatus } from 'api/tinybirdCalls/types'
import { OrderFulfillment_FulfillmentStatus_Enum } from 'gen/perkup/v1/vendor_pb'

const ORDER_FULFILLMENT_STATUS_BASE: Record<
  number | string,
  {
    label: string
    color: string
    icon?: React.ReactNode
  }
> = {
  [OrderFulfillment_FulfillmentStatus_Enum.FULFILLMENT_STATUS_UNSPECIFIED]: {
    label: 'Unspecified',
    color: 'default',
  },
  [OrderFulfillment_FulfillmentStatus_Enum.pending]: {
    label: 'Fulfillment in progress',
    color: 'gold',
    icon: <SyncOutlined />,
  },
  [OrderFulfillment_FulfillmentStatus_Enum.open]: {
    label: 'Open',
    color: 'primary',
  },
  [OrderFulfillment_FulfillmentStatus_Enum.success]: {
    label: 'Fulfilled',
    color: 'success',
    icon: <CheckCircleOutlined />,
  },

  [OrderFulfillment_FulfillmentStatus_Enum.cancelled]: {
    label: 'Cancelled',
    color: 'error',
    icon: <CloseCircleOutlined />,
  },

  [OrderFulfillment_FulfillmentStatus_Enum.error]: {
    label: 'Error',
    color: 'error',
  },

  [OrderFulfillment_FulfillmentStatus_Enum.failure]: {
    label: 'Failure',
    color: 'error',
  },
}

export const ORDER_FULFILLMENT_STATUS = {
  ...Object.fromEntries(
    Object.values(OrderFulfillment_FulfillmentStatus_Enum).map(status => [
      status,
      ORDER_FULFILLMENT_STATUS_BASE[
        OrderFulfillment_FulfillmentStatus_Enum[status as number]
      ],
    ])
  ),
  ...ORDER_FULFILLMENT_STATUS_BASE,
}

export function OrderStatusTag({
  status,
  withIcon = false,
}: {
  status:
    | FulfillmentStatus
    | OrderFulfillment_FulfillmentStatus_Enum
    | undefined
  withIcon?: boolean
}) {
  if (!status || !ORDER_FULFILLMENT_STATUS[status])
    return (
      <Tag color="error" style={{ width: 'fit-content' }}>
        Error
      </Tag>
    )

  const { color, label, icon } = ORDER_FULFILLMENT_STATUS[status]

  return (
    <Tag
      color={color}
      icon={withIcon ? icon : undefined}
      style={{ width: 'fit-content' }}
    >
      {label}
    </Tag>
  )
}
