import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Flex, Image, Modal, Tabs } from 'antd'
import {
  removeCollectionProducts,
  updateCollectionProducts,
} from 'api/databaseCalls'
import { addCollectionProductsAsset } from 'assets'
import { Loader, ProductsGrid } from 'components'
import {
  ALGOLIA_DELIVERY_TYPE_FACET_VALUE,
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE,
  TYPE_NEAR_CASH,
} from 'constants/algolia'
import { NEARCASH } from 'constants/productVariants'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import { Heading, Pane, Text, toaster } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import {
  ProductVariant,
  ProductVariant_DeliveryType,
  ProductVariant_Type,
} from 'gen/perkup/v1/product_variant_pb'

import { useListenToProductVariantsByCollectionId } from 'hooks/productVariants/useListenToProductVariantsByCollectionId'
import { keys } from 'lodash-es'
import { buildProgramGiftAsGenericProduct } from 'pages/NewReward/utils/program-gifts'
import { useContext, useState } from 'react'
import { InstantSearchProps } from 'react-instantsearch'
import { useNavigate } from 'react-router'
import { Selectable } from 'types'
import { Mode } from 'types/edit'
import {
  calculateShopifyProductVariantTotal,
  insertFieldIf,
  makePlural,
} from 'utils'
import {
  getAlgoliaOrgSwagFilters,
  getAlgoliaShippingCountriesFilter,
} from 'utils/Algolia'
import { getUniqAndFormatWithSelectable } from 'utils/productVariant'

interface ProductTab {
  label: string
  value: string
  key: string
  algoliaSearchFilter: string
  placeHolder: string
  hidePrice?: boolean
  menuFilters?: (keyof ProductVariant)[]
  intialUiState?: InstantSearchProps['initialUiState']
  type: ProductVariant_Type
}

const getProductTabs = ({
  orgSwagFilter,
  hideGiftCards,
}: {
  orgSwagFilter: string
  hideGiftCards: boolean
}): ProductTab[] => {
  const shippingCountries = getAlgoliaShippingCountriesFilter()

  return [
    {
      label: 'Swag',
      value: 'swag',
      key: 'swag',
      algoliaSearchFilter: orgSwagFilter,
      placeHolder: 'Search swag',
      menuFilters: ['productType'],
      type: ProductVariant_Type.swag,
    },
    {
      label: 'Gifts',
      value: 'gifts',
      key: 'gifts',
      algoliaSearchFilter: TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE,
      placeHolder: 'Search gifts',
      intialUiState: {
        [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
          menu: {
            shippingCountries,
          },
        },
      },
      type: ProductVariant_Type.publicGift,
    },
    {
      ...insertFieldIf(!hideGiftCards, {
        label: 'Gift cards',
        value: NEARCASH,
        key: NEARCASH,
        algoliaSearchFilter: `${ALGOLIA_DELIVERY_TYPE_FACET_VALUE}:${
          ProductVariant_DeliveryType[ProductVariant_DeliveryType.realtime]
        } AND ${TYPE_NEAR_CASH}`,
        placeHolder: 'Search gift cards',
        hidePrice: true,
        menuFilters: ['productCategories'],
        type: ProductVariant_Type.nearCash,
        intialUiState: {
          [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
            menu: {
              shippingCountries,
            },
          },
        },
      }),
    },
  ]
}

export function ProductCollectionProducts({
  collection,
  mode = Mode.view,
}: {
  collection: ProductCollection
  mode: Mode
}) {
  const { id: collectionId } = collection
  const org = useContext(OrgContext)
  const orgId = org.id

  const navigate = useNavigate()

  const isInEditMode = mode === Mode.edit

  const hideGiftCards = !!org?.settings?.hideNearCash
  const orgSwagFilter = getAlgoliaOrgSwagFilters({ orgId })
  const productTabs = getProductTabs({
    orgSwagFilter,
    hideGiftCards,
  })

  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [showProductDrawer, setShowProductDrawer] = useState(false)
  const [selectedTab, setSelectedTab] = useState<ProductTab>(productTabs[0])
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const { productVariants, hasLoaded } =
    useListenToProductVariantsByCollectionId({ collectionId })

  const allCollectionProducts = getUniqAndFormatWithSelectable(productVariants)

  const tabCollectionProductsToRender: Selectable<ProductVariant>[] =
    allCollectionProducts.filter(pv => pv.type === selectedTab.type)

  const handleAddProduct = async (selectedProduct: ProductVariant) => {
    await updateCollectionProducts({
      collectionId,
      productIds: [selectedProduct.productId],
    })
  }

  const handleRemoveProduct = async (selectedProduct: ProductVariant) => {
    await removeCollectionProducts({
      collectionId,
      productIds: [selectedProduct.productId],
    })
  }

  const handleSelectProduct = async (selectedProduct: ProductVariant) => {
    setIsLoadingSave(true)
    const productIsAdded = allCollectionProducts.some(
      pv => pv.productId === selectedProduct.productId
    )
    const { productName } = selectedProduct
    if (productIsAdded) {
      await handleRemoveProduct(selectedProduct)
      toaster.warning(`${productName} removed from collection`)
    } else {
      await handleAddProduct(selectedProduct)
      toaster.success(`${productName} added to collection`)
    }
    setIsLoadingSave(false)
  }

  const handleGoToProductPage = (productVariant: ProductVariant) => {
    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS}/${productVariant.productId}`
    )
  }

  const handleSendSwagProduct = (productVariant: ProductVariant) => {
    const gift = buildProgramGiftAsGenericProduct({ productVariant })
    const total = calculateShopifyProductVariantTotal(productVariant)
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
      state: { gift, budget: total },
    })
  }

  const productToShowHasOptions =
    keys(productVariantToShow?.options || {}).length >= 1

  const selectedVariantAdded = allCollectionProducts.some(
    pv => pv.productId === productVariantToShow?.productId
  )

  if (!hasLoaded) return <Loader />

  const collectionHasProducts = allCollectionProducts.length > 0

  return (
    <>
      {/** Main content */}
      <Flex vertical gap={16}>
        {/** Header */}
        <Flex justify="space-between">
          <Flex align="center" gap={32}>
            <Heading size={800}>Products</Heading>

            {collectionHasProducts && isInEditMode && (
              <Button
                onClick={() => setShowProductDrawer(true)}
                icon={<PlusOutlined />}
              >
                Add products
              </Button>
            )}
          </Flex>

          <Text color="muted">
            {makePlural('product', allCollectionProducts.length, true)}
          </Text>
        </Flex>

        {/** Products grid or empty state */}
        {!collectionHasProducts && isInEditMode ? (
          <Flex vertical align="center" gap={32}>
            <Image
              preview={false}
              src={addCollectionProductsAsset}
              style={{ maxWidth: 100 }}
            />
            <Flex vertical gap={16} align="center">
              <Heading>Add swag, gifts, or gift cards</Heading>
              <Text>Curate your own custom collection</Text>
            </Flex>
            <Button
              icon={<PlusOutlined />}
              style={{ width: 'fit-content' }}
              type="primary"
              onClick={() => setShowProductDrawer(true)}
            >
              Add products
            </Button>
          </Flex>
        ) : (
          <ProductsGrid
            products={allCollectionProducts}
            onCardClick={setProductVariantToShow}
            onCardAddButtonClick={
              isInEditMode ? handleSelectProduct : undefined
            }
            withPrices
            withAmountsInUsd
            hideSelectedRibbon
          />
        )}
      </Flex>

      {/** Product details modal */}
      <Modal
        width={1024}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => {
          setProductVariantToShow(undefined)
        }}
        styles={{
          body: {
            height: 600,
            overflowY: 'auto',
          },
        }}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice={selectedTab.hidePrice}
            showShipping
            showShippingCountries
            showInStockText={selectedTab.value === 'swag'}
            disableOutOfStock={false}
            onPrimaryCtaClick={
              isInEditMode ? handleSelectProduct : handleSendSwagProduct
            }
            onSecondaryCtaClick={
              isInEditMode ? undefined : handleGoToProductPage
            }
            primaryCtaProps={
              isInEditMode
                ? {
                    type: selectedVariantAdded ? 'default' : 'primary',
                    danger: selectedVariantAdded,
                    children: selectedVariantAdded
                      ? 'Remove product'
                      : 'Add product',
                    loading: isLoadingSave,
                  }
                : {
                    children: 'Send',
                  }
            }
            secondaryCtaProps={
              isInEditMode
                ? undefined
                : {
                    children: 'Edit product',
                  }
            }
            disableVariantOptions
            alertProps={
              productToShowHasOptions
                ? {
                    type: 'info',
                    message:
                      'Recipients will only see options less than the reward budget',
                  }
                : undefined
            }
          />
        )}
      </Modal>

      {/** Drawer with all the other products to choose from */}
      <Drawer
        zIndex={5}
        open={showProductDrawer}
        placement="bottom"
        width="100vw"
        height="100vh"
        closeIcon={null}
        title={
          <Pane
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            zIndex={2}
          >
            <Heading size={600}>Add products</Heading>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setShowProductDrawer(false)}
            />
          </Pane>
        }
        styles={{
          header: {
            borderBottom: 'none',
            width: '100%',
          },
          body: {
            paddingTop: '0px',
          },
        }}
        destroyOnClose
      >
        <Tabs
          items={productTabs}
          activeKey={selectedTab.key}
          onChange={key => {
            const selectedTab = productTabs.find(tab => tab.key === key)
            if (selectedTab) setSelectedTab(selectedTab)
          }}
          className="customTabs"
        />

        <AlgoliaBrowseProducts initialUiState={selectedTab.intialUiState}>
          <AlgoliaBrowseProducts.Old
            key={selectedTab.key}
            onProductCardClick={setProductVariantToShow}
            searchFilter={selectedTab.algoliaSearchFilter}
            addedVariants={tabCollectionProductsToRender}
            withCountryFilter
            withPriceFilter
            withCollectionsFilter={selectedTab.value === 'gifts'}
            withCategoriesFilter={selectedTab.value === 'gifts'}
            withProductAmounts
            filterUiProps={{
              searchPlaceholder: selectedTab.placeHolder,
              priceFilterPrefix: selectedTab.label,
            }}
            onCardAddButtonClick={handleSelectProduct}
            menuFilters={selectedTab.menuFilters}
            selectedRibbonText="Added"
            showAddedVariantsFirst={false}
          />
        </AlgoliaBrowseProducts>
      </Drawer>
    </>
  )
}
