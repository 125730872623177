import { SwagOrdersTable } from 'features'
import { Helmet } from 'react-helmet-async'

export default function OrgSwagOrdersPage() {
  return (
    <>
      <Helmet>
        <title>Orders | Swag</title>
      </Helmet>

      <SwagOrdersTable />
    </>
  )
}
