import { BellOutlined } from '@ant-design/icons'
import { Button, Flex, Tooltip, message } from 'antd'
import { ANT_DESIGN_SUCCESS } from 'constants/colors'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext, ProgramContext } from 'context'
import { Notification_Type } from 'gen/perkup/v1/notification_pb'
import { Member, Program } from 'gen/perkup/v1/program_pb'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SendRewardReminderEmail } from 'services'
import { makePlural } from 'utils'

function checkIfReminderSentInLast24Hrs({
  program,
  member,
}: {
  program: Program
  member?: Member
}) {
  const programReminderLastSent = program.reminderLastSent
  const memberReminderLastSent = member?.actions.reminderLastSent

  const now = new Date()
  const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000)

  // If program was sent reminder, we want to disable button for program and members
  if (programReminderLastSent && programReminderLastSent.toDate() > oneDayAgo) {
    return true
  }
  // If looking at specific member and program was not sent a reminder, we only want to disable button for that member
  if (
    member?.id &&
    memberReminderLastSent &&
    memberReminderLastSent.toDate() > oneDayAgo
  ) {
    return true
  }
  return false
}

export function RewardReminderButton({
  member,
  memberName,
  withText = true,
  buttonType = 'default',
  disabled = false,
  onClick,
}: {
  member?: Member
  memberName?: string
  withText?: boolean
  buttonType?: 'default' | 'text'
  disabled?: boolean
  onClick?: () => void
}) {
  const org = useContext(OrgContext)
  const program = useContext(ProgramContext)

  const navigate = useNavigate()

  const [messageApi, contextHolder] = message.useMessage()

  const orgId = org.id
  const programId = program.id
  const memberId = member?.id

  const [reminderSent, setReminderSent] = useState(() =>
    checkIfReminderSentInLast24Hrs({ program, member })
  )

  const determineTooltipTitle = () => {
    // If a reminder was sent, we need a tooltip to explain why the button is disabled
    if (reminderSent) return 'Reminders can only be sent once every 24 hours'

    // If we have a member, we want to remind that specific member
    if (member) return `Remind ${memberName || 'this recipient'}`

    return `Remind recipients that haven't redeemed this ${program.gift ? 'gift' : 'reward'}`
  }

  const handleSendReminder = async () => {
    setReminderSent(true)

    const messageKey = 'reminder-notif'

    messageApi.open({
      key: messageKey,
      type: 'loading',
      content: 'Sending reminder...',
    })

    await SendRewardReminderEmail({
      orgId,
      programId,
      memberId,
    })

    messageApi.open({
      key: messageKey,
      type: 'success',
      content: (
        <Flex gap={32} align="center">
          {memberId
            ? 'Reminder sent'
            : `Reminder sent to ${program.totalMembers} ${makePlural('recipient', program.totalMembers, true)}`}
          <Button
            type="primary"
            size="small"
            style={{ backgroundColor: ANT_DESIGN_SUCCESS }}
            onClick={() =>
              navigate(DEFAULT_ROUTES.ORGANIZATION.INSIGHTS.LOGS, {
                state: {
                  defaultNotificationType:
                    Notification_Type.rewardReminderNotification,
                },
              })
            }
          >
            View activity
          </Button>
        </Flex>
      ),
      duration: 6,
    })
  }

  return (
    <>
      <Tooltip title={determineTooltipTitle()}>
        <Button
          icon={<BellOutlined />}
          type={buttonType}
          disabled={disabled || reminderSent}
          onClick={e => {
            e.preventDefault()
            onClick?.()
            handleSendReminder()
          }}
        >
          {withText && 'Send reminder'}
        </Button>
      </Tooltip>
      {contextHolder}
    </>
  )
}
