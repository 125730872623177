import {
  AnyMessage,
  Message,
  ScalarType,
  Timestamp as BufTimestamp,
} from '@bufbuild/protobuf'
import { PartialFieldInfo } from '@bufbuild/protobuf/dist/cjs/field'
import {
  DocumentData,
  FieldValue,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  Timestamp,
  WithFieldValue,
} from 'firebase/firestore'
import isEmpty from 'lodash-es/isEmpty'

export function canToDate(x: unknown): x is { toDate(): Date } {
  return typeof x === 'object' && x !== null && 'toDate' in x
}

export function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === 'object'
}

export function isDate(x: unknown): x is Date {
  return x instanceof Date
}

export function fromFirestore(
  obj: Record<string, unknown>
): Record<string, unknown> {
  Object.keys(obj).forEach(key => {
    const val = obj[key]
    if (!obj[key]) return
    if (canToDate(val)) {
      /** @TODO figure this eslint error out */
      // eslint-disable-next-line no-param-reassign
      obj[key] = val.toDate()
    } else if (isObject(val)) {
      fromFirestore(val)
    }
  })
  return obj
}

export function toFirestore(
  obj: Record<string, unknown>
): Record<string, unknown> {
  Object.keys(obj).forEach(key => {
    const val = obj[key]
    if (!obj[key]) return
    if (isDate(val)) {
      /** @TODO figure this eslint error out */
      // eslint-disable-next-line no-param-reassign
      obj[key] = BufTimestamp.fromDate(val)
    } else if (isObject(val)) {
      toFirestore(val)
    }
  })
  return obj
}

// @todo maybe handle optional SetOptions
export function messageToFirestore<T extends Message>(
  M: { new (): T },
  object: WithFieldValue<T> | PartialWithFieldValue<T>
): DocumentData {
  function fieldToFirestore(fieldInfo: PartialFieldInfo, value: any): any {
    if (value instanceof FieldValue) {
      return value
    }
    switch (fieldInfo.kind) {
      case 'message': {
        if (value instanceof BufTimestamp) {
          return Timestamp.fromDate(value.toDate())
        }
        return messageToFirestore(fieldInfo.T, value)
      }
      case 'map': {
        const obj: Record<string, unknown> = {}
        for (let i = 0; i < Object.entries(value).length; i += 1) {
          const [k, v] = Object.entries(value)[i]
          obj[k] = fieldToFirestore(fieldInfo.V as PartialFieldInfo, v)
        }
        return obj
      }
      case 'enum': {
        if (value > 0) {
          const n = fieldInfo.T.findNumber(value)?.name
          if (n !== undefined) {
            return n
          }
        }
        return undefined
      }
      case 'scalar': {
        if (
          fieldInfo.T === ScalarType.INT64 ||
          fieldInfo.T === ScalarType.UINT64
        ) {
          return Number(value)
        }
        return value
      }
      default:
        return value
    }
  }

  const inobj = object as Record<string, unknown>
  const obj: Record<string, unknown> = {}
  const message: AnyMessage = new M()
  message
    .getType()
    .fields.byNumber()
    .forEach(fieldInfo => {
      const value = inobj[fieldInfo.localName]
      if (value !== undefined) {
        if (fieldInfo.repeated && value instanceof Array) {
          obj[fieldInfo.jsonName] = value.map(v =>
            fieldToFirestore(fieldInfo as PartialFieldInfo, v)
          )
        } else {
          obj[fieldInfo.jsonName] = fieldToFirestore(
            fieldInfo as PartialFieldInfo,
            value
          )
        }
      }
    })
  return obj
}

export function messageFromFirestore<T extends Message>(
  M: { new (): T },
  obj: Record<string, unknown>
): T {
  function fieldFromFirestore(fieldInfo: PartialFieldInfo, value: any): any {
    switch (fieldInfo.kind) {
      case 'message': {
        if (value instanceof Timestamp) {
          return BufTimestamp.fromDate(value.toDate())
        }
        return messageFromFirestore(
          fieldInfo.T,
          value as Record<string, unknown>
        )
      }
      case 'enum': {
        if (typeof value === 'string') {
          const n = fieldInfo.T.findName(value)?.no
          if (n !== undefined) {
            return n
          }
        }
        return undefined
      }
      case 'map': {
        const obj: Record<string, unknown> = {}
        for (let i = 0; i < Object.entries(value).length; i += 1) {
          const [k, v] = Object.entries(value)[i]
          obj[k] = fieldFromFirestore(fieldInfo.V as PartialFieldInfo, v)
        }
        return obj
      }
      default:
        return value
    }
  }
  const message: AnyMessage = new M()
  message
    .getType()
    .fields.byNumber()
    .forEach(fieldInfo => {
      const value = obj[fieldInfo.jsonName ?? fieldInfo.localName]
      if (value !== null && value !== undefined) {
        if (fieldInfo.repeated && value instanceof Array) {
          message[fieldInfo.localName] = value.map(v =>
            fieldFromFirestore(fieldInfo, v)
          )
        } else {
          message[fieldInfo.localName] = fieldFromFirestore(
            fieldInfo as PartialFieldInfo,
            value
          )
        }
      }
    })
  return message as T
}

/*
  Convert Object to Message
  - Timestamp (@bufbuild/protobuf/Timestamp of google.protobuf.Timestamp):
    <- firebase.Timestamp (v8 Compat)
    <- Timestamp (firestore v9)
    <- Timestamp (@bufbuild/protobuf/Timestamp)
  - Enum in
    <- string
    <- message.EnumType
  - undefined
    <- null
 */
export function objectToMessage<T extends Message>(
  M: { new (): T },
  obj: Record<string, unknown>
): T {
  function fieldFromObject(fieldInfo: PartialFieldInfo, value: any): any {
    switch (fieldInfo.kind) {
      case 'message': {
        if (value instanceof BufTimestamp) {
          return value
        }
        if (canToDate(value)) {
          return BufTimestamp.fromDate(value.toDate())
        }
        if (value instanceof Date) {
          return BufTimestamp.fromDate(value)
        }
        return objectToMessage(fieldInfo.T, value as Record<string, unknown>)
      }
      case 'enum': {
        if (typeof value === 'string') {
          const n = fieldInfo.T.findName(value)?.no
          if (n !== undefined) {
            return n
          }
        }
        return undefined
      }
      default:
        return value
    }
  }
  const message: AnyMessage = new M()
  message
    .getType()
    .fields.byNumber()
    .forEach(fieldInfo => {
      let value = obj[fieldInfo.localName]
      if (value === null) {
        value = undefined
      }
      if (value !== undefined || !isEmpty(value)) {
        if (fieldInfo.repeated && value instanceof Array) {
          message[fieldInfo.localName] = value.map(v =>
            fieldFromObject(fieldInfo, v)
          )
        } else {
          message[fieldInfo.localName] = fieldFromObject(
            fieldInfo as PartialFieldInfo,
            value
          )
        }
      }
    })
  return message as T
}

export const fromFirestoreIdHook = (
  snap: QueryDocumentSnapshot
): Record<string, unknown> => {
  // Document Ref.id overrides snap.data().id
  return { ...snap.data(), id: snap.id }
}

export const toFirestoreIdHook = (
  doc: Record<string, unknown>
): Record<string, unknown> => {
  // Document Ref.id overrides snap.data().id
  // eslint-disable-next-line no-param-reassign
  delete doc.id
  return doc
}

type FromFirestoreHook = (
  snap: QueryDocumentSnapshot
) => Record<string, unknown>
type ToFirestoreHook = (doc: Record<string, unknown>) => Record<string, unknown>

export class Converter<T extends Message> implements FirestoreDataConverter<T> {
  private readonly M: { new (): T }

  private readonly fromFirestoreHook: FromFirestoreHook

  private readonly toFirestoreHook: ToFirestoreHook

  constructor(
    M: { new (): T },
    options?: {
      fromFirestoreHook?: FromFirestoreHook
      toFirestoreHook?: ToFirestoreHook
    }
  ) {
    this.M = M
    this.fromFirestoreHook = options?.fromFirestoreHook ?? fromFirestoreIdHook
    this.toFirestoreHook = options?.toFirestoreHook ?? toFirestoreIdHook
  }

  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): T {
    return messageFromFirestore(this.M, this.fromFirestoreHook(snapshot))
  }

  toFirestore(modelObject: WithFieldValue<T>): DocumentData
  toFirestore(
    modelObject: PartialWithFieldValue<T>,
    options: SetOptions
  ): DocumentData
  toFirestore(
    modelObject: WithFieldValue<T> | PartialWithFieldValue<T>
  ): DocumentData {
    return this.toFirestoreHook(messageToFirestore(this.M, modelObject))
  }
}

export const converter = <T extends Message>(
  M: {
    new (): T
  },
  options?: {
    fromFirestoreHook?: (snap: QueryDocumentSnapshot) => Record<string, unknown>
    toFirestoreHook?: (doc: Record<string, unknown>) => Record<string, unknown>
  }
): FirestoreDataConverter<T> => {
  return new Converter(M, options)
}

export const storedId = <T extends Message>(M: {
  new (): T
}): FirestoreDataConverter<T> => {
  return new Converter(M, {
    toFirestoreHook: doc => {
      // Document Ref.id is empty when addDoc()
      // must remove when empty as this blows up functions that do
      // { id: docId, ...snap.data() }
      if (doc.id === '') {
        // eslint-disable-next-line no-param-reassign
        delete doc.id
      }
      return doc
    },
  })
}
