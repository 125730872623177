import { Button, Flex, Image, Upload } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/es/upload'
import { Text } from 'evergreen-ui'
import { CSSProperties } from 'react'
import { Mode } from 'types/edit'

export function EditableImage({
  src,
  maxHeight,
  description = 'Suggested dimensions: 984x416. Format: JPG or PNG.',
  mode = Mode.view,
  onImageChange,
}: {
  src: string | undefined
  maxHeight?: CSSProperties['maxHeight']
  description?: string
  mode?: Mode
  onImageChange?: (upload: UploadChangeParam<UploadFile<any>>) => void
}) {
  const isViewMode = mode === Mode.view

  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: isViewMode ? undefined : '#cfcfcf',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Image
        preview={false}
        src={src}
        width="100%"
        style={{
          width: '100%',
          objectFit: 'cover',
          maxHeight: maxHeight || '40vh',
          filter: isViewMode ? undefined : 'brightness(.65)',
        }}
      />

      {!isViewMode && (
        <Flex
          vertical
          gap={8}
          align="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Upload
            onChange={upload => {
              if (upload.file.percent === 100) onImageChange?.(upload)
            }}
            maxCount={1}
            showUploadList={false}
            accept="image/*"
          >
            <Button>Change image</Button>
          </Upload>

          {description && <Text style={{ color: 'white' }}>{description}</Text>}
        </Flex>
      )}
    </div>
  )
}
