import { LoadingOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { Text, useTheme } from 'evergreen-ui'
import { motion } from 'framer-motion'

export function FooterWithCTA({
  ctaText,
  ctaIcon,
  footerWidth = '100%',
  disabled = false,
  isLoading = false,
  isSaving = false,
  fullCta = false,
  children,
  onCTAClick,
}: {
  ctaText: string
  ctaIcon?: JSX.Element
  footerWidth?: string | number
  disabled?: boolean
  isLoading?: boolean
  isSaving?: boolean
  fullCta?: boolean
  children?: JSX.Element
  onCTAClick: () => void
}) {
  const theme = useTheme()
  return (
    <motion.div
      variants={{ open: { height: 250 }, closed: { height: undefined } }}
      style={{
        position: 'fixed',
        width: footerWidth,
        background: theme.colors.white,
        borderTop: `1px solid ${theme.colors.gray300}`,
        bottom: 0,
        right: 0,
        padding: '16px 32px',
        zIndex: 2,
      }}
    >
      <Flex justify="end" align="center" gap={16}>
        {isSaving && (
          <Flex align="center" gap={16}>
            <Text color="muted">Saving</Text>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </Flex>
        )}

        {children}

        <Button
          size="large"
          type="primary"
          onClick={onCTAClick}
          disabled={disabled}
          loading={isLoading}
          style={{
            width: fullCta ? '100%' : 248,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 8,
          }}
        >
          {ctaText}
          {ctaIcon}
        </Button>
      </Flex>
    </motion.div>
  )
}
