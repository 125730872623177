import { captureException, captureMessage } from '@sentry/react'
import { doc, getFirestore, increment, updateDoc } from 'firebase/firestore'
import { toSentry } from 'utils'

export function incrementProductVariantClick({ id }: { id: string }) {
  if (!id) {
    captureMessage('Missing Product Variant ID')
    return
  }

  const db = getFirestore()
  const docRef = doc(db, `productVariants/${id}`)

  updateDoc(docRef, {
    'metrics.clicks': increment(1),
  }).catch(error => {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        incrementProductVariantClick: {
          id,
        },
      },
    })
  })
}

export function updateProductVariantClicksAmount({
  id,
  clicksAmount,
}: {
  id: string
  clicksAmount: number
}) {
  try {
    const db = getFirestore()
    const docRef = doc(db, `productVariants/${id}`)

    return updateDoc(docRef, {
      'metrics.clicks': clicksAmount,
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        incrementProductVariantClick: {
          id,
        },
      },
    })
    return undefined
  }
}
