import { ReloadOutlined } from '@ant-design/icons'
import { Button, Flex, Input, Tooltip } from 'antd'
import { EmojiTextInput, TextEditor } from 'components'
import { defaultOccasions } from 'constants/newReward/occasions'
import { RewardUseCaseEmailData } from 'constants/newReward/rewardUseCases'
import { Strong, useTheme } from 'evergreen-ui'
import { Program } from 'gen/perkup/v1/program_pb'
import { sample } from 'lodash-es'
import { EmailFormData } from '../types/forms'
import { parseDraftData } from '../utils/programs'

export default function EmailForm({
  email,
  setEmail,
  program,
  disabled = false,
}: {
  email: EmailFormData
  setEmail: (email: EmailFormData) => void
  program: Program
  disabled?: boolean
}) {
  const theme = useTheme()

  const handleChangeTitle = (newTitle: string) => {
    setEmail({ ...email, title: newTitle })
  }

  const handleChangeNote = (newNote: string) => {
    setEmail({ ...email, note: newNote })
  }

  const handleChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ ...email, fromName: e.currentTarget.value })
  }

  const handleRefreshData = (field: 'title' | 'note') => {
    // First look for use case if it exists, if not default to occasion
    const rewardUseCase = parseDraftData({ program })?.useCase

    if (rewardUseCase) {
      const emailData = sample(RewardUseCaseEmailData[rewardUseCase])
      if (emailData) return field === 'title' ? emailData.title : emailData.note
    }

    const defaultOccasionData = defaultOccasions.find(
      defaultOccasion => defaultOccasion.occasion === program?.occasion
    )

    if (!defaultOccasionData) return undefined

    const emailData = sample(defaultOccasionData?.emailData)

    if (!emailData) return undefined

    return field === 'title'
      ? handleChangeTitle(emailData.title)
      : handleChangeNote(emailData.note)
  }

  return (
    <Flex vertical gap={16}>
      <Flex vertical gap={4}>
        <Strong>Title</Strong>
        <EmojiTextInput
          key={email.title}
          value={email.title}
          onChange={handleChangeTitle}
          onRefresh={() => handleRefreshData('title')}
          disabled={disabled}
        />
      </Flex>
      <Flex vertical gap={4}>
        <Strong>Message</Strong>
        <TextEditor
          htmlContent={email.note}
          onBlur={handleChangeNote}
          persistEditorMode
          disabled={disabled}
          extraButtons={
            <Tooltip title="Generate new message" placement="top">
              <Button
                disabled={disabled}
                type="text"
                icon={
                  <ReloadOutlined style={{ color: theme.colors.gray500 }} />
                }
                onClick={() => handleRefreshData('note')}
              />
            </Tooltip>
          }
        />
      </Flex>
      <Flex vertical>
        <Strong>From</Strong>
        <Input
          style={{ display: 'block', marginTop: 4, width: '50%' }}
          required
          defaultValue={email.fromName}
          placeholder="from"
          onBlur={handleChangeFrom}
        />
      </Flex>
    </Flex>
  )
}
