import { CopyOutlined } from '@ant-design/icons'
import { Button, Flex, Tooltip } from 'antd'
import { Text } from 'evergreen-ui'
import { useState } from 'react'
import { copyToClipboard } from 'utils'

export function TruncatedCopyableText({
  text,
  textMaxWidth,
  type,
}: {
  text: string
  textMaxWidth?: number
  type?: string
}) {
  const [isHovered, setIsHovered] = useState(false)

  const handleCopyClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    copyToClipboard(text, type)
  }
  return (
    <Flex
      gap={8}
      align="center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCopyClick}
      style={{ cursor: 'pointer', height: 22 }}
    >
      <Tooltip title={text}>
        <Text className="one-line-truncate" maxWidth={textMaxWidth}>
          {text}
        </Text>
      </Tooltip>
      <Tooltip title="Copy to clipboard">
        {isHovered && <Button type="text" icon={<CopyOutlined />} />}
      </Tooltip>
    </Flex>
  )
}
