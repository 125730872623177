import { Flex } from 'antd'
import { ListenToProgramsByOrgId } from 'api/databaseCalls'
import { ReactComponent as DawnSVG } from 'assets/dawn.svg'
import { NewProgramDialog, PageHeader, withOrgSidebar } from 'components'
import { OrgContext } from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { useContext, useEffect, useState } from 'react'
import NewProgram from './NewProgram'
import ProgramCard from './ProgramCard'

function PerkProgram() {
  const org = useContext(OrgContext)
  const [programs, setPrograms] = useState<Program[]>([])

  const orgId = org?.id

  // List perk programs
  useEffect(() => {
    if (!orgId) return undefined
    return ListenToProgramsByOrgId({
      orgId,
      filters: { types: [ProgramType.perks] },
      callback: querySnapshot =>
        setPrograms(querySnapshot.docs.map(doc => doc.data())),
    })
  }, [orgId])

  return (
    <>
      <Flex vertical gap={16}>
        <PageHeader
          title="Recurring Programs"
          description="Engage your team with monthly or annual stipends."
          buttonsToRenderOnLeft={<NewProgram type="primary" />}
        />
        {programs.length === 0 ? (
          <Pane marginTop={64} textAlign="center">
            <Pane marginBottom={16}>
              <DawnSVG width={128} />
            </Pane>
            <Heading size={700} marginBottom={16}>
              No recurring programs
            </Heading>
          </Pane>
        ) : (
          <Pane
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(448px, 1fr))"
            gap={16}
          >
            {programs.map(program => (
              <ProgramCard key={program.id} program={program} />
            ))}
          </Pane>
        )}
      </Flex>
      <NewProgramDialog />
    </>
  )
}

export default withOrgSidebar(PerkProgram)
