import {
  appreciation,
  birthday,
  holiday,
  incentive,
  lifeEvent,
  lunch,
  newEmployee,
  teamEvent,
  workAnniversary,
} from 'assets/icons'
import { ProgramOccasion } from 'gen/perkup/v1/program_pb'
import { UseCaseEmailData } from 'pages/NewReward/types/forms'
import type { RewardUseCase } from './rewardUseCases'
import { RewardUseCasesKeys } from './rewardUseCases/RewardUseCasesKeys'

export interface OccasionType {
  occasion: ProgramOccasion
  image: string
  useCases: RewardUseCase[]
  emailData: UseCaseEmailData[]
  label: string
}

export const defaultOccasions: OccasionType[] = [
  {
    occasion: ProgramOccasion.appreciation,
    image: appreciation,
    useCases: [
      RewardUseCasesKeys.THANKS,
      RewardUseCasesKeys.SHOUTOUTS,
      RewardUseCasesKeys.ABOVE_AND_BEYOND,
      RewardUseCasesKeys.CONGRATULATIONS,
      RewardUseCasesKeys.LEADERSHIP,
      RewardUseCasesKeys.INNOVATION,
      RewardUseCasesKeys.TEAMWORK,
      RewardUseCasesKeys.HIGH_IMPACT,
      RewardUseCasesKeys.ROLE_MODEL,
      // RewardUseCasesKeys.MENTAL_HEALTH_DAY,
    ],
    emailData: [
      {
        title: 'We Appreciate You!',
        note: "Thank you for continuously going above & beyond! Words can't describe how much we appreciate you. Keep it up!",
      },
      {
        title: 'Outstanding Work! 🌟',
        note: 'Your dedication and hard work are truly inspiring! Thank you for everything you do!',
      },
      {
        title: 'Innovative Thinker! 💡',
        note: 'We are constantly amazed by your creativity and innovative thinking. Thank you for your outstanding contributions!',
      },
      {
        title: 'Positive Impact! 😊',
        note: 'Your positive attitude and work ethic are contagious. Thanks for bringing your best every day!',
      },
      {
        title: 'Thank you! 🎉',
        note: 'Thank you for being a key part of our success. Your commitment has not gone unnoticed!',
      },
      {
        title: 'Shoutout 📣',
        note: "Your ability to tackle challenges head-on is admirable. We're grateful for your resilience and hard work!",
      },
      {
        title: 'High Achiever! 🏆',
        note: 'Thank you for your exceptional work. You set a high bar for everyone around!',
      },
      {
        title: 'Exceeding Expectations! ✨',
        note: 'We truly appreciate your constant effort to not just meet, but exceed expectations. Thank you!',
      },
      {
        title: 'Team Strength! 💪',
        note: 'Your enthusiasm and dedication are a source of strength to our team. Thanks for being amazing!',
      },
      {
        title: 'Vital Team Member! 🌟',
        note: 'Thank you for your unwavering commitment. You are a vital member of our team!',
      },
      {
        title: 'Oustanding Performance!',
        note: 'Your exceptional performance consistently impresses us. Thank you for your hard work!',
      },
      {
        title: 'Creative Mind! 🎨',
        note: "We're grateful for the unique perspective and ideas you bring to the team. Thank you for your contributions!",
      },
      {
        title: 'Exemplary Team Player! 🙌',
        note: 'Thank you for being an exemplary team player and a positive force in our workplace!',
      },
      {
        title: 'Inspiring Leader! 🔥',
        note: 'Your ability to turn challenges into opportunities is incredible. Thank you for inspiring us!',
      },
      {
        title: 'Attention to Detail! 🕵️',
        note: 'We appreciate your meticulous attention to detail and dedication to excellence. Thank you!',
      },
      {
        title: 'Invaluable Asset! 💎',
        note: 'Your relentless drive and passion are what make you an invaluable asset to our team. Thank you!',
      },
      {
        title: 'Commitment to Quality! 🌟',
        note: 'We admire your ability to maintain high standards in your work. Thank you for your commitment!',
      },
      {
        title: "You're a ⭐️",
        note: 'Your continuous improvement and growth are commendable. Thank you for always setting such a great example!',
      },
      {
        title: 'Quality Results! ✔️',
        note: 'Thank you for consistently delivering quality results. Your work ethic is truly appreciated!',
      },
      {
        title: 'Positive Energy! ⚡',
        note: 'Your positive energy and attitude make the workplace a better place. Thank you for all you do!',
      },
      {
        title: 'Thanks for stepping up!',
        note: "We're thankful for the way you always step up in times of need. Your reliability is a treasure!",
      },
      {
        title: 'Success Driver! 🚗',
        note: 'Thank you for your innovative solutions and approaches. You are a key driver of our success!',
      },
      {
        title: 'Team Unifier! 🤗',
        note: 'Your dedication to teamwork and unifying our efforts is invaluable. Thank you for being you!',
      },
      {
        title: 'Extra Mile! 🏃‍♂️',
        note: 'We appreciate how you always go the extra mile in your work. Thank you for your exceptional effort!',
      },
      {
        title: 'Role Model! 🌟',
        note: 'Your ability to listen, adapt, and grow is highly appreciated. Thank you for being a role model!',
      },
      {
        title: 'Significant Impact! 💥',
        note: 'Thank you for consistently bringing your all and making a significant impact!',
      },
      {
        title: 'Team Spark! ✨',
        note: 'Your passion and dedication shine through in everything you do. Thank you for being a part of our team!',
      },
      {
        title: 'Pursuit of Excellence! 🚀',
        note: 'We are grateful for your relentless pursuit of excellence. Thank you for setting the standard!',
      },
      {
        title: 'Problem Solver! 🧠',
        note: 'Your creative problem-solving skills are a true asset to our team. Thank you for your contributions!',
      },
      {
        title: 'Dependability Award! 👊',
        note: 'Thank you for always being someone we can count on. Your dependability is remarkable',
      },
      {
        title: 'Radiating Positivity!',
        note: 'Your enthusiasm for your work is infectious. Thank you for adding so much positivity to our team!',
      },
      {
        title: 'Innovative Thinker! 🌐',
        note: 'We value your unique insights and approach. Thank you for helping us think outside the box!',
      },
      {
        title: "You're a ⭐️",
        note: 'Thank you for being an essential piece of our success puzzle. Your efforts are greatly appreciated!',
      },
      {
        title: 'You inspire us!',
        note: 'Your ability to lead by example is inspiring. Thank you for being an outstanding team member!',
      },
      {
        title: 'Proactive Visionary! 🔭',
        note: "We're thankful for your proactive approach and ability to foresee needs. Your foresight is invaluable!",
      },
      {
        title: 'Quality Champion! 🏅',
        note: 'Your commitment to quality is evident in everything you do. Thank you for your excellent work!',
      },
      {
        title: 'Key Contributor! 🗝️',
        note: 'Your hard work and dedication have not gone unnoticed. Thank you for being a key contributor!',
      },
      {
        title: 'Excellence Icon! 🌠',
        note: 'We appreciate how you always strive for excellence and lead by example. Thank you for your hard work!',
      },
      {
        title: 'Innovation Driver! 🚀',
        note: 'Your continuous efforts to improve and innovate are truly inspiring. Thank you for your dedication!',
      },
      {
        title: 'Teamwork Master! 👥',
        note: 'Thank you for always being ready to lend a hand and support your teammates. Your teamwork is exemplary!',
      },
      {
        title: 'Solution Seeker! 🔦',
        note: 'Your positive approach to problem-solving is highly valued. Thank you for your creative solutions!',
      },
      {
        title: 'Positivity Beacon!',
        note: 'Thank you for being a consistent source of positivity and professionalism in our team!',
      },
      {
        title: 'We Appreciate You!',
        note: 'Your determination and drive are awe-inspiring. Thank you for pushing the boundaries!',
      },
      {
        title: 'Brave Explorer! 🚀',
        note: 'We appreciate your willingness to take on new challenges and grow. Thank you for your bravery!',
      },
      {
        title: 'Valued Contributor!',
        note: "Your contribution to our team's success is deeply valued. Thank you for your hard work and dedication!",
      },
      {
        title: 'Collaboration Hero! 🦸',
        note: 'Thank you for your ability to collaborate and bring out the best in others. Your teamwork is commendable!',
      },
      {
        title: 'Fantastic Energy! ⚡',
        note: "We're thankful for the energy and expertise you bring to our team. Thank you for being a vital part of our success!",
      },
      {
        title: "You're a Star! 🌟",
        note: 'Your consistent performance and dedication are key to our growth. Thank you!',
      },
      {
        title: 'Thank You!',
        note: "Your hard work hasn't gone unnoticed. Thanks for everything!",
      },
      {
        title: 'Gratitude Alert 🚨',
        note: 'Just a little note to say thank you for being amazing!',
      },
      { title: 'Kudos!', note: "You've outdone yourself. Thank you!" },
      {
        title: 'Extraordinary Efforts!',
        note: "You've gone above & beyond, and we're beyond grateful. Keep soaring!",
      },
      {
        title: 'Raising the Bar 📈',
        note: 'Your dedication and hard work are unmatched. Thank you for pushing limits!',
      },
      {
        title: 'Standing Ovation 👏',
        note: 'For the one who always gives their best. We see you, and we appreciate you!',
      },
    ],
    label: 'Appreciation',
  },
  {
    occasion: ProgramOccasion.teamEvent,
    image: teamEvent,
    useCases: [
      RewardUseCasesKeys.COMPANY_EVENTS,
      RewardUseCasesKeys.MOVIE_NIGHT,
      RewardUseCasesKeys.GAME_NIGHT,
      RewardUseCasesKeys.CONTEST_WINNER,
      RewardUseCasesKeys.TRIVIA_NIGHT,
      RewardUseCasesKeys.BOOK_CLUB,
      RewardUseCasesKeys.COMPANY_OFFSITE,
      RewardUseCasesKeys.TEAM_DINNER,
    ],
    emailData: [
      {
        title: "Let's Celebrate Together!",
        note: "Join us for a memorable time! We've planned some exciting team events to strengthen our bond. Let's make some memories.",
      },
      {
        title: 'Movie Buff!',
        note: 'Thanks for the film recommendations. Movie night was a hit!',
      },
      {
        title: 'Popcorn & Flicks 🍿',
        note: 'Your presence made movie night even more special. Thanks for joining!',
      },
      {
        title: 'Movie Night!',
        note: 'From thrillers to comedies, you make movie nights awesome!',
      },
      {
        title: 'Game Master!',
        note: "Thanks for making game night a blast. Can't wait for the next one!",
      },
      {
        title: 'Game Night 🎲',
        note: 'Your strategies and skills lit up game night!',
      },
      {
        title: 'Congratulations!',
        note: 'Thanks for joining in on the fun. Game nights are better with you!',
      },
      {
        title: 'Trivia Titan!',
        note: "Your knowledge is impressive! Can't wait for the next trivia night.",
      },
      {
        title: 'Brainiac Alert 🧠',
        note: "You rocked trivia night. Here's to more fun and learning!",
      },
      {
        title: 'Quiz Whiz!',
        note: 'Your trivia skills are top-notch. Great job!',
      },
      {
        title: 'Adventure Awaits!',
        note: 'Join us for an exciting company offsite. Fun and bonding await!',
      },
      {
        title: 'Team Getaway 🌴',
        note: 'Pack your bags for a memorable team offsite. See you there!',
      },
      {
        title: 'Offsite Alert!',
        note: "Get ready for some team fun and adventure. Can't wait to see you there!",
      },
    ],
    label: 'Team Event',
  },
  {
    occasion: ProgramOccasion.lunch,
    image: lunch,
    useCases: [
      RewardUseCasesKeys.TEAM_LUNCH,
      RewardUseCasesKeys.COFFEE_BREAK,
      RewardUseCasesKeys.TEAM_DINNER,
      RewardUseCasesKeys.WELCOME_LUNCH,
      RewardUseCasesKeys.SNACK_BREAK,
      RewardUseCasesKeys.ICE_CREAM_PARTY,
      RewardUseCasesKeys.DONUT_DAY,
    ],
    emailData: [
      {
        title: "Let's Break Bread!",
        note: 'Good food is even better when shared. Join us for a delicious meal and bonding time. A culinary treat awaits you!',
      },
      {
        title: 'Lunch Break!',
        note: "Good food with great team members! Let's enjoy.",
      },
      {
        title: 'Feast Mode 🍴',
        note: "Here's to delicious memories and team bonding over lunch.",
      },
      {
        title: 'Lunch Vibes!',
        note: 'Lunches with the team are always special. Thanks for being there!',
      },
      {
        title: 'Coffee Break!',
        note: 'Every sip is a recharge. Enjoy your coffee break!',
      },
      {
        title: 'Coffee Break ☕️',
        note: 'A little break makes everything better. Treat yourself to a cup of coffee and a snack on us!',
      },
      {
        title: 'Espresso Yourself!',
        note: 'Take a moment, relax, and enjoy your coffee break.',
      },
      {
        title: 'Snack & Relax!',
        note: 'A little treat to keep the energy high. Enjoy the snack break!',
      },
      {
        title: 'Crunch Time 🍪',
        note: "Take a delicious break, you've earned it!",
      },
      { title: "Treat O'Clock!", note: "It's snack time! Refuel and refresh." },
      {
        title: 'Dinner Delights!',
        note: "Here's to good food and even better company. Cheers to the team dinner!",
      },
      {
        title: 'Feast & Fun 🍲',
        note: 'Celebrating our successes with a delightful team dinner.',
      },
      {
        title: 'Dine & Shine!',
        note: 'A meal shared among colleagues makes for the best memories. Enjoy the team dinner!',
      },
    ],
    label: 'Lunch',
  },
  {
    occasion: ProgramOccasion.workAnniversary,
    image: workAnniversary,
    useCases: [
      RewardUseCasesKeys.ANNIVERSARIES,
      RewardUseCasesKeys.ONE_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.TWO_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.THREE_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.FOUR_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.FIVE_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.SIX_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.SEVEN_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.EIGHT_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.NINE_YEAR_ANNIVERSARY,
      RewardUseCasesKeys.TEN_YEAR_ANNIVERSARY,
    ],
    emailData: [
      {
        title: 'Happy Work Anniversary!',
        note: "Congratulations on reaching this milestone with us! Your dedication and passion are unparalleled. Here's to many more years!",
      },
      {
        title: 'Happy Work Anniversary!',
        note: 'Congratulations on another successful year with us! Your hard work and commitment are truly appreciated. Cheers to the journey ahead!',
      },
      {
        title: 'Celebrating Your Milestone!',
        note: "It's time to celebrate your work anniversary! Your dedication is inspiring, and we're lucky to have you. Here's to more achievements together!",
      },
      {
        title: 'Cheers to Your Work Anniversary!',
        note: 'Today marks another year of your incredible contribution to our team. Thank you for your unwavering dedication. Looking forward to many more years!',
      },
      {
        title: 'A Toast to Your Tenure!',
        note: "Congratulations on reaching this significant milestone with us. Your passion and perseverance set you apart. Here's to continued success!",
      },
      {
        title: 'Saluting Your Service!',
        note: 'Your work anniversary is a testament to your loyalty and excellence. Thank you for being an essential part of our success story. To many more years together!',
      },
      {
        title: 'Celebrating Your Dedication!',
        note: "Happy work anniversary! Your commitment to excellence has not gone unnoticed. We're thrilled to celebrate this milestone with you. Here's to the future!",
      },
      {
        title: 'Marking Your Milestone!',
        note: 'Your work anniversary is a moment to reflect on your incredible journey with us. Your efforts and achievements are commendable. Cheers to many more fruitful years!',
      },
      {
        title: 'Honoring Your Commitment!',
        note: "On your work anniversary, we want to express our gratitude for your hard work and dedication. Your contribution is invaluable. Here's to our continued collaboration!",
      },
      {
        title: 'Another Year of Excellence!',
        note: 'Congratulations on your work anniversary! Your dedication to your role is exemplary. We look forward to achieving more milestones together.',
      },
      {
        title: 'Celebrating Your Journey with Us!',
        note: "Happy work anniversary! Your journey with us has been remarkable, and we appreciate every effort you've made. Excited for the road ahead!",
      },
    ],
    label: 'Work Anniversary',
  },
  {
    occasion: ProgramOccasion.birthday,
    image: birthday,
    useCases: [
      RewardUseCasesKeys.BIRTHDAYS,
      RewardUseCasesKeys.FUNNY_BIRTHDAY,
      RewardUseCasesKeys.PET_BIRTHDAY,
    ],
    emailData: [
      {
        title: 'Happy Birthday!',
        note: 'Wishing you a year filled with happiness, growth, and laughter. Enjoy your day and the treats coming your way!',
      },
      {
        title: 'Happy Birthday!',
        note: 'We hope you have an amazing birthday 🥳',
      },
      {
        title: 'Happy Birthday 🎂',
        note: 'Have an outstanding birthday, you deserve it!',
      },
      {
        title: 'Celebrate!',
        note: 'Another year, another adventure! Happy Birthday!',
      },
      {
        title: 'Older But Gold!',
        note: 'Another year, another wrinkle! Just kidding, happy birthday!',
      },
      {
        title: 'Aging Like Fine Wine 🍷',
        note: 'They say age is just a number, but you make it look good!',
      },
      {
        title: 'Birthdays & Bloopers!',
        note: 'Another year to prove that older doesn’t really mean wiser! Have a fun-filled birthday!',
      },
    ],
    label: 'Birthday',
  },
  {
    occasion: ProgramOccasion.holiday,
    image: holiday,
    useCases: [
      RewardUseCasesKeys.HOLIDAYS,
      RewardUseCasesKeys.CHRISTMAS,
      RewardUseCasesKeys.HANUKKAH,
      RewardUseCasesKeys.HALLOWEEN,
      RewardUseCasesKeys.THANKSGIVING,
    ],
    emailData: [
      {
        title: "Season's Greetings!",
        note: 'Wishing you a joyous holiday season filled with warmth and cheer. Enjoy the festivities and this special token of our appreciation for you!',
      },
      {
        title: 'Merry & Bright',
        note: 'May the magic of the holidays fill your heart and home!',
      },
      {
        title: 'Festive Cheers!',
        note: 'Thanks for bringing joy to our workplace. Happy holidays!',
      },
      {
        title: "Season's Greetings!",
        note: 'Warm wishes and joy this holiday season!',
      },
      {
        title: 'Happy Holidays!',
        note: 'Enjoy the festivities and the time with loved ones.',
      },
      {
        title: 'Holiday Cheers 🥂',
        note: "Here's to a season filled with warmth, comfort, and good cheer!",
      },
    ],
    label: 'Holiday',
  },
  {
    occasion: ProgramOccasion.newEmployee,
    image: newEmployee,
    useCases: [
      RewardUseCasesKeys.NEW_EMPLOYEE,
      RewardUseCasesKeys.WELCOME_LUNCH,
      RewardUseCasesKeys.NEW_HIRE_SWAG,
    ],
    emailData: [
      {
        title: 'Welcome Aboard!',
        note: "Thrilled to have you with us! Let's make your journey memorable. A little something to kickstart your new chapter.",
      },
      {
        title: 'Welcome to the team!',
        note: "A warm welcome to the team. Can't wait to get to know each other better!",
      },
      {
        title: 'First of Many',
        note: 'This is just the beginning. Welcome to the team!',
      },
      {
        title: 'New Journey Begins 🚀',
        note: "We're excited to embark on this journey with you. Welcome!",
      },
      {
        title: 'Hey there, Newbie!',
        note: "A big warm welcome to our team. We're so glad you're here!",
      },
      {
        title: 'A Hearty Welcome Aboard!',
        note: "It's fantastic to have you with us. Your talents and energy are a wonderful addition to our team's dynamic.",
      },
      {
        title: 'Thrilled to Have You Join Us!',
        note: 'Welcome to the family! Your unique skills are going to be an essential part of our journey towards success.',
      },
      {
        title: 'The Team Just Got Better!',
        note: "Your arrival marks an exciting time for us. We're looking forward to your fresh ideas and contributions.",
      },
      {
        title: 'New Beginnings, Welcome!',
        note: "We're so excited to start this new chapter with you. Your presence is already making a difference.",
      },
      {
        title: 'Welcome!',
        note: "Your expertise is a valuable asset to our team. We can't wait to see all that we can achieve together.",
      },
      {
        title: 'Welcome to Your New Team!',
        note: "We believe your skills and enthusiasm will bring great energy to our team. Excited for what's ahead!",
      },
      {
        title: 'Cheers to New Adventures!',
        note: "Welcome aboard! Each day is an opportunity for growth and collaboration, and we're glad you're here to share it with us.",
      },
      {
        title: 'Embarking on a Shared Journey',
        note: "Your first day is just the beginning. We're here to support and celebrate every milestone with you.",
      },
      {
        title: 'Adding Value from Day One!',
        note: "Your role in our team is crucial, and we're thrilled to start this journey together. Welcome to the team!",
      },
      {
        title: "Our Team's Newest Star!",
        note: "Welcome! Your talents and dedication shine bright. We're eager to see the heights we'll reach together.",
      },
    ],
    label: 'New Employee',
  },
  {
    occasion: ProgramOccasion.incentive,
    image: incentive,
    useCases: [
      RewardUseCasesKeys.PERFORMANCE_BONUS,
      RewardUseCasesKeys.SALES_SPIFF,
      RewardUseCasesKeys.EMPLOYEE_OF_THE_WEEK,
      RewardUseCasesKeys.KUDOS,
      RewardUseCasesKeys.LEADERSHIP,
      RewardUseCasesKeys.INNOVATION,
      RewardUseCasesKeys.TEAMWORK,
      RewardUseCasesKeys.HIGH_IMPACT,
      RewardUseCasesKeys.ROLE_MODEL,
    ],
    emailData: [
      {
        title: 'Amazing Job!',
        note: "Your dedication and hard work have caught our eyes. We are proud to have you in our team. Here's a token of our appreciation.",
      },
      {
        title: 'Extraordinary Efforts!',
        note: "You've gone above & beyond, and we're beyond grateful. Keep soaring!",
      },
      {
        title: 'Raising the Bar 📈',
        note: 'Your dedication and hard work are unmatched. Thank you for pushing limits!',
      },
      {
        title: 'Standing Ovation 👏',
        note: 'For the one who always gives their best. We see you, and we appreciate you!',
      },
      {
        title: 'Shoutout!',
        note: 'Your efforts have not gone unnoticed. Amazing job!',
      },
      {
        title: "You're a Star ⭐",
        note: 'Giving a big shoutout for your outstanding work!',
      },
      {
        title: 'Applause!',
        note: "Everyone's talking about the great job you did. Kudos!",
      },
      {
        title: 'Star of the Week!',
        note: 'Your hard work shines bright! Congrats on being the employee of the week.',
      },
      {
        title: 'Weekly Wonder 🌟',
        note: 'A well-deserved recognition for a job well done this week!',
      },
      {
        title: "Week's Best!",
        note: "You've outshone us all this week. Keep up the incredible work!",
      },
      {
        title: 'Big Kudos!',
        note: 'Your achievements are making waves. Fantastic job!',
      },
      {
        title: 'Pat on the Back 👏',
        note: "Just some kudos for the amazing work you're doing. Cheers!",
      },
      {
        title: 'Hats Off!',
        note: "We're in awe of your accomplishments. Keep going!",
      },
    ],
    label: 'Incentive',
  },
  {
    occasion: ProgramOccasion.lifeEvent,
    image: lifeEvent,
    useCases: [
      RewardUseCasesKeys.NEW_BABY,
      RewardUseCasesKeys.NEW_HOME,
      RewardUseCasesKeys.WEDDING,
      RewardUseCasesKeys.GET_WELL,
      RewardUseCasesKeys.RETIREMENT,
      RewardUseCasesKeys.BEREAVEMENT,
      RewardUseCasesKeys.CONGRATULATIONS,
    ],
    emailData: [
      {
        title: 'Congrats on your milestone!',
        note: "Wishing you love and happiness on this special occasion. We're honored to be a part of your journey. Here's a little something!",
      },
      {
        title: "Here's to Your Achievement!",
        note: "What a remarkable achievement! We're so proud of you and excited to see what you'll accomplish next.",
      },
      {
        title: 'A Toast to Your Success!',
        note: 'Your hard work and dedication have truly paid off. Congratulations on this well-deserved success.',
      },
      {
        title: 'A Special Moment to Celebrate!',
        note: 'This is a special moment in your life, and we are thrilled to celebrate it with you. Congratulations!',
      },
      {
        title: 'Congrats on Your Big Day!',
        note: "Your big day is here, and it's so well-deserved. Congratulations on reaching this significant milestone.",
      },
      {
        title: 'Celebrating Your Hard Work!',
        note: 'Your hard work and perseverance have led to this moment. Congratulations on this remarkable achievement.',
      },
    ],
    label: 'Life Event',
  },
]
