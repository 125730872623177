import { Image, ImageProps } from 'antd'

const buildContentfulSrcSet = (url: string) => {
  const urlAlreadyHasQueryParams = url.includes('?')

  if (urlAlreadyHasQueryParams) {
    return `${url}&w=360 360w, ${url}&w=480 480w, ${url}&w=640 640w, ${url}&w=1080 1080w`
  }

  return `${url}?w=360 360w, ${url}?w=480 480w, ${url}?w=640 640w, ${url}?w=1080 1080w`
}

const buildUnsplashSrcSet = (url: string) => {
  return `${url}&auto=format&w=360&fit=max 360w, ${url}&auto=format&w=480&fit=max 480w, ${url}&auto=format&w=640&fit=max 640w, ${url}&auto=format&w=1080&q=80&fit=max 1080w`
}

const buildShopifySrcSet = (url: string) => {
  return `${url}&width=720 720w, ${url}&width=1080 1080w, ${url}&width=1440 1440w`
}

const buildGiphySrcSet = (url: string) => {
  const smallUrl = url.replaceAll('giphy.gif', 'giphy-preview.webp')
  const mediumUrl = url.replaceAll('giphy.gif', 'giphy.webp')

  return `${smallUrl} 380w, ${mediumUrl} 480w`
}

const buildSrcSet = (url: string) => {
  const isContentfulUrl = url.includes('images.ctfassets.net')
  const isUnsplashUrl = url.includes('unsplash')
  const isShopifyUrl = url.includes('shopify')
  const isGiphyUrl = url.includes('giphy')

  if (isContentfulUrl) return buildContentfulSrcSet(url)
  if (isUnsplashUrl) return buildUnsplashSrcSet(url)
  if (isShopifyUrl) return buildShopifySrcSet(url)
  if (isGiphyUrl) return buildGiphySrcSet(url)

  return undefined
}

const buildPreviewProps = (): ImageProps['preview'] => {
  return {
    toolbarRender: () => null,
    imageRender: (_, { image: { url, alt } }) => (
      <img
        src={url}
        alt={`Preview of ${alt}`}
        width="100%"
        style={{ maxWidth: '720px' }}
      />
    ),
  }
}

export function PerkImage({
  src,
  alt,
  style,
  sizes,
  preview = false,
}: {
  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/sizes
   *
   * Overriding to make this mandatory as sizes under the hood defaults to "100vw"!
   *
   * Think of sizes as a way to give the browser and this component a bit of information about the parent / page’s layout a little ahead of time,
   * so that it can pick a source. If the user of this component knows that their image will only ever occupy a third of the viewport,
   * then our sizes attribute should look like this: sizes="33.3vw"
   */
  sizes: string
} & Pick<ImageProps, 'src' | 'alt' | 'style' | 'preview'>) {
  return (
    <Image
      src={src}
      fallback="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ=" // Replace this with a static svg asset created by design
      alt={alt}
      style={style}
      srcSet={src ? buildSrcSet(src) : undefined}
      sizes={sizes}
      preview={preview ? buildPreviewProps() : false}
      onClick={e => {
        if (!preview) return
        e.stopPropagation()
        e.preventDefault()
      }}
    />
  )
}
