import { Button, Flex, Select, Tabs } from 'antd'
import { ALL_PRODUCTS, FILTER_DISPLAY_NAMES } from 'constants/algolia'
import { Strong } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { capitalize, startCase } from 'lodash-es'
import { useClearRefinements, useHierarchicalMenu } from 'react-instantsearch'
import { cleanCategoryLabel } from 'utils/Algolia'

export function AlgoliaHierachicalMenuFilter({
  attribute,
  numLevels,
  asSelectDropdown,
}: {
  attribute: keyof ProductVariant
  numLevels: number
  asSelectDropdown?: boolean
}) {
  const attributes = new Array(numLevels)
    .fill(attribute)
    .map((attr, index) => `${attr}.lvl${index}`)

  const { refine: clearRefinements } = useClearRefinements({
    includedAttributes: attributes,
  })

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } =
    useHierarchicalMenu({
      limit: 10,
      attributes,
      sortBy: ['name:asc'],
      showMore: true,
      showMoreLimit: 1000,
    })

  const cleanItems = items.filter(item => item.value !== 'Mature') // Remove 'Mature' from the list of items. We can add more check's in the future if needed.

  const activeItem = items.find(item => item.isRefined)

  const itemsWithAllTab = [
    {
      key: ALL_PRODUCTS,
      label: startCase(ALL_PRODUCTS),
      value: ALL_PRODUCTS,
    },
    ...cleanItems.map(item => ({
      key: item.value,
      label: cleanCategoryLabel(item.label),
      value: item.value,
    })),
  ]

  if (asSelectDropdown) {
    return (
      <Select
        style={{ width: '100%' }}
        allowClear
        onClear={clearRefinements}
        popupMatchSelectWidth={false}
        placeholder={startCase(FILTER_DISPLAY_NAMES[attribute])}
        onSelect={(_, { key }) =>
          key === ALL_PRODUCTS ? clearRefinements() : refine(key)
        }
        options={itemsWithAllTab}
        showSearch
      />
    )
  }

  return (
    <Flex vertical gap={8}>
      <Strong>{capitalize(FILTER_DISPLAY_NAMES[attribute])}</Strong>

      <Tabs
        className="custom-ant-vertical-tabs"
        defaultActiveKey="All"
        tabPosition="right"
        items={itemsWithAllTab}
        activeKey={activeItem?.value ?? ALL_PRODUCTS}
        onChange={activeKey =>
          activeKey === ALL_PRODUCTS ? clearRefinements() : refine(activeKey)
        }
        tabBarGutter={1}
      />

      {canToggleShowMore && (
        <Button onClick={toggleShowMore}>
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Flex>
  )
}
