import { Flex, Skeleton } from 'antd'
import { ProductThumbnail } from 'components'
import { ProductVariant_SourceType } from 'gen/perkup/v1/product_variant_pb'
import useListAllProductVariantsByProductIds from 'hooks/productVariants/useListProductVariantsByProductIds'
import { useMemo } from 'react'
import { getUniqAndFormatWithSelectable } from 'utils/productVariant'
import { useSelectRewardContext } from '../SelectRewardContext'

function ThumbnailsSkeleton({ length }: { length: number }) {
  return (
    <>
      {Array.from(Array(length).keys()).map(id => (
        <Skeleton.Input
          style={{
            height: 48,
            width: '100%',
          }}
          key={id}
          active
        />
      ))}
    </>
  )
}

export function EditCurrentSelectedRewardForm({
  readOnly = false,
}: {
  readOnly?: boolean
}) {
  const { selectedReward, swagForm } = useSelectRewardContext()

  const productIds = useMemo(
    () => selectedReward?.gift?.productIds ?? [],
    [selectedReward]
  )

  const { isLoading, productVariants } = useListAllProductVariantsByProductIds({
    productIds,
  })

  return (
    <Flex
      vertical
      gap={8}
      style={{
        width: 480,
      }}
      key={`${isLoading}-${productIds.join('-')}`}
    >
      {isLoading && <ThumbnailsSkeleton length={productIds.length} />}
      {!isLoading &&
        getUniqAndFormatWithSelectable(productVariants).map(
          (productVariant, _, variants) => (
            <ProductThumbnail key={productVariant.id}>
              <Flex
                gap={18}
                align="center"
                style={{
                  width: '100%',
                }}
              >
                <ProductThumbnail.Image imageUrl={productVariant.imageUrl} />

                <Flex vertical gap={4}>
                  <ProductThumbnail.Name>
                    {productVariant.productName}
                  </ProductThumbnail.Name>

                  <ProductThumbnail.ShippingCountries
                    iso3ShippingCountries={productVariant.shippingCountries}
                  />
                </Flex>

                <Flex align="center" justify="end" flex={1} gap={24}>
                  <Flex vertical gap={4}>
                    <ProductThumbnail.Price
                      alignSelf="end"
                      price={productVariant.price}
                    />
                    {productVariant.sourceType ===
                      ProductVariant_SourceType.fullPrepaid && (
                      <ProductThumbnail.Badge color="green">
                        Prepaid
                      </ProductThumbnail.Badge>
                    )}
                  </Flex>

                  {!readOnly && (
                    <ProductThumbnail.RemoveButton
                      onClick={async () => {
                        await swagForm.handleRemoveProductVariant(
                          productVariant,
                          variants.filter(v => v.id !== productVariant.id)
                        )
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            </ProductThumbnail>
          )
        )}
    </Flex>
  )
}
