import { DownloadOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { ReactComponent as PurchaseOrder } from 'assets/purchase-order.svg'
import {
  FilterTransactions,
  Loader,
  PageHeader,
  PrepaidBadge,
  withOrgSidebar,
} from 'components'
import { PENDING, REFUND } from 'constants/txnStates'
import { Heading, Pane, Strong, Text, Tooltip } from 'evergreen-ui'
import { ProgramType } from 'gen/perkup/v1/program_pb'
import { capitalize, truncate } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import { CSVLink } from 'react-csv'
import { getDateTimeString, numToDollars } from 'utils'
import { useInfiniteOrgTransactions } from './hooks/useInfiniteOrgTransactions'

const headers = [
  { label: 'Date', key: 'created' },
  { label: 'Description', key: 'description' },
  { label: 'User', key: 'name' },
  { label: 'Employee Id', key: 'employeeId' },
  { label: 'Location', key: 'location' },
  { label: 'Department', key: 'department' },
  { label: 'Country', key: 'country' },
  { label: 'Approved', key: 'approved' },
  { label: 'Status', key: 'status' },
  { label: 'Refund', key: 'refund' },
  { label: 'Transaction Amount', key: 'amount' },
  { label: 'Personal Funds Amount', key: 'personalAmount' },
  { label: 'Currency', key: 'currency' },
  { label: 'Id', key: 'id' },
  { label: 'Authorization Id', key: 'authId' },
  { label: 'Program Name', key: 'programName' },
  { label: 'Program Type', key: 'programType' },
  { label: 'Program Id', key: 'programId' },
  { label: 'Program Internal Memo', key: 'programInternalMemo' },
  { label: 'Prepaid', key: 'isPrepaid' },
  { label: 'Category', key: 'category' },
  { label: 'Account Name', key: 'accountName' },
  { label: 'Account Id', key: 'accountId' },
]

interface DataType {
  key: string
  date: string
  description: string
  name: string
  fundingSource: string
  status: string
  amount: number
  isRefund: boolean
  approved: boolean
  programType: string
  currency?: string
  isPrepaid: boolean
  lastTxnRef: ((node?: Element | null | undefined) => void) | null
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (date, record) => (
      <Text whiteSpace="nowrap" ref={record.lastTxnRef}>
        {date}
      </Text>
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: description => {
      return (
        <Pane>
          {description.length <= 60 && <Text>{description}</Text>}
          {description.length > 60 && (
            <Tooltip content={description}>
              <Text>
                {truncate(description, {
                  length: 60,
                })}
              </Text>
            </Tooltip>
          )}
        </Pane>
      )
    },
  },
  {
    title: 'User',
    dataIndex: 'name',
    key: 'name',
    render: name => <Text>{name}</Text>,
  },
  {
    title: 'Source of funds',
    dataIndex: 'fundingSource',
    key: 'fundingSource',
    render: fundingSource => <Text>{fundingSource}</Text>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status, record) => (
      <Pane display="flex" flexWrap="wrap" gap={8}>
        {!record.isRefund &&
          (record.approved ? (
            <Tag color="green">Approved</Tag>
          ) : (
            <Tag color="red">Declined</Tag>
          ))}
        {record.isRefund && <Tag color="gold">Refunded</Tag>}
        {status && (
          <Tag color={status === PENDING ? 'blue' : 'default'}>
            {capitalize(status)}
          </Tag>
        )}

        {record.programType === ProgramType[ProgramType.personalFunds] && (
          <Tag color="purple">Personal</Tag>
        )}
      </Pane>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount, record) => (
      <Pane display="flex" gap={4} justifyContent="end">
        {record.isPrepaid && <PrepaidBadge />}
        <Strong color={amount > 0 ? 'green' : 'default'}>
          {numToDollars(amount)}
        </Strong>
      </Pane>
    ),
  },
]

function OrgTransactions() {
  const {
    lastOrgTransactionRef,
    isLoadingMore,
    transactions,
    formattedTxns,
    isLoadingInitial,
    setTransactions,
    transactionsToRender,
  } = useInfiniteOrgTransactions()

  const disableDownloadBtn = !formattedTxns || formattedTxns.length === 0

  const data: DataType[] = transactionsToRender.map((txn, index) => {
    const adjustedDate = new Date(txn.created)

    adjustedDate.setTime(
      adjustedDate.getTime() - adjustedDate.getTimezoneOffset() * 60 * 1000
    )

    const formattedDate = getDateTimeString(new Date(adjustedDate))

    const isRefund = txn.type === REFUND

    const isLastTxn = index === transactionsToRender.length - 1

    const approved = JSON.parse(txn.approved)
    const isPrepaid = JSON.parse(txn.isPrepaid)

    return {
      key: txn.resolvedId + txn.programId,
      date: formattedDate,
      description: txn.description,
      name: txn.userFullName,
      fundingSource: txn.programName,
      status: txn.status,
      amount: txn.amount,
      isRefund,
      approved,
      programType: txn.programType,
      currency: txn?.currency,
      lastTxnRef: isLastTxn ? lastOrgTransactionRef : null,
      isPrepaid,
    }
  })

  return (
    <Pane width="100%">
      <Pane
        display="flex"
        gap={16}
        alignItems="flex-end"
        marginBottom={32}
        width="100%"
      >
        <Pane flex={1}>
          <PageHeader
            title="Transactions"
            description="View and download all transactions."
            vlogLink="https://www.loom.com/embed/db48a480a9c0417995464e8238282e36?sid=0f8f8628-42b2-45ca-8d17-a51adad4b42d"
          />

          {!isEmpty(transactions) && <Text>Last updated: 2 hours ago</Text>}
        </Pane>

        {/* FILTER  */}
        <FilterTransactions
          disabled={!transactions}
          setTransactions={setTransactions}
        />

        {/* DOWNLOAD */}
        <CSVLink
          data={formattedTxns}
          headers={headers}
          filename="perkup_transactions.csv"
        >
          <Button
            type="primary"
            disabled={disableDownloadBtn}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        </CSVLink>
      </Pane>
      {(isLoadingMore || isLoadingInitial) && <Loader />}

      {isEmpty(data) && !isLoadingInitial && (
        <Pane marginTop={64} textAlign="center">
          <PurchaseOrder />
          <Heading size={600} marginTop={32} marginBottom={8}>
            Start spending
          </Heading>
          <Text>Your transactions will appear here.</Text>
        </Pane>
      )}

      {!isLoadingInitial && data && data.length > 0 && (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: true }}
        />
      )}
    </Pane>
  )
}

export default withOrgSidebar(OrgTransactions)
