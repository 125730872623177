import { ListenToProductCollectionsByOrgId } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { useContext, useEffect, useState } from 'react'

export const useListenToCollectionsByOrgId = () => {
  const { id: orgId } = useContext(OrgContext)

  const [productCollections, setProductCollections] = useState<
    ProductCollection[]
  >([])
  const [hasLoaded, setHasLoaded] = useState(false)

  useEffect(() => {
    return ListenToProductCollectionsByOrgId({
      orgId,
      cb: collections => {
        setProductCollections(collections)
        setHasLoaded(true)
      },
    })
  }, [orgId])

  return {
    productCollections,
    hasLoaded,
  }
}
