import {
  LeftCircleOutlined,
  RightCircleOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { Button, Flex, Tag } from 'antd'
import { PerkImage } from 'components'
import { WidthBreakpoints } from 'constants/layout'
import { Pane } from 'evergreen-ui'
import { ProductVariant_Image } from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors, useEmblaCarouselApi } from 'hooks'
import { isFunction } from 'lodash-es'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'

/**
 *
 * Used to display images in product details view.
 * Carousel logic/JSX can be extrapolated into a new compound component should we need to use it elsewhere in the future.
 */
export function ProductImages({
  defaultImage,
  productImages,
  isVertical = false,
  onConfirmThumbnail,
}: {
  defaultImage: ProductVariant_Image
  productImages: ProductVariant_Image[]
  isVertical?: boolean
  onConfirmThumbnail?: (thumbnail: ProductVariant_Image) => void
}) {
  const { defaultColor } = useDefaultOrgColors()
  const [selectedImage, setSelectedImage] = useState(defaultImage)
  const [isThumbnailButtonLoading, setIsThumbnailButtonLoading] =
    useState(false)

  const verticalLayout = isVertical || isMobile
  const showSetThumbnailButton = isFunction(onConfirmThumbnail)
  const isSelectedImageTheThumbnailImage = Number(selectedImage.position) === 1

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
    scrollTo,
    emblaRef,
  } = useEmblaCarouselApi({
    onAfterSelectSlide: index => setSelectedImage(productImages[index]),
  })

  const emblaStyles = {
    embla: {
      overflow: 'hidden',
    },
    viewport: {},
    container: {
      display: 'flex',
      width: '30vw',
      minWidth: 220,
      maxWidth: 400,
    },
    slide: {
      flex: '0 0 100%',
    },
  }

  return (
    <Flex
      flex="1"
      gap={16}
      style={{
        flexDirection: verticalLayout ? 'column-reverse' : undefined,
        width: '100%',
      }}
      align={verticalLayout ? 'center' : undefined}
    >
      <Flex
        vertical={!verticalLayout}
        gap={16}
        style={{
          overflow: 'scroll',
          maxWidth: verticalLayout ? 320 : undefined,
          maxHeight: verticalLayout ? undefined : 480,
        }}
        justify={verticalLayout ? 'start' : undefined}
      >
        {productImages.slice(0, 20).map((image, index) => {
          return (
            <Pane
              key={image.id}
              cursor="pointer"
              onClick={() => {
                setSelectedImage(image)
                scrollTo(index)
              }}
            >
              <PerkImage
                src={image.src}
                alt={image.id}
                sizes={`(max-width: ${WidthBreakpoints.XS}px) 64px,
              100px`}
                style={{
                  aspectRatio: '1/1',
                  objectFit: 'cover',
                  width: isVertical ? 64 : 100,
                  height: isVertical ? 64 : 100,
                  borderRadius: 8,
                  border:
                    image.id === selectedImage.id
                      ? `1px solid ${defaultColor}`
                      : undefined,
                }}
              />
            </Pane>
          )
        })}
      </Flex>

      <Flex flex={1} vertical gap={16} align="center">
        <Pane display="flex" alignItems="center" gap={16}>
          {productImages.length > 1 && (
            <Button
              icon={<LeftCircleOutlined />}
              type="text"
              size="large"
              onClick={() => {
                onPrevButtonClick()
                const currentIndex = productImages.findIndex(
                  pi => pi.id === selectedImage.id
                )
                setSelectedImage(productImages[currentIndex - 1])
              }}
              disabled={prevBtnDisabled}
            />
          )}
          <Pane style={emblaStyles.embla} ref={emblaRef}>
            <Pane style={emblaStyles.container}>
              {productImages.map(image => (
                <Pane key={image.src} style={emblaStyles.slide}>
                  <PerkImage
                    preview
                    src={image.src}
                    sizes={verticalLayout ? '360px' : '30vw'}
                    style={{
                      width: '100%',
                      borderRadius: 4,
                      minWidth: 220,
                      maxWidth: verticalLayout ? 360 : undefined,
                      height: 'fit-content',
                    }}
                  />
                </Pane>
              ))}
            </Pane>
          </Pane>
          {productImages.length > 1 && (
            <Button
              icon={<RightCircleOutlined />}
              type="text"
              size="large"
              onClick={() => {
                onNextButtonClick()
                const currentIndex = productImages.findIndex(
                  pi => pi.id === selectedImage.id
                )
                setSelectedImage(productImages[currentIndex + 1])
              }}
              disabled={nextBtnDisabled}
            />
          )}
        </Pane>
        {showSetThumbnailButton && isSelectedImageTheThumbnailImage && (
          <Tag icon={<StarOutlined />} color="success">
            Thumbnail
          </Tag>
        )}

        {showSetThumbnailButton && !isSelectedImageTheThumbnailImage && (
          <Button
            loading={isThumbnailButtonLoading}
            disabled={isThumbnailButtonLoading}
            icon={<StarOutlined />}
            style={{ width: 'fit-content' }}
            onClick={() => {
              onConfirmThumbnail(selectedImage)
              setIsThumbnailButtonLoading(true)
            }}
          >
            Set as thumbnail
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
