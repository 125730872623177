import { WidthBreakpoints } from 'constants/layout'
import { Pane } from 'evergreen-ui'
import { useIsImagePortrait } from 'hooks/images/useIsImagePortrait'
import { isMobile } from 'react-device-detect'

export function CardWithImage({
  imageUrl,
  children,
}: React.PropsWithChildren<{ imageUrl?: string }>) {
  const { isImagePortrait } = useIsImagePortrait({
    imageUrl,
  })

  return (
    <Pane
      display="flex"
      flexDirection={isImagePortrait ? 'row' : 'column'}
      maxWidth={isImagePortrait ? WidthBreakpoints.LG : WidthBreakpoints.XM}
      width="100%"
      borderRadius={4}
      background="white"
    >
      <Pane flex={isImagePortrait ? 1 : 1.7} position="relative">
        <img
          src={imageUrl}
          alt="reward-acceptance-banner"
          style={{
            objectFit: 'cover',
            position: 'absolute', // Don't push the flex container
            width: '100%',
            height: '100%',
          }}
        />
      </Pane>

      <Pane
        flex={isImagePortrait ? 1.2 : 1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={isMobile ? 16 : 32}
      >
        {children}
      </Pane>
    </Pane>
  )
}
