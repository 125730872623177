import { Timestamp } from '@bufbuild/protobuf'
import dayjs from 'dayjs'
import { OrgTransaction as Transaction } from 'gen/perkup/v1/organization_pb'

export function getMonthDayInt(date: Date) {
  if (!date) return 0
  const monthDayInt = date.getUTCMonth() * 100 + date.getUTCDate()
  return monthDayInt
}

export function isDST(date: Date) {
  const month = date.getMonth()

  if (month < 3 || month > 10) {
    return false
  }
  if (month > 3 && month < 10) {
    return true
  }

  const previousSunday = date.getDate() - date.getDay()
  if (month === 3) {
    return previousSunday >= 25
  }
  if (month === 10) {
    return previousSunday < 25
  }

  return false
}

export const GetDateByTransaction = (transaction: Transaction) => {
  return transaction.created?.toDate().toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
  })
}

export function getUpcomingYear(date: Date, isPast?: boolean) {
  const today = new Date()

  const currentYear = today.getUTCFullYear()

  const firstMonthDayInt = getMonthDayInt(date)
  const secondMonthDayInt = getMonthDayInt(today)

  let isYearOffset = firstMonthDayInt < secondMonthDayInt
  let yearOffset = currentYear + 1

  if (isPast) {
    isYearOffset = firstMonthDayInt > secondMonthDayInt
    yearOffset = currentYear - 1
  }

  return isYearOffset ? yearOffset : currentYear
}

export function getUTCDate(date: Date) {
  return new Date(getUpcomingYear(date), date.getUTCMonth(), date.getUTCDate())
}

export function daysDiff(
  date: Date | undefined,
  isPast: boolean,
  returnHours: boolean
) {
  if (!date) return null
  const today = getUTCDate(new Date())

  const firstDateYear = getUpcomingYear(date, isPast)

  const firstDate = new Date(
    firstDateYear,
    date.getUTCMonth(),
    date.getUTCDate()
  )

  if (firstDate === today) return 0

  const oneDay = 86400000 // 1 day in milliseconds
  const diff = Math.abs((firstDate.valueOf() - today.valueOf()) / oneDay)

  const sameMonth = date.getUTCMonth() === today.getUTCMonth()
  const sameDay = date.getUTCDate() === today.getUTCDate()
  if (diff < 1 && sameMonth && sameDay) return 0
  return returnHours && diff < 1 ? diff : Math.ceil(diff)
}

export function getDateTimeString(
  date: Timestamp | Date | undefined,
  options: {
    dateOnly?: boolean
    timeOnly?: boolean
    longDisplay?: boolean
  } = {}
) {
  if (!date) return ''

  const { dateOnly, timeOnly, longDisplay } = options

  const dateToConvert: Date = date instanceof Timestamp ? date.toDate() : date

  // this should not include hour, minute or timezone
  const dateSettings: Intl.DateTimeFormatOptions = {
    weekday: longDisplay ? 'long' : 'short',
    month: longDisplay ? 'long' : 'short',
    day: 'numeric',
    year: 'numeric',
  }

  // this should not include month, day or year
  const timeSettings: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'shortGeneric',
  }

  if (dateOnly) return dateToConvert.toLocaleDateString('en-US', dateSettings)

  if (timeOnly) return dateToConvert.toLocaleTimeString('en-US', timeSettings)

  return dateToConvert.toLocaleDateString('en-US', {
    ...dateSettings,
    ...timeSettings,
  })
}

export function getAnniversaryYear(joinDate: Date | undefined) {
  if (!joinDate) return null

  const today = new Date()

  const compareYear =
    getMonthDayInt(joinDate) >= getMonthDayInt(today)
      ? today.getUTCFullYear()
      : today.getUTCFullYear() + 1

  const compareDate = new Date(
    compareYear,
    joinDate.getUTCMonth(),
    joinDate.getUTCDate(),
    joinDate.getUTCHours(),
    joinDate.getUTCMinutes(),
    joinDate.getUTCSeconds(),
    joinDate.getUTCMilliseconds()
  )

  return dayjs(compareDate).diff(dayjs(joinDate), 'years')
}

export function getUTCIndividualDate(date?: Date) {
  if (!date) return undefined
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export function hoursDiff({ date }: { date: Date }) {
  const now = new Date()

  const hoursDiff = Math.abs((now.getTime() - date.getTime()) / 36e5)
  const roundedHours = Math.ceil(hoursDiff)
  if (roundedHours > 24) {
    if (!Intl.DateTimeFormat) {
      const month = date.getUTCMonth() + 1
      const day = date.getUTCDate()
      return `Last seen ${month}/${day}`
    }
    const options = {
      day: 'numeric',
      month: 'long',
    } as const
    const lang = window.navigator.language
    return `Last seen ${new Intl.DateTimeFormat(lang, options).format(date)}`
  }
  return `Last seen ${roundedHours} hour${roundedHours > 1 ? 's' : ''} ago`
}

export function sortTimestamps(
  a: Timestamp | undefined,
  b: Timestamp | undefined
) {
  if (!a || !b) return 0
  if (a?.toDate() > b?.toDate()) {
    return -1
  }
  if (a?.toDate() < b?.toDate()) {
    return 1
  }
  // a must be equal to b
  return 0
}

class SeededRNG {
  private seed: number

  constructor(seed: number) {
    this.seed = seed
  }

  // Method to generate a pseudo-random number based on the current seed
  public random(): number {
    // eslint-disable-next-line no-multi-assign
    let t = (this.seed += 0x6d2b79f5)
    // eslint-disable-next-line no-bitwise
    t = Math.imul(t ^ (t >>> 15), t | 1)
    // eslint-disable-next-line no-bitwise
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
    // eslint-disable-next-line no-bitwise
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296
  }
}

// Function to shuffle an array using the Fisher-Yates algorithm and a seeded RNG
export function shuffleArrayWithDateSeed<T>(array: T[]): T[] {
  const today = new Date()
  // Create a seed based on today's date
  const seed =
    today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate()
  const rng = new SeededRNG(seed)

  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(rng.random() * (i + 1))
    // eslint-disable-next-line no-param-reassign
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

export function getMinutesBetweenDates(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime()

  return Math.round(diff / 60000)
}
