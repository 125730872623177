import { Flex } from 'antd'
import { updateProgram } from 'api/databaseCalls'
import { Loader } from 'components'
import {
  allApprovedCats,
  ApprovedCategories,
} from 'constants/EligibleCategories'
import { PROGRAM_STEP_COMPLETED } from 'constants/events'
import { defaultBudgets, RewardSteps } from 'constants/rewards'
import { DEFAULT, DEFAULT_ROUTES, IMAGE, TEMPLATES } from 'constants/routes'
import { ProgramContext, RewardStepGuideContext } from 'context'
import { deleteField } from 'firebase/firestore'
import { Template } from 'gen/perkup/v1/org_template_pb'
import {
  Program,
  Program_DraftDataField,
  Program_RewardTab,
  ProgramOccasion,
} from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { isEmpty, sample } from 'lodash-es'
import { useOrgTemplatesByAccess } from 'pages/Templates/hooks/useOrgTemplatesByAccess'
import { useContext, useState } from 'react'
import { Outlet, Route, Routes, useNavigate } from 'react-router'
import { getRewardStepsFromTemplate, logEvent } from 'utils'

import { DEFAULT_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import { defaultOccasions } from 'constants/newReward/occasions'
import { COLLECTION_IDS } from 'constants/productCollections'
import { DraftProgramGrid } from './components/DraftProgramGrid'
import { OccasionForm } from './components/OccasionForm'
import { SelectTemplateForm } from './components/SelectTemplateForm'
import { buildProgramGiftFromProductCollection } from './utils/program-gifts'
import {
  getDefaultRewardTabByTemplate,
  getProgramEndsOnFromTemplateExpiry,
} from './utils/programs'

function OccasionLayout({ program }: { program: Program }) {
  return (
    <Flex
      vertical
      gap={32}
      align="center"
      style={{ width: '100%', maxWidth: 1024, margin: 'auto' }}
    >
      <Outlet />
      <DraftProgramGrid currentProgram={program} />
    </Flex>
  )
}

export function Occasion() {
  const program = useContext(ProgramContext)
  const { orgId, userId } = useIds()
  const { currentStepIndex } = useContext(RewardStepGuideContext)
  const navigate = useNavigate()

  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const { templates, hasLoaded: hasLoadedTemplates } = useOrgTemplatesByAccess()

  const handleSelectOccasion = async (occasion: ProgramOccasion) => {
    setIsLoadingSave(true)
    const approvedCategories =
      occasion === ProgramOccasion.lunch
        ? [ApprovedCategories.FOOD]
        : allApprovedCats
    const allMerchants = approvedCategories.length > 1

    const occasionData = defaultOccasions.find(
      defaultOccasion => defaultOccasion.occasion === occasion
    )

    const emailData = sample(occasionData?.emailData)

    await updateProgram({
      orgId,
      programId: program.id,
      program: {
        occasion,
        note: emailData?.note,
        name: emailData?.title,
        email: { ...program.email, title: emailData?.title },
        approvedCategories,
        allMerchants,
        isDeleted: false,
        templateId: deleteField(),
      },
      merge: true,
    })
    logEvent(PROGRAM_STEP_COMPLETED, {
      step: RewardSteps.OCCASION,
      orgId,
      userId,
      programId: program.id,
      occasion,
    })
    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${IMAGE}`
    )
    setIsLoadingSave(false)
  }

  const handleSelectTemplate = async (template: Template) => {
    const templateId = template.id
    const expiryWritable = template?.expiry?.writable
    const endsOn = expiryWritable
      ? getProgramEndsOnFromTemplateExpiry({ templateExpiry: template?.expiry })
      : undefined

    const defaultRewardTab = getDefaultRewardTabByTemplate(template)

    const isCatalogTab = defaultRewardTab === Program_RewardTab.catalog

    // If catalog tab, we need to initialize with default collection gift since the UI autoselects

    const defaultCatalogCollection = isCatalogTab
      ? buildProgramGiftFromProductCollection(COLLECTION_IDS[0])
      : undefined

    const defaultCatalogBudget =
      template?.budget?.maxAmount || DEFAULT_PROGRAM_BUDGET_AMOUNT

    const perkDollarBudget = template?.budget?.amounts
      ? template.budget.amounts[0]
      : defaultBudgets[0]

    const programBudget = isCatalogTab ? defaultCatalogBudget : perkDollarBudget

    const draftData = new Program_DraftDataField({
      rewardTab: defaultRewardTab,
      perkupDollars: {
        approvedCategories: template.approvedCategories,
        amount: perkDollarBudget,
      },
      collectionAmount: defaultCatalogBudget,
      catalogGift: defaultCatalogCollection,
    }).toJsonString()

    await updateProgram({
      orgId,
      programId: program.id,
      program: {
        templateId,
        occasion: template.occasion,
        note: template?.email?.message,
        name: template?.email?.title,
        email: {
          banner: template?.image?.url,
          title: template?.email?.title,
        },
        gift: defaultCatalogCollection,
        internalMemo: template?.internalMemo,
        approvedCategories: template.approvedCategories,
        coreValues: template.coreValues?.values,
        isDeleted: false,
        endsOn,
        budget: programBudget,
        allMerchants: template.approvedCategories.length > 1,
        draftData,
      },
    })

    logEvent(PROGRAM_STEP_COMPLETED, {
      step: RewardSteps.OCCASION,
      orgId,
      userId,
      programId: program.id,
      templateId,
    })

    const filteredRewardSteps = getRewardStepsFromTemplate(template)

    const nextStep = filteredRewardSteps[currentStepIndex + 1]?.path

    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${nextStep}`
    )
    setIsLoadingSave(false)
  }

  if (isLoadingSave || !hasLoadedTemplates) {
    return <Loader />
  }

  const hasTemplates = !isEmpty(templates)
  const hasProgramGift = !!program?.gift

  const defaultElement =
    hasTemplates && !hasProgramGift ? (
      <SelectTemplateForm
        templates={templates}
        onSelectTemplate={handleSelectTemplate}
        program={program}
      />
    ) : (
      <OccasionForm program={program} onSelectOccasion={handleSelectOccasion} />
    )

  return (
    <Routes>
      <Route path="/" element={<OccasionLayout program={program} />}>
        <Route path="/" element={defaultElement} />
        <Route
          path={DEFAULT}
          element={
            <OccasionForm
              program={program}
              onSelectOccasion={handleSelectOccasion}
            />
          }
        />
        <Route
          path={TEMPLATES}
          element={
            <SelectTemplateForm
              templates={templates}
              onSelectTemplate={handleSelectTemplate}
              program={program}
            />
          }
        />
      </Route>
    </Routes>
  )
}
