import { removeScheduledMember } from 'api/databaseCalls'
import { ProgramContext } from 'context'
import { IconButton, RemoveIcon, Table, Text, Tooltip } from 'evergreen-ui'
import { ScheduledMember } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useContext } from 'react'
import { getDateTimeString } from 'utils'

export function ScheduledMembersTable({
  scheduledMembers,
}: {
  scheduledMembers: ScheduledMember[]
}) {
  const { orgId } = useIds()
  const program = useContext(ProgramContext)
  const getSendAtDate = (sendAtSeconds: bigint | undefined) => {
    return getDateTimeString(new Date(Number(sendAtSeconds) * 1000))
  }
  return scheduledMembers?.map(scheduledMember => (
    <Table.Row key={scheduledMember.id}>
      <Table.Cell>
        <Text>{getSendAtDate(scheduledMember.sendAt?.seconds)}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{scheduledMember.email}</Text>
      </Table.Cell>
      <Table.Cell>
        <Tooltip content="Delete">
          <IconButton
            icon={RemoveIcon}
            appearance="minimal"
            intent="danger"
            onClick={() =>
              removeScheduledMember({
                orgId,
                programId: program.id,
                scheduledMemberId: scheduledMember.id,
              })
            }
          />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  ))
}
