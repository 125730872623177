import { Flex } from 'antd'
import {
  AlgoliaCountryFilter,
  AlgoliaCurrentRefinements,
  AlgoliaHierachicalMenuFilter,
  AlgoliaMenuFilter,
  AlgoliaPriceFilterCheckbox,
  AlgoliaRefinementList,
  AlgoliaSearchBox,
  Loader,
  ProductCount,
  ScrollTo,
} from 'components'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE,
} from 'constants/algolia'
import { Pane } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useProductVariantSearchClient } from 'hooks'
import { useResizeObserver } from 'hooks/useResizeObserver'
import { useScrollObserver } from 'hooks/useScrollObserver'
import { PropsWithChildren } from 'react'
import { isMobile } from 'react-device-detect'
import {
  Configure,
  InstantSearch,
  InstantSearchProps,
  UseNumericMenuProps,
} from 'react-instantsearch'
import { Selectable } from 'types'
import { AlgoliaProductsGrid } from './AlgoliaProductsGrid'

/**
 *  @deprecated Use another child component of AlgoliaBrowseProducts instead
 */
function AlgoliaBrowseProductsOld({
  onProductCardClick,
  withCountryFilter = false,
  withPriceFilter = false,
  withProductAmounts = false,
  withLocalAmounts = false,
  withCategoriesFilter = false,
  withCollectionsFilter = false,
  withProductCount = false,
  searchFilter = TAGS_PUBLIC_GIFT_AND_IS_AVAILABLE,
  stickyNavTopAmount = 0,
  bottomOffsetAmount = 0,
  extraElementsInFirstRow,
  firstRowPrefix,
  addedVariants,
  filterUiProps,
  onCardAddButtonClick,
  menuFilters = ['vendor'],
  defaultPriceFilter = [],
  disablePriceFilter = false,
  selectedRibbonText,
  showAddedVariantsFirst,
  showPrepaidBadge,
}: {
  onProductCardClick: (productVariant: ProductVariant) => void
  withCountryFilter?: boolean
  withPriceFilter?: boolean
  withProductAmounts?: boolean
  withLocalAmounts?: boolean
  withCategoriesFilter?: boolean
  withCollectionsFilter?: boolean
  withProductCount?: boolean
  searchFilter?: string
  extraElementsInFirstRow?: React.ReactNode
  firstRowPrefix?: React.ReactNode
  addedVariants?: Selectable<ProductVariant>[]
  filterUiProps?: {
    searchPlaceholder?: string
    priceFilterPrefix?: string
  }
  stickyNavTopAmount?: number
  bottomOffsetAmount?: number
  onCardAddButtonClick?: (productVariant: ProductVariant) => void
  menuFilters?: (keyof ProductVariant)[]
  defaultPriceFilter?: UseNumericMenuProps['items']
  disablePriceFilter?: boolean
  selectedRibbonText?: string
  showAddedVariantsFirst?: boolean
  showPrepaidBadge?: boolean
}) {
  const { show, hasReachedBottom } = useScrollObserver({ hideThreshold: 1000 })

  const [stickyTopNavRef, stickyTopNavHeight] =
    useResizeObserver<HTMLDivElement>({
      valueToObserve: 'height',
    })

  const stickyTopPosition = show ? stickyNavTopAmount : stickyNavTopAmount - 60 // When user is actively scrolling, we hide the search bar
  const bottomYPositionOfTopNav = stickyTopPosition + stickyTopNavHeight // The side nav should start below the top nav, and will need to move dynamically based on scroll too

  return (
    <>
      <Configure filters={searchFilter} hitsPerPage={30} />

      <ScrollTo>
        <Flex vertical gap={8}>
          {/** TOP NAV */}
          <Flex
            ref={stickyTopNavRef}
            vertical
            gap={16}
            style={{
              position: 'sticky',
              top: stickyTopPosition,
              zIndex: 2,
              backgroundColor: 'white',
              padding: isMobile ? '16px 0 0 0' : '16px 0',
              transition: 'top 0.2s ease-in-out',
            }}
          >
            {/** FIRST ROW */}
            <Flex gap={32} align="center">
              {firstRowPrefix}
              <AlgoliaSearchBox
                placeholder={filterUiProps?.searchPlaceholder}
              />
              {extraElementsInFirstRow && extraElementsInFirstRow}
            </Flex>

            {/** SECOND ROW */}
            <Flex gap={16} justify="space-between" align="center">
              {withCollectionsFilter && (
                <AlgoliaMenuFilter
                  attribute="collectionIds"
                  limit={100}
                  asPills
                />
              )}
              {withProductCount && !isMobile && <ProductCount />}
            </Flex>
          </Flex>

          {/** SIDE NAV WITH GRID */}
          <Flex gap={32} vertical={isMobile}>
            {/** FILTERS
             * on mobile rendered as a top nav with dropdowns
             * on desktop rendered as a side nav
             */}
            {isMobile ? (
              <Flex vertical gap={8}>
                {withCategoriesFilter && (
                  <AlgoliaHierachicalMenuFilter
                    attribute="categories"
                    numLevels={1}
                    asSelectDropdown
                  />
                )}
                {menuFilters.map(filter => (
                  <AlgoliaMenuFilter
                    key={filter}
                    attribute={filter}
                    limit={500}
                    asSelectDropdown
                  />
                ))}
              </Flex>
            ) : (
              <Flex
                vertical
                gap={32}
                style={{
                  position: 'sticky',
                  top: bottomYPositionOfTopNav + 40,
                  alignSelf: 'flex-start',
                  height: `calc(100vh - ${bottomYPositionOfTopNav + 40}px - ${hasReachedBottom ? 0 : bottomOffsetAmount}px)`, // The height of the side nav really needs to adapt dynamically
                  overflowY: 'auto',
                  maxWidth: 'fit-content',
                  overscrollBehavior: 'contain',
                  paddingBottom: 16,
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                {withCountryFilter && <AlgoliaCountryFilter withTitle />}
                {withPriceFilter && (
                  <AlgoliaPriceFilterCheckbox
                    attribute="amount"
                    defaultFilter={defaultPriceFilter}
                    disabled={disablePriceFilter}
                    filterLabelPrefix={filterUiProps?.priceFilterPrefix}
                  />
                )}
                {withCategoriesFilter && (
                  <AlgoliaHierachicalMenuFilter
                    attribute="categories"
                    numLevels={1}
                  />
                )}
                {menuFilters.map(filter => {
                  // Render the brands filter as a searchable refinment list on desktop
                  if (filter === 'vendor')
                    return (
                      <AlgoliaRefinementList key={filter} attribute="vendor" />
                    )
                  return (
                    <AlgoliaMenuFilter
                      key={filter}
                      attribute={filter}
                      limit={10}
                    />
                  )
                })}
              </Flex>
            )}

            {/** MAIN CONTENT */}
            <Flex
              vertical
              gap={8}
              style={{ width: '100%', minHeight: '90svh' }}
            >
              {!isMobile && (
                <Pane
                  zIndex={1}
                  position="sticky"
                  backgroundColor="white"
                  top={bottomYPositionOfTopNav + 32}
                  transition="top 0.2s ease-in-out"
                  paddingBottom={4}
                >
                  <AlgoliaCurrentRefinements />
                </Pane>
              )}

              <Pane width="100%">
                <AlgoliaProductsGrid
                  withProductAmounts={withProductAmounts}
                  withAmountsInUsd={!withLocalAmounts}
                  onCardClick={onProductCardClick}
                  addedVariants={addedVariants}
                  onCardAddButtonClick={onCardAddButtonClick}
                  selectedRibbonText={selectedRibbonText}
                  showAddedVariantsFirst={showAddedVariantsFirst}
                  showPrepaidBadge={showPrepaidBadge}
                />
              </Pane>
            </Flex>
          </Flex>
        </Flex>
      </ScrollTo>
    </>
  )
}

function AlgoliaBrowseProducts({
  initialUiState,
  children,
}: PropsWithChildren<{
  initialUiState?: InstantSearchProps['initialUiState']
}>) {
  const { searchClient } = useProductVariantSearchClient()

  if (!searchClient) return <Loader />

  return (
    <InstantSearch
      indexName={ALGOLIA_PRODUCT_VARIANTS_INDEX}
      searchClient={searchClient}
      initialUiState={initialUiState}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <ScrollTo>{children}</ScrollTo>
    </InstantSearch>
  )
}

/**
 * @deprecated Use another child component of AlgoliaBrowseProducts instead
 */
AlgoliaBrowseProducts.Old = AlgoliaBrowseProductsOld

export default AlgoliaBrowseProducts
