import { SearchOutlined } from '@ant-design/icons'
import { Input, Tag } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import {
  AlgoliaDropdownFilter,
  IndividualAvatar,
  IndividualStatusTag,
  ManageMemberSidesheet,
} from 'components'
import { TooltipIconButton } from 'components/Buttons/TooltipIconButton'
import {
  ORG_PAGE_PADDING,
  SIDEBAR_PADDING,
  SIDEBAR_WIDTH,
} from 'constants/layout'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext, UserContext } from 'context'
import {
  Button,
  CornerDialog,
  Pane,
  PlusIcon,
  SendMessageIcon,
  Strong,
  Text,
  toaster,
} from 'evergreen-ui'
import {
  Individual,
  Individual_Role,
  Individual_State,
  Individual_Status,
} from 'gen/perkup/v1/individual_pb'
import { NotificationSetting_RuleGroup } from 'gen/perkup/v1/notification_pb'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { useDefaultOrgColors } from 'hooks'
import { isNumber } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import map from 'lodash-es/map'
import startCase from 'lodash-es/startCase'
import { useContext, useMemo, useState } from 'react'
import { useInstantSearch, useSearchBox } from 'react-instantsearch'
import { useNavigate } from 'react-router'
import { CreateRewardLocation } from 'types'
import {
  getAnniversaryYear,
  getUTCIndividualDate,
  makePlural,
  numToDollars,
  numberWithCommas,
  ordinalSuffixOf,
} from 'utils'
import { getAlgoliaTenureFilterOptions } from 'utils/Algolia'
import {
  getIndividualDisplayName,
  getIndividualPrivacySettings,
} from 'utils/individual'
import { AlgoliaRefreshButton } from './AlgoliaRefreshButton'
import { DirectorySkeleton } from './DirectorySkeleton'
import DownloadIndividualsButton from './DownloadIndividualsButton'
import { FilterByManager } from './FilterByManager'
import InviteIndividualButton from './InviteIndividualButton'

interface ProfileDetailsProps {
  name: string
  email: string
  profilePicture: string | undefined
  role: Individual_Role
  showIsYouBadge: boolean
  countryId: string | undefined
  state: Individual_State | undefined
}

export interface DataType {
  key: string
  profileDetails: ProfileDetailsProps
  status: Individual_Status
  balance: number
  individual: Individual
  dob: Date | undefined
  startDate: Date | undefined
  hidden?: boolean
}

export interface IDirectoryTable {
  individuals: Individual[]
  isDirectReports: boolean
  lists: OrgList[]
  numberOfResults: number
  isLoading: boolean
}

export function DirectoryTable({
  individuals,
  isDirectReports,
  lists,
  numberOfResults,
  isLoading,
}: IDirectoryTable) {
  const user = useContext(UserContext)
  const individual = useContext(IndividualContext)
  const { defaultColor } = useDefaultOrgColors()

  const userId = user.id

  const navigate = useNavigate()

  const isAdmin = individual.role === Individual_Role.admin
  const isManager = individual.role === Individual_Role.manager

  const { refresh, uiState } = useInstantSearch()
  const { query, refine } = useSearchBox()

  // Manage singular person sidesheet
  const [selectedPersonId, setSelectedPersonId] = useState<string>('')
  // Manage multiple people
  const [selectedPeopleIds, setSelectedPeopleIds] = useState<string[]>([])

  const showLoader = isLoading && !individuals.length && query.length === 0
  const isDirectReportsAndManager = isDirectReports && isManager
  const showPrivateFields = isDirectReportsAndManager || isAdmin

  const maxTableWidth = `calc(100vw - ${SIDEBAR_WIDTH}px - ${SIDEBAR_PADDING}px - ${ORG_PAGE_PADDING}px)`

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      key: 'name',

      render: (_, { individual }) => (
        <IndividualAvatar individual={individual} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: !isManager ? 'status' : '',
      render: (_, { individual, status }) => (
        <>
          <IndividualStatusTag individual={individual} />
          {status === Individual_Status.none && (
            <InviteIndividualButton
              individual={individual}
              onClick={() => {
                refresh()
              }}
            />
          )}
        </>
      ),
      sorter: (a, b) => {
        const aString = Individual_Status[a.status]
        const bString = Individual_Status[b.status]

        return aString.localeCompare(bString)
      },
    },
    {
      title: 'Birthday',
      dataIndex: 'dob',
      key: showPrivateFields ? 'dob' : '',
      render: (_, { individual, dob }) => {
        const birthdayPrivacySettings = getIndividualPrivacySettings({
          individual,
          ruleGroup: NotificationSetting_RuleGroup.birthdays,
        })
        if (isAdmin && !birthdayPrivacySettings.allowAdmins) return null
        if (isManager && !birthdayPrivacySettings.allowManagers) return null

        if (showPrivateFields && dob) {
          return (
            <Text>
              {getUTCIndividualDate(dob)?.toLocaleString('default', {
                month: 'short',
                day: 'numeric',
              })}
            </Text>
          )
        }
        return null
      },
    },
    {
      title: 'Anniversary',
      dataIndex: 'startDate',
      key: showPrivateFields ? 'startDate' : '',
      render: startDate => {
        const yearsDiff = getAnniversaryYear(startDate)

        if (showPrivateFields) {
          return (
            <Pane display="flex" alignItems="center" gap={4} minWidth={96}>
              <Text>
                {getUTCIndividualDate(startDate)?.toLocaleString('default', {
                  month: 'short',
                  day: 'numeric',
                })}
              </Text>
              {isNumber(yearsDiff) && (
                <Pane>
                  <Tag color="orange">{ordinalSuffixOf(yearsDiff)}</Tag>
                </Pane>
              )}
            </Pane>
          )
        }
        return null
      },
    },
    ...lists.map(list => ({
      title: startCase(list.name),
      dataIndex: list.id,
      key: list.id,
      sorter: (a: any, b: any) =>
        a[list.name] ? a[list.name].localeCompare(b[list.name]) : false,
    })),
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: showPrivateFields ? 'balance' : '',
      render: (_, { balance }) => <Strong>{numToDollars(balance)}</Strong>,
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: '',
      dataIndex: 'sendReward',
      key: 'sendReward',
      render: () => (
        <TooltipIconButton
          tooltipContent="Send reward"
          icon={<SendMessageIcon color={defaultColor} />}
        />
      ),
      onCell: ({ individual }) => ({
        style: { cursor: 'pointer' },
        onClick: e => {
          e.stopPropagation()
          const locationState: CreateRewardLocation = {
            state: {
              recipientIndividuals: [individual],
            },
          }
          navigate(
            DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD,
            locationState
          )
        },
      }),
    },
  ]

  const filteredColumns = columns.filter(c => c.key)

  // Data being rendered to table
  const individualsData = useMemo(
    () =>
      map(individuals, individual => {
        const displayName = getIndividualDisplayName(individual)

        const showIsYouBadge = userId === individual?.userId

        const profileDetails = {
          name: displayName,
          email: individual.email,
          profilePicture: individual?.profilePicture,
          role: individual.role,
          showIsYouBadge,
          state: individual?.state,
          countryId: individual?.countryId,
        }

        return {
          key: individual.id,
          profileDetails,
          status: individual.status,
          dob: individual.dob?.toDate(),
          startDate: individual.startDate?.toDate(),
          ...individual.labels,
          balance: Number(individual.balance),
          individual,
        }
      }),
    [individuals, userId]
  )

  return (
    <>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={8}
      >
        <Pane display="flex" alignItems="center" gap={8} flexWrap="wrap">
          <Input
            placeholder="Search by name, email, status or role"
            value={query}
            onChange={e => refine(e.target.value)}
            autoFocus
            allowClear
            prefix={<SearchOutlined />}
            style={{ width: 312 }}
          />

          <AlgoliaDropdownFilter
            name="status"
            attribute="status"
            styles={isManager ? { display: 'none' } : {}}
          />
          <AlgoliaDropdownFilter name="role" attribute="role" />
          <AlgoliaDropdownFilter
            name="Tenure"
            attribute="dates.anniversary.year"
            customOptions={getAlgoliaTenureFilterOptions({})}
          />

          {lists.map(listItem => (
            <AlgoliaDropdownFilter
              key={listItem.id}
              name={listItem.name}
              attribute={`labels.${listItem.id}`}
              capitalizeLabel={false}
            />
          ))}
          {isAdmin && <FilterByManager />}
        </Pane>

        {isAdmin && (
          <DownloadIndividualsButton query={query} uiState={uiState} />
        )}
      </Pane>
      {showLoader && <DirectorySkeleton rows={20} />}
      {!showLoader && (
        <>
          <Pane marginY={8}>
            <Text color="muted">
              {numberWithCommas(numberOfResults)}{' '}
              {makePlural('result', individuals.length)}
            </Text>
            <AlgoliaRefreshButton
              tooltipProps={{ content: 'Refresh results' }}
              iconButtonProps={{
                onClick: () => {
                  refresh()
                  toaster.success('Results refreshed')
                },
                size: 'small',
                marginLeft: 8,
              }}
            />
          </Pane>
          <Pane width={maxTableWidth}>
            <Table
              columns={filteredColumns}
              dataSource={individualsData}
              pagination={{
                showSizeChanger: true,
                hideOnSinglePage: true,
                defaultPageSize: 100,
              }}
              onRow={({ key }) => ({
                style: { cursor: 'pointer' },
                onClick: () => setSelectedPersonId(key),
              })}
              rowSelection={{
                type: 'checkbox',
                onChange: (_, selectedRow: DataType[]) =>
                  setSelectedPeopleIds(selectedRow.map(({ key }) => key)),
              }}
              scroll={{ x: true }}
            />
          </Pane>
          <ManageMemberSidesheet
            isShown={!!selectedPersonId}
            onCloseComplete={() => {
              setSelectedPersonId('')
              refresh()
            }}
            id={selectedPersonId}
          />
          <CornerDialog
            isShown={!isEmpty(selectedPeopleIds)}
            hasClose={false}
            hasFooter={false}
            title={`Manage ${selectedPeopleIds.length} ${
              selectedPeopleIds.length === 1 ? 'person' : 'people'
            }`}
          >
            <Pane display="flex" alignItems="center" gap={8}>
              <Button
                iconBefore={PlusIcon}
                appearance="primary"
                onClick={() => {
                  // Navigate to the new rewards page with the selected people
                  const recipientIndividuals = individuals.filter(individual =>
                    selectedPeopleIds.includes(individual.id)
                  )

                  const locationState: CreateRewardLocation = {
                    state: {
                      recipientIndividuals,
                    },
                  }

                  navigate(
                    DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD,
                    locationState
                  )
                }}
              >
                Send reward
              </Button>
            </Pane>
          </CornerDialog>
        </>
      )}
    </>
  )
}
