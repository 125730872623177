import { EnvironmentOutlined } from '@ant-design/icons'
import { Pane, useTheme } from 'evergreen-ui'
import { TrackingInfo } from 'types'
import Tracking from './Tracking'

export function TrackingList({
  trackingNumbers,
}: {
  trackingNumbers: TrackingInfo[]
}) {
  const theme = useTheme()
  return (
    <Pane display="flex" alignItems="center" gap={8} flexWrap="wrap">
      <EnvironmentOutlined style={{ color: theme.colors.gray600 }} />
      {trackingNumbers.map(({ number, url }, index) => {
        const displayComma = index !== trackingNumbers.length - 1
        return (
          <Tracking key={number}>
            <Tracking.Link url={url}>
              {number}
              {displayComma ? ', ' : ''}
            </Tracking.Link>
          </Tracking>
        )
      })}
    </Pane>
  )
}
