import { ItemInfo, PrepaidBadge } from 'components'
import { Paragraph, Text } from 'evergreen-ui'
import { useItemsToVariants } from 'hooks/productVariants/useProgramItemsToVariants'
import { compact, isEmpty } from 'lodash-es'
import { getDirectMailOrderInfo } from 'pages/NewReward/utils/uiUtils'
import { useMemo } from 'react'
import { SelectedItems } from 'types/Items'
import { afterLastSlash } from 'utils'
import { flattenItems } from 'utils/items'
import { isPrepaidProductVariant } from 'utils/productVariant'

/**
 * @deprecated Please use ProductsSummary.tsx instead.
 *
 * To be removed in ENG-6192
 * This component only has one reference. We have a component that does the same thing + is used in more spots + uses our new ProductVariant proto.
 */
export function OrderLineItems({ items }: { items: SelectedItems }) {
  const itemKeys = Array.from(items.keys()).reverse()

  const flattenedItems = useMemo(() => {
    return flattenItems({ items })
  }, [items])

  const { productVariants } = useItemsToVariants({
    items: flattenedItems,
  })

  const hasProductVariants = !isEmpty(itemKeys) || !isEmpty(productVariants)

  if (!hasProductVariants) {
    return <Paragraph color="muted">No products selected</Paragraph>
  }

  return (
    <>
      {itemKeys.map(id => {
        const {
          hasQuantities,
          sizesSelected,
          allSizedVariants,
          defaultVariant,
          itemDisplayName,
          lineItemTotal,
          lineItems,
        } = getDirectMailOrderInfo({
          itemKey: id,
          items,
          selectedProductVariants: productVariants,
        })
        if (!hasQuantities) return null

        const sizes = compact(
          sizesSelected.map(size => {
            const variantSize = allSizedVariants[size][0]

            const variantSizeQuantity = lineItems.find(
              item => afterLastSlash(item.productVariantId) === variantSize.id
            )?.quantity

            const hasNoSize = size === 'undefined'

            const displaySize = hasNoSize ? 'Qty' : size

            if (!variantSizeQuantity) return null
            return (
              <Text color="muted" key={size} whiteSpace="nowrap">
                {displaySize}: {variantSizeQuantity}
              </Text>
            )
          })
        )

        const isPrepaid = isPrepaidProductVariant(defaultVariant)

        return (
          <ItemInfo
            key={id}
            amount={Number(defaultVariant.amount)}
            totalAmount={Number(lineItemTotal)}
            shippingCountries={defaultVariant.shippingCountries}
            name={itemDisplayName}
            image={defaultVariant.imageUrl}
          >
            {sizes}
            {isPrepaid && <PrepaidBadge />}
          </ItemInfo>
        )
      })}
    </>
  )
}
