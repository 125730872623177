import { Alert, AlertProps, Button, ButtonProps, Flex } from 'antd'
import { CountryIconGroup, ProductImages, ProductOptions } from 'components'
import { MetricsUpdater } from 'components/ProductVariants/MetricsUpdater'
import { VariantEstimatedShippingTime } from 'components/ProductVariants/VariantEstimatedShippingTime'
import { Slot, getSlots } from 'components/Slot'
import { PUBLIC_GIFT_TAG } from 'constants/algolia'
import { NUMBER_GREEN } from 'constants/colors'
import { PRODUCT_VARIANT_SELECTED } from 'constants/events'
import { IN_STOCK } from 'constants/productVariants'
import { ExchangeRateContext, OrgContext, UserContext } from 'context'
import { Heading, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors } from 'hooks'
import { useIsFlagged } from 'hooks/useIsFlagged'
import parse from 'html-react-parser'
import { compact } from 'lodash-es'
import { PropsWithChildren, useContext, useState } from 'react'
import { SelectedVariantOptions } from 'types'
import { logEvent, numToDollars } from 'utils'
import {
  determineInventoryStatus,
  extractProductCategoryName,
  getProductVariantOptions,
  isNearCashProductVariant,
} from 'utils/productVariant'

interface DefaultSelected {
  options?: SelectedVariantOptions
  filterOtherOptions: boolean
}

const SLOTS = [
  'aboveCta',
  'belowCta',
  'aboveOptions',
  'displayAddress',
  'shippingEstimation',
] as const

function ProductDetails({
  productVariant: defaultVariant,
  showPrice = false,
  onPrimaryCtaClick,
  onSecondaryCtaClick,
  onSelectedVariantChange,
  withAmountInUsd = false,
  disableCta = false,
  showShipping = false,
  showShippingCountries = false,
  showInStockText = false,
  isVerticalLayout = false,
  alertProps,
  primaryCtaProps = {
    type: 'primary',
    children: 'Select',
  },
  secondaryCtaProps,
  defaultSelected,
  maxAmount,
  disableVariantOptions = false,
  disableOutOfStock = true,
  children,
}: PropsWithChildren<{
  productVariant: ProductVariant
  showPrice?: boolean
  onPrimaryCtaClick?: (productVariant: ProductVariant) => void
  onSecondaryCtaClick?: (productVariant: ProductVariant) => void
  onSelectedVariantChange?: (productVariant?: ProductVariant) => void
  withAmountInUsd?: boolean
  disableCta?: boolean
  showShipping?: boolean
  showShippingCountries?: boolean
  showInStockText?: boolean
  isVerticalLayout?: boolean
  alertProps?: AlertProps
  primaryCtaProps?: ButtonProps
  secondaryCtaProps?: ButtonProps
  defaultSelected?: DefaultSelected
  maxAmount?: number
  disableVariantOptions?: boolean
  disableOutOfStock?: boolean
}>) {
  const {
    productId,
    productName,
    categories,
    description,
    options,
    vendor,
    tags,
    shippingCountries,
    productTagline,
  } = defaultVariant

  const {
    aboveCta,
    belowCta,
    aboveOptions,
    displayAddress,
    shippingEstimation,
  } = getSlots(SLOTS, children)

  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const exchangeRate = useContext(ExchangeRateContext)

  const isFlagged = useIsFlagged()

  const { defaultColor } = useDefaultOrgColors()

  const secondLevelCategoryName = extractProductCategoryName(categories, 1)

  const optionKeys = Object.keys(options || {})
  const hasOptions = optionKeys.length > 0
  const hasOptionChoice =
    hasOptions && optionKeys.every(key => options[key].values.length > 1)

  const [selectedProductVariant, setSelectedProductVariant] = useState(
    hasOptionChoice && !defaultSelected?.options ? undefined : defaultVariant
  )

  const handlePrimaryCtaClick = () => {
    if (!onPrimaryCtaClick) return
    // Sometimes we want to select all variants at once
    if (disableVariantOptions) {
      onPrimaryCtaClick(defaultVariant)
      return
    }
    if (!selectedProductVariant) return

    logEvent(PRODUCT_VARIANT_SELECTED, {
      userId: user.id,
      orgId: org.id,
      userToken: user.id,
      productVariant: selectedProductVariant.id,
      ...selectedProductVariant,
    })
    onPrimaryCtaClick(selectedProductVariant)
  }

  const handleSecondaryCtaClick = () => {
    if (!onSecondaryCtaClick) return
    onSecondaryCtaClick(selectedProductVariant ?? defaultVariant)
  }

  const handleSelectedVariantChange = (productVariant?: ProductVariant) => {
    if (onSelectedVariantChange) {
      onSelectedVariantChange(productVariant)
    }
    setSelectedProductVariant(productVariant)
  }

  const isNearCash = isNearCashProductVariant(defaultVariant)

  const indexToSliceDescription = isNearCash // Plum descriptions need to be sliced at the first colon
    ? description?.indexOf(':')
    : undefined

  const slicedProductDescription = indexToSliceDescription
    ? description?.slice(indexToSliceDescription + 1)
    : description

  const displayAmountInUsd = numToDollars(
    Number(selectedProductVariant?.amount || defaultVariant.amount)
  )
  const displayAmountInLocal = numToDollars(
    Number(selectedProductVariant?.amount || defaultVariant.amount) *
      exchangeRate,
    2,
    false,
    user.displayCurrency || org?.displayCurrency
  )
  const displayAmount = withAmountInUsd
    ? displayAmountInUsd
    : displayAmountInLocal

  const vendorTitle = isNearCash ? productName : vendor

  const inventoryStatus = determineInventoryStatus({
    productVariants: [selectedProductVariant || defaultVariant],
    displayInStockThreshold: 100,
    level: selectedProductVariant ? 'variant' : 'product',
  })

  const hideInStockText = inventoryStatus.text === IN_STOCK && !showInStockText // Hide "In stock" if it's the only thing to show and we're in member view

  const selectedProductAmount = selectedProductVariant?.amount
  const selectedProductImage = selectedProductVariant?.productImages.find(
    image => selectedProductVariant.imageUrl === image.src
  )

  // Don't show vendor for swag - ever
  const showVendor = tags.includes(PUBLIC_GIFT_TAG) || isNearCash

  const selectedVariantTooExpensive =
    !!maxAmount && !!selectedProductAmount && selectedProductAmount > maxAmount

  const selectedVariantOutOfStock =
    (!defaultVariant.productIsAvailable ||
      (selectedProductVariant && !selectedProductVariant?.isAvailable)) &&
    disableOutOfStock

  const CTADisabled =
    disableCta ||
    selectedVariantOutOfStock ||
    (!selectedProductVariant && !disableVariantOptions) ||
    selectedVariantTooExpensive

  // @todo ENG-6175 remove this when we have a proper product image
  const tempFallBackImageForPlum = {
    id: 'plum-fallback',
    src: defaultVariant.imageUrl,
    position: 1,
  }

  const subheading = productTagline || secondLevelCategoryName

  return (
    <Flex
      vertical={isVerticalLayout}
      gap={16}
      wrap="wrap"
      style={{ width: '100%' }}
    >
      <ProductImages
        key={selectedProductVariant?.id}
        defaultImage={
          selectedProductImage ||
          defaultVariant.productImages[0] ||
          tempFallBackImageForPlum
        }
        productImages={defaultVariant.productImages}
        isVertical={isVerticalLayout}
      />

      <Flex flex="1" vertical gap={16} style={{ maxWidth: '100%' }}>
        <Flex vertical gap={8}>
          {showVendor && (
            <Heading size={400} color={defaultColor}>
              {vendorTitle?.toUpperCase()}
            </Heading>
          )}
          <Heading size={800}>{productName}</Heading>
          {subheading && (
            <Text textTransform="capitalize" color="muted">
              {subheading}
            </Text>
          )}
          <Flex gap={16}>
            {showPrice && (
              <Flex gap={8}>
                <Text color={NUMBER_GREEN}>{displayAmount}</Text>
                {!isNearCash && showShipping && (
                  <Text color="muted">FREE shipping</Text>
                )}
              </Flex>
            )}
            {!hideInStockText && (
              <Text color={inventoryStatus.color}>{inventoryStatus.text}</Text>
            )}
          </Flex>
        </Flex>

        {alertProps && <Alert {...alertProps} />}

        {displayAddress}

        {aboveOptions}

        {hasOptions && (
          <ProductOptions
            key={productId}
            product={defaultVariant}
            onSelectProductVariant={handleSelectedVariantChange}
            defaultOptions={
              !hasOptionChoice
                ? getProductVariantOptions({ productVariant: defaultVariant })
                : defaultSelected?.options
            }
            maxBudget={maxAmount}
            disableOptions={disableVariantOptions}
            filterNonSelected={defaultSelected?.filterOtherOptions}
          />
        )}

        {aboveCta}

        <Flex vertical gap={8}>
          <Flex gap={8}>
            {!!onPrimaryCtaClick && (
              <Button
                style={{ flex: 1 }}
                type="primary"
                disabled={CTADisabled}
                onClick={handlePrimaryCtaClick}
                {...primaryCtaProps}
              />
            )}
            {!!onSecondaryCtaClick && (
              <Button
                style={{ flex: 1 }}
                disabled={CTADisabled}
                onClick={handleSecondaryCtaClick}
                {...secondaryCtaProps}
              />
            )}
          </Flex>

          {shippingEstimation || (
            <VariantEstimatedShippingTime productVariant={defaultVariant} />
          )}

          {selectedVariantTooExpensive && (
            <Alert message="Variant not available" type="warning" showIcon />
          )}

          {belowCta}
        </Flex>

        {showShippingCountries && compact(shippingCountries).length > 0 && (
          <CountryIconGroup
            iso3s={shippingCountries}
            label={isNearCash ? 'Available in' : 'Ships to'}
          />
        )}

        {slicedProductDescription && (
          <Text overflow="scroll" maxHeight={300}>
            {parse(slicedProductDescription)}
          </Text>
        )}
        {isFlagged && (
          <Text size={300} color="muted">
            PRODUCT ID: {productId}
            <br />
            PRODUCT VARIANT ID: {defaultVariant.id}
          </Text>
        )}
        {isFlagged && <MetricsUpdater productVariant={defaultVariant} />}
      </Flex>
    </Flex>
  )
}

ProductDetails.Slot = Slot<(typeof SLOTS)[number]> // Expose the slots for the parent component to use. User of <ProductDetails /> can define as many <ProductDetails.Slot /> components as they want

export default ProductDetails
