import { captureException } from '@sentry/react'
import { toaster } from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import { CourierAuth } from 'gen/courier/auth_connect'
import { createClient } from 'hooks/useClient'
import { toSentry } from 'utils/sentry'

export async function getCourierToken() {
  try {
    const accessToken = await getAuth().currentUser?.getIdToken()
    return await createClient(CourierAuth)
      .authenticateCourier(
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
      .then(CourierResponse => CourierResponse.userSignature)
  } catch (error: any) {
    toaster.warning('Something went wrong, please contact support')
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        getCourierToken: {},
      },
    })

    return undefined
  }
}
