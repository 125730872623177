import { PlusOutlined } from '@ant-design/icons'
import { Button, Image } from 'antd'
import { PerkUpLink } from 'components/Typography/PerkUpLink'
import { DRAFT_PROGRAM_CLICKED } from 'constants/events'
import { Heading, Pane, Strong, Text } from 'evergreen-ui'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'
import { getDraftProgramNavigationPath, logEvent, numToDollars } from 'utils'
import { useDraftProgramsByOwnerId } from '../hooks/useDraftProgramsByOwnerId'

const DEFAULT_DRAFT_PROGRAMS_TO_SHOW = 3

export function DraftProgramGrid({
  currentProgram,
}: {
  currentProgram?: Program
}) {
  const [draftProgramsToShow, setDraftProgramsToShow] = useState(
    DEFAULT_DRAFT_PROGRAMS_TO_SHOW
  )

  const { orgId, userId } = useIds()

  const { draftPrograms } = useDraftProgramsByOwnerId()

  // Filter out current draft program
  const filteredDraftPrograms = draftPrograms.filter(
    draftProgram =>
      draftProgram.id !== currentProgram?.id &&
      draftProgram.type !== ProgramType.direct
  )

  const showSeeMoreButton = draftPrograms.length > draftProgramsToShow

  if (isEmpty(draftPrograms)) return null

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap={16}
      alignItems="center"
      width="100%"
      marginTop={16}
    >
      <Heading size={500}>Drafts</Heading>
      <Pane
        width="100%"
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(248px, 0.25fr))"
        gap={16}
        justifyContent="center"
      >
        {filteredDraftPrograms.map((draftProgram, index) => {
          if (index >= draftProgramsToShow) return null
          const isGift = !!draftProgram?.gift
          const href = getDraftProgramNavigationPath({
            programId: draftProgram.id,
            draftData: draftProgram?.draftData,
          })

          const onClick = () => {
            logEvent(DRAFT_PROGRAM_CLICKED, {
              programId: draftProgram.id,
              userId,
              orgId,
              location: window.location.pathname,
            })
          }

          return (
            <PerkUpLink
              key={draftProgram.id}
              to={href}
              rel="noopener noreferrer"
              onClick={onClick}
            >
              <Pane
                borderRadius={8}
                border="muted"
                display="flex"
                alignItems="center"
                gap={16}
                padding={16}
                hoverElevation={1}
                cursor="pointer"
                minWidth={248}
              >
                <Image
                  width={72}
                  height={72}
                  preview={false}
                  style={{ objectFit: 'cover', borderRadius: 8 }}
                  src={draftProgram?.email?.banner}
                />
                <Pane display="flex" flexDirection="column" gap={4}>
                  <Strong>{draftProgram.name}</Strong>
                  {!isGift && (
                    <Text>{`${numToDollars(
                      draftProgram.budget
                    )} per person`}</Text>
                  )}
                </Pane>
              </Pane>
            </PerkUpLink>
          )
        })}
      </Pane>
      {showSeeMoreButton && (
        <Button
          onClick={() =>
            setDraftProgramsToShow(
              draftProgramsToShow + DEFAULT_DRAFT_PROGRAMS_TO_SHOW
            )
          }
          style={{ width: 352 }}
          type="text"
          icon={<PlusOutlined />}
        >
          See more
        </Button>
      )}
    </Pane>
  )
}
