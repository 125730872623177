import { CloseOutlined } from '@ant-design/icons'
import { Button, Skeleton } from 'antd'
import { ItemInfo } from 'components'
import { BulkOptionSelection } from 'components/AlgoliaProducts/BulkProductOptions'
import { Pane, Tooltip } from 'evergreen-ui'
import { Item } from 'gen/perkup/v1/program_pb'
import { useItemsToVariants } from 'hooks/productVariants/useProgramItemsToVariants'
import { isEmpty } from 'lodash-es'
import { SelectedItems } from 'types/Items'

/** @deprecated We should move away from anything related to "Items" and instead use our ProductVariant components */
function SelectedItem({
  itemsKey,
  items,
  onChangeItems,
  onClearItems,
}: {
  itemsKey: string
  items: Item[]
  onChangeItems: ({
    items,
    itemsKey,
  }: {
    items: Item[]
    itemsKey: string
  }) => void
  onClearItems: (itemKey: string) => void
}) {
  const { productVariants: selectedProductVariants, isLoading } =
    useItemsToVariants({
      items,
    })

  const noProducts = isEmpty(selectedProductVariants)

  const defaultProduct = selectedProductVariants[0]

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap={16}
      border="muted"
      padding={32}
      borderRadius={8}
      marginBottom={32}
    >
      {isLoading &&
        noProducts &&
        Array.from({ length: 2 }).map((_, index) => (
          <Skeleton
            style={{ width: '32vw' }}
            key={`${index.toString()}`}
            active
          />
        ))}
      {!noProducts && (
        <>
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <ItemInfo
              amount={Number(defaultProduct.amount)}
              image={defaultProduct.imageUrl}
              name={defaultProduct.productName}
            />
            <Tooltip content="Remove item">
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => onClearItems(itemsKey)}
              />
            </Tooltip>
          </Pane>
          <BulkOptionSelection
            itemsKey={itemsKey}
            items={items}
            productId={defaultProduct.productId}
            productOptions={defaultProduct?.options}
            onChangeItems={onChangeItems}
          />
        </>
      )}
    </Pane>
  )
}

/** @deprecated We should move away from anything related to "Items" and instead use our ProductVariant components */
export function SelectedItemsList({
  selectedItems,
  onChangeItems,
  onClearItems,
}: {
  selectedItems: SelectedItems
  onChangeItems: ({
    items,
    itemsKey,
  }: {
    items: Item[]
    itemsKey: string
  }) => void
  onClearItems: (itemKey: string) => void
}) {
  const selectedItemKeys = Array.from(selectedItems.keys()).reverse()
  if (isEmpty(selectedItemKeys)) return null
  return (
    <Pane width="100%">
      {selectedItemKeys.map(id => {
        const items: Item[] = selectedItems.get(id) || []
        return (
          <SelectedItem
            key={id}
            itemsKey={id}
            items={items}
            onChangeItems={onChangeItems}
            onClearItems={onClearItems}
          />
        )
      })}
    </Pane>
  )
}
