import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Flex, Tag, Tooltip } from 'antd'
import {
  ListenToScheduledMembersByProgramId,
  updateProgram,
} from 'api/databaseCalls'
import {
  AddIndividualToProgramButton,
  AddressDisplay,
  ConfettiCelebration,
  CreatedBy,
  DeleteProgram,
  DuplicateReward,
  EditableTitle,
  ExpireReward,
  RewardLinkButton,
  withOrgSidebar,
} from 'components'
import { ProgramFeedbackModal } from 'components/Programs/ProgramFeedbackModal'
import { ProgramStatusTag } from 'components/Programs/ProgramStatusTag'
import { MemberTable } from 'components/Tables/Users/MemberTable'
import { ScheduledMembersTable } from 'components/Tables/Users/ScheduledMembersTable'
import { OrderFulfillments } from 'components/Transactions/order-fulfillments'
import { SURVEY } from 'constants/params'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext, ProgramContext } from 'context'
import { Heading, Pane, toaster } from 'evergreen-ui'
import {
  EngagementSummary,
  ProgramEmailPreviewButton,
  RewardDetails,
} from 'features'
import {
  ProgramOccasion,
  ProgramStatus,
  ProgramType,
  ScheduledMember,
} from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useListenToProgram } from 'hooks'
import useOrderFulfillmentsByOrder from 'hooks/orders/useOrderFulfillmentsByOrder'
import { useProgramIdToOrders } from 'hooks/orders/useProgramIdToOrders'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ItemsDetails from './ItemsDetails'

// TODO @CD cleam up order related code eng-5999
export function ProgramDetails() {
  const org = useContext(OrgContext)

  const navigate = useNavigate()

  const { programId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const surveyParam = searchParams.get(SURVEY)

  const orgId = org?.id

  const [scheduledMembers, setScheduledMembers] = useState<ScheduledMember[]>(
    []
  )
  const [editMode, setEditMode] = useState(false)

  const { program } = useListenToProgram({ programId })

  const isDirectMail = program?.type === ProgramType.direct

  const { orders } = useProgramIdToOrders({
    programId,
    ownerId: program?.ownerId,
  })

  const defaultOrder = orders?.[0]
  const recipientName = `${defaultOrder?.shippingAddress?.firstName} ${defaultOrder?.shippingAddress?.lastName}`

  const recipientAddress = new ShippingAddress({
    ...defaultOrder?.shippingAddress,
    name: recipientName,
  })

  const { orderFulfillments } = useOrderFulfillmentsByOrder({
    order: orders[0],
  })

  useEffect(() => {
    if (!orgId || !programId) return undefined

    return ListenToScheduledMembersByProgramId({
      orgId,
      programId,
      cb: setScheduledMembers,
    })
  }, [programId, orgId])

  if (!program) return null

  const { created, ownerId } = program

  const paddingSize = 40

  const handleGoBackClick = () => {
    const isRewardsProgram = program.type === ProgramType.rewards
    if (isRewardsProgram || isDirectMail) {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
    } else if (program.type === ProgramType.anniversaries) {
      if (program.occasion === ProgramOccasion.workAnniversary) {
        navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.ANNIVERSARIES.SENT)
      } else {
        navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_HIRE.SENT)
      }
    } else if (program.type === ProgramType.birthdays) {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.BIRTHDAYS.SENT)
    } else {
      navigate(-1) // go back
    }
  }

  const handleConfirmTitle = (name: string) => {
    updateProgram({
      orgId,
      programId: program.id,
      program: { name },
    }).then(() => toaster.success('Title updated', { id: name }))
  }

  const showGiftLink =
    !editMode && !isDirectMail && program.status === ProgramStatus.active

  const showSurvey =
    String(true) === surveyParam &&
    !program?.feedback &&
    program?.status === ProgramStatus.active // Show survey if it hasn't been submitted yet and program is active, and survey param is true

  return (
    <>
      {showSurvey && (
        <ProgramFeedbackModal
          programId={program.id}
          onAfterSubmit={() => {
            searchParams.delete(SURVEY)
            setSearchParams(searchParams)
          }}
        />
      )}

      <Flex vertical>
        {/* HEADING */}
        <Pane
          display="flex"
          alignItems="flex-start"
          marginBottom={16}
          justifyContent="space-between"
        >
          <Pane>
            <Pane display="flex">
              <Pane>
                <Pane
                  display="flex"
                  gap={8}
                  alignItems="center"
                  marginBottom={16}
                >
                  <Tooltip title="Go back">
                    <Button
                      icon={<ArrowLeftOutlined />}
                      onClick={handleGoBackClick}
                      type="text"
                    />
                  </Tooltip>

                  <EditableTitle
                    currentTitle={program.name}
                    onConfirmTitle={handleConfirmTitle}
                  />

                  <Flex gap={8} align="center" style={{ marginLeft: 16 }}>
                    {program.type === ProgramType.direct && (
                      <Tag color="gold">Direct mail</Tag>
                    )}
                    <ProgramStatusTag program={program} />
                  </Flex>
                </Pane>
                <Pane paddingX={paddingSize}>
                  {created && ownerId && (
                    <CreatedBy createdDate={created.toDate()}>
                      <CreatedBy.User userId={ownerId} />
                    </CreatedBy>
                  )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>

          {/* PROGRAM TOP RIGHT BUTTONS */}
          <Pane display="flex" gap={4}>
            {showGiftLink && (
              <RewardLinkButton programId={program.id} withText />
            )}
            {!editMode && !isDirectMail && (
              <Button
                icon={<EditOutlined />}
                type="text"
                onClick={() => setEditMode(true)}
              >
                Edit reward
              </Button>
            )}
            {!isDirectMail && <ProgramEmailPreviewButton program={program} />}
            <ExpireReward program={program} />
            <DuplicateReward programId={program.id} />
            {!isDirectMail && <DeleteProgram program={program} />}
          </Pane>
        </Pane>

        {/** PROGRAM DETAILS */}
        <Pane paddingX={paddingSize}>
          <Pane
            display="flex"
            flexDirection={isDirectMail ? 'column-reverse' : 'column'}
          >
            <ItemsDetails
              program={program}
              editMode={editMode}
              isDirectMail={isDirectMail}
              orders={orders}
            />

            <RewardDetails
              key={program.id}
              program={program}
              editMode={editMode}
              orders={orders}
              setEditMode={setEditMode}
            />
          </Pane>

          {/* ENGAGEMENT SUMMARY & DIVIDER */}
          {!isDirectMail && (
            <Pane>
              <EngagementSummary programId={program.id} />
              <Pane borderBottom marginY={16} />
            </Pane>
          )}
          <ProgramContext.Provider value={program}>
            {/* SCHEDULED REWARDS */}
            {scheduledMembers?.length > 0 && (
              <Pane marginY={32}>
                <Pane
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  marginBottom={16}
                >
                  <Heading size={600} marginBottom={16}>
                    Scheduled Rewards
                  </Heading>

                  <AddIndividualToProgramButton
                    enrolledIndividualIds={scheduledMembers.map(m => m.id)}
                    sendAtSeconds={Number(scheduledMembers[0]?.sendAt?.seconds)}
                    buttonCTA="Schedule people"
                  />
                </Pane>
                <ScheduledMembersTable scheduledMembers={scheduledMembers} />
              </Pane>
            )}

            {/* If reward type is direct, do not render */}
            {!isDirectMail && <MemberTable />}
          </ProgramContext.Provider>
          {isDirectMail && recipientAddress?.country && (
            <Pane flex={1} display="flex" flexDirection="column" minWidth={256}>
              <AddressDisplay address={recipientAddress} hasBadge />
            </Pane>
          )}
          {isDirectMail && orderFulfillments && (
            <Pane marginTop={16}>
              <OrderFulfillments
                order={defaultOrder}
                orderFulfillments={orderFulfillments}
              />
            </Pane>
          )}

          <ConfettiCelebration />
        </Pane>
      </Flex>
    </>
  )
}

export default withOrgSidebar(ProgramDetails)
