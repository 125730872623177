import { loadStripe, Stripe } from '@stripe/stripe-js'
import * as KEYS from 'constants/keys'
import { toaster } from 'evergreen-ui'
import { useEffect, useState } from 'react'

export function useStripe() {
  // create async function to load stripe and capture error then store stripe in state
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    loadStripe(KEYS.STRIPE_API_PUBLIC_KEY)
      .then(s => {
        if (!s) return
        setStripe(s)
      })
      .catch(error => {
        console.error(error)
        toaster.warning(
          'Something went wrong, refresh the page or contact support'
        )
        // Note: don't capture this error to Sentry
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
  return { stripe, isLoading }
}
