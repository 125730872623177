import { ClockCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { updateProgram } from 'api/databaseCalls'
import { DEFAULT_ROUTES } from 'constants/routes'
import { serverTimestamp } from 'firebase/firestore'

import { Program, ProgramStatus } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useNavigate } from 'react-router'

export function ExpireReward({ program }: { program?: Program }) {
  const navigate = useNavigate()
  const { orgId } = useIds()
  const canExpireProgram = program?.status === ProgramStatus.active

  if (!canExpireProgram || !program) return null
  const programId = program.id

  const handleExpireProgram = () => {
    updateProgram({
      orgId,
      programId,
      program: {
        status: ProgramStatus.inactive,
        endsOn: serverTimestamp(),
      },
    }).finally(() => {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
    })
  }

  return (
    <Tooltip title="Set as expired">
      <Button
        onClick={handleExpireProgram}
        type="text"
        icon={<ClockCircleOutlined />}
      />
    </Tooltip>
  )
}
