import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { WithSelectedQuantity } from 'types'

export const calculateCartSubtotal = (
  productVariants: WithSelectedQuantity<ProductVariant>[]
) => {
  return productVariants.reduce(
    (acc, { amount, selectedQuantity }) =>
      acc + Number(amount) * selectedQuantity,
    0
  )
}
