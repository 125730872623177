// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/individual.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /organizations/:id/individuals
 * Most fields are snake_case...
 * The following are camelCase
 * - userId, individualId, profilePicture
 *
 *
 * make another calendar individual
 *
 * @generated from message perkup.v1.Individual
 */
export class Individual extends Message<Individual> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: optional string first_name = 2;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 3;
   */
  lastName?: string;

  /**
   * @generated from field: optional string user_id = 4;
   */
  userId?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp start_date = 5 [json_name = "start_date"];
   */
  startDate?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp end_date = 6 [json_name = "end_date"];
   */
  endDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * @generated from field: string email = 8;
   */
  email = "";

  /**
   * @generated from field: bool is_active = 9;
   */
  isActive = false;

  /**
   * @generated from field: optional string external_id = 10;
   */
  externalId?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp dob = 11;
   */
  dob?: Timestamp;

  /**
   * @generated from field: optional string title = 12;
   */
  title?: string;

  /**
   * @generated from field: perkup.v1.Individual.Status status = 13;
   */
  status = Individual_Status.STATUS_UNSPECIFIED;

  /**
   * @generated from field: optional string profile_picture = 14;
   */
  profilePicture?: string;

  /**
   * @generated from field: map<string, string> labels = 15;
   */
  labels: { [key: string]: string } = {};

  /**
   * manager_id -> organizations/:orgId/individuals/:id
   *
   * @generated from field: optional string manager_id = 16;
   */
  managerId?: string;

  /**
   * @generated from field: optional bool block_sync = 17;
   */
  blockSync?: boolean;

  /**
   * @generated from field: optional string external_manager_id = 18;
   */
  externalManagerId?: string;

  /**
   * @generated from field: string org_id = 19;
   */
  orgId = "";

  /**
   * @generated from field: perkup.v1.Individual.Role role = 20;
   */
  role = Individual_Role.ROLE_UNSPECIFIED;

  /**
   * @generated from field: optional string country_id = 21;
   */
  countryId?: string;

  /**
   * from org user or members if not on user
   *
   * @generated from field: int64 balance = 22;
   */
  balance = protoInt64.zero;

  /**
   * Dates by part to provide better searching
   * dates.anniversary.year
   * dates.birthday.year
   *
   * @generated from field: map<string, perkup.v1.Individual.Date> dates = 23;
   */
  dates: { [key: string]: Individual_Date } = {};

  /**
   * Emails
   *
   * @generated from field: map<string, perkup.v1.Individual.Email> emails = 24;
   */
  emails: { [key: string]: Individual_Email } = {};

  /**
   * @generated from field: optional google.protobuf.Timestamp state_updated = 25;
   */
  stateUpdated?: Timestamp;

  /**
   * @generated from field: optional perkup.v1.Individual.State state = 26;
   */
  state?: Individual_State;

  /**
   * the key is the ruleGroup enum in notificationSetting.proto
   *
   * @generated from field: map<string, perkup.v1.Individual.PrivacySettings> privacy_settings = 27;
   */
  privacySettings: { [key: string]: Individual_PrivacySettings } = {};

  /**
   * sync_id is the integration id used for syncing the individual
   *
   * @generated from field: optional string sync_id = 28;
   */
  syncId?: string;

  /**
   * EmployeeId based on the Org and not the intermediate ExternalId from the sync integration
   *
   * @generated from field: optional string employee_id = 29;
   */
  employeeId?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp removed_at = 30;
   */
  removedAt?: Timestamp;

  /**
   * individualId of whoever did the removing action 
   *
   * @generated from field: optional string removed_by = 31;
   */
  removedBy?: string;

  /**
   * balance at the time of removal
   *
   * @generated from field: optional int32 removed_balance = 32;
   */
  removedBalance?: number;

  /**
   * The key is the notification_type enum in notification.proto. This can be used for both admin/member notifications.
   *
   * @generated from field: map<string, perkup.v1.Individual.NotificationSettings> notification_settings = 33;
   */
  notificationSettings: { [key: string]: Individual_NotificationSettings } = {};

  constructor(data?: PartialMessage<Individual>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Individual";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "start_date", jsonName: "start_date", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "end_date", jsonName: "end_date", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "dob", kind: "message", T: Timestamp, opt: true },
    { no: 12, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 13, name: "status", kind: "enum", T: proto3.getEnumType(Individual_Status) },
    { no: 14, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 15, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 16, name: "manager_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 17, name: "block_sync", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 18, name: "external_manager_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 19, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "role", kind: "enum", T: proto3.getEnumType(Individual_Role) },
    { no: 21, name: "country_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 22, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 23, name: "dates", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Individual_Date} },
    { no: 24, name: "emails", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Individual_Email} },
    { no: 25, name: "state_updated", kind: "message", T: Timestamp, opt: true },
    { no: 26, name: "state", kind: "enum", T: proto3.getEnumType(Individual_State), opt: true },
    { no: 27, name: "privacy_settings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Individual_PrivacySettings} },
    { no: 28, name: "sync_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 29, name: "employee_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "removed_at", kind: "message", T: Timestamp, opt: true },
    { no: 31, name: "removed_by", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 32, name: "removed_balance", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 33, name: "notification_settings", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Individual_NotificationSettings} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Individual {
    return new Individual().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Individual {
    return new Individual().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Individual {
    return new Individual().fromJsonString(jsonString, options);
  }

  static equals(a: Individual | PlainMessage<Individual> | undefined, b: Individual | PlainMessage<Individual> | undefined): boolean {
    return proto3.util.equals(Individual, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Individual.Status
 */
export enum Individual_Status {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: invited = 2;
   */
  invited = 2,

  /**
   * @generated from enum value: blocked = 3;
   */
  blocked = 3,

  /**
   * @generated from enum value: removed = 4;
   */
  removed = 4,

  /**
   * @generated from enum value: none = 5;
   */
  none = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Individual_Status)
proto3.util.setEnumType(Individual_Status, "perkup.v1.Individual.Status", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "invited" },
  { no: 3, name: "blocked" },
  { no: 4, name: "removed" },
  { no: 5, name: "none" },
]);

/**
 * @generated from enum perkup.v1.Individual.Role
 */
export enum Individual_Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  ROLE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: admin = 1;
   */
  admin = 1,

  /**
   * @generated from enum value: manager = 2;
   */
  manager = 2,

  /**
   * @generated from enum value: member = 3;
   */
  member = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Individual_Role)
proto3.util.setEnumType(Individual_Role, "perkup.v1.Individual.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 1, name: "admin" },
  { no: 2, name: "manager" },
  { no: 3, name: "member" },
]);

/**
 * @generated from enum perkup.v1.Individual.State
 */
export enum Individual_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  STATE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: online = 1;
   */
  online = 1,

  /**
   * @generated from enum value: offline = 2;
   */
  offline = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Individual_State)
proto3.util.setEnumType(Individual_State, "perkup.v1.Individual.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "online" },
  { no: 2, name: "offline" },
]);

/**
 * @generated from message perkup.v1.Individual.PrivacySettings
 */
export class Individual_PrivacySettings extends Message<Individual_PrivacySettings> {
  /**
   * if false, the individual dob should not show up in the calendar, admin notification will be blocked
   *
   * @generated from field: bool allow_admins = 1;
   */
  allowAdmins = false;

  /**
   * if false, the individual dob should not show up in the calendar, manager notification will be blocked
   *
   * @generated from field: bool allow_managers = 2;
   */
  allowManagers = false;

  /**
   * if false, the individual dob should not show up in the calendar, public announcements will be blocked
   *
   * @generated from field: bool allow_members = 3;
   */
  allowMembers = false;

  constructor(data?: PartialMessage<Individual_PrivacySettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Individual.PrivacySettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "allow_admins", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "allow_managers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "allow_members", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Individual_PrivacySettings {
    return new Individual_PrivacySettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Individual_PrivacySettings {
    return new Individual_PrivacySettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Individual_PrivacySettings {
    return new Individual_PrivacySettings().fromJsonString(jsonString, options);
  }

  static equals(a: Individual_PrivacySettings | PlainMessage<Individual_PrivacySettings> | undefined, b: Individual_PrivacySettings | PlainMessage<Individual_PrivacySettings> | undefined): boolean {
    return proto3.util.equals(Individual_PrivacySettings, a, b);
  }
}

/**
 * @generated from message perkup.v1.Individual.NotificationSettings
 */
export class Individual_NotificationSettings extends Message<Individual_NotificationSettings> {
  /**
   * If true, doesn't receive notifications of this type. If false or undefined, receives notifications.
   *
   * @generated from field: optional bool block = 1;
   */
  block?: boolean;

  constructor(data?: PartialMessage<Individual_NotificationSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Individual.NotificationSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "block", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Individual_NotificationSettings {
    return new Individual_NotificationSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Individual_NotificationSettings {
    return new Individual_NotificationSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Individual_NotificationSettings {
    return new Individual_NotificationSettings().fromJsonString(jsonString, options);
  }

  static equals(a: Individual_NotificationSettings | PlainMessage<Individual_NotificationSettings> | undefined, b: Individual_NotificationSettings | PlainMessage<Individual_NotificationSettings> | undefined): boolean {
    return proto3.util.equals(Individual_NotificationSettings, a, b);
  }
}

/**
 * @generated from message perkup.v1.Individual.Date
 */
export class Individual_Date extends Message<Individual_Date> {
  /**
   * Birthday may or may not have a year
   *
   * @generated from field: optional int32 year = 1;
   */
  year?: number;

  /**
   * @generated from field: int32 month = 2;
   */
  month = 0;

  /**
   * @generated from field: int32 day = 3;
   */
  day = 0;

  /**
   * month day for ranging and simple filtering
   *
   * @generated from field: int32 mmdd = 4;
   */
  mmdd = 0;

  constructor(data?: PartialMessage<Individual_Date>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Individual.Date";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "mmdd", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Individual_Date {
    return new Individual_Date().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Individual_Date {
    return new Individual_Date().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Individual_Date {
    return new Individual_Date().fromJsonString(jsonString, options);
  }

  static equals(a: Individual_Date | PlainMessage<Individual_Date> | undefined, b: Individual_Date | PlainMessage<Individual_Date> | undefined): boolean {
    return proto3.util.equals(Individual_Date, a, b);
  }
}

/**
 * @generated from message perkup.v1.Individual.Email
 */
export class Individual_Email extends Message<Individual_Email> {
  /**
   * map key on emails
   *
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: optional string user_id = 2;
   */
  userId?: string;

  /**
   * @generated from field: optional bool verified = 3;
   */
  verified?: boolean;

  constructor(data?: PartialMessage<Individual_Email>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Individual.Email";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Individual_Email {
    return new Individual_Email().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Individual_Email {
    return new Individual_Email().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Individual_Email {
    return new Individual_Email().fromJsonString(jsonString, options);
  }

  static equals(a: Individual_Email | PlainMessage<Individual_Email> | undefined, b: Individual_Email | PlainMessage<Individual_Email> | undefined): boolean {
    return proto3.util.equals(Individual_Email, a, b);
  }
}

/**
 * @generated from message perkup.v1.IndividualList
 */
export class IndividualList extends Message<IndividualList> {
  /**
   * @generated from field: repeated perkup.v1.Individual individuals = 1;
   */
  individuals: Individual[] = [];

  constructor(data?: PartialMessage<IndividualList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.IndividualList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "individuals", kind: "message", T: Individual, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndividualList {
    return new IndividualList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndividualList {
    return new IndividualList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndividualList {
    return new IndividualList().fromJsonString(jsonString, options);
  }

  static equals(a: IndividualList | PlainMessage<IndividualList> | undefined, b: IndividualList | PlainMessage<IndividualList> | undefined): boolean {
    return proto3.util.equals(IndividualList, a, b);
  }
}

/**
 * List Individual Ids
 * for use in building program draft members
 *  - all active individuals if no arguments set
 *  - all by labels
 *
 * @generated from message perkup.v1.ListIndividualIdsRequest
 */
export class ListIndividualIdsRequest extends Message<ListIndividualIdsRequest> {
  /**
   * @generated from field: map<string, string> labels = 1;
   */
  labels: { [key: string]: string } = {};

  /**
   * @generated from field: repeated string statuses = 2;
   */
  statuses: string[] = [];

  constructor(data?: PartialMessage<ListIndividualIdsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ListIndividualIdsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "labels", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 2, name: "statuses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIndividualIdsRequest {
    return new ListIndividualIdsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIndividualIdsRequest {
    return new ListIndividualIdsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIndividualIdsRequest {
    return new ListIndividualIdsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListIndividualIdsRequest | PlainMessage<ListIndividualIdsRequest> | undefined, b: ListIndividualIdsRequest | PlainMessage<ListIndividualIdsRequest> | undefined): boolean {
    return proto3.util.equals(ListIndividualIdsRequest, a, b);
  }
}

/**
 * @generated from message perkup.v1.ListIndividualIdsResponse
 */
export class ListIndividualIdsResponse extends Message<ListIndividualIdsResponse> {
  /**
   * @generated from field: repeated string ids = 1;
   */
  ids: string[] = [];

  constructor(data?: PartialMessage<ListIndividualIdsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ListIndividualIdsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListIndividualIdsResponse {
    return new ListIndividualIdsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListIndividualIdsResponse {
    return new ListIndividualIdsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListIndividualIdsResponse {
    return new ListIndividualIdsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListIndividualIdsResponse | PlainMessage<ListIndividualIdsResponse> | undefined, b: ListIndividualIdsResponse | PlainMessage<ListIndividualIdsResponse> | undefined): boolean {
    return proto3.util.equals(ListIndividualIdsResponse, a, b);
  }
}

