import { ListSendableOrgAccountsForAllAdmins } from 'api/databaseCalls'
import { IndividualContext, OrgContext } from 'context'
import { Account } from 'gen/perkup/v1/account_pb'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useContext, useEffect, useMemo, useState } from 'react'
import { getDefaultAccount } from 'utils/accounts'

export function useAdminsSendableAccounts() {
  const individual = useContext(IndividualContext)
  const { defaultAccountId } = useContext(OrgContext)

  const { orgId, individualId } = useIds()
  const individualRole = individual?.role

  const [sendableAccounts, setSendableAccounts] = useState<Account[]>([])

  useEffect(() => {
    if (!orgId || !individualRole || !individualId) return
    ListSendableOrgAccountsForAllAdmins({
      orgId,
    }).then(accounts => {
      if (accounts) setSendableAccounts(accounts)
    })
  }, [orgId, setSendableAccounts, individualId, individualRole])

  const defaultSendableAccount = useMemo(() => {
    if (isEmpty(sendableAccounts)) return undefined
    return getDefaultAccount({
      accounts: sendableAccounts,
      defaultAccountId,
    })
  }, [defaultAccountId, sendableAccounts])

  return { sendableAccounts, defaultSendableAccount }
}
