import { captureException } from '@sentry/react'
import AmazonBusinessPrimaryLogo_Small from 'assets/AmazonBusinessPrimaryLogo_Small.png'
import {
  AddressShortDisplay,
  AmazonReturnPolicy,
  Loader,
  PrimeLogo,
  QuantitySelection,
} from 'components'
import {
  AMAZON_PRICE,
  AMAZON_THUMBNAIL_ORANGE_BORDER,
  NUMBER_GREEN,
} from 'constants/colors'
import { countryIdToCountryCode } from 'constants/countries'
import {
  AMAZON_BUSINESS_STORE,
  PRODUCT_ADDED,
  PRODUCT_CLICKED,
  PRODUCT_VIEWED,
} from 'constants/events'
import * as PARAMS from 'constants/params'
import * as ROUTES from 'constants/routes'
import { AMAZON_CHECKOUT } from 'constants/routes'
import {
  CountryContext,
  OrgContext,
  UserContext,
  UserShippingAddressesContext,
} from 'context'
import {
  ArrowRightIcon,
  Button,
  Heading,
  Image,
  ListItem,
  Pane,
  Paragraph,
  SelectField,
  Small,
  Strong,
  Text,
  UnorderedList,
} from 'evergreen-ui'
import { getAuth } from 'firebase/auth'
import { Search } from 'gen/amazon/search_connect'
import { GetProductRequest, GetProductResponse } from 'gen/amazon/search_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { createClient } from 'hooks/useClient'
import useNumToDollarsConversion from 'hooks/useNumToDollarsConversion'
import cloneDeep from 'lodash-es/cloneDeep'
import isEqual from 'lodash-es/isEqual'
import isObject from 'lodash-es/isObject'
import isUndefined from 'lodash-es/isUndefined'
import toNumber from 'lodash-es/toNumber'
import { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AmazonOffer,
  AmazonProduct,
  AmazonProductDimension,
  AmazonProductVariation,
} from 'types/Amazon'
import { getAmazonDisplayPrices, logEvent } from 'utils'
import { toSentry } from 'utils/sentry'

function ProductPrice(props: { offer: AmazonOffer }) {
  const { offer } = props
  const user = useContext(UserContext)
  const org = useContext(OrgContext)
  const { offerAmount, offerCurrency, listAmount, savingsAmount, percentOff } =
    getAmazonDisplayPrices(offer)

  const offerDisplayPrice = useNumToDollarsConversion({
    num: offerAmount,
    numCurrency: offerCurrency,
    decimal: 2,
    displayCurrency: user.displayCurrency || org?.displayCurrency,
  })

  const listDisplayPrice = useNumToDollarsConversion({
    num: listAmount,
    numCurrency: offerCurrency,
    decimal: 2,
    displayCurrency: user.displayCurrency || org?.displayCurrency,
  })

  const savingsDisplayPrice = useNumToDollarsConversion({
    num: savingsAmount,
    numCurrency: offerCurrency,
    decimal: 2,
    displayCurrency: user.displayCurrency || org?.displayCurrency,
  })

  return (
    <Pane
      display="grid"
      gridTemplateColumns="max-content 1fr"
      alignItems="center"
      rowGap={4}
      columnGap={8}
    >
      {listAmount > 0 && (
        <>
          <Text justifySelf="flex-end">Was:</Text>
          <Text textDecoration="line-through">{listDisplayPrice}</Text>
          <Text justifySelf="flex-end">With Deal:</Text>
        </>
      )}

      <Pane display="flex" alignItems="center" gap={8}>
        <Heading size={700} color={AMAZON_PRICE}>
          {offerDisplayPrice}
        </Heading>
        <PrimeLogo />
      </Pane>

      {savingsAmount > 0 && (
        <>
          <Text justifySelf="flex-end">You Save:</Text>

          <Heading size={400} color={AMAZON_PRICE}>
            {savingsDisplayPrice} ({percentOff}%)
          </Heading>
        </>
      )}
    </Pane>
  )
}

function ProductImages(props: {
  images?: AmazonProduct['includedDataTypes']['IMAGES']
}) {
  const [selImageIndex, setSelImageIndex] = useState(0)
  const { images } = props

  if (!images?.length) return null

  return (
    <Pane>
      <Pane
        width={isMobile ? '100%' : 450}
        height={400}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={images[selImageIndex].large.url}
          alt={images[selImageIndex].altText}
          maxHeight={400}
          maxWidth={isMobile ? '100%' : 450}
        />
      </Pane>
      <Pane
        display="flex"
        flexWrap="wrap"
        gap={16}
        marginTop={16}
        maxWidth={512}
      >
        {images?.map((image, index) => {
          const isSelected = index === selImageIndex

          const thumbnailStyles = isSelected
            ? {
                boxShadow: '0 0 3px 2px rgb(228 121 17 / 50%)',
                border: `1px solid ${AMAZON_THUMBNAIL_ORANGE_BORDER}`,
              }
            : {}

          return (
            <Pane
              onMouseEnter={() => setSelImageIndex(index)}
              cursor="pointer"
              key={image.thumbnail.url}
              {...thumbnailStyles}
            >
              <Image
                src={image.thumbnail.url}
                width={image.thumbnail.width}
                height={image.thumbnail.height}
                alt={image.altText}
              />
            </Pane>
          )
        })}
      </Pane>
    </Pane>
  )
}

function ProductDetail(props: {
  productDetails: AmazonProduct['productDetails']
}) {
  const { productDetails = {} } = props

  if (!Object.entries(productDetails)?.length) return null

  return (
    <>
      {Object.entries(productDetails).map(([key, value]) => {
        if (isObject(value)) {
          return <ProductDetail key={key} productDetails={value} />
        }

        return (
          <Pane key={key}>
            <Heading size={400} marginBottom={4}>
              {key}
            </Heading>

            <Paragraph>{value}</Paragraph>
          </Pane>
        )
      })}
    </>
  )
}

function ProductDetails(props: {
  productDetails: AmazonProduct['productDetails']
}) {
  const { productDetails = {} } = props

  if (!Object.entries(productDetails)?.length) return null

  return (
    <Pane
      border="muted"
      borderRadius={4}
      flex={1}
      display="flex"
      flexDirection="column"
      gap="1rem"
      padding={16}
    >
      <ProductDetail productDetails={productDetails} />
    </Pane>
  )
}

function ProductDescription(props: { features?: AmazonProduct['features'] }) {
  const { features } = props

  if (!features?.length) return null

  return (
    <>
      <Heading size={600}>About this item</Heading>
      <UnorderedList>
        {features?.map(feature => <ListItem key={feature}>{feature}</ListItem>)}
      </UnorderedList>
    </>
  )
}

export function ProductOverview(props: {
  title: string
  brand: string

  offer: AmazonOffer
  productOverview: AmazonProduct['productOverview']
}) {
  const { title, brand, offer, productOverview = {} } = props

  return (
    <Pane display="flex" flexDirection="column">
      {!isMobile && (
        <>
          <Heading size={500} marginBottom={8}>
            {title}
          </Heading>
          {brand && <Small color="muted">Brand: {brand}</Small>}
          <Pane marginY={16}>
            <ProductPrice offer={offer} />
          </Pane>
        </>
      )}

      <Pane marginTop={16}>
        <Pane display="flex" flexDirection="column" gap="0.5rem">
          {Object.entries(productOverview).map(([key, value]) => (
            <Pane key={key} display="flex" gap="0.2rem">
              <Text flex={1}>
                <Strong>{key}</Strong>
              </Text>
              <Text flex={1}>{value}</Text>
            </Pane>
          ))}
        </Pane>
      </Pane>
    </Pane>
  )
}

function ProductDimension({
  dimensionIndex, // The index of this dimension in the product's dimensions array.
  dimensionTitle, // The title of this dimension (ex: size, color, etc...)
  dimensionValues, // The product options in this dimension
  currentProductVariantIndex = 0, // The current index of this product in this dimension array. Defaults to 0 if 'undefined' because Amazon API uses 'undefined' for 0 indexes
  changeProduct, // Callback to navigate to a desired product variant page
}: {
  dimensionIndex: number
  dimensionTitle: string
  dimensionValues: {
    displayString: string
    index: number
  }[]
  currentProductVariantIndex: number | undefined
  changeProduct: Function
}) {
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const validationMessage = isInvalid
    ? `Selected ${dimensionTitle.toLowerCase()} is unavailable.`
    : undefined

  return (
    <SelectField
      label={dimensionTitle}
      isInvalid={isInvalid}
      validationMessage={validationMessage}
      defaultValue={currentProductVariantIndex}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        changeProduct(dimensionIndex, toNumber(e.target.value), setIsInvalid)
      }
    >
      {dimensionValues.map(dimensionValue => {
        const { displayString, index = 0 } = dimensionValue

        return (
          <option key={index} value={index}>
            {displayString}
          </option>
        )
      })}
    </SelectField>
  )
}

function ProductVariants({
  product,
  dimensions,
  variations,
  setCanPurchase, // Callback function to disable/enable 'Buy now' button
}: {
  product: AmazonProduct
  dimensions: AmazonProductDimension[]
  variations: AmazonProductVariation[]
  setCanPurchase: Function
}) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const org = useContext(OrgContext)
  const orgId = org?.id
  const user = useContext(UserContext)
  const userId = user?.id

  const currentProductVariant = variations.find(
    variant => variant.asin === product.asin
  )

  const getVariantProduct = (dimensionIndex: number, variantIndex: number) => {
    // Make a copy of our current product's dimension indexes
    const variantToFind = cloneDeep(currentProductVariant?.variationValues)

    if (isUndefined(variantToFind)) return undefined

    // Modify the appropriate dimension index
    variantToFind[dimensionIndex].value = variantIndex

    // Find the product we want based on our new dimension indexes
    const selectedVariant = variations.find(variant =>
      isEqual(variant.variationValues, variantToFind)
    )

    return selectedVariant
  }

  const navigateToProductPage = (productAsin: string) => {
    navigate(
      `${ROUTES.AMAZON_PRODUCT}/${productAsin}?${searchParams.toString()}`
    )
    logEvent(PRODUCT_CLICKED, {
      affiliation: AMAZON_BUSINESS_STORE,
      sku: productAsin,
      orgId,
      userId,
    })
  }

  const changeProduct = (
    dimensionIndex: number,
    variantIndex: number,
    setIsInvalid: Function
  ) => {
    // Get the variant product based on the dimension index and desired variant index
    const selectedVariant = getVariantProduct(dimensionIndex, variantIndex)

    // Chosen variant is unavailable
    if (isUndefined(selectedVariant)) {
      setIsInvalid(true)
      setCanPurchase(false)
      return
    }

    setIsInvalid(false)
    setCanPurchase(true)
    navigateToProductPage(selectedVariant.asin)
  }

  return (
    <Pane display="flex" flexDirection="column" gap={8}>
      {dimensions.map((dimension, index) => (
        <ProductDimension
          key={dimension.displayString}
          dimensionIndex={index}
          dimensionTitle={dimension.displayString}
          dimensionValues={dimension.dimensionValues}
          currentProductVariantIndex={
            currentProductVariant?.variationValues[index].value
          }
          changeProduct={changeProduct}
        />
      ))}
    </Pane>
  )
}

function ProductOffers(props: {
  canPurchase: boolean
  amount: number
  amountCurrency: string
  product: AmazonProduct
  offer: AmazonOffer
  shippingAddress?: ShippingAddress
  onShippingAddressChange: (address?: ShippingAddress) => void
}) {
  const {
    canPurchase,
    amount,
    amountCurrency,
    product,
    offer,
    shippingAddress,
    onShippingAddressChange,
  } = props

  const user = useContext(UserContext)
  const userId = user?.id
  const org = useContext(OrgContext)
  const orgId = org?.id
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const [quantity, setQuantity] = useState<number>(
    offer?.quantityLimits?.minQuantity
  )

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const amountDisplayPrice = useNumToDollarsConversion({
    num: amount,
    numCurrency: amountCurrency,
    decimal: 2,
    displayCurrency: user.displayCurrency || org?.displayCurrency,
  })

  return (
    <Pane
      display="flex"
      flexDirection="column"
      gap="1rem"
      border
      padding={16}
      borderRadius={4}
    >
      <Pane>
        {offer?.price.priceType === 'BUSINESS' ? (
          <Pane marginBottom={16}>
            <Image src={AmazonBusinessPrimaryLogo_Small} width={128} />
          </Pane>
        ) : (
          <Heading size={100} marginBottom={8}>
            {offer?.price.priceType}
          </Heading>
        )}
        {isMobile ? (
          <ProductPrice offer={offer} />
        ) : (
          <>
            <Heading size={600} color={AMAZON_PRICE}>
              {amountDisplayPrice}
            </Heading>
            <PrimeLogo />
          </>
        )}
      </Pane>
      {offer.deliveryInformation && <Text>{offer.deliveryInformation}</Text>}
      {shippingAddress && (
        <AddressShortDisplay
          shippingAddress={shippingAddress}
          shippingAddresses={shippingAddresses}
          onShippingAddressChange={onShippingAddressChange}
        />
      )}

      <Heading size={600} color={NUMBER_GREEN}>
        {offer?.availability}
      </Heading>
      {offer?.quantityLimits && (
        <Pane display="flex" alignItems="center">
          <Text marginRight={4}>Quantity:</Text>
          <Pane>
            <QuantitySelection
              offer={offer}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          </Pane>
        </Pane>
      )}
      <Button
        disabled={!canPurchase}
        appearance="primary"
        iconAfter={ArrowRightIcon}
        size="large"
        onClick={() => {
          searchParams.set(PARAMS.ASIN, product.asin)
          searchParams.set(PARAMS.OFFER_ID, offer.offerId)
          searchParams.set(PARAMS.QUANITTY, quantity.toString())
          navigate(`${AMAZON_CHECKOUT}?${searchParams.toString()}`, {
            state: { product, offer, quantity },
          })
          logEvent(PRODUCT_ADDED, {
            affiliation: AMAZON_BUSINESS_STORE,
            sku: product.asin,
            name: product.title,
            orgId,
            userId,
          })
        }}
      >
        Buy Now
      </Button>

      <Pane>
        <Paragraph>
          Ships from:{' '}
          {offer?.fulfillmentType === 'AMAZON_FULFILLMENT'
            ? 'Amazon'
            : offer.merchant.name}
        </Paragraph>

        <Paragraph>Sold by: {offer?.merchant.name}</Paragraph>
        <AmazonReturnPolicy marginTop={16} />
      </Pane>
    </Pane>
  )
}

function Product({
  shippingAddress,
  onAddressChange,
}: {
  shippingAddress?: ShippingAddress
  onAddressChange: (address?: ShippingAddress) => void
}) {
  const country = useContext(CountryContext)
  const org = useContext(OrgContext)
  const orgId = org?.id
  const user = useContext(UserContext)
  const userId = user?.id

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [product, setProduct] = useState<AmazonProduct | null>(null)
  const [canPurchase, setCanPurchase] = useState<boolean>(true)

  const { asin } = useParams<{ asin: string }>()

  useEffect(() => {
    if (product?.asin) {
      logEvent(PRODUCT_VIEWED, {
        affiliation: AMAZON_BUSINESS_STORE,
        sku: product.asin,
        name: product.title,
        orgId,
        userId,
      })
    }
  }, [product, orgId, userId])

  // Get product on page load
  useEffect(() => {
    if (asin) {
      setIsLoading(true)
      const getProductReq = new GetProductRequest({ asin })

      if (shippingAddress?.postalCode) {
        getProductReq.postalCode = shippingAddress?.postalCode
      }

      getProductReq.countryCode =
        shippingAddress?.country ||
        countryIdToCountryCode.get(country?.id) ||
        'US'
      const getProduct = async () => {
        const accessToken = await getAuth().currentUser?.getIdToken()
        createClient(Search)
          .getProduct(getProductReq, {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((value: GetProductResponse) => {
            const productResponse = JSON.parse(value.productResponse)
            if (productResponse) setProduct(productResponse)
          })
          .catch((error: any) => {
            console.error(error)
            captureException(toSentry(error), {
              contexts: {
                getProduct: { asin, postalCode: shippingAddress?.postalCode },
              },
            })
          })
          .finally(() => setIsLoading(false))
      }
      getProduct()
    }
  }, [asin, shippingAddress?.postalCode, country?.id, shippingAddress?.country])

  const offer =
    product?.includedDataTypes.OFFERS && product.includedDataTypes.OFFERS[0]

  if (isLoading) {
    return <Loader />
  }
  if (!product?.asin) {
    return <Pane>Product not found</Pane>
  }
  if (!offer) {
    return <Pane>No offers available for this product.</Pane>
  }
  if (!offer.price.value) {
    return <Pane>No price available for this product.</Pane>
  }

  const { offerAmount, offerCurrency } = getAmazonDisplayPrices(offer)

  const brand = product.productOverview
    ? product.productOverview.Manufacturer
    : ''

  return (
    <Pane display="flex" gap="1rem" flexDirection="column">
      <Helmet>
        <title>{product.title}</title>
      </Helmet>
      {isMobile && (
        <Pane>
          <Heading size={600} marginBottom={8}>
            {product.title}
          </Heading>
          {brand?.length > 0 && <Small color="muted">Brand: {brand}</Small>}
        </Pane>
      )}
      <Pane display="flex" gap="1.5rem" flexWrap={isMobile ? 'wrap' : 'nowrap'}>
        <ProductImages images={product.includedDataTypes.IMAGES} />

        {/** In mobile view, place the product options right after the product image */}
        {isMobile && (
          <ProductVariants
            product={product}
            dimensions={product?.productVariations.dimensions}
            variations={product?.productVariations.variations}
            setCanPurchase={setCanPurchase}
          />
        )}

        <Pane
          display="flex"
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          gap={8}
        >
          <Pane width={isMobile ? '100%' : '60%'}>
            <ProductOverview
              title={product.title}
              brand={brand}
              offer={offer}
              productOverview={product.productOverview}
            />

            {/** In desktop view, place the product options with all the other product info */}
            {!isMobile && (
              <Pane marginY={16}>
                <ProductVariants
                  product={product}
                  dimensions={product?.productVariations.dimensions}
                  variations={product?.productVariations.variations}
                  setCanPurchase={setCanPurchase}
                />
              </Pane>
            )}
          </Pane>

          <Pane width={isMobile ? '100%' : '40%'} marginBottom={16}>
            <ProductOffers
              canPurchase={canPurchase}
              amount={offerAmount}
              amountCurrency={offerCurrency}
              product={product}
              offer={offer}
              onShippingAddressChange={onAddressChange}
            />
          </Pane>
        </Pane>
      </Pane>
      <Pane display="flex" gap="2rem" marginTop={32} flexWrap="wrap">
        <Pane flex={1}>
          <ProductDescription features={product.features} />
        </Pane>

        <ProductDetails productDetails={product.productDetails} />
      </Pane>
    </Pane>
  )
}

export default Product
