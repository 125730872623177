import { useSpace } from '@flatfile/react'
import { Button } from 'antd'
import { ListListsByOrgId } from 'api/databaseCalls'
import { FLATFILE_ENV_ID, FLATFILE_PUBLIC_KEY } from 'constants/keys'
import { IndividualContext, OrgContext } from 'context'
import { Pane } from 'evergreen-ui'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { useDefaultOrgColors } from 'hooks'
import useIds from 'hooks/useIds'
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { genWorkbook, listener } from 'services/flatfile'
import { buildFullName } from 'utils'

const Space = ({
  setShowSpace,
  lists,
}: {
  setShowSpace: Dispatch<SetStateAction<boolean>>
  lists: OrgList[]
}) => {
  const { defaultColor } = useDefaultOrgColors()
  const { userId, orgId } = useIds()
  const org = useContext(OrgContext)
  const individual = useContext(IndividualContext)

  const space = useSpace({
    name: `${org?.name} Embedded Space`,
    publishableKey: FLATFILE_PUBLIC_KEY,
    environmentId: FLATFILE_ENV_ID,

    workbook: genWorkbook({ orgId, lists }),
    listener,
    userInfo: {
      userId,
      name: buildFullName({
        firstName: individual.firstName,
        lastName: individual.lastName,
      }),
      companyId: orgId,
      companyName: org?.name,
    },
    themeConfig: {
      root: {
        primaryColor: defaultColor,
      },
      sidebar: {
        logo: 'https://asset.brandfetch.io/id2dHAotyK/idzRY-FwD4.svg',
      },
    },
    sidebarConfig: {
      showSidebar: false,
    },
    closeSpace: {
      operation: 'submitActionFg',
      onClose: () => {
        setShowSpace(false)
      },
    },
  })

  return space
}

export default function UploadCSVButton() {
  const [showSpace, setShowSpace] = useState(false)
  const { orgId } = useIds()
  const [lists, setLists] = useState<OrgList[]>([])

  useEffect(() => {
    ListListsByOrgId({ orgId }).then(setLists)
  }, [orgId])

  return (
    <Pane>
      <Button onClick={() => setShowSpace(true)} loading={showSpace}>
        Upload people
      </Button>
      {showSpace && <Space setShowSpace={setShowSpace} lists={lists} />}
    </Pane>
  )
}
