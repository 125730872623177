import { ExportOutlined } from '@ant-design/icons'
import { Button, Dropdown, Flex, Image, MenuProps, Tooltip } from 'antd'
import PerkUpLogo from 'assets/PerkUp-Logo.svg'
import { SWAG_CARD } from 'assets/contentful'
import { EditableImage, Loader, PerkUpLink, withOrgSidebar } from 'components'
import {
  PLACID_BUCKET_URL_SUFFIX,
  TEMPORARY_SWAG_BANNER_FALLBACK_PLACID_FILE_NAME,
} from 'constants/images'
import {
  DEFAULT_ROUTES,
  PRODUCTS,
  SWAG_COLLECTION,
  SWAG_INVENTORY_PRODUCT,
  SWAG_ORDERS_TABLE,
} from 'constants/routes'
import { OrgContext } from 'context'
import { Heading, Pane, Tab, Tablist, Text } from 'evergreen-ui'
import { AlgoliaBrowseSwag } from 'features'
import { useIndividualRole, usePublicProductCollections } from 'hooks'
import NoMatch404 from 'pages/NoMatch404'
import { useContext, useEffect, useState } from 'react'
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { GetFirebaseStorageImage } from 'services/images'
import { OrgSwagCollectionPage } from './org-swag-collection-page'
import OrgSwagCollectionsPage from './org-swag-collections-page'
import OrgSwagOrdersPage from './org-swag-orders-page'
import OrgSwagProductPage from './org-swag-product-page'

const sendExistingSwagMenu: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <PerkUpLink to={DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}>
        Send as reward
      </PerkUpLink>
    ),
  },
  {
    key: '2',
    label: (
      <PerkUpLink to={DEFAULT_ROUTES.ORGANIZATION.ORDER.SWAG}>
        Send to address
      </PerkUpLink>
    ),
  },
]

const sendNewSwagMenu: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <PerkUpLink
        to="https://projects.perkupapp.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        New 1-unit
      </PerkUpLink>
    ),
  },
  {
    key: '2',
    label: (
      <PerkUpLink
        to="https://perkupapp.com/swag"
        target="_blank"
        rel="noopener noreferrer"
      >
        Order in bulk
      </PerkUpLink>
    ),
  },
]

// Temporarily falling back to the same placid image for all orgs
function useTemporarySwagBannerFallbackUrl() {
  const { id: orgId } = useContext(OrgContext)

  const [isLoading, setIsLoading] = useState(false)
  const [tempPlacidFallBackUrl, setTemplacidFallBackUrl] = useState<string>()

  useEffect(() => {
    setIsLoading(true)

    GetFirebaseStorageImage({
      orgId,
      suffix: PLACID_BUCKET_URL_SUFFIX,
      imageName: TEMPORARY_SWAG_BANNER_FALLBACK_PLACID_FILE_NAME,
    })
      .then(image => image && setTemplacidFallBackUrl(image.url))
      .finally(() => setIsLoading(false))
  }, [orgId])

  return { tempPlacidFallBackUrl, isLoading }
}

function SwagStoreButton() {
  const navigate = useNavigate()

  const { collections } = usePublicProductCollections()

  const noPublicCollections = collections.length === 0

  return (
    <Tooltip
      title={
        noPublicCollections ? 'Publish a Swag Store to view' : `View Swag Store`
      }
    >
      <Button
        disabled={noPublicCollections}
        icon={<ExportOutlined />}
        type="text"
        onClick={() =>
          navigate(
            collections.length === 1
              ? `${DEFAULT_ROUTES.SWAG.ROOT}/${collections[0].id}`
              : DEFAULT_ROUTES.SWAG.ROOT
          )
        }
      >
        Swag Store
      </Button>
    </Tooltip>
  )
}

function OrgSwagLayout() {
  const { name, logoUrl, swagBannerUrl } = useContext(OrgContext)

  const navigate = useNavigate()
  const location = useLocation()

  const { isAdmin } = useIndividualRole()

  const { tempPlacidFallBackUrl, isLoading } =
    useTemporarySwagBannerFallbackUrl() // Temporary

  // const [isHoveringBanner, setIsHoveringBanner] = useState(false)

  const isOnOrdersPage = location.pathname.includes(SWAG_ORDERS_TABLE)
  const isOnProductsPage = location.pathname.includes(PRODUCTS)

  const tabOptions = [
    {
      label: 'Collections',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT,
      isSelected: !isOnProductsPage && !isOnOrdersPage,
    },
    {
      label: 'Products',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS,
      isSelected: isOnProductsPage,
    },
    {
      label: 'Orders',
      route: DEFAULT_ROUTES.ORGANIZATION.SWAG.ORDERS_TABLE,
      isSelected: isOnOrdersPage,
      isHidden: !isAdmin,
    },
  ]

  if (isLoading) return <Loader />

  // Bring back once DES-279 is done
  // const handleSwagBannerImageUpload = (
  //   upload: UploadChangeParam<UploadFile<any>>
  // ) => {
  //   if (!upload.file.originFileObj) return
  //   if (upload.file.percent === 100) {
  //     console.log('Maybe?')
  //     uploadOrgAsset({ upload, orgId, field: 'swagBannerUrl' })
  //       .then(() =>
  //         toaster.success('Successfully updated collection photo', {
  //           id: upload.file.fileName,
  //         })
  //       )
  //       .catch(() =>
  //         toaster.danger('Failed to upload image. Please contact support.', {
  //           id: upload.file.fileName,
  //         })
  //       )
  //   }
  // }

  return (
    <Flex vertical align="center" style={{ margin: '-32px -32px 0 -32px' }}>
      {/** ORG SWAG BANNER */}
      <Pane
        style={{ margin: '-32px -32px 0 -32px' }}
        // onMouseEnter={() => setIsHoveringBanner(true)} // Bring back once DES-279 is done
        // onMouseLeave={() => setIsHoveringBanner(false)} // Bring back once DES-279 is done
        width="100%"
      >
        <EditableImage
          src={swagBannerUrl || tempPlacidFallBackUrl || SWAG_CARD}
          // mode={isHoveringBanner ? Mode.edit : Mode.view} // Bring back once DES-279 is done
          maxHeight="25vh"
          // onImageChange={handleSwagBannerImageUpload} // Bring back once DES-279 is done
        />
      </Pane>

      {/** SWAG STORES LINK */}
      <Flex
        justify="space-between"
        style={{ padding: '12px 32px', width: '100%' }}
      >
        <Flex gap={8}>
          <Dropdown menu={{ items: sendExistingSwagMenu }}>
            <Button type="default">Send existing swag</Button>
          </Dropdown>
          <Dropdown menu={{ items: sendNewSwagMenu }}>
            <Button type="default">Set up new swag</Button>
          </Dropdown>
        </Flex>
        <SwagStoreButton />
      </Flex>

      {/** ORG LOGO */}
      <Pane
        display="flex"
        justifyContent="center"
        elevation={1}
        padding={24}
        width={150}
        height={120}
        borderRadius={8}
        backgroundColor="white"
        zIndex={1}
        marginTop={-120}
        marginBottom={16}
      >
        <Image
          src={logoUrl || PerkUpLogo}
          preview={false}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Pane>

      {/** ORG NAME AND SWAG TABS */}
      <Flex vertical gap={16} align="center" style={{ marginBottom: 32 }}>
        <Heading size={900}>{name} Swag</Heading>
        <Tablist>
          {tabOptions.map(tab => {
            if (tab.isHidden) return null
            return (
              <Tab
                key={tab.label}
                appearance="primary"
                isSelected={tab.isSelected}
                onSelect={() => navigate(tab.route)}
              >
                <Text size={500} color="primary">
                  {tab.label}
                </Text>
              </Tab>
            )
          })}
        </Tablist>
      </Flex>

      {/** ORG SWAG OUTLETS */}
      <Flex style={{ width: '100%', padding: '0 32px' }}>
        <Outlet />
      </Flex>
    </Flex>
  )
}

function OrgSwag() {
  return (
    <Routes>
      <Route element={<OrgSwagLayout />}>
        <Route index element={<OrgSwagCollectionsPage />} />
        <Route path={PRODUCTS} element={<AlgoliaBrowseSwag />} />
        <Route path={SWAG_ORDERS_TABLE} element={<OrgSwagOrdersPage />} />
        <Route path="*" element={<NoMatch404 hasFooter={false} />} />
      </Route>

      {/** We want the collection & product pages to be in full view, no layout / header */}
      <Route path={SWAG_COLLECTION} element={<OrgSwagCollectionPage />} />
      <Route path={SWAG_INVENTORY_PRODUCT} element={<OrgSwagProductPage />} />
    </Routes>
  )
}

export default withOrgSidebar(OrgSwag)
