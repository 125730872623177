import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { Pane } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AddressButton } from './address-button'
import { AddressDisplay } from './address-display'
import { AddressesList } from './addresses-list'

/**
 *
 * @deprecated
 * Would be great to just have one address button component in the future
 */
export function ChangeAddressButton({
  showDeleteButton = false,
  selectedAddress,
  shippingAddresses,
  onAddressChange,
}: {
  showDeleteButton?: boolean
  selectedAddress?: ShippingAddress
  shippingAddresses: ShippingAddress[]
  onAddressChange: (addressFormData?: ShippingAddress) => void
}) {
  const [showAddressesDialog, setShowAddressesDialog] = useState(false)
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false)

  if (!selectedAddress) {
    return (
      <AddressButton
        addAddressCTALabel="Add address"
        submitLabel="Add"
        dialogTitle="Add a new address"
        icon={<PlusOutlined />}
        asSmallButton
        onAddressSubmit={onAddressChange}
      />
    )
  }

  return (
    <>
      <Pane
        flex={1}
        display="flex"
        flexDirection="column"
        minWidth={isMobile ? undefined : 248}
      >
        <AddressDisplay address={selectedAddress} hasBadge />

        <Pane marginTop={8} display="flex" gap={8}>
          <Button onClick={() => setShowAddressesDialog(true)}>Edit</Button>
          {showDeleteButton && (
            <Button
              onClick={() => setShowRemoveDialog(true)}
              type="text"
              icon={<DeleteOutlined />}
            >
              Remove
            </Button>
          )}
        </Pane>
        <Modal
          open={showAddressesDialog}
          onClose={() => setShowAddressesDialog(false)}
          title="Choose your address"
          footer={null}
          onCancel={() => setShowAddressesDialog(false)}
          styles={{
            body: {
              height: 600,
              overflowY: 'auto',
            },
          }}
        >
          <AddressesList
            onSelectAddress={address => {
              onAddressChange(address)
              setShowAddressesDialog(false)
            }}
            selectedAddress={selectedAddress}
            shippingAddresses={shippingAddresses}
          />
        </Modal>
      </Pane>
      <Modal
        title="Remove shipping address"
        open={showRemoveDialog}
        destroyOnClose
        afterClose={() => setShowRemoveDialog(false)}
        onOk={() => {
          onAddressChange(undefined)
          setShowRemoveDialog(false)
        }}
        onCancel={() => setShowRemoveDialog(false)}
        okText="Remove"
      >
        <AddressDisplay address={selectedAddress} hasBadge />
      </Modal>
    </>
  )
}
