import { captureException } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import { OrgContext } from 'context'
import { IconButton, RefreshIcon, toaster, Tooltip } from 'evergreen-ui'
import { useContext, useState } from 'react'
import { toSentry } from 'utils/sentry'

export function RefreshButton() {
  const org = useContext(OrgContext)
  const orgId = org?.id

  const [isLoading, setIsLoading] = useState(false)

  const handleRefresh = () => {
    setIsLoading(true)
    callFunction('firestore-WriteIndividuals', { orgId })
      .then(() => toaster.success('Successfully refreshed people directory'))
      .catch(error => {
        captureException(toSentry(error), {
          contexts: {
            FirestoreWriteIndividuals: { orgId },
          },
        })
        console.error(error)
        toaster.warning(
          'Internal error refreshing directory. Please contact PerkUp support.'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Tooltip content="Integrations automatically refresh every 24 hours. To refresh now, click this button.">
      <IconButton
        appearance="minimal"
        icon={RefreshIcon}
        onClick={handleRefresh}
        isLoading={isLoading}
        marginLeft={32}
      />
    </Tooltip>
  )
}
