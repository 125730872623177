import { Select } from 'antd'
import { capitalize, startCase } from 'lodash-es'
import { useClearRefinements, useRefinementList } from 'react-instantsearch'

export function AlgoliaDropdownFilter({
  name,
  attribute,
  operator = 'or',
  capitalizeLabel = true,
  styles,
  customOptions,
}: {
  name: string
  attribute: string
  operator?: 'and' | 'or' | undefined
  capitalizeLabel?: boolean
  styles?: React.CSSProperties
  customOptions?: any[]
}) {
  const { items, refine } = useRefinementList({
    attribute,
    operator,
    limit: 100,
  })
  const { refine: clearRefine } = useClearRefinements({
    includedAttributes: [attribute],
  })

  const itemsToOptions = items.map(item => ({
    ...item,
    label: capitalizeLabel ? capitalize(item.label) : item.label,
  }))

  const selectOptions = customOptions || itemsToOptions

  return (
    <Select
      key={attribute}
      mode="multiple"
      placeholder={`Select ${startCase(name)}`}
      value={items.filter(i => i.isRefined).map(i => i.value)}
      options={selectOptions}
      onSelect={refine}
      onDeselect={refine}
      onClear={clearRefine}
      allowClear
      showSearch
      style={{ minWidth: '190px', ...styles }}
    />
  )
}
