import { AlertProps, Drawer, Flex } from 'antd'
import { ProductsGrid } from 'components'
import { ExchangeRateContext } from 'context'
import { Pane } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useDisplayCurrency } from 'hooks/useDisplayCurrency'
import { isUndefined, uniqBy } from 'lodash-es'
import { useContext, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { InstantSearchProps } from 'react-instantsearch'
import { numToDollars } from 'utils'
import {
  getProductVariantOptions,
  getUniqAndFormatWithSelectable,
} from 'utils/productVariant'

export function SelectProductVariants({
  productVariantsAvailable = [],
  addedVariants,
  onAddVariant,
  showPrice = false,
  budgetRemaining,
  giftsRemaining,
  continueFooter,
  header,
  withAlgolia = false,
  initialUiState,
  searchFilter,
  maxAmount,
}: {
  productVariantsAvailable?: ProductVariant[]
  addedVariants: ProductVariant[]
  onAddVariant: (productVariant: ProductVariant) => void
  showPrice?: boolean
  budgetRemaining?: number
  giftsRemaining?: number
  continueFooter?: JSX.Element
  header?: JSX.Element
  withAlgolia?: boolean
  initialUiState?: InstantSearchProps['initialUiState']
  searchFilter?: string
  maxAmount?: number
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const displayCurrency = useDisplayCurrency()
  const [toggledProductVariant, setToggledProductVariant] =
    useState<ProductVariant>()

  const specificVariantOutOfStock = !toggledProductVariant?.isAvailable
  const handleSelectProductVariant = (productVariant: ProductVariant) => {
    onAddVariant(productVariant)
    setToggledProductVariant(undefined)
  }

  const handleChangeVariant = (productVariant?: ProductVariant) => {
    if (!productVariant) return
    setToggledProductVariant(productVariant)
    const productIsAdded = addedVariants.some(
      pv => pv.productId === productVariant.productId
    )

    const variantIsAdded = addedVariants.some(pv => pv.id === productVariant.id)

    if (productIsAdded && !variantIsAdded) {
      // Will remove the added product when toggling a different variant
      onAddVariant(productVariant)
    }
  }

  const handleToggleProductVariant = (productVariant: ProductVariant) => {
    if (toggledProductVariant?.productId === productVariant.productId) {
      setToggledProductVariant(undefined)
    } else {
      setToggledProductVariant(productVariant)
    }
  }

  const currentVariantIsSelected = useMemo(() => {
    if (!toggledProductVariant) return false
    return !!addedVariants.find(
      variant => variant.productId === toggledProductVariant.productId
    )
  }, [toggledProductVariant, addedVariants])

  const maxGiftsSelected = !isUndefined(giftsRemaining) && giftsRemaining === 0

  const oneGiftRemaining = !isUndefined(giftsRemaining) && giftsRemaining === 1

  const determinePrimaryCtaText = () => {
    if (currentVariantIsSelected) return 'Unselect'

    if (specificVariantOutOfStock) return 'Out of stock'

    if (oneGiftRemaining) return 'Select and continue'

    return 'Select'
  }

  const productExceedsBudget =
    !isUndefined(budgetRemaining) &&
    !!toggledProductVariant?.amount &&
    toggledProductVariant.amount > budgetRemaining

  const disableSelectVariant =
    ((maxGiftsSelected || productExceedsBudget) && !currentVariantIsSelected) ||
    specificVariantOutOfStock

  // Toggled variant already selected
  const addedToggledVariant = addedVariants.find(
    av => av.productId === toggledProductVariant?.productId
  )

  const getAlertInfo = () => {
    if (productExceedsBudget)
      return {
        message: "You don't have enough funds",
        description: `You have ${numToDollars(
          budgetRemaining * exchangeRate,
          2,
          false,
          displayCurrency
        )} to spend.`,
      }

    if (maxGiftsSelected) {
      if (addedVariants.length === 1) {
        return {
          message: 'You have already selected your gift.',
          description: 'To select this item, unselect the gift.',
        }
      }
      return {
        message: `You have already selected ${addedVariants.length} gifts.`,
        description:
          'To select this item, unselect one of your selected items.',
      }
    }

    return undefined
  }

  const alertInfo = getAlertInfo()

  const showAlert = !!alertInfo && disableSelectVariant

  const alertProps: AlertProps | undefined = showAlert
    ? {
        message: alertInfo?.message,
        description: alertInfo?.description,
        type: 'warning',
        showIcon: true,
      }
    : undefined

  const addedVariantsWithSelectable =
    getUniqAndFormatWithSelectable(addedVariants)

  // In bare products grid, need to also render variants that were added via swap
  const productsGridProducts = uniqBy(
    [...addedVariantsWithSelectable, ...productVariantsAvailable],
    'productId'
  )

  const defaultSelectedVariant = !addedToggledVariant
    ? undefined
    : {
        options: getProductVariantOptions({
          productVariant: addedToggledVariant,
        }),
        filterOtherOptions: false,
      }

  return (
    <Flex style={{ height: '100%' }} vertical={isMobile} gap={8}>
      <Flex
        vertical
        justify="space-between"
        style={{ height: '100%', maxWidth: '100%' }}
        flex={2}
      >
        <Pane display="flex" flexDirection="column" overflowY="auto" gap={16}>
          {header}
          <Pane height="100%" paddingBottom={40}>
            {withAlgolia ? (
              <AlgoliaBrowseProducts initialUiState={initialUiState}>
                <AlgoliaBrowseProducts.Old
                  onProductCardClick={handleToggleProductVariant}
                  withCategoriesFilter
                  withProductAmounts={showPrice}
                  searchFilter={searchFilter}
                  addedVariants={addedVariantsWithSelectable}
                  showAddedVariantsFirst={false}
                />
              </AlgoliaBrowseProducts>
            ) : (
              <ProductsGrid
                products={productsGridProducts}
                onCardClick={handleToggleProductVariant}
                withPrices={showPrice}
              />
            )}
          </Pane>
        </Pane>
        {!isMobile && continueFooter}
      </Flex>
      {toggledProductVariant && (
        <Drawer
          placement="right"
          key={toggledProductVariant.productId}
          open
          onClose={() => setToggledProductVariant(undefined)}
          width={600}
        >
          <Pane display="flex" flexDirection="column">
            <ProductDetails
              productVariant={addedToggledVariant || toggledProductVariant}
              onPrimaryCtaClick={handleSelectProductVariant}
              showPrice={showPrice}
              disableCta={disableSelectVariant}
              showShipping={false}
              isVerticalLayout
              defaultSelected={defaultSelectedVariant}
              alertProps={alertProps}
              primaryCtaProps={{
                type: currentVariantIsSelected ? 'default' : 'primary',
                children: determinePrimaryCtaText(),
              }}
              maxAmount={maxAmount}
              onSelectedVariantChange={handleChangeVariant}
            />

            <Pane paddingY="20vh" width="100%" />
          </Pane>
        </Drawer>
      )}
      {isMobile && continueFooter}
    </Flex>
  )
}
