import { PageHeader } from 'components'
import { Pane } from 'evergreen-ui'
import { RewardsDaily } from './components/RewardsDaily'
import { StatBoxes } from './components/StatBoxes'
import { ThankYouMessages } from './components/ThankYouMessages'
import { TopRecipients } from './components/TopRecipients'
import { TopSenders } from './components/TopSenders'

export function InsightsDashboard() {
  return (
    <Pane maxWidth={1096}>
      <PageHeader
        title="Insights"
        description="See how your team has been engaging with PerkUp."
        vlogLink="https://www.loom.com/embed/4563979f265d4055a6bb0df723a8cbbc?sid=1926afc0-06a3-4f4c-9671-99fc17769e9c"
      />
      <Pane
        display="flex"
        flexDirection="column"
        gap={16}
        width="100%"
        marginTop={32}
      >
        <StatBoxes />
        <Pane display="flex" gap={16} width="100%" flexWrap="wrap">
          <Pane display="flex" flexDirection="column" gap={16}>
            <RewardsDaily />
            <TopSenders />
          </Pane>

          <Pane display="flex" flexDirection="column" gap={16} flex={1}>
            <ThankYouMessages />
            <TopRecipients />
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}
