import { Flex } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import {
  CountryIconGroup,
  Loader,
  PerkEmptyState,
  PerkImage,
  PrepaidBadge,
} from 'components'
import { TooltipIconButton } from 'components/Buttons/TooltipIconButton'
import { PerkUpLink } from 'components/Typography/PerkUpLink'
import { NUMBER_GREEN } from 'constants/colors'
import { IN_STOCK_THRESHOLD } from 'constants/productVariants'
import { DEFAULT_ROUTES } from 'constants/routes'
import { Pane, SendMessageIcon, Strong, Text, toaster } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { useDefaultOrgColors } from 'hooks'
import useInfiniteProductVariants from 'hooks/productVariants/use-infinite-product-variants'
import { compact } from 'lodash-es'
import { buildProgramGiftAsGenericProduct } from 'pages/NewReward/utils/program-gifts'
import { useNavigate } from 'react-router'
import { WithKey } from 'types'
import {
  calculateShopifyProductVariantTotal,
  makePlural,
  numToDollars,
} from 'utils'
import {
  determineInventoryStatus,
  getProductVariantProductImage,
} from 'utils/productVariant'

export function AlgoliaBrowseSwagTable() {
  const { defaultColor } = useDefaultOrgColors()

  const {
    productVariants,
    isLoadingInitial,
    sentinelRef,
    isLoadingMore,
    isEmpty,
  } = useInfiniteProductVariants()

  const navigate = useNavigate()

  const handleSendSwagProduct = (productVariant: ProductVariant) => {
    const gift = buildProgramGiftAsGenericProduct({ productVariant })
    const total = calculateShopifyProductVariantTotal(productVariant)
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
      state: { gift, budget: total },
    })
  }

  const columns: ColumnsType<WithKey<ProductVariant>> = [
    {
      title: '',
      dataIndex: 'img',
      key: 'img',
      render: (_, pv) => {
        return (
          <PerkImage
            preview
            src={getProductVariantProductImage(pv)}
            sizes="190px"
            style={{
              width: 190,
              objectFit: 'cover',
              aspectRatio: '1/1',
              borderRadius: 4,
            }}
          />
        )
      },
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      render: (
        productName,
        { variantsCount, productId, shippingCountries }
      ) => {
        const variantNumber = Number(variantsCount) || 1
        return (
          <Flex vertical gap={8}>
            <PerkUpLink
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
              to={`${DEFAULT_ROUTES.ORGANIZATION.SWAG.PRODUCTS}/${productId}`}
            >
              {productName}
            </PerkUpLink>
            <Text color="muted">
              {`${variantNumber} ${makePlural('variant', variantNumber)}`}
            </Text>
            {compact(shippingCountries).length > 0 && (
              <CountryIconGroup iso3s={shippingCountries} />
            )}
          </Flex>
        )
      },
      sorter: (a, b) => {
        if (a.productName && b.productName)
          return a.productName.localeCompare(b.productName)
        return 0
      },
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => {
        const { amount } = record
        const isPrepaid =
          record?.sourceType === ProductVariant_SourceType.fullPrepaid
        return (
          <Pane display="flex" flexDirection="column" gap={8}>
            {isPrepaid && <PrepaidBadge />}
            <Strong color={NUMBER_GREEN}>{numToDollars(Number(amount))}</Strong>
          </Pane>
        )
      },
    },
    {
      title: 'Inventory',
      dataIndex: 'inventory',
      key: 'inventory',
      render: (_, productVariant) => {
        const inventoryStatus = determineInventoryStatus({
          productVariants: [productVariant],
          displayInStockThreshold: IN_STOCK_THRESHOLD,
          level: 'product',
        })
        return <Text color={inventoryStatus.color}>{inventoryStatus.text}</Text>
      },
    },
    {
      title: 'Category',
      dataIndex: 'productType',
      key: 'productType',
      render: productType => {
        return <Text>{productType}</Text>
      },
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'sendGift',
      render: () => {
        return (
          <TooltipIconButton
            tooltipContent="Send gift"
            icon={<SendMessageIcon color={defaultColor} />}
          />
        )
      },
      onCell: productVariant => ({
        style: { cursor: 'pointer' },
        onClick: e => {
          e.stopPropagation()
          handleSendSwagProduct(productVariant)
        },
      }),
    },
  ]

  if (isLoadingInitial) return <Loader />

  if (isEmpty) {
    return (
      <PerkEmptyState
        header="No results found"
        description="We couldn't find exactly what you were looking for"
      />
    )
  }

  const formattedData: WithKey<ProductVariant>[] = productVariants.map(pv => {
    Object.assign(pv, { key: pv.productId })
    return pv
  })

  return (
    <Pane>
      <Table
        dataSource={formattedData}
        columns={columns}
        onRow={({ productId }) => ({
          style: { cursor: 'pointer' },
          onClick: () => {
            if (productId) {
              navigate(productId)
            } else {
              toaster.notify('No handle found for this product')
            }
          },
        })}
        pagination={false}
      />
      <div aria-hidden="true" ref={sentinelRef} />
      {isLoadingMore && <Loader />}
    </Pane>
  )
}
