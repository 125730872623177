import { PlayCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { EditableTitle } from 'components/Forms'
import { Dialog, Heading, ManualIcon, Pane, Paragraph } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import { isFunction } from 'lodash-es'
import { ReactNode, useState } from 'react'

export function PageHeader({
  title,
  blogLinkLabel,
  description,
  blogLink,
  vlogLink,
  buttonsToRenderOnLeft,
  buttonsToRenderOnRight,
  onEditTitle,
}: {
  title: string
  blogLinkLabel?: string
  description?: string | ReactNode
  blogLink?: string
  vlogLink?: string
  buttonsToRenderOnLeft?: React.ReactNode
  buttonsToRenderOnRight?: React.ReactNode
  onEditTitle?: (title: string) => void
}) {
  const [showDialog, setShowDialog] = useState(false)
  const { defaultColor } = useDefaultOrgColors()

  const titleIsEditable = isFunction(onEditTitle)

  return (
    <Pane display="flex" justifyContent="space-between" alignItems="flex-start">
      {/** Section on left */}
      <Pane display="flex" flexDirection="column" gap={8}>
        {/** Top Section: Includes Header and Buttons */}
        <Pane
          display="flex"
          gap={16}
          alignItems="center"
          marginBottom={buttonsToRenderOnLeft ? 4 : 0}
        >
          {titleIsEditable ? (
            <EditableTitle currentTitle={title} onConfirmTitle={onEditTitle} />
          ) : (
            <Heading size={800}>{title}</Heading>
          )}

          <Pane display="flex" gap={8}>
            {buttonsToRenderOnLeft}
          </Pane>
        </Pane>
        {/** Mid Section: Includes Description */}
        {typeof description === 'string' ? (
          <Paragraph size={500}>{description}</Paragraph>
        ) : (
          description
        )}
        {/** Bottom Section: Includes extra links */}
        {(blogLink || vlogLink) && (
          <Pane marginLeft={-16}>
            {/* If blog link was inputted */}
            {blogLink && (
              <Button
                href={blogLink}
                target="_blank"
                rel="noopener noreferrer"
                type="link"
                style={{
                  color: defaultColor,
                }}
                icon={<ManualIcon />}
              >
                {blogLinkLabel || `How ${title} works`}
              </Button>
            )}

            {/* If video link was inputted */}
            {vlogLink && (
              <Button
                icon={<PlayCircleOutlined />}
                onClick={() => setShowDialog(true)}
                type="link"
                style={{
                  color: defaultColor,
                }}
              >
                See it in action
              </Button>
            )}
          </Pane>
        )}
      </Pane>

      {/** Section on right */}
      <Pane display="flex" gap={8}>
        {buttonsToRenderOnRight}
      </Pane>

      <Dialog
        isShown={showDialog}
        onCloseComplete={() => setShowDialog(false)}
        hasFooter={false}
        minHeightContent={512}
        width="100%"
      >
        <iframe
          title={title}
          src={vlogLink}
          frameBorder="0"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Dialog>
    </Pane>
  )
}
