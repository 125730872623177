import { countryIso3to2Map } from 'constants/addresses'
import { TEST_ORG_ID } from 'constants/algolia'
import { isProduction } from 'constants/keys'
import { ALL_ORGS } from 'constants/productCollections'
import {
  ProductCollection,
  ProductCollection_ProductInfo,
} from 'gen/perkup/v1/product_collections_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { isEmpty, lowerCase } from 'lodash-es'

export function getProductIdsFilterString({
  productIds,
  orgId,
}: {
  productIds: string[]
  orgId: string
}) {
  const productIdsFilterString =
    productIds.map(id => `productId:${id}`).join(' OR ') ||
    'productId:no-matchy'
  return `${productIdsFilterString} AND tags:${
    isProduction ? orgId : TEST_ORG_ID
  }`
}

export function filterProductCollectionsByCountry({
  iso3,
  collections,
}: {
  iso3: string
  collections?: ProductCollection[]
}) {
  if (!collections) return []

  const collectionsFilteredByCountry = collections.filter(collection => {
    const { shippingCountries } = collection
    return (
      shippingCountries.includes(iso3.toLowerCase()) ||
      isEmpty(shippingCountries)
    )
  })
  return collectionsFilteredByCountry
}

export function sortCollectionProductsByRank({
  uniqueHits,
  collectionProducts,
}: {
  uniqueHits: ProductVariant[]
  collectionProducts: { [key: string]: ProductCollection_ProductInfo }
}) {
  const sortedHits = uniqueHits.sort((a, b) => {
    if (!a && !b) return 0
    if (!a) return 1
    if (!b) return -1
    const collectionProductA = collectionProducts[a.productId]
    const collectionProductB = collectionProducts[b.productId]

    if (!collectionProductA) return 1
    if (!collectionProductB) return -1

    return Number(collectionProductA.rank) - Number(collectionProductB.rank)
  })
  return sortedHits
}

export const invalidCollectionShippingCountry = ({
  collection,
  shippingAddress,
}: {
  collection?: ProductCollection
  shippingAddress?: ShippingAddress
}) => {
  const isGlobalCollection = collection?.orgId === ALL_ORGS
  const shippingAddressIso2 = lowerCase(shippingAddress?.country || '')

  return (
    isGlobalCollection &&
    collection &&
    !collection.shippingCountries.some(countryIso3 => {
      const shippingCountryIso2 = countryIso3to2Map[countryIso3]
      return shippingCountryIso2 === shippingAddressIso2
    })
  )
}

export const getCollectionRange = (collection: ProductCollection) => {
  const collectionProducts = Object.values(collection.products)
  const maxCollectionValue = collectionProducts.reduce((acc, product) => {
    if (Number(product.maxAmount) > acc) return Number(product.maxAmount)
    return acc
  }, 0)
  // This is defensive coding, it makes sure the reduce function always returns something, even if collectionProducts.length === 0,
  // even though it should never happen.
  const findCheapestProduct = (
    acc: ProductCollection_ProductInfo,
    product: ProductCollection_ProductInfo
  ) => {
    return Number(product.minAmount) < Number(acc.minAmount) ? product : acc
  }

  const cheapestCollectionProduct = isEmpty(collectionProducts)
    ? 0
    : collectionProducts.reduce(findCheapestProduct).minAmount

  const minCollectionValue = Number(cheapestCollectionProduct)

  return { minCollectionValue, maxCollectionValue }
}
