import { SearchOutlined } from '@ant-design/icons'
import { Breadcrumb, Flex } from 'antd'
import { BoxShadowImage, PerkEmptyState } from 'components'
import { NUMBER_GREEN } from 'constants/colors'
import { DEFAULT_ROUTES, HOME, SWAG } from 'constants/routes'
import {
  ExchangeRateContext,
  OrgContext,
  SwagCollectionIdsContext,
  UserContext,
} from 'context'
import { Heading, Text } from 'evergreen-ui'
import { AlgoliaBrowseProducts } from 'features'
import { ProductCollection } from 'gen/perkup/v1/product_collections_pb'
import { useOrgUserSwagBalance } from 'hooks'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useOutletContext } from 'react-router'
import { Link } from 'react-router-dom'
import { numToDollars } from 'utils'
import { getAlgoliaOrgSwagFilters } from 'utils/Algolia'

export function SwagStore() {
  const org = useContext(OrgContext)
  const swagCollections = useContext(SwagCollectionIdsContext)
  const exchangeRate = useContext(ExchangeRateContext)
  const user = useContext(UserContext)

  const { orgUserSwagBalance } = useOrgUserSwagBalance()
  const outletContext: { collection: ProductCollection | undefined } =
    useOutletContext()
  const navigate = useNavigate()

  const collection = outletContext?.collection

  if (!collection) {
    return (
      <PerkEmptyState
        header="Swag store not found"
        description="We couldn't find the swag store associated with this id."
        iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(`${HOME}`),
          type: 'primary',
        }}
      />
    )
  }

  const searchFilter = `${getAlgoliaOrgSwagFilters({
    orgId: org.id,
  })} AND collectionIds:${collection.id}`

  return (
    <>
      <Helmet>
        <title>{collection?.name}</title>
      </Helmet>

      <Flex vertical gap={16} style={{ width: '100%' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={DEFAULT_ROUTES.CARD.ROOT}>Home</Link>
          </Breadcrumb.Item>
          {swagCollections.length > 1 && (
            <Breadcrumb.Item>
              <Link to={SWAG}>Collections</Link>
            </Breadcrumb.Item>
          )}
          <Breadcrumb.Item>{collection?.name}</Breadcrumb.Item>
        </Breadcrumb>

        <Flex
          gap={16}
          align={isMobile ? undefined : 'center'}
          style={{
            marginTop: 16,
            flexDirection: isMobile ? 'column' : 'row-reverse',
          }}
        >
          <Flex flex={1}>
            {collection?.imageUrl && (
              <BoxShadowImage src={collection.imageUrl} />
            )}
          </Flex>
          <Flex vertical gap={16} flex={1}>
            <Heading size={isMobile ? 800 : 900}>{collection?.name}</Heading>
            <Text>{collection?.description}</Text>
          </Flex>
        </Flex>

        <Flex vertical gap={8}>
          <Flex gap={8}>
            <Heading>Balance:</Heading>
            <Heading color={NUMBER_GREEN}>
              {numToDollars(
                orgUserSwagBalance * exchangeRate,
                2,
                false,
                user.displayCurrency || org?.displayCurrency
              )}
            </Heading>
          </Flex>

          <AlgoliaBrowseProducts>
            <AlgoliaBrowseProducts.Old
              onProductCardClick={pv =>
                navigate(`/swag/${collection.id}/${pv.productId}`)
              }
              withPriceFilter
              withProductAmounts
              withLocalAmounts
              menuFilters={['productType']}
              searchFilter={searchFilter}
              withCountryFilter
              filterUiProps={{
                priceFilterPrefix: '',
              }}
              stickyNavTopAmount={60}
            />
          </AlgoliaBrowseProducts>
        </Flex>
      </Flex>
    </>
  )
}
