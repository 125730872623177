import { CopyOutlined } from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { Button } from 'antd'
import {
  GetOrgAccountById,
  removeProgramField,
  updateProgram,
} from 'api/databaseCalls'
import { ProgramCategories, ProgramDates } from 'components'
import { ProgramCoreValues } from 'components/Programs/ProgramCoreValues'
import { ProgramMemo } from 'components/Programs/ProgramMemo'
import { DEFAULT_ROUTES } from 'constants/routes'
import { OrgContext } from 'context'
import dayjs from 'dayjs'
import {
  Link as EvergreenLink,
  Heading,
  Pane,
  Text,
  Tooltip,
  WarningSignIcon,
  toaster,
  useTheme,
} from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { Order } from 'gen/perkup/v1/vendor_pb'
import { getProgramEndDate } from 'pages/NewReward/utils/programs'
import { useOrgTemplate } from 'pages/Templates/hooks/useOrgTemplate'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RangeValue } from 'types/RewardsDate'
import { copyToClipboard } from 'utils'
import { SelectAmount } from './SelectAmount'

export function RewardDetails({
  program,
  editMode,
  setEditMode,
  orders,
}: {
  program: Program
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  orders: Order[]
}) {
  const org = useContext(OrgContext)

  const isDirectMail = program?.type === ProgramType.direct

  const { template } = useOrgTemplate({ templateId: program?.templateId })

  const orgId = org?.id
  const programId = program.id
  const accountId = program?.accountId

  const defaultStartDate = program.startsOn
    ? dayjs(program.startsOn.toDate())
    : null
  const defaultEndDate = program.endsOn ? dayjs(program.endsOn.toDate()) : null
  const theme = useTheme()
  const [account, setAccount] = useState<Account>()
  const [duration, setDuration] = useState<RangeValue>([
    defaultStartDate,
    defaultEndDate,
  ])
  const [approvedCategories, setApprovedCategories] = useState(
    program.approvedCategories
  )
  const [allMerchants, setAllMerchants] = useState(!!program.allMerchants)
  const [internalMemo, setInternalMemo] = useState(program.internalMemo)
  const [coreValues, setCoreValues] = useState(program.coreValues)
  const [budgetVal, setBudgetVal] = useState(program.budget)

  useEffect(() => {
    if (accountId) {
      GetOrgAccountById({ orgId, accountId }).then(setAccount)
    }
  }, [accountId, orgId])

  const programEndDate = getProgramEndDate({ expiryDate: undefined, duration })
  const endsOn = programEndDate
    ? Timestamp.fromDate(programEndDate.endOf('day').toDate())
    : undefined

  const handleSubmit = async () => {
    await updateProgram({
      orgId,
      programId,
      program: {
        endsOn,
        allMerchants,
        internalMemo,
        coreValues,
        approvedCategories,
        budget: budgetVal,
      },
    })
    if (!endsOn) {
      await removeProgramField({
        orgId,
        programId,
        field: 'endsOn',
      })
    }
    toaster.success('Program updated')
    setEditMode(false)
  }

  const handleCancel = () => {
    setEditMode(false)
    setApprovedCategories(program.approvedCategories)
    setAllMerchants(!!program.allMerchants)
    setBudgetVal(program.budget)
    setInternalMemo(program.internalMemo)
    setCoreValues(program.coreValues)
  }

  const orderId: string | undefined = orders?.[0]?.id

  const isGiftOrDirectMail = !!program.gift || isDirectMail

  const canEnterCustomBudget = !template || template?.budget?.writable

  const maxCustomBudget = template?.budget?.maxAmount

  return (
    <Pane
      display="grid"
      gridTemplateColumns="max-content 3fr"
      alignItems={editMode ? 'flex-start' : 'center'}
      columnGap={32}
      rowGap={16}
      paddingX={editMode ? 24 : undefined}
      paddingY={editMode ? 40 : 24}
      borderRadius={editMode ? 8 : undefined}
      background={editMode ? 'tint2' : undefined}
    >
      <>
        <Heading size={100}>Program ID</Heading>
        <Pane display="flex" alignItems="center" gap={8}>
          <Text>{programId}</Text>
          <Tooltip content="Copy ID">
            <Button
              size="small"
              icon={<CopyOutlined />}
              type="text"
              onClick={() => copyToClipboard(programId, 'ID')}
            />
          </Tooltip>
        </Pane>
      </>

      {orderId && (
        <>
          <Heading size={100}>Order ID</Heading>
          <Pane display="flex" alignItems="center" gap={8}>
            <Text>{orderId}</Text>
            <Tooltip content="Copy ID">
              <Button
                size="small"
                icon={<CopyOutlined />}
                type="text"
                onClick={() => copyToClipboard(orderId, 'ID')}
              />
            </Tooltip>
          </Pane>
        </>
      )}
      {account?.id && (
        <>
          <Heading size={100}>Balance</Heading>
          <Pane>
            <Link
              to={`${DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}/${account.id}`}
            >
              <EvergreenLink>{account.name}</EvergreenLink>
            </Link>
          </Pane>
        </>
      )}

      {editMode && !isGiftOrDirectMail && (
        <>
          <Heading size={100}>Budget</Heading>
          <Pane display="flex" flexDirection="column">
            <SelectAmount
              onAmountChange={setBudgetVal}
              amount={budgetVal}
              amountOptions={template?.budget?.amounts}
              showCustomInput={canEnterCustomBudget}
              maxCustomBudget={maxCustomBudget}
            />

            <Pane display="flex" gap={8} marginTop={8} alignItems="center">
              <WarningSignIcon color="warning" />
              <Text color={theme.colors.orange700}>
                Changing the budget will also apply to existing rewards
              </Text>
            </Pane>
          </Pane>
        </>
      )}
      {!isGiftOrDirectMail && (
        <ProgramCategories
          edit={editMode}
          program={program}
          orgId={org.id}
          allMerchants={allMerchants}
          setAllMerchants={setAllMerchants}
          approvedCategories={approvedCategories}
          setApprovedCategories={setApprovedCategories}
        />
      )}
      {!isDirectMail && program.startsOn && (
        <ProgramDates
          key={program.id}
          duration={duration}
          edit={editMode}
          setDuration={setDuration}
          program={program}
          orgId={org.id}
        />
      )}

      {!isDirectMail && (
        <ProgramCoreValues
          setCoreValues={setCoreValues}
          edit={editMode}
          coreValues={coreValues}
        />
      )}

      <ProgramMemo
        edit={editMode}
        setMemo={setInternalMemo}
        memo={internalMemo}
        setEdit={setEditMode}
      />

      {editMode && (
        <Pane>
          <Button
            type="default"
            onClick={handleCancel}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Pane>
      )}
    </Pane>
  )
}
