import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { GiftCard, ProductGridCard } from 'components'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { ExchangeRateContext } from 'context'
import { Pane } from 'evergreen-ui'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { useDisplayCurrency } from 'hooks'
import { isFunction } from 'lodash-es'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { Selectable } from 'types'
import { numToDollars } from 'utils'
import {
  getProductVariantProductImage,
  isNearCashProductVariant,
} from 'utils/productVariant'

export function ProductsGrid({
  products,
  onCardClick,
  withPrices = false,
  withAmountsInUsd = false,
  withShipping = false,
  showPrepaidBadge = false,
  onCardAddButtonClick,
  selectedRibbonText,
  hideSelectedRibbon = false,
}: {
  products: Selectable<ProductVariant>[]
  onCardClick?: (productVariant: ProductVariant) => void
  withPrices?: boolean
  withAmountsInUsd?: boolean
  withShipping?: boolean
  onCardAddButtonClick?: (
    productVariant: ProductVariant
  ) => void | Promise<void>
  selectedRibbonText?: string
  showPrepaidBadge?: boolean
  hideSelectedRibbon?: boolean
}) {
  const exchange = useContext(ExchangeRateContext)
  const displayCurrency = useDisplayCurrency()

  return (
    <Pane
      display={isMobile ? 'flex' : 'grid'}
      flexDirection={isMobile ? 'column' : undefined}
      alignItems={isMobile ? 'center' : undefined}
      gridTemplateColumns={
        isMobile
          ? undefined
          : `repeat(auto-fit, minmax(280px, ${products.length > 2 ? '0.5fr' : '0.33fr'}  ))`
      }
      gap={32}
    >
      {products.map(productVariant => {
        const displayAmountInUsd = numToDollars(Number(productVariant.amount))
        const displayAmountInLocal = numToDollars(
          Number(productVariant.amount) * exchange,
          2,
          false,
          displayCurrency
        )
        const displayAmount = withAmountsInUsd
          ? displayAmountInUsd
          : displayAmountInLocal

        const isProductSelected = productVariant.isSelected

        const onClick = onCardClick
          ? () => onCardClick(productVariant)
          : undefined

        if (isNearCashProductVariant(productVariant)) {
          return (
            <GiftCard
              key={productVariant.id}
              onClick={onClick}
              productId={productVariant.productId}
              productVariantId={productVariant.id}
              productImage={productVariant.imageUrl}
              showSelectedRibbon={isProductSelected && !hideSelectedRibbon}
              selectedRibbonText={selectedRibbonText}
            />
          )
        }

        const extraCta = isFunction(onCardAddButtonClick) ? (
          <AsyncButton
            size="small"
            type="default"
            icon={isProductSelected ? <CloseOutlined /> : <PlusOutlined />}
            danger={isProductSelected}
            onClick={async e => {
              e.stopPropagation()
              await onCardAddButtonClick(productVariant)
            }}
          >
            {isProductSelected ? 'Remove' : 'Add'}
          </AsyncButton>
        ) : undefined

        return (
          <ProductGridCard
            key={productVariant.id}
            onClick={onClick}
            productId={productVariant.productId}
            productVariantId={productVariant.id}
            productName={productVariant.productName}
            productImage={getProductVariantProductImage(productVariant)}
            vendor={productVariant.vendor}
            showSelectedRibbon={isProductSelected && !hideSelectedRibbon}
            outOfStock={!productVariant.productIsAvailable}
            price={withPrices ? displayAmount || undefined : undefined}
            showShipping={withShipping}
            tags={productVariant.tags}
            cardCTA={extraCta}
            iso3s={productVariant?.shippingCountries}
            selectedRibbonText={selectedRibbonText}
            tagline={productVariant.productTagline}
            showPrepaidBadge={
              showPrepaidBadge &&
              productVariant.sourceType ===
                ProductVariant_SourceType.fullPrepaid
            }
          />
        )
      })}
    </Pane>
  )
}
