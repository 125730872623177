import { Skeleton } from 'antd'
import { Pane } from 'evergreen-ui'

export function CarouselSkeleton({
  skeletonNumber,
}: {
  skeletonNumber: number
}) {
  return (
    <Pane width="100%" display="flex" marginTop={32} gap={16}>
      {new Array<undefined>(skeletonNumber).fill(undefined).map((_, i) => {
        const id = i.toString()
        return (
          <Pane
            padding={4}
            key={id}
            border
            borderRadius={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={32}
            width={600}
          >
            <Skeleton.Image active />

            <Skeleton active paragraph={{ rows: 1 }} />
          </Pane>
        )
      })}
    </Pane>
  )
}
