import api, { Flatfile } from '@flatfile/api'
import { FlatfileEvent, FlatfileListener } from '@flatfile/listener'
import { recordHook } from '@flatfile/plugin-record-hook'
import { captureException } from '@sentry/react'
import { callFunction } from 'api/functionCalls'
import { isProduction } from 'constants/keys'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { isDate, isString, startCase } from 'lodash-es'
import { isEmail } from 'utils'

// create an enum for the different type of flat file keys
export enum FlatFileWorkbookKeys {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  employeeId = 'employeeId',
  dob = 'dob',
  startDate = 'start_date',
  endDate = 'end_date',
  title = 'title',
  managerId = 'managerId',
  userRole = 'userRole',
  department = 'department',
  location = 'location',
}

export function genWorkbook({
  orgId,
  lists = [],
}: {
  orgId: string
  lists: OrgList[]
}): Pick<
  Flatfile.CreateWorkbookConfig,
  'name' | 'labels' | 'sheets' | 'actions'
> {
  const orgsWithoutEmails = [
    'eCYCdSvIcuiYQtkDkoFT', // Crestline
  ]
  const emailNotRequired = isProduction
    ? orgsWithoutEmails.includes(orgId)
    : true

  const workbook: Pick<
    Flatfile.CreateWorkbookConfig,
    'name' | 'labels' | 'sheets' | 'actions'
  > = {
    name: 'All Data',
    labels: ['pinned'],
    sheets: [
      {
        name: 'People',
        slug: 'people',
        allowAdditionalFields: true,
        fields: [
          {
            key: FlatFileWorkbookKeys.firstName,
            type: 'string',
            label: 'First Name',
          },
          {
            key: FlatFileWorkbookKeys.lastName,
            type: 'string',
            label: 'Last Name',
          },
          {
            key: FlatFileWorkbookKeys.email,
            type: 'string',
            label: 'Email',
            constraints: emailNotRequired
              ? []
              : [
                  {
                    type: 'required',
                  },
                ],
          },
          {
            key: FlatFileWorkbookKeys.employeeId,
            type: 'string',
            label: 'Employee ID',
          },
          {
            key: FlatFileWorkbookKeys.dob,
            type: 'date',
            label: 'Birthday',
          },
          {
            key: FlatFileWorkbookKeys.startDate,
            type: 'date',
            label: 'Start date',
          },
          {
            key: FlatFileWorkbookKeys.endDate,
            type: 'date',
            label: 'End date',
          },
          {
            key: FlatFileWorkbookKeys.title,
            type: 'string',
            label: 'Title',
          },
          {
            key: FlatFileWorkbookKeys.managerId,
            type: 'string',
            label: 'Manager ID',
            description: 'The employee ID for the direct manager',
          },
          {
            key: FlatFileWorkbookKeys.userRole,
            type: 'string',
            label: 'User Role',
            description: 'Must be admin, manager, or member',
          },
          {
            key: FlatFileWorkbookKeys.department,
            type: 'string',
            label: 'Department',
          },
          {
            key: FlatFileWorkbookKeys.location,
            type: 'string',
            label: 'Location',
          },
        ],
      },
    ],
    actions: [
      {
        operation: 'submitActionFg',
        mode: 'foreground',
        label: 'Submit ',
        description: 'Upload people into PerkUp',
        primary: true,
      },
    ],
  }

  lists.forEach(list => {
    if (workbook?.sheets && workbook?.sheets[0]) {
      const existingField = workbook.sheets[0].fields.find(
        field => field.key === list.id
      )
      if (!existingField) {
        workbook.sheets[0].fields.push({
          key: list.id,
          type: 'string',
          label: startCase(list.name),
        })
      }
    }
  })

  return workbook
}

export const listener = FlatfileListener.create(listener => {
  listener.on('**', event => {
    console.log(`Received event: ${event.topic}`)
  })

  listener.use(
    recordHook('people', record => {
      const email = record.get(FlatFileWorkbookKeys.email)
      const cleanedEmail = email?.toString().trim().toLowerCase()
      if (cleanedEmail && isEmail(cleanedEmail)) {
        record.set(FlatFileWorkbookKeys.email, cleanedEmail)
      } else if (email) {
        record.addError(FlatFileWorkbookKeys.email, 'Invalid email address')
      }

      const checkName = (key: string) => {
        const name = record.get(key)
        if (isString(name) && name?.trim().length >= 2) {
          record.set(key, name?.trim())
        } else if (name) {
          record.addError(key, `${key} must be at least 2 character`)
        }
      }

      checkName(FlatFileWorkbookKeys.firstName)
      checkName(FlatFileWorkbookKeys.lastName)

      const checkDate = (key: string) => {
        const dateValue = record.get(key)
        const formattedDate = dateValue
          ? new Date(dateValue?.toString())
          : undefined
        if (
          formattedDate &&
          isDate(formattedDate) &&
          !Number.isNaN(formattedDate.getTime())
        ) {
          record.set(key, formattedDate.toDateString())
        } else if (dateValue) {
          record.addError(key, `Invalid ${key}, must be MM/DD/YY`)
        }
      }

      checkDate(FlatFileWorkbookKeys.dob)
      checkDate(FlatFileWorkbookKeys.startDate)
      checkDate(FlatFileWorkbookKeys.endDate)

      const userRole = record.get(FlatFileWorkbookKeys.userRole)
      if (userRole) {
        const formattedUserRole = userRole?.toString().trim().toLowerCase()
        if (['admin', 'manager', 'member'].includes(formattedUserRole)) {
          record.set(FlatFileWorkbookKeys.userRole, formattedUserRole)
        } else {
          record.addError(
            FlatFileWorkbookKeys.userRole,
            'Invalid user role, must be admin, manager, or member'
          )
        }
      }

      return record
    })
  )

  listener.on(
    'job:ready',
    { job: 'workbook:submitActionFg' },
    async ({ context: { jobId, workbookId } }: FlatfileEvent) => {
      try {
        await api.jobs.ack(jobId, {
          info: 'Getting started.',
          progress: 10,
        })

        const response = await callFunction('services-CSVUploader', {
          workbookId,
        })

        if (response.uploaded) {
          await api.jobs.complete(jobId, {
            outcome: {
              acknowledge: true,
              message: `People were successfully uploaded into PerkUp. Go to your employee directory.`,
            },
          })
        } else {
          throw new Error('Failed to upload people into PerkUp')
        }
      } catch (error: any) {
        console.error('Error:', error.stack)
        captureException(error, {
          contexts: {
            flatfileJobReady: {
              jobId,
              workbookId,
            },
          },
        })

        await api.jobs.fail(jobId, {
          outcome: {
            message: 'This job encountered an error.',
          },
        })
      }
    }
  )
})
