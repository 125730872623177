import { Flex } from 'antd'
import { UserContext } from 'context'
import { Pane } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors } from 'hooks'
import { useContext } from 'react'
import { AddressButton } from './address-button'
import { AddressDisplay } from './address-display'

export function AddressesList({
  shippingAddresses,
  onSelectAddress,
  selectedAddress,
}: {
  shippingAddresses: ShippingAddress[]
  onSelectAddress: (address: ShippingAddress) => void
  selectedAddress?: ShippingAddress
}) {
  const { defaultShippingAddressId } = useContext(UserContext)
  const { backgroundColor } = useDefaultOrgColors()
  return (
    <Pane marginBottom={32}>
      <Pane marginBottom={16}>
        <AddressButton
          addAddressCTALabel="Add address"
          onAddressSubmit={address => {
            onSelectAddress(address)
          }}
          submitLabel="Add"
          dialogTitle="Add new address"
          asSmallButton
        />
      </Pane>
      <Flex vertical>
        {shippingAddresses.map(shippingAddress => {
          return (
            <Pane
              key={shippingAddress?.id}
              padding={16}
              background={
                selectedAddress?.id === shippingAddress?.id
                  ? backgroundColor
                  : ''
              }
              hoverElevation={1}
              cursor="pointer"
              onClick={() => {
                onSelectAddress(shippingAddress)
              }}
              borderRadius={8}
            >
              <AddressDisplay
                address={shippingAddress}
                hasBadge
                isDefault={defaultShippingAddressId === shippingAddress?.id}
              />
            </Pane>
          )
        })}
      </Flex>
    </Pane>
  )
}
