import { countryIso3to2Map } from 'constants/addresses'
import { get } from 'lodash-es'

const regions = new Intl.DisplayNames(['en'], { type: 'region' })

export function getCountryIsoAlpha2(iso3: string): string {
  const iso2 = get(countryIso3to2Map, iso3.toLowerCase())
  if (!iso2) return ''
  return iso2
}

const isValidIso3 = (iso3: string) => {
  if (!iso3) return false
  try {
    regions.of(getCountryIsoAlpha2(iso3).toUpperCase())
    return true
  } catch {
    return false
  }
}

const iso3ToNameComparator = (aIso3: string, bIso3: string) => {
  if (!isValidIso3(aIso3) || !isValidIso3(bIso3))
    return (aIso3 ?? '').localeCompare(bIso3 ?? '') // If we can't find the country name, don't sort it

  const aIso2 = getCountryIsoAlpha2(aIso3).toUpperCase()
  const bIso2 = getCountryIsoAlpha2(bIso3).toUpperCase()
  const aCountryName = regions.of(aIso2)
  const bCountryName = regions.of(bIso2)

  if (!aCountryName || !bCountryName) return aIso3.localeCompare(bIso3) // If we can't find the country name, don't sort it

  return aCountryName.localeCompare(bCountryName)
}

export const compareAlgoliaCountryFacets = (
  a: { name: string },
  b: { name: string }
) => iso3ToNameComparator(a.name, b.name)

export const getCountryNameFromIso2 = (iso2: string | undefined) => {
  if (!iso2) return ''
  return regions.of(iso2.toUpperCase())
}

export const buildCountries = (iso3s: string[]) => {
  const countries: { iso2: string; iso3: string; name: string }[] = []

  iso3s.forEach(iso3 => {
    const iso2 = getCountryIsoAlpha2(iso3)
    if (!iso2) return
    const name = getCountryNameFromIso2(iso2)
    if (!name) return
    countries.push({
      iso2,
      iso3,
      name,
    })
  })

  return countries
}
