import { RichTextEditor } from '@mantine/tiptap'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Button, Flex } from 'antd'
import { EmojiPickerButton } from 'components/Buttons'
import { Paragraph, toaster } from 'evergreen-ui'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'

export function TextEditor({
  htmlContent,
  confirmLabel = 'Save',
  onConfirm,
  onBlur,
  disabled = false,
  persistEditorMode = false,
  hideCancelButton = false,
  preventEventPropagation = false,
  extraButtons,
}: {
  htmlContent: string
  confirmLabel?: string
  onConfirm?: (htmlContent: string) => void
  onBlur?: (htmlContent: string) => void
  disabled?: boolean
  persistEditorMode?: boolean
  hideCancelButton?: boolean
  preventEventPropagation?: boolean
  extraButtons?: React.ReactNode
}) {
  const [edit, setEdit] = useState(false)

  const editor = useEditor({
    extensions: [StarterKit, Underline],
    content: htmlContent,
    onBlur: ({ editor, event }) => {
      if (!onBlur) return
      if (preventEventPropagation && event.relatedTarget) return // User just clicked the mantine buttons, don't blur because they're still editing!
      if (editor.isEmpty) {
        toaster.warning('Text cannot be empty')
        return
      }
      setEdit(false)
      onBlur(editor.getHTML())
    },
  })

  // Ideally we use keys in the parent component instead of useEffects, but the key was creating a big flash in here
  useEffect(() => {
    if (!editor) return

    // User hit the refresh button in the parent element, so brand new starting html content was passed in
    if (htmlContent !== editor.getHTML()) {
      editor.commands.setContent(htmlContent)
    }
  }, [editor, htmlContent])

  const handleSelectEmoji = (emoji: string) => {
    if (!editor) return
    editor.chain().focus().insertContent(emoji).run()
  }

  const showCancelButton = !hideCancelButton && !persistEditorMode

  if (edit || persistEditorMode) {
    return (
      <div style={{ position: 'relative' }}>
        <RichTextEditor style={{ width: '100%' }} editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <Flex align="center" gap={8}>
              {showCancelButton && (
                <Button size="small" onClick={() => setEdit(false)}>
                  Cancel
                </Button>
              )}

              {!!onConfirm && (
                <Button
                  type="primary"
                  size="small"
                  disabled={disabled}
                  onClick={() => {
                    if (!editor) return
                    if (editor.isEmpty) {
                      toaster.warning('Text cannot be empty')
                      return
                    }
                    setEdit(false)
                    onConfirm(editor.getHTML())
                  }}
                >
                  {confirmLabel}
                </Button>
              )}
            </Flex>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content style={{ paddingBottom: 38 }} />
        </RichTextEditor>
        <Flex gap={4} style={{ position: 'absolute', bottom: 4, right: 4 }}>
          <EmojiPickerButton onEmojiSelect={handleSelectEmoji} />
          {extraButtons}
        </Flex>
      </div>
    )
  }

  return (
    <Paragraph
      size="large"
      onClick={() => {
        if (disabled) return
        setEdit(true)
      }}
    >
      {parse(htmlContent)}
    </Paragraph>
  )
}
