import { MemberContext, ProgramContext } from 'context'
import { BlankIcon, EmptyState } from 'evergreen-ui'
import { Member_ConvertedTo } from 'gen/perkup/v1/program_pb'
import { useContext } from 'react'
import { AnimatedPage, GiftRevealed, MoneyRevealed } from './components'

export function RewardAcceptanceRevealed() {
  const program = useContext(ProgramContext)
  const member = useContext(MemberContext)

  const { id: programId } = program

  const memberGiftConverted =
    member?.convertedTo === Member_ConvertedTo.nearCash
  const isGift = program?.gift && !memberGiftConverted

  // Program has single gift
  if (isGift) {
    return (
      <AnimatedPage>
        <GiftRevealed />
      </AnimatedPage>
    )
  }

  // Program has no gift, so it's a cash reward
  if (programId) {
    return <MoneyRevealed />
  }

  return (
    <EmptyState
      title="Could not find gift"
      icon={<BlankIcon />}
      iconBgColor=""
    />
  )
}
