import { ListActiveProgramsForOrgBalance } from 'api/databaseCalls'
import { OrgContext } from 'context'
import { Program, ProgramType } from 'gen/perkup/v1/program_pb'
import { useContext, useEffect, useState } from 'react'

export function useProgramsForAccountBalance({
  accountId,
}: {
  accountId: string
}) {
  const org = useContext(OrgContext)
  const orgId = org.id
  const [accountPrograms, setAccountPrograms] = useState<Program[]>([])
  useEffect(() => {
    ListActiveProgramsForOrgBalance({ orgId, accountId }).then(programs =>
      setAccountPrograms(programs)
    )
  }, [accountId, orgId])

  const birthdayPrograms = accountPrograms.filter(
    program => program.type === ProgramType.birthdays
  )

  const anniversaryPrograms = accountPrograms.filter(
    program => program.type === ProgramType.anniversaries
  )

  return { accountPrograms, birthdayPrograms, anniversaryPrograms }
}
