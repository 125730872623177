import { Avatar, Flex, Popover, Tooltip } from 'antd'
import { iso3RankedList } from 'constants/addresses'
import { ACTIVE_ISO3 } from 'constants/sessionOrLocalStorage'
import { Text } from 'evergreen-ui'
import { ComponentProps } from 'react'
import { buildCountries } from 'utils'
import { FlagAvatar } from './FlagAvatar'
import { CountryOverflowMenu } from './country-overflow-menu'

export function CountryIconGroup({
  iso3s,
  maxCount = 4,
  label = 'Ships to',
  labelColor,
}: {
  iso3s: string[]
  maxCount?: number
  label?: 'Ships to' | 'Available in'
  labelColor?: ComponentProps<typeof Text>['color']
}) {
  const userStoredIso3 = localStorage.getItem(ACTIVE_ISO3)

  // sort the iso3s based on the iso3RankedList
  iso3s.sort((a, b) => {
    const aIndex = iso3RankedList.indexOf(a)
    const bIndex = iso3RankedList.indexOf(b)
    if (aIndex === -1 && bIndex === -1) return 0
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1
    return aIndex - bIndex
  })

  // If the user's stored iso3 is in the list, push that to the front
  if (userStoredIso3 && iso3s.includes(userStoredIso3)) {
    iso3s.splice(iso3s.indexOf(userStoredIso3), 1)
    iso3s.unshift(userStoredIso3)
  }

  // Restructure the iso3s with the iso2 and country name
  const countriesToDisplay = buildCountries(iso3s)

  // Determine if we need to show the overflow menu
  const countriesToShowBeforeMenu = countriesToDisplay.slice(0, maxCount)
  const countriesToShowAfterMenu = countriesToDisplay.slice(maxCount)

  return (
    <Flex gap={8} align="center">
      <Text color={labelColor}>{label}</Text>
      <Flex gap={4}>
        {countriesToShowBeforeMenu.map(({ name, iso2 }) => (
          <Tooltip key={name} title={name} placement="bottom">
            <div>
              <FlagAvatar iso2={iso2} />
            </div>
          </Tooltip>
        ))}

        {countriesToShowAfterMenu.length > 0 && (
          <Popover
            destroyTooltipOnHide
            placement="bottom"
            content={
              <CountryOverflowMenu
                countries={countriesToDisplay} // Pass the full list of countries to the overflow menu
              />
            }
          >
            <Avatar size="small" style={{ cursor: 'pointer' }}>
              +{countriesToShowAfterMenu.length}
            </Avatar>
          </Popover>
        )}
      </Flex>
    </Flex>
  )
}
