import { DollarIcon, Pane, TextInput, TextInputProps } from 'evergreen-ui'

interface AmountTextInputProp extends TextInputProps {
  height?: number | string
  value: number
}

export function AmountTextInput({
  height = 32,
  ...props
}: AmountTextInputProp) {
  return (
    <Pane position="relative" display="inline-flex" height={height} width={64}>
      <Pane
        height={height}
        width={height}
        pointerEvents="none"
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <DollarIcon color="default" zIndex={3} />
      </Pane>
      <TextInput
        type="number"
        placeholder="0.00"
        marginRight={8}
        paddingLeft={height}
        {...props}
      />
    </Pane>
  )
}
