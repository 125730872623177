import { ArrowRightOutlined } from '@ant-design/icons'
import {
  AMAZON_CARD,
  FOOD_CARD,
  GIFT_CARD,
  SHOP_CARD,
  SWAG_CARD,
} from 'assets/contentful'
import { PerkCardPreview } from 'components/PerkCard'
import { ALGOLIA_PRODUCT_VARIANTS_INDEX } from 'constants/algolia'
import { PRODUCT_CATALOG_CLICKED } from 'constants/events'
import * as ROUTES from 'constants/routes'
import { OrgContext, OrgUserContext, UserContext } from 'context'
import { Heading, Pane, Text } from 'evergreen-ui'
import { useDefaultOrgColors, usePublicProductCollections } from 'hooks'
import { useSwagStoreRoute } from 'hooks/routingHooks/useSwagStoreRoute'
import { useSpendingOptions } from 'hooks/useSpendingOptions'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router'
import { logEvent } from 'utils'

interface SpendingOptionCard {
  hidden?: boolean
  cardLabel: string
  cardDescription: string
  cardImage?: string
  onClick: () => void
  backgroundColor?: string
}
export function Discover() {
  const { showAmazon, showShopping, showSwag, showNearCash, showFood } =
    useSpendingOptions()
  const { swagRoute } = useSwagStoreRoute()
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const orgUser = useContext(OrgUserContext)
  const userId = user.id
  const orgUserBalance = orgUser?.balance
  const orgId = org.id
  const navigate = useNavigate()
  const { backgroundColor } = useDefaultOrgColors()
  const { collections } = usePublicProductCollections()

  const handleCardClick = ({ route }: { route: string }) => {
    logEvent(PRODUCT_CATALOG_CLICKED, {
      orgId,
      userId,
      route,
      orgUserBalance,
      component: 'card',
      userToken: userId,
      index: ALGOLIA_PRODUCT_VARIANTS_INDEX,
    })
    navigate(route)
  }

  const marketplaceOptions: SpendingOptionCard[][] = [
    [
      {
        hidden: !showSwag,
        cardLabel: `${org.name} Swag`,
        cardDescription: `Shop premium ${org.name} swag.`,
        onClick: () => {
          handleCardClick({ route: swagRoute })
        },
        backgroundColor,
        // For swag, if there is only one public collection, we use that collection's image
        cardImage:
          collections?.length === 1
            ? collections[0].imageUrl || SWAG_CARD
            : SWAG_CARD,
      },
    ],
    [
      {
        hidden: !showShopping,
        cardLabel: 'Shop local brands',
        cardDescription:
          'Browse a wide range of products from our specialty hand-picked catalog.',
        onClick: () => {
          handleCardClick({ route: ROUTES.SHOP_PRODUCTS })
        },
        cardImage: SHOP_CARD,
      },
    ],

    [
      {
        hidden: !showNearCash,
        cardLabel: 'Gift cards',
        cardDescription:
          'Choose from 100s gift cards of brands you know and love.',
        onClick: () => {
          handleCardClick({ route: ROUTES.SHOP_GIFT_CARDS })
        },
        cardImage: GIFT_CARD,
      },

      {
        hidden: !showAmazon,
        cardLabel: 'Shop Amazon',
        cardDescription: 'Buy anything on Amazon.',
        onClick: () => {
          handleCardClick({ route: ROUTES.AMAZON })
        },
        cardImage: AMAZON_CARD,
      },
    ],
    [
      {
        hidden: !showFood,
        cardLabel: 'Food',
        cardDescription: 'Satisfy your cravings with our food options.',
        cardImage: FOOD_CARD,
        onClick: () => {
          handleCardClick({ route: ROUTES.SHOP_FOOD })
        },
      },
    ],
  ]

  return (
    <Pane display="flex" flexDirection="column" gap={32}>
      {marketplaceOptions.map(rowOptions => {
        if (rowOptions.every(option => option.hidden)) {
          return null
        }
        return (
          <Pane
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 32 : 16}
            key={`container-${rowOptions[0].cardLabel}`}
          >
            {rowOptions.map(option => {
              if (option.hidden) {
                return null
              }
              return (
                <Pane
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  borderRadius={8}
                  onClick={option.onClick}
                  cursor="pointer"
                  hoverElevation={1}
                  key={option.cardLabel}
                >
                  <Pane
                    backgroundImage={`url(${option.cardImage})`}
                    backgroundColor={
                      option.backgroundColor
                        ? option.backgroundColor
                        : undefined
                    }
                    borderTopRightRadius={8}
                    borderTopLeftRadius={8}
                    height={248}
                    backgroundSize="cover"
                    backgroundPosition="center"
                    backgroundRepeat="no-repeat"
                  />
                  <Pane
                    display="flex"
                    flexDirection="column"
                    gap={8}
                    padding={32}
                  >
                    <Pane display="flex" alignItems="center" gap={24}>
                      <Heading size={800}>{option.cardLabel}</Heading>
                      <ArrowRightOutlined />
                    </Pane>
                    <Text>{option.cardDescription}</Text>
                  </Pane>
                </Pane>
              )
            })}
          </Pane>
        )
      })}
      <PerkCardPreview />
    </Pane>
  )
}
