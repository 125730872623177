// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/product_collections.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * Firestore /productCollections/:id/
 *
 * @generated from message perkup.v1.ProductCollection
 */
export class ProductCollection extends Message<ProductCollection> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: perkup.v1.ProductCollection.CollectionAccess.Enum access = 4;
   */
  access = ProductCollection_CollectionAccess_Enum.PRODUCT_COLLECTION_ACCESS_UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;

  /**
   * @generated from field: repeated string product_ids = 11 [deprecated = true];
   * @deprecated
   */
  productIds: string[] = [];

  /**
   * key is product id
   *
   * @generated from field: map<string, perkup.v1.ProductCollection.ProductInfo> products = 7;
   */
  products: { [key: string]: ProductCollection_ProductInfo } = {};

  /**
   * @generated from field: string org_id = 8;
   */
  orgId = "";

  /**
   * @generated from field: optional string image_url = 9;
   */
  imageUrl?: string;

  /**
   * expecting iso-3 (3 letters) lower_case country code
   *
   * @generated from field: repeated string shipping_countries = 10;
   */
  shippingCountries: string[] = [];

  /**
   * @generated from field: int64 products_count = 12;
   */
  productsCount = protoInt64.zero;

  /**
   *
   * productCollections default new collection
   * 'permissions.admin': full
   * 'permissions.manager': send
   * 'permissions.${individualId}': full
   *
   * @generated from field: map<string, perkup.v1.ProductCollection.Permission> permissions = 13;
   */
  permissions: { [key: string]: ProductCollection_Permission } = {};

  /**
   * id of the individual who created the collection
   *
   * @generated from field: optional string individual_id = 14;
   */
  individualId?: string;

  constructor(data?: PartialMessage<ProductCollection>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductCollection";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "access", kind: "enum", T: proto3.getEnumType(ProductCollection_CollectionAccess_Enum) },
    { no: 5, name: "created_at", kind: "message", T: Timestamp },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 11, name: "product_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "products", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ProductCollection_ProductInfo} },
    { no: 8, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "shipping_countries", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "products_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "permissions", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "enum", T: proto3.getEnumType(ProductCollection_Permission)} },
    { no: 14, name: "individual_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductCollection {
    return new ProductCollection().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductCollection {
    return new ProductCollection().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductCollection {
    return new ProductCollection().fromJsonString(jsonString, options);
  }

  static equals(a: ProductCollection | PlainMessage<ProductCollection> | undefined, b: ProductCollection | PlainMessage<ProductCollection> | undefined): boolean {
    return proto3.util.equals(ProductCollection, a, b);
  }
}

/**
 * @generated from enum perkup.v1.ProductCollection.Permission
 */
export enum ProductCollection_Permission {
  /**
   * Collection is hidden for these users
   *
   * @generated from enum value: PERMISSION_UNSPECIFIED = 0;
   */
  PERMISSION_UNSPECIFIED = 0,

  /**
   * Full access: Can set permissions, can edit collection details, can edit which products are in the collection, can send
   *
   * @generated from enum value: full = 1;
   */
  full = 1,

  /**
   * Can send: Can send or direct mail order
   *
   * @generated from enum value: send = 2;
   */
  send = 2,

  /**
   * Can view: Can view
   *
   * @generated from enum value: view = 3;
   */
  view = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductCollection_Permission)
proto3.util.setEnumType(ProductCollection_Permission, "perkup.v1.ProductCollection.Permission", [
  { no: 0, name: "PERMISSION_UNSPECIFIED" },
  { no: 1, name: "full" },
  { no: 2, name: "send" },
  { no: 3, name: "view" },
]);

/**
 * product collection status
 *
 * @generated from message perkup.v1.ProductCollection.CollectionAccess
 */
export class ProductCollection_CollectionAccess extends Message<ProductCollection_CollectionAccess> {
  constructor(data?: PartialMessage<ProductCollection_CollectionAccess>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductCollection.CollectionAccess";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductCollection_CollectionAccess {
    return new ProductCollection_CollectionAccess().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductCollection_CollectionAccess {
    return new ProductCollection_CollectionAccess().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductCollection_CollectionAccess {
    return new ProductCollection_CollectionAccess().fromJsonString(jsonString, options);
  }

  static equals(a: ProductCollection_CollectionAccess | PlainMessage<ProductCollection_CollectionAccess> | undefined, b: ProductCollection_CollectionAccess | PlainMessage<ProductCollection_CollectionAccess> | undefined): boolean {
    return proto3.util.equals(ProductCollection_CollectionAccess, a, b);
  }
}

/**
 * @generated from enum perkup.v1.ProductCollection.CollectionAccess.Enum
 */
export enum ProductCollection_CollectionAccess_Enum {
  /**
   * @generated from enum value: PRODUCT_COLLECTION_ACCESS_UNSPECIFIED = 0;
   */
  PRODUCT_COLLECTION_ACCESS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: private = 1;
   */
  private = 1,

  /**
   * @generated from enum value: public = 2;
   */
  public = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ProductCollection_CollectionAccess_Enum)
proto3.util.setEnumType(ProductCollection_CollectionAccess_Enum, "perkup.v1.ProductCollection.CollectionAccess.Enum", [
  { no: 0, name: "PRODUCT_COLLECTION_ACCESS_UNSPECIFIED" },
  { no: 1, name: "private" },
  { no: 2, name: "public" },
]);

/**
 * @generated from message perkup.v1.ProductCollection.ProductInfo
 */
export class ProductCollection_ProductInfo extends Message<ProductCollection_ProductInfo> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 rank = 2;
   */
  rank = protoInt64.zero;

  /**
   * min amount from all variants in cents
   *
   * @generated from field: int64 min_amount = 3;
   */
  minAmount = protoInt64.zero;

  /**
   * max amount from all variants in cents
   *
   * @generated from field: int64 max_amount = 4;
   */
  maxAmount = protoInt64.zero;

  /**
   * @generated from field: optional string image_url = 5;
   */
  imageUrl?: string;

  /**
   * @generated from field: optional string product_type = 6;
   */
  productType?: string;

  constructor(data?: PartialMessage<ProductCollection_ProductInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.ProductCollection.ProductInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rank", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "min_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "max_amount", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "product_type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductCollection_ProductInfo {
    return new ProductCollection_ProductInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductCollection_ProductInfo {
    return new ProductCollection_ProductInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductCollection_ProductInfo {
    return new ProductCollection_ProductInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ProductCollection_ProductInfo | PlainMessage<ProductCollection_ProductInfo> | undefined, b: ProductCollection_ProductInfo | PlainMessage<ProductCollection_ProductInfo> | undefined): boolean {
    return proto3.util.equals(ProductCollection_ProductInfo, a, b);
  }
}

/**
 * @generated from message perkup.v1.UpdateCollectionProductRequest
 */
export class UpdateCollectionProductRequest extends Message<UpdateCollectionProductRequest> {
  /**
   * @generated from field: string productCollectionId = 1;
   */
  productCollectionId = "";

  /**
   * @generated from field: repeated string productIds = 2;
   */
  productIds: string[] = [];

  constructor(data?: PartialMessage<UpdateCollectionProductRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.UpdateCollectionProductRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "productCollectionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "productIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCollectionProductRequest {
    return new UpdateCollectionProductRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCollectionProductRequest {
    return new UpdateCollectionProductRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCollectionProductRequest {
    return new UpdateCollectionProductRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCollectionProductRequest | PlainMessage<UpdateCollectionProductRequest> | undefined, b: UpdateCollectionProductRequest | PlainMessage<UpdateCollectionProductRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCollectionProductRequest, a, b);
  }
}

/**
 * @generated from message perkup.v1.UpdateCollectionProductSuperAdminRequest
 */
export class UpdateCollectionProductSuperAdminRequest extends Message<UpdateCollectionProductSuperAdminRequest> {
  /**
   * @generated from field: string productCollectionId = 1;
   */
  productCollectionId = "";

  /**
   * @generated from field: repeated string productIds = 2;
   */
  productIds: string[] = [];

  /**
   * @generated from field: string orgId = 3;
   */
  orgId = "";

  constructor(data?: PartialMessage<UpdateCollectionProductSuperAdminRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.UpdateCollectionProductSuperAdminRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "productCollectionId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "productIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "orgId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCollectionProductSuperAdminRequest {
    return new UpdateCollectionProductSuperAdminRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCollectionProductSuperAdminRequest {
    return new UpdateCollectionProductSuperAdminRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCollectionProductSuperAdminRequest {
    return new UpdateCollectionProductSuperAdminRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCollectionProductSuperAdminRequest | PlainMessage<UpdateCollectionProductSuperAdminRequest> | undefined, b: UpdateCollectionProductSuperAdminRequest | PlainMessage<UpdateCollectionProductSuperAdminRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCollectionProductSuperAdminRequest, a, b);
  }
}

