import { Timestamp } from '@bufbuild/protobuf'
import { Flex, Modal, Tag, TagProps } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { Loader } from 'components'
import {
  notificationStatusDetails,
  resendNotificationLabels,
} from 'constants/notifications'
import { Text } from 'evergreen-ui'
import {
  Notification,
  Notification_Message,
  Notification_Status,
  Notification_Type,
} from 'gen/perkup/v1/notification_pb'
import { useInfiniteNotifications } from 'hooks'
import { useState } from 'react'
import { RangeValue } from 'types/RewardsDate'
import { getDateTimeString } from 'utils'
import { NotificationDetails } from './NotificationDetails'

interface DataType {
  key: string
  createdAt?: Timestamp
  message?: Notification_Message
  status: Notification_Status
  notificationType: Notification_Type
  notification: Notification
}

function getNotificationStatusTagProps(
  status: Notification_Status
): TagProps | undefined {
  const displayProps = notificationStatusDetails[Notification_Status[status]]

  if (!displayProps) return undefined

  return {
    color: displayProps.color,
    icon: <displayProps.icon />,
    children: displayProps.label,
  }
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Date sent',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: createdAt => {
      const displayString = getDateTimeString(createdAt)
      if (typeof displayString === 'string') {
        return <Text>{displayString}</Text>
      }
      return null
    },
  },
  {
    title: 'Recipient',
    dataIndex: 'data.to',
    key: 'recipient',
    render: (_, { message }) => {
      const toEmails = message?.to

      const getToString = () => {
        if (!toEmails) return '-'

        if (toEmails.length > 1) {
          return `${toEmails.slice(0, 2).join(', ')}...`
        }
        return toEmails[0]
      }

      return <Text>{getToString()}</Text>
    },
  },
  {
    title: 'Notification type',
    dataIndex: 'data.tags',
    key: 'notification',
    render: (_, { notificationType }) => {
      if (!notificationType) return <Text>-</Text>

      const notificationLabel =
        resendNotificationLabels[notificationType] || notificationType

      return <Text>{notificationLabel}</Text>
    },
  },
  {
    title: 'Status',
    dataIndex: 'type',
    key: 'status',
    render: (_, { status }) => {
      const tagProps = getNotificationStatusTagProps(status)
      return <Tag {...tagProps} />
    },
  },
]

export function BrowseNotifications({
  dates,
  individualId,
  notificationType,
  notificationStatus,
}: {
  dates: RangeValue
  individualId?: string
  notificationType?: Notification_Type
  notificationStatus?: Notification_Status
}) {
  const [focusedNotification, setFocusedNotification] = useState<Notification>()

  const {
    notifications,
    isLoadingInitial,
    isLoadingMore,
    sentinelRef,
    isLastPage,
  } = useInfiniteNotifications({
    individualId,
    dateRange: dates,
    notificationType,
    notificationStatus,
  })

  const data: DataType[] = notifications.map(notification => ({
    key: notification.id,
    createdAt: notification.createdAt,
    message: notification.message,
    status: notification.status,
    notificationType: notification.type,
    notification,
  }))
  if (isLoadingInitial) return <Loader />

  return (
    <Flex vertical>
      {focusedNotification && (
        <Modal
          open={!!focusedNotification}
          onCancel={() => setFocusedNotification(undefined)}
          destroyOnClose
          title={
            resendNotificationLabels[focusedNotification.type] ||
            focusedNotification.type
          }
          footer={null}
          width="fit-content"
          styles={{
            body: {
              padding: 16,
              paddingTop: 32,
              maxHeight: '70vh',
              overflowY: 'auto',
            },
          }}
        >
          <NotificationDetails notification={focusedNotification} />
        </Modal>
      )}
      <Table
        dataSource={data}
        pagination={false}
        columns={columns}
        onRow={({ notification }) => ({
          style: { cursor: 'pointer' },
          onClick: () => setFocusedNotification(notification),
        })}
      />
      {!isLastPage && <div aria-hidden="true" ref={sentinelRef} />}
      {isLoadingMore && <Loader />}
    </Flex>
  )
}
