import { SearchOutlined } from '@ant-design/icons'
import { Empty, Flex } from 'antd'
import { deleteProgram, updateProgram } from 'api/databaseCalls'
import { Loader, PerkEmptyState, StepsBar } from 'components'
import { NO_SIDEBAR_PAGE_PADDING_Y } from 'constants/layout'
import { NEW_REWARD_PARAM } from 'constants/params'
import {
  CHECKOUT,
  DEFAULT_ROUTES,
  EDIT,
  IMAGE,
  OCCASION,
  RECIPIENT,
  REVIEW,
} from 'constants/routes'
import {
  ProgramContext,
  RewardStepGuideContext,
  TemplateContext,
} from 'context'
import { Pane } from 'evergreen-ui'
import { ProgramStatus, Program_DraftDataField } from 'gen/perkup/v1/program_pb'
import { useCreateDraftProgram, useListenToProgram } from 'hooks'
import useIds from 'hooks/useIds'
import NoMatch404 from 'pages/NoMatch404'
import { useOrgTemplate } from 'pages/Templates/hooks/useOrgTemplate'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router'
import { RewardStepGuide } from 'types/Rewards'
import { getCurrentStepIndex, getRewardStepsFromTemplate } from 'utils'
import { EditReward } from './EditReward'
import NewRewardCheckout from './NewRewardCheckout'
import { Occasion } from './Occasion'
import { Recipients } from './Recipients'
import { Review } from './Review'
import { RewardImage } from './RewardImage'
import { getRewardStepFromPathname, parseDraftData } from './utils/programs'

function NewRewardLayout() {
  const program = useContext(ProgramContext)
  const navigate = useNavigate()
  const { orgId } = useIds()
  const { steps, currentStepIndex } = useContext(RewardStepGuideContext)
  const [isSendingReward, setIsSendingReward] = useState(false)

  const rewardStepItems = steps.map(item => {
    const navigationPath = `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${item.path}`
    return {
      key: item.title,
      title: item.title,
      onClick: () => navigate(navigationPath),
    }
  })

  const handleClose = () => {
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
    if (!program?.occasion) {
      deleteProgram({ orgId, programId: program?.id })
    }
  }

  const handleGoBack = () => {
    // Handle going back to previous step if not on first step
    if (currentStepIndex !== 0) {
      const previousStep = steps[currentStepIndex - 1]
      navigate(
        `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${previousStep.path}`
      )
      return
    }

    // If on first step and hasn't edited, delete program
    if (!program?.occasion) {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
      deleteProgram({ orgId, programId: program?.id })
    } else if (window.location.pathname.includes(CHECKOUT)) {
      navigate(
        `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${REVIEW}`
      )
    } else {
      navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT)
    }
  }

  return (
    <Pane
      paddingX={64}
      paddingY={NO_SIDEBAR_PAGE_PADDING_Y}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={48}
    >
      <StepsBar
        showSteps={currentStepIndex > 0}
        currentStep={currentStepIndex}
        stepItems={rewardStepItems}
        handleClose={handleClose}
        handleGoBack={handleGoBack}
      />
      <Pane width="100%" maxWidth={1536}>
        {program?.status === ProgramStatus.draft || isSendingReward ? (
          <Outlet context={{ setIsSendingReward, isSendingReward }} />
        ) : (
          <Flex align="center" style={{ height: '60vh' }}>
            <PerkEmptyState
              iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
              header="This reward has already been sent"
              description="To make changes, view the reward."
              ctaProps={{
                onClick: () =>
                  navigate(
                    `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${program.id}`
                  ),
                children: 'View reward',
                type: 'primary',
              }}
            />
          </Flex>
        )}
      </Pane>
    </Pane>
  )
}

function RewardStepGuideContextWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  const template = useContext(TemplateContext)

  const filteredRewardSteps = getRewardStepsFromTemplate(template)

  const currentStepIndex = getCurrentStepIndex({ steps: filteredRewardSteps })

  const guide: RewardStepGuide = useMemo(
    () => ({
      steps: filteredRewardSteps,
      currentStepIndex,
    }),
    [filteredRewardSteps, currentStepIndex]
  )

  return (
    <RewardStepGuideContext.Provider value={guide}>
      {children}
    </RewardStepGuideContext.Provider>
  )
}

/**
 * Renderless component that updates the draft program data when the user navigates to a different step
 */
function DraftProgramUpdater() {
  const { orgId } = useIds()
  const program = useContext(ProgramContext)
  const { pathname } = window.location

  useEffect(() => {
    const isActivated = program?.status === ProgramStatus.active
    if (!program.id || !program || isActivated) return
    const rewardStep = getRewardStepFromPathname({
      pathname,
    })

    const parsedData = parseDraftData({ program })

    if (rewardStep === undefined || rewardStep === parsedData?.rewardStep) {
      return
    }

    const updatedDraftData = new Program_DraftDataField({
      ...parsedData,
      rewardStep,
    }).toJsonString()

    updateProgram({
      orgId,
      programId: program.id,
      merge: true,
      program: { draftData: updatedDraftData },
    })
  }, [orgId, pathname, program])

  return null
}

function NewRewardForm() {
  const { programId } = useParams()
  const navigate = useNavigate()

  const { program, hasLoaded } = useListenToProgram({ programId })

  const { template, isLoading: isLoadingTemplate } = useOrgTemplate({
    templateId: program?.templateId,
  })

  if (!hasLoaded || isLoadingTemplate) return <Loader />

  if (!program)
    return (
      <Flex align="center" style={{ height: '60vh' }}>
        <PerkEmptyState
          iconNode={Empty.PRESENTED_IMAGE_SIMPLE}
          header="No program found"
          description={"We couldn't find the program you were looking for."}
          ctaProps={{
            type: 'primary',
            children: 'Go home',
            onClick: () => navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.SPOT),
          }}
        />
      </Flex>
    )

  return (
    <ProgramContext.Provider value={program}>
      <TemplateContext.Provider value={template}>
        <DraftProgramUpdater />
        <RewardStepGuideContextWrapper>
          <Routes>
            <Route element={<NewRewardLayout />}>
              <Route path={`${OCCASION}/*`} element={<Occasion />} />
              <Route path={IMAGE} element={<RewardImage />} />
              <Route path={`${EDIT}/*`} element={<EditReward />} />
              <Route path={`${RECIPIENT}`} element={<Recipients />} />
              <Route path={`${REVIEW}`} element={<Review />} />
              <Route path={`/${CHECKOUT}`} element={<NewRewardCheckout />} />
              <Route path="*" element={<NoMatch404 hasFooter={false} />} />
            </Route>
          </Routes>
        </RewardStepGuideContextWrapper>
      </TemplateContext.Provider>
    </ProgramContext.Provider>
  )
}

export default function NewReward() {
  const { isCreatingDraftProgram } = useCreateDraftProgram()

  if (isCreatingDraftProgram) return <Loader />

  return (
    <Routes>
      <Route
        index
        path={`/:${NEW_REWARD_PARAM}/*`}
        element={<NewRewardForm />}
      />
    </Routes>
  )
}
