import Table, { ColumnsType } from 'antd/es/table'
import { FetchTinyBirdDataByOrgId } from 'api/tinybirdCalls/reads'
import { Loader, UserAvatar } from 'components'
import { tablePaginationSettings } from 'constants/antdesign'
import { TOP_RECIPIENTS_URL } from 'constants/tinybird'
import { Pane, Strong, Text } from 'evergreen-ui'
import { useDefaultOrgColors } from 'hooks'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'
import { TopRecipientsData } from 'types/TinyBird'
import {
  COLUMN_MAX_HEIGHT,
  COLUMN_MIN_HEIGHT,
  COLUMN_MIN_WIDTH,
  COLUMN_WIDTH,
} from '../constants/insights'
import { NoData } from './NoData'

interface TopRecipientsFormattedData extends TopRecipientsData {
  rank: number
  defaultColor: string
}

const columns: ColumnsType<TopRecipientsFormattedData> = [
  {
    title: '',
    dataIndex: 'rank',
    key: 'rank',
    render: rank => <Text>{rank}</Text>,
  },
  {
    title: 'Name',
    dataIndex: 'firstName',
    key: 'name',
    render: (firstName, record) => {
      const recipientName = `${firstName} ${record.lastName}`
      return (
        <Pane display="flex" gap={16} alignItems="center">
          <UserAvatar
            email={record.email}
            name={isEmpty(recipientName.trim()) ? record.email : recipientName}
            profilePicture={record.profilePicture}
            pfpSize={40}
          />
          <Pane display="flex" flexDirection="column" gap={8}>
            <Text>{recipientName}</Text>
            <Text color={record.defaultColor}>{record.email}</Text>
          </Pane>
        </Pane>
      )
    },
  },
  {
    title: 'Rewards received',
    key: 'rewardsReceived',
    dataIndex: 'rewardsReceived',
    render: rewardsReceived => {
      return <Text>{rewardsReceived}</Text>
    },
  },
]

export function TopRecipients() {
  const { defaultColor } = useDefaultOrgColors()
  const { orgId } = useIds()
  const [topRecipients, setTopRecipients] = useState<TopRecipientsData[]>()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    FetchTinyBirdDataByOrgId({
      orgId,
      cb: setTopRecipients,
      dataTitle: 'top recipients',
      baseUrl: TOP_RECIPIENTS_URL,
    }).finally(() => setIsLoading(false))
  }, [orgId])

  const formattedData = topRecipients?.map((recipient, index) => {
    return {
      ...recipient,
      rank: index + 1,
      defaultColor,
    }
  })

  const hasData = !isLoading && !isEmpty(formattedData)
  const hasNoData = !isLoading && isEmpty(formattedData)

  return (
    <Pane
      maxWidth={COLUMN_WIDTH}
      minWidth={COLUMN_MIN_WIDTH}
      maxHeight={COLUMN_MAX_HEIGHT}
      minHeight={COLUMN_MIN_HEIGHT}
      border="muted"
      display="flex"
      flexDirection="column"
      borderRadius={8}
      paddingY={16}
      gap={16}
    >
      <Pane display="flex" flexDirection="column" paddingX={32} gap={16}>
        <Pane display="flex" flexDirection="column" gap={4}>
          <Strong>Top recipients</Strong>
          <Text size={300} color="muted">
            Last 30 days
          </Text>
        </Pane>
        {hasNoData && <NoData />}
      </Pane>
      {isLoading && <Loader />}

      {hasData && (
        <Pane overflowY="auto">
          <Table
            columns={columns}
            dataSource={formattedData}
            pagination={tablePaginationSettings}
          />
        </Pane>
      )}
    </Pane>
  )
}
