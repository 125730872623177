// A card that shows each swag product and its colors and sizes
import { Flex, Skeleton } from 'antd'
import { ProductThumbnail } from 'components/ProductVariants'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  COLLECTION_IDS,
} from 'constants/algolia'
import { Text } from 'evergreen-ui'
import { ProductVariant_SourceType } from 'gen/perkup/v1/product_variant_pb'
import { useProductVariantSearchClient } from 'hooks'
import useInfiniteProductVariants from 'hooks/productVariants/use-infinite-product-variants'
import { Configure, InstantSearch } from 'react-instantsearch'
import { makePlural } from 'utils'

function ThumbnailsSkeleton({ length }: { length: number }) {
  return (
    <>
      {Array.from(Array(length).keys()).map(id => (
        <Skeleton.Input
          style={{
            height: 48,
            width: '100%',
          }}
          key={id}
          active
        />
      ))}
    </>
  )
}

function InfiniteProductsList() {
  const { productVariants, sentinelRef, totalProductVariantCount, status } =
    useInfiniteProductVariants()

  return (
    <Flex vertical gap={12}>
      {status !== 'idle' && (
        <>
          <Skeleton.Input
            style={{
              height: 22,
            }}
          />
          <Flex
            vertical
            gap={4}
            style={{
              maxHeight: 336,
              overflowY: 'auto',
              padding: '0 24px',
            }}
          >
            <ThumbnailsSkeleton length={3} />
          </Flex>
        </>
      )}
      {status === 'idle' && (
        <>
          <Text color="muted">
            {makePlural('product', totalProductVariantCount, true)}
          </Text>
          <Flex
            vertical
            gap={4}
            style={{
              maxHeight: 336,
              overflowY: 'auto',
              padding: '0 24px',
            }}
          >
            {productVariants.map(productVariant => (
              <ProductThumbnail key={productVariant.id}>
                <Flex
                  gap={18}
                  align="center"
                  style={{
                    width: '100%',
                  }}
                >
                  <ProductThumbnail.Image imageUrl={productVariant.imageUrl} />

                  <Flex vertical gap={4}>
                    <ProductThumbnail.Name>
                      {productVariant.productName}
                    </ProductThumbnail.Name>

                    <ProductThumbnail.ShippingCountries
                      iso3ShippingCountries={productVariant.shippingCountries}
                    />
                  </Flex>

                  <Flex align="center" justify="end" flex={1} gap={24}>
                    <Flex vertical gap={4}>
                      <ProductThumbnail.Price
                        alignSelf="end"
                        price={productVariant.price}
                      />
                      {productVariant.sourceType ===
                        ProductVariant_SourceType.fullPrepaid && (
                        <ProductThumbnail.Badge color="green">
                          Prepaid
                        </ProductThumbnail.Badge>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </ProductThumbnail>
            ))}
          </Flex>
        </>
      )}

      <div ref={sentinelRef} />
    </Flex>
  )
}

export function AlgoliaBrowseCollectionProducts({
  productCollectionId,
}: {
  productCollectionId: string
}) {
  const { searchClient } = useProductVariantSearchClient()

  return (
    <InstantSearch
      indexName={ALGOLIA_PRODUCT_VARIANTS_INDEX}
      searchClient={searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure
        filters={`${COLLECTION_IDS}:${productCollectionId}`}
        hitsPerPage={30}
      />

      <InfiniteProductsList />
    </InstantSearch>
  )
}
