import { updateOrgTemplate } from 'api/databaseCalls/writes/templates'
import { Loader } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { toaster } from 'evergreen-ui'
import { Template } from 'gen/perkup/v1/org_template_pb'
import useIds from 'hooks/useIds'
import { useNavigate, useParams } from 'react-router-dom'
import TemplateForm from './components/TemplateForm'
import { useOrgTemplate } from './hooks/useOrgTemplate'

export function ExistingTemplate() {
  const navigate = useNavigate()
  const { orgId } = useIds()
  const { templateId } = useParams()
  const { template, isLoading } = useOrgTemplate({ templateId })
  if (isLoading) return <Loader />

  if (!template) {
    return null
  }

  const handleUpdateTemplate = async (template: Template) => {
    await updateOrgTemplate({ template, orgId })
    toaster.success('Successfully updated template')
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.TEMPLATES.ROOT)
  }

  return (
    <TemplateForm
      defaultTemplate={template}
      onConfirmTemplate={handleUpdateTemplate}
      isEditMode
    />
  )
}
