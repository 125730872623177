import { CloseOutlined, SendOutlined } from '@ant-design/icons'
import { AlertProps, Button } from 'antd'
import { AccountBalanceForm, FooterWithCTA } from 'components'
import { PerkUpLink } from 'components/Typography/PerkUpLink'
import { NO_SIDEBAR_PAGE_PADDING_Y } from 'constants/layout'
import { DEFAULT_DIRECT_MAIL_ORDER_TITLE } from 'constants/rewards'
import { DEFAULT_ROUTES, GIFTS, SWAG } from 'constants/routes'
import { IndividualContext, OrgContext, UserContext } from 'context'
import { Pane, Text, toaster } from 'evergreen-ui'
import { Account } from 'gen/perkup/v1/account_pb'
import { Item } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useSendableAccounts, useShopifyCalcDraftOrder } from 'hooks'
import { useItemsToVariants } from 'hooks/productVariants/useProgramItemsToVariants'
import { isEmpty, round } from 'lodash-es'
import NoMatch404 from 'pages/NoMatch404'
import { useContext, useMemo, useState } from 'react'
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router'
import { placeProductVariantsOrder } from 'services'
import { SelectedItems } from 'types/Items'
import { getCountryNameFromIso2 } from 'utils'
import { flattenItems } from 'utils/items'
import { invalidShippingCountryVariants } from 'utils/productVariant'
import { v4 as uuid } from 'uuid'
import { OrderGiftsPage } from './order-gifts-page'
import { OrderSwagPage } from './order-swag-page'

function OrderProductsLayout({
  onOrderProductsSubmit,
  CTADisabled,
  isLoadingSubmit,
  selectedAccount,
  setSelectedAccount,
}: {
  onOrderProductsSubmit: () => void
  CTADisabled: boolean
  isLoadingSubmit: boolean
  selectedAccount?: Account
  setSelectedAccount: (account?: Account) => void
}) {
  const navigate = useNavigate()

  return (
    <Pane
      paddingX={64}
      paddingY={NO_SIDEBAR_PAGE_PADDING_Y}
      maxWidth="100%"
      display="flex"
      flexDirection="column"
    >
      <Button
        type="text"
        icon={<CloseOutlined />}
        style={{ alignSelf: 'end' }}
        onClick={() => navigate(-1)}
      />
      <Outlet />
      <Pane marginBottom={64} />
      <FooterWithCTA
        ctaText="Order"
        ctaIcon={<SendOutlined />}
        onCTAClick={onOrderProductsSubmit}
        disabled={CTADisabled}
        isLoading={isLoadingSubmit}
      >
        <AccountBalanceForm
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          size="large"
        />
      </FooterWithCTA>
    </Pane>
  )
}

export default function OrderProducts() {
  const individual = useContext(IndividualContext)
  const org = useContext(OrgContext)
  const user = useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const formatDefaultItems = (state: { items?: Item[] }) => {
    // Format items from location state to SelectedItems
    if (!state?.items) return new Map()
    const selectedItems: SelectedItems = new Map()
    state.items.forEach(item => {
      selectedItems.set(uuid(), [item])
    })
    return selectedItems
  }
  const { sendableAccounts, hasLoadedAccounts } = useSendableAccounts()

  const [selectedItems, setSelectedItems] = useState<SelectedItems>(
    formatDefaultItems(state)
  )
  const [shippingAddress, setShippingAddress] = useState<ShippingAddress>()
  const [selectedAccount, setSelectedAccount] = useState<Account>()
  const [orderTitle, setOrderTitle] = useState(DEFAULT_DIRECT_MAIL_ORDER_TITLE)
  const [internalMemo, setInternalMemo] = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const flattenedItems = useMemo(() => {
    return flattenItems({ items: selectedItems })
  }, [selectedItems])

  const { productVariants } = useItemsToVariants({ items: flattenedItems })

  const invalidCountryVariants = useMemo(() => {
    return invalidShippingCountryVariants({
      productVariants,
      shippingAddress,
    })
  }, [productVariants, shippingAddress])

  const { draftOrderCalculation } = useShopifyCalcDraftOrder({
    programItems: flattenedItems,
    address: shippingAddress,
  })

  const totalCost = draftOrderCalculation?.totalPrice
    ? round(Number(draftOrderCalculation?.totalPrice) * 100, 2)
    : undefined

  const noItemsSelected =
    isEmpty(flattenedItems) || !flattenedItems.some(item => item.quantity > 0)

  const insufficientFunds =
    !!selectedAccount && !!totalCost && totalCost > selectedAccount.balance

  const CTADisabled =
    noItemsSelected ||
    !shippingAddress ||
    !selectedAccount ||
    !orderTitle ||
    insufficientFunds

  const handleSendDirectMailReward = () => {
    // If order-swag and order-gifts logic starts to diverge, can move the handler & state down to the page level
    if (!shippingAddress) return
    setIsLoadingSubmit(true)
    placeProductVariantsOrder({
      shippingAddress,
      items: flattenedItems,
      user,
      org,
      individual,
      orderTitle,
      account: selectedAccount,
      internalMemo,
      draftOrderCalculation,
      orderTotal: totalCost,
    })
      .then(response => {
        if (response.status === 'error') {
          toaster.warning(response.message)
          return
        }
        toaster.success(response.message)

        const { addedProgramId } = response.data

        if (!addedProgramId) return

        navigate(
          `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${addedProgramId}`,
          {
            state: { confetti: true },
          }
        )
      })
      .finally(() => setIsLoadingSubmit(false))
  }

  const needsAccountAlert = hasLoadedAccounts && isEmpty(sendableAccounts)

  const getProductAlertProps = (): AlertProps | undefined => {
    // As soon as this helper starts to look at isGift or isSwag, move it down to the page level - for now helps reduce duplicate code
    if (!isEmpty(invalidCountryVariants))
      return {
        message: `The following products are not available to ship to ${
          getCountryNameFromIso2(shippingAddress?.country) || 'this address'
        }:`,
        description: invalidCountryVariants
          .map(pv => pv.productName)
          .join(', '),
      }
    if (needsAccountAlert)
      return {
        message: 'Active budget required',
        description: (
          <Text>
            To place an order,{' '}
            <PerkUpLink to={DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}>
              create a budget and add funds.
            </PerkUpLink>
          </Text>
        ),
      }

    if (insufficientFunds) {
      return {
        message: 'Insufficient funds',
        description: (
          <Text>
            The selected account does not have enough funds to place this order.
            Please select a different account or{' '}
            <PerkUpLink
              to={`${DEFAULT_ROUTES.ORGANIZATION.ACCOUNTS.ROOT}/${selectedAccount.id}`}
            >
              add funds
            </PerkUpLink>
          </Text>
        ),
      }
    }

    return undefined
  }
  const productAlertProps = getProductAlertProps()

  return (
    <Routes>
      <Route
        path="/"
        element={
          <OrderProductsLayout
            onOrderProductsSubmit={handleSendDirectMailReward}
            CTADisabled={CTADisabled}
            isLoadingSubmit={isLoadingSubmit}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        }
      >
        <Route
          path={SWAG}
          element={
            <OrderSwagPage
              totalCost={totalCost}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              shippingAddress={shippingAddress}
              setShippingAddress={setShippingAddress}
              setOrderTitle={setOrderTitle}
              setInternalMemo={setInternalMemo}
              productAlertProps={productAlertProps}
            />
          }
        />
        <Route
          path={GIFTS}
          element={
            <OrderGiftsPage
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              shippingAddress={shippingAddress}
              setShippingAddress={setShippingAddress}
              setOrderTitle={setOrderTitle}
              setInternalMemo={setInternalMemo}
              productAlertProps={productAlertProps}
            />
          }
        />
        <Route path="*" element={<NoMatch404 />} />
      </Route>
    </Routes>
  )
}
