/* eslint-disable react/function-component-definition */
import { IndividualContext } from 'context'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

export const IsAdmin = (BaseComponent: any) => {
  const Wrap = (props: any) => {
    const individual = useContext(IndividualContext)

    if (individual.role === Individual_Role.admin)
      return <BaseComponent {...props} />

    return <Navigate to="/" />
  }
  return Wrap
}
