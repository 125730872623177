import { Flex, Timeline, TimelineItemProps } from 'antd'
import { notificationStatusDetails } from 'constants/notifications'
import { Pane, Strong, Text } from 'evergreen-ui'
import {
  Notification,
  Notification_Status,
} from 'gen/perkup/v1/notification_pb'
import { compact, keys, sortBy } from 'lodash-es'
import { getDateTimeString } from 'utils'

const getNotificationTimelineItems = (
  notification: Notification
): TimelineItemProps[] => {
  const eventKeys = keys(notification?.events)
  const sortedKeys = sortBy(eventKeys, key =>
    notification?.events[key].createdAt?.toDate()
  ).reverse()

  return compact(
    sortedKeys.map(key => {
      const event = notification?.events[key]

      const eventStatusDetails =
        notificationStatusDetails[Notification_Status[event.status]]
      if (!eventStatusDetails) return undefined

      return {
        children: (
          <Flex vertical gap={8}>
            <Text>{eventStatusDetails.label}</Text>
            <Text>{getDateTimeString(event?.createdAt)}</Text>
          </Flex>
        ),
        dot: <eventStatusDetails.icon />,
        color: eventStatusDetails?.color,
      }
    })
  )
}

export function NotificationDetails({
  notification,
}: {
  notification: Notification
}) {
  const { to, from, subject, body } = notification?.message || {}

  return (
    <Flex gap={64} wrap="wrap">
      <Timeline items={getNotificationTimelineItems(notification)} />
      <Flex vertical gap={16}>
        <Flex gap={128}>
          {to && (
            <Flex vertical gap={4} wrap style={{ maxWidth: 400 }}>
              <Strong>To</Strong>
              <Text>{to}</Text>
            </Flex>
          )}
          {from && (
            <Flex vertical gap={4}>
              <Strong>From</Strong>
              <Text>{from}</Text>
            </Flex>
          )}
        </Flex>
        {subject && (
          <Flex vertical gap={4}>
            <Strong>Subject</Strong>
            <Text>{subject}</Text>
          </Flex>
        )}

        {body && (
          // Check with CD before removing this marginTop
          <Pane marginTop={-36} dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </Flex>
    </Flex>
  )
}
