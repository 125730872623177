import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { useCallback, useEffect, useState } from 'react'

/**
 *
 * Hook to use Embla Carousel API.
 * TODO: In future, as this hook becomes more needed, we can create some sort of compound carousel component with the hook baked in.
 */
export const useEmblaCarouselApi = ({
  onAfterSelectSlide,
}: {
  onAfterSelectSlide?: (index: number) => void
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      // loop: true,
      align: 'start',
      dragFree: true,
      containScroll: 'trimSnaps',
    },
    [WheelGesturesPlugin()]
  )
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const scrollTo = useCallback(
    (index: number) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onSelect = useCallback(
    (emblaApi: EmblaCarouselType) => {
      setPrevBtnDisabled(!emblaApi.canScrollPrev())
      setNextBtnDisabled(!emblaApi.canScrollNext())
      if (onAfterSelectSlide) {
        onAfterSelectSlide(emblaApi.selectedScrollSnap())
      }
    },
    [onAfterSelectSlide]
  )

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
    scrollTo,
    emblaRef,
  }
}
