import { Tag } from 'antd'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { Avatar, Heading, Pane, Paragraph } from 'evergreen-ui'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import useCountryDisplayName from 'hooks/useCountryDisplayName'
import { parsePhoneNumber } from 'utils'

function AddressHeading({
  hasBadge,
  isDefault,
  name,
}: {
  hasBadge?: boolean
  isDefault?: boolean
  name: string
}) {
  if (hasBadge) {
    return (
      <>
        {isDefault && (
          <Tag color="orange" style={{ marginBottom: 16 }}>
            Default
          </Tag>
        )}
        <Heading size={400}>{name}</Heading>
      </>
    )
  }

  return <Heading size={400}>{name}</Heading>
}

export function AddressDisplay({
  address,
  hasBadge,
  isDefault,
}: {
  address: ShippingAddress
  hasBadge?: boolean
  isDefault?: boolean
}) {
  const {
    name,
    line1,
    line2,
    city,
    state,
    postalCode,
    phoneNumber,
    country,
    localizationExtensions,
  } = address

  const addressLines = `${line1} ${line2 ?? ''}`.trim() // Address 2 might be undefined, so we don't want to render the string 'undefined'
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

  const countryFlag = findFlagUrlByIso2Code(country)
  const { countryDisplayName } = useCountryDisplayName({ iso2: country })
  const brazilCFP = localizationExtensions?.shippingCredentialBr

  return (
    <Pane marginBottom={8}>
      {name && (
        <AddressHeading name={name} isDefault={isDefault} hasBadge={hasBadge} />
      )}
      <Paragraph size={400}>{addressLines}</Paragraph>
      <Paragraph size={400}>
        {city}, {state} {postalCode}
      </Paragraph>
      <Pane display="flex">
        <Paragraph size={400}>{countryDisplayName}</Paragraph>
        <Avatar
          size={18}
          src={countryFlag}
          alignSelf="center"
          marginLeft={10}
        />
      </Pane>

      {parsedPhoneNumber && (
        <Paragraph size={400}>
          Phone number: {parsedPhoneNumber.formatNational()}
        </Paragraph>
      )}
      {brazilCFP && <Paragraph size={400}>CPF: {brazilCFP}</Paragraph>}
    </Pane>
  )
}
