/* eslint-disable react/function-component-definition */
import { IndividualContext } from 'context'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'

export const IsManager = (BaseComponent: any) => {
  const Wrap = (props: any) => {
    const individual = useContext(IndividualContext)

    const isAdmin = individual.role === Individual_Role.admin
    const isManager = individual.role === Individual_Role.manager

    return isAdmin || isManager ? (
      <BaseComponent {...props} />
    ) : (
      <Navigate to="/" />
    )
  }
  return Wrap
}
