import { Loader, PerkEmptyState } from 'components'
import { DEFAULT_ROUTES } from 'constants/routes'
import { ProductCollectionDetails } from 'features'
import { useCollectionById } from 'hooks'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Mode } from 'types/edit'

interface CollectionPageState {
  state: {
    mode?: Mode
  }
}

export function OrgSwagCollectionPage() {
  const { collectionId } = useParams()
  const navigate = useNavigate()
  const location = useLocation() as CollectionPageState

  const { collection, hasLoaded } = useCollectionById({ id: collectionId })

  if (!hasLoaded) return <Loader />

  if (!collection || !collectionId) {
    return (
      <PerkEmptyState
        header="Collection not found"
        description="This collection does not exist or has been deleted."
        ctaProps={{
          onClick: () => navigate(DEFAULT_ROUTES.ORGANIZATION.SWAG.ROOT),
          type: 'primary',
          children: 'Go back',
        }}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{collection.name} | Swag</title>
      </Helmet>

      <main style={{ margin: -32 }}>
        <ProductCollectionDetails
          collection={collection}
          intitialMode={location?.state?.mode}
        />
      </main>
    </>
  )
}
