import { Button, Flex, Tag } from 'antd'
import { COLLECTION_ID_TO_NAME } from 'constants/productCollections'
import { useDefaultOrgColors } from 'hooks'
import flatten from 'lodash-es/flatten'
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch'
import { cleanCategoryLabel } from 'utils/Algolia'

type Item = ReturnType<typeof useCurrentRefinements>['items'][0]
type Refinement = Item['refinements'][0]

function itemsToRefinements(items: Item[]) {
  return flatten(
    items
      .filter(i => i.refinements.filter(r => r.label).length)
      .filter(item => !['shippingCountries'].includes(item.attribute))
      .map(item => item.refinements)
  ).filter(r => r.label !== '≥ 0')
}

function parseRefinement(refinement: Refinement) {
  switch (refinement.attribute) {
    case 'amount':
      return `${
        refinement.operator?.includes('>') ? 'Over' : 'Under'
      } $${Number(refinement.value) / 100}`

    case 'collectionIds':
      return COLLECTION_ID_TO_NAME[refinement.label] ?? refinement.label

    case 'categories.lvl0':
      return cleanCategoryLabel(refinement.label)

    default:
      return refinement.label
  }
}

export function AlgoliaCurrentRefinements() {
  const { backgroundColor, defaultColor } = useDefaultOrgColors()
  const { items, refine } = useCurrentRefinements()

  const { refine: clearRefinements } = useClearRefinements({
    excludedAttributes: ['shippingCountries'],
  })

  const refinements = itemsToRefinements(items)

  if (!refinements.length) return null

  return (
    <Flex justify="space-between">
      <Flex gap={4} wrap flex={1}>
        {refinements.map(refinement => (
          <Tag
            key={refinement.label}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '4px 8px',
              borderRadius: 16,
              backgroundColor,
              color: defaultColor,
              fontSize: 14,
            }}
            bordered={false}
            closable
            color="processing"
            onClose={() => refine(refinement)}
          >
            {parseRefinement(refinement)}
          </Tag>
        ))}
      </Flex>

      <Button
        style={{ padding: 0, color: defaultColor }}
        type="link"
        onClick={() => clearRefinements()}
      >
        Clear all filters
      </Button>
    </Flex>
  )
}
