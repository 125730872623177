import { GetIndividualById, updateIndividualRole } from 'api/databaseCalls'
import { IndividualsSelection } from 'components'
import {
  ALGOLIA_INDIVIDUALS_INDEX,
  STATUS_NONE_OR_INVITED_OR_ACTIVE_OR_BLOCKED,
} from 'constants/algolia'
import { nonRemovedIndividualStatuses } from 'constants/individuals'
import { OrgContext } from 'context'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { cloneDeep, difference } from 'lodash-es'
import { useContext } from 'react'
import { InstantSearch } from 'react-instantsearch'
import { individualsSearchClient } from 'services/algolia'
import { Permissions } from 'types/Permissions'

export function PermissionsIndividualSelectWrapper({
  currentPermissions,
  currentIndividualIds,
  onPermissionsChange,
}: {
  currentPermissions: Permissions
  currentIndividualIds: string[]
  onPermissionsChange: (newPermissions: Permissions) => void
}) {
  const { id: orgId } = useContext(OrgContext)
  const searchClient = individualsSearchClient(orgId)

  const handleRemovePermissionsFromIndividual = (individualId: string) => {
    if (!currentPermissions[individualId]) return
    const newPermissions = cloneDeep(currentPermissions)
    delete newPermissions[individualId]
    onPermissionsChange(newPermissions)
  }

  const handleAddPermissionsToIndividual = async (individualId: string) => {
    const individual = await GetIndividualById({ orgId, individualId })

    if (!individual) return

    const newPermissions = cloneDeep(currentPermissions)
    Object.assign(newPermissions, { [individualId]: 1 }) // Full access
    onPermissionsChange(newPermissions)

    const upgradeToManager =
      (!individual.role || individual.role === Individual_Role.member) &&
      currentPermissions[individualId]

    if (upgradeToManager)
      updateIndividualRole({
        orgId,
        individualId: individual.id,
        role: Individual_Role.manager,
      })
  }

  const handleIndividualClick = (individualIds: string[]) => {
    const addedId = difference(individualIds, currentIndividualIds)[0]
    const removedId = difference(currentIndividualIds, individualIds)[0]

    if (removedId) {
      handleRemovePermissionsFromIndividual(removedId)
    } else {
      handleAddPermissionsToIndividual(addedId)
    }
  }

  return (
    <InstantSearch
      indexName={ALGOLIA_INDIVIDUALS_INDEX}
      searchClient={searchClient}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <IndividualsSelection
        width="100%"
        selectedIndividualIds={currentIndividualIds}
        onIndividualClick={individualData => {
          handleIndividualClick(individualData?.selectedIndividualIds || [])
        }}
        showAdvancedSelectOptions={false}
        defaultLabel="Select or search users to approve access permission"
        filters={STATUS_NONE_OR_INVITED_OR_ACTIVE_OR_BLOCKED}
        eligibleStatuses={nonRemovedIndividualStatuses}
      />
    </InstantSearch>
  )
}
