import { Select } from 'antd'
import { ListListsByOrgId } from 'api/databaseCalls'
import { IndividualClickData } from 'components'
import { eligibleIndividualStatuses } from 'constants/individuals'
import { Pane, Strong, toaster } from 'evergreen-ui'
import { OrgList } from 'gen/perkup/v1/organization_pb'
import { Program_RecipientFilters } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import isEmpty from 'lodash-es/isEmpty'
import { useEffect, useState } from 'react'
import { getIndividualIds } from 'services/individuals'
import { formatLabelSelectOptions } from 'utils/labels'

export default function LabelSelection({
  onLabelSelect,
}: {
  onLabelSelect: ({
    selectedIndividualIds,
    selectionFilters,
  }: IndividualClickData) => void
}) {
  const [orgLists, setOrgLists] = useState<OrgList[]>([])
  const { orgId } = useIds()

  useEffect(() => {
    if (!orgId) return
    ListListsByOrgId({ orgId }).then(labels => setOrgLists(labels))
  }, [orgId])

  const handleSelectLabel = async ({
    value,
    key,
  }: {
    value: string
    key: string
  }) => {
    const individualIdsResponse = await getIndividualIds({
      labels: { [key]: value },
      statuses: eligibleIndividualStatuses,
    })
    const individualIds = individualIdsResponse.ids
    if (isEmpty(individualIds)) {
      toaster.warning('This label is empty, please select another label')
      return
    }

    const selectionFilters = new Program_RecipientFilters({
      label: JSON.stringify({ [key]: value }),
      allSelected: false,
    })

    // Use the callback
    onLabelSelect({
      selectedIndividualIds: individualIds,
      selectionFilters,
    })

    toaster.success(
      `${individualIds?.length} ${
        individualIds?.length === 1 ? 'person' : 'people'
      } selected`
    )
  }

  return (
    <Pane>
      <Pane marginBottom={8}>
        <Strong>Select recipients by label</Strong>
      </Pane>
      <Select
        key="Label"
        placeholder="Select a label..."
        showSearch
        style={{ minWidth: '190px' }}
        options={formatLabelSelectOptions({ orgLists })}
        onSelect={value => {
          const labelValue = value.substring(
            value.indexOf(':') + 2,
            value.length - 1
          )
          const labelKey = value.substring(0, value.indexOf(':'))
          handleSelectLabel({ value: labelValue, key: labelKey })
        }}
      />
    </Pane>
  )
}
