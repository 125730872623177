import { captureException } from '@sentry/react'
import { ORG_PROGRAM_TYPES } from 'constants/programs'
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { Program, ProgramStatus } from 'gen/perkup/v1/program_pb'
import sum from 'lodash-es/sum'
import { storedId } from 'utils/firestore'
import { toSentry } from 'utils/sentry'

export async function ListActiveProgramsForOrgBalance({
  orgId,
  accountId,
}: {
  orgId: string
  accountId: string
}) {
  try {
    const db = getFirestore()
    const colRef = collection(
      db,
      `organizations/${orgId}/programs`
    ).withConverter(storedId(Program))
    const q = query(
      colRef,
      where('type', 'in', ORG_PROGRAM_TYPES),
      where('status', '==', ProgramStatus[ProgramStatus.active]),
      where('accountId', '==', accountId)
    )
    return await getDocs(q).then(query => {
      const programs = query.docs.map(doc => doc.data())
      return programs.filter(program => !program.paymentIntentId)
    })
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        ListActiveProgramsForOrgBalance: { orgId },
      },
      tags: { orgId },
    })
  }
  return []
}

export async function GetTotalsOfAllActiveProgram({
  orgId,
  accountId,
}: {
  orgId: string
  accountId: string
}) {
  try {
    const programs =
      (await ListActiveProgramsForOrgBalance({ orgId, accountId })) || []
    const totalBudget = sum(programs.map(program => program.totalBudget))
    const totalSpent = sum(programs.map(program => program.totalSpent))
    return { totalBudget, totalSpent }
  } catch (error) {
    console.error(error)
    captureException(toSentry(error), {
      contexts: {
        GetTotalBudgetOfAllActiveProgram: { orgId },
      },
      tags: { orgId },
    })
  }
}
