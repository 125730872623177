import { ListProductVariantsByIds } from 'api/databaseCalls'
import { toaster } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { isEmpty, isEqual } from 'lodash-es'
import { useEffect, useState } from 'react'
import {
  getProductVariantsFromSessionStorage,
  saveProductVariantsToSessionStorage,
} from 'utils/productVariant'

export function useProductVariantsFromSessionStorage({
  programId,
}: {
  programId?: string
}) {
  const [addedVariants, setAddedVariants] = useState<ProductVariant[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const parsedIds = getProductVariantsFromSessionStorage({ programId })
    if (isEmpty(parsedIds)) return
    setIsLoading(true)
    ListProductVariantsByIds({ ids: parsedIds })
      .then(savedProductVariants => {
        if (!savedProductVariants) return
        const inStockVariants = savedProductVariants.filter(
          pv => pv?.isAvailable
        )

        if (!isEqual(inStockVariants, savedProductVariants)) {
          saveProductVariantsToSessionStorage({
            productVariants: inStockVariants,
            programId,
          })
          toaster.warning(
            'Some products you selected are now out of stock. Please select new products.'
          )
        }

        setAddedVariants(inStockVariants)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [programId])

  return { addedVariants, setAddedVariants, isLoading }
}
