import { Modal } from 'antd'
import {
  ALGOLIA_PRODUCT_VARIANTS_INDEX,
  INITIAL_AMOUNT_FILTER,
} from 'constants/algolia'
import { MIN_PROGRAM_BUDGET_AMOUNT } from 'constants/money'
import {
  COLLECTION_IDS,
  FEATURED_COLLECTION_ID,
} from 'constants/productCollections'
import { AlgoliaBrowseCollections, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useState } from 'react'
import { UseNumericMenuProps } from 'react-instantsearch'
import { insertFieldIf, numToDollars } from 'utils'
import { getAlgoliaShippingCountriesFilter } from 'utils/Algolia'

export function SelectProductCollection({
  currentCollectionId,
  currentBudget,
  onSelectProductCollection,
}: {
  currentCollectionId?: string
  currentBudget?: number
  onSelectProductCollection: (collectionId: string, budget: number) => void
}) {
  const [productVariantToShow, setProductVariantToShow] =
    useState<ProductVariant>()

  const defaultSelectedCollectionId =
    currentCollectionId &&
    COLLECTION_IDS.includes(currentCollectionId) &&
    currentCollectionId !== FEATURED_COLLECTION_ID
      ? currentCollectionId
      : ''

  const defaultSelectedBudget: UseNumericMenuProps['items'][0] =
    currentBudget && currentBudget >= MIN_PROGRAM_BUDGET_AMOUNT
      ? {
          label: numToDollars(currentBudget, 0) || '',
          ...insertFieldIf(currentBudget > MIN_PROGRAM_BUDGET_AMOUNT, {
            start: currentBudget - MIN_PROGRAM_BUDGET_AMOUNT,
          }),
          end: currentBudget,
        }
      : undefined

  const defaultAmountFilter = defaultSelectedBudget
    ? `${defaultSelectedBudget.start ?? 0}:${defaultSelectedBudget.end}`
    : INITIAL_AMOUNT_FILTER

  return (
    <>
      <AlgoliaBrowseCollections
        defaultSelectedBudget={defaultSelectedBudget}
        onProductClick={setProductVariantToShow}
        onConfirmProductCollection={onSelectProductCollection}
        initialUiState={{
          [ALGOLIA_PRODUCT_VARIANTS_INDEX]: {
            menu: {
              collectionIds: defaultSelectedCollectionId,
              shippingCountries: getAlgoliaShippingCountriesFilter(),
            },
            numericMenu: {
              amount: defaultAmountFilter,
            },
          },
        }}
      />
      <Modal
        width={1280}
        centered
        title="‎"
        open={!!productVariantToShow}
        footer={false}
        onCancel={() => setProductVariantToShow(undefined)}
      >
        {productVariantToShow && (
          <ProductDetails
            key={productVariantToShow.id}
            productVariant={productVariantToShow}
            showPrice
            showShipping
            showShippingCountries
            primaryCtaProps={{
              children: 'Select gift',
            }}
            withAmountInUsd
          />
        )}
      </Modal>
    </>
  )
}
